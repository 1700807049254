export const constantsTypes = {
    VIEW_MODE : "VIEW_MODE",
    TRAILER_MODE:"TRAILER_MODE",
    ADD_TO_CART:'ADD_TO_CART',
    ADD_FOOD_TO_CART:'ADD_FOOD_TO_CART',
    UPDATE_FOOD_QUANTITY: 'UPDATE_FOOD_QUANTITY',
    SET_USER_SELECTED_FOOD: 'SET_USER_SELECTED_FOOD',
    REMOVE_FOOD_TO_CART:'REMOVE_FOOD_TO_CART',
    FOOD_VOUCHER:'FOOD_VOUCHER',
    REMOVE_FROM_CART: 'REMOVE_FROM_CART',
    LOGIN_VIEW:"LOGIN_VIEW",
    MOVIE_DETAILS_VIEW:"MOVIE_DETAILS_VIEW",
    MOBILE_FILTER:'MOBILE_FILTER',
    NOW_SHOWING_MOVIE:'NOW_SHOWING_MOVIE',
    COMING_SOON_MOVIE:'COMING_SOON_MOVIE',
    GRAND_TOTAL:'GRAND_TOTAL',
    CITY_CHANGE:'CITY_CHANGE',
    ADD_ORDER: 'ADD_ORDER',
    UPDATE_ORDER_TIMES: 'UPDATE_ORDER_TIMES',
    MARK_ORDER_AS_VIEWED: 'MARK_ORDER_AS_VIEWED',
    UPDATE_ORDER_STATUS: 'UPDATE_ORDER_STATUS',
    FETCH_ORDER_STATUS_REQUEST: 'FETCH_ORDER_STATUS_REQUEST',
    FETCH_ORDER_STATUS_SUCCESS: 'FETCH_ORDER_STATUS_SUCCESS',
    FETCH_ORDER_STATUS_FAILURE: 'FETCH_ORDER_STATUS_FAILURE',
    TIME_VIEW_MODE:'TIME_VIEW_MODE'
}