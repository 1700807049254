import styled from "styled-components";

export const Li = styled.li`
background-color: ${(props) => props.theme.colors.bgColor};
color: ${(props) => props.theme.colors.txtColor};
    border-radius: 30px;
    cursor: pointer;
`

export const LoginButton = styled.button`
background-color: ${(props) => props.theme.colors.bgColorLight};
`;