import React, { useEffect, useState } from "react";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import RemoveCricle from "../../../assets/static-page/remove-cricle.svg";
import { DonateButton } from "../paymentTags";
import SweetAlert from "react-bootstrap-sweetalert";
import { applycoupon } from "src/services/CouponPayment";
import { InputText } from "primereact/inputtext";
import { ProceedBtn } from "src/views/get-food/getfoodTags";


const Mcoupon = ({ ticketSummary, sendDataForPayment,ViewMode }) => {
  const theme = localStorage.getItem("theme");
  const [numOfSeats, setTotalSeat] = useState(1);
  const [formValues, setFormValues] = useState([{ code: "" }]);
  const [cardnumber, setCardnumber] = useState(null);
  const [mblnumber, setMblnumber] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [outputData, setOutputData] = useState(null);
  const [showproccedbut, setShowproccedbut] = useState(false);  

  const [couponForm, setCouponForm] = useState([]);
  const [selectedCouponCode, setSelectedCouponCode] = useState(null);
  // console.log("totalCoupons", couponForm, numOfSeats);

  useEffect(() => {
    if (ticketSummary && ticketSummary.orderTicket) {
      const ticketNum = ticketSummary.orderTicket.numOfSeats;
      setTotalSeat(ticketNum);
      const totalValidCoupons = Array.from({ length: ticketNum }, (_, idx) => ({
        id: `couponCode${idx + 1}`,
        code: "",
        applied: false,
      }));
      setCouponForm(totalValidCoupons);
    }
  }, [ticketSummary]);

  let handleChange = (i, e) => {
    // console.log(i, e);
    let newFormValues = [...formValues];
    newFormValues[i]["code"] = e.target.value
      .replace(/[^a-zA-Z0-9]/g, "")
      .toUpperCase();
    setFormValues(newFormValues);
  };

  const handleCouponFormChange = (idx, evnt) => {
    // console.log("handleCouponFormChange", idx);
    let newFormValues = [...couponForm];
    newFormValues[idx]["code"] = evnt.target.value
      .replace(/[^a-zA-Z0-9]/g, "")
      .toUpperCase();
    setCouponForm(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { code: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    if (newFormValues.length > 1) {
      newFormValues.splice(i, 1);
      setFormValues(newFormValues);
    }
  };

  let handleProccedbtn = () => {
  setShowproccedbut(true);

  }

  let handleSubmit = () => {
    let arrayval = [];
    let sendVal;
    formValues.map((element, index) => {
      arrayval.push(element.code);
    });
    sendVal = arrayval.toString();
    if (!sendVal) {
      setErrorMsg("Please enter voucher code");
      setShowAlert(true);
      return "";
    }
    getApply(sendVal);
  };

  const handleCouponFormSubmit = (idx, codeval) => {
    getApply(idx, codeval);
  };

  const getApply = async (idx, couponval) => {
    if (!mblnumber) {
      setErrorMsg("Please enter last 5 digit mobile number");
      setShowAlert(true);
      return "";
    }
    if (!cardnumber) {
      setErrorMsg("Please enter last 4 digit card number");
      setShowAlert(true);
      return "";
    }
    setLoading(true);
    const postData = {
      ccode: localStorage.getItem("theaterId"),
      bookingId: localStorage.getItem("bookingid"),
      transId: localStorage.getItem("transid"),
      bookType: localStorage.getItem("booking_type")
        ? localStorage.getItem("booking_type")
        : "BOOKING",
      coupon: couponval,
      couponId: 0,
      couponType: "MCOUPON",
      qr: "NO",
      card: cardnumber,
      mobile: mblnumber,
    };
    applycoupon(postData)
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          setOutputData(dt);

          let newFormValues = [...couponForm];
          newFormValues[idx]["applied"] = true;
          setCouponForm(newFormValues);
        }
        console.log("dt", dt);
        dt.voucheName = "M-COUPON";
        sendDataForPayment(dt);
        localStorage.setItem("isOfferAppplied", true);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };
  // console.log('formValues', formValues)
  return (
    <>
      <SweetAlert
        error
        show={showAlert}
        title={errorMsg}
        onConfirm={() => {
          setShowAlert(false);
          // console.log('confirm')
          //navigate('/')
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnCssClass={
          theme === "inoxTheme"
            ? "confirm-btn-inox border-0"
            : "confirm-btn-pvr border-0"
        }
      >
        {errorMsg}
      </SweetAlert>
      <div className="">
        <div className="payment-form">
          <div className="paywithdebit">
            <div className="debit-card show-in-desktop-view">
              <h6>M-Coupon</h6>
            </div>
            <div className="to-paid">
              {/* <p>
                To be Paid:
                <span className="price-cross">₹8888</span>{" "}
                <span className="price-blod">₹8888</span>
              </p> */}
            </div>
          </div>
          <div className="payment-debitcard">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="payment-feild">
                    <span className="p-float-label">
                      <InputText
                        type="text"
                        maxlength="4"
                        id="cardnumber"
                        className="offer-control"
                        value={cardnumber || ""}
                        onChange={(e) =>
                          setCardnumber(
                            e.target.value
                              .replace(/ /g, "")
                              .replace(/[^0-9]/g, "")
                          )
                        }
                      />
                      <label htmlFor="cardnumber">
                        Last 4 digits of credit card
                      </label>
                    </span>
                    {/* <p>We promise to keep your voucher safe</p> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="payment-feild">
                    <span className="p-float-label">
                      <InputText
                        type="text"
                        maxlength="5"
                        id="mblnumber"
                        className="offer-control"
                        value={mblnumber || ""}
                        onChange={(e) =>
                          setMblnumber(
                            e.target.value
                              .replace(/ /g, "")
                              .replace(/[^0-9]/g, "")
                          )
                        }
                      />
                      <label htmlFor="mblnumber ssn_input">
                        Last 5 digits of mobile number
                      </label>
                    </span>
                    {/* <p>We promise to keep your voucher safe</p> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="noofcoupon">
                    <div className="coupon-no">
                      <h6>No. of Coupons: {numOfSeats}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  {couponForm.map((item, idx) => (
                    <div className="row" key={idx}>
                      <div className="col-9 col-md-10">
                        <div className="payment-feild">
                          <span className="p-float-label">
                            <InputText
                              type="text"
                              maxLength={18}
                              id={item.id}
                              className="offer-control"
                              value={item.code || ""}
                              onChange={(e) => handleCouponFormChange(idx, e)}
                              disabled={item.applied}
                            />
                            <label htmlFor="chips ssn_input">
                              {`Enter Voucher Code ${idx + 1}`}
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="col-3 col-md-2">
                        <div className="verfiy-pay mt-1">
                          <DonateButton
                            className={
                              !loading && item?.code && !item.applied
                                ? "verfiy-payment"
                                : "verfiy-payment btn-opacity"
                            }
                            type="button"
                            onClick={() => {
                              setSelectedCouponCode(item);
                              handleCouponFormSubmit(idx, item?.code);
                            }}
                          >
                            {loading && selectedCouponCode?.id === item.id ? (
                              <i
                                className="pi pi-spin pi-spinner mx-1"
                                style={{ fontSize: "1.5em" }}
                              ></i>
                            ) : item.applied ? (
                              <i
                                className="pi pi-check-circle mx-1"
                                style={{ fontSize: "1.5em" }}
                              ></i>
                            ) : (
                              ""
                            )}
                            {item.applied ? "Applied" : "Apply"}
                          </DonateButton>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-md-12">
                  <div className="gyftr-box">
                    <p>You can redeem up to one M-Coupon per seat booking!</p>                    
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="gyftr-box">
                    <p>M-Coupon will not be refunded for cancellation</p>
                    <div className="divider-gyftr"></div>
                    <p>
                      *If there is an intent connectivity problem while booking
                      the free tickets using your coupon codes and the tickets
                      are not issued please wait for 30 minutes before trying to
                      use the Coupon Codes again.
                    </p>
                  </div>
                </div>
                <div className="col-md-12 show-in-mobile-view">
                  <div className="verfiy-pay">
                <DonateButton
                type="button"
                className={`verfiy-payment ${!outputData ? "p-disabled" : ""}`}
                onClick={handleProccedbtn}
                disabled={!outputData} >
                Proceed
              </DonateButton>
              </div>
            <SweetAlert
           success
          show={showproccedbut}
          onConfirm={() => {
            setShowproccedbut(false);
            // console.log('confirm')
            ViewMode(1);
          }}         
           confirmBtnCssClass={
            theme === "inoxTheme" ? "confirm-btn-inox border-0" : "confirm-btn-pvr border-0"
          }
        >
          <p>M-Coupons were applied successfully! Please check revised payable balance and pay!</p>
        </SweetAlert>

              </div>

                {/* <div className="col-md-12">
                  <div className="verfiy-pay">
                    <DonateButton
                      className={
                        !loading && formValues[0]?.code
                          ? "verfiy-payment"
                          : "verfiy-payment btn-opacity"
                      }
                      type="button"
                      onClick={handleSubmit}
                    >
                      Proceed
                      {loading ? (
                        <i
                          className="pi pi-spin pi-spinner"
                          style={{ fontSize: "1.5em" }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </DonateButton>
                  </div>
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mcoupon;
