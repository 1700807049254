import styled from "styled-components";
const theme = localStorage.getItem("theme");

export const DarkButton = styled.button`
  color: ${(props) => props.theme.colors.txtColor} !important;
  background-color: ${(props) => props.theme.colors.bgColor} !important;
`;

export const LightButton = styled.button`
  background-color: ${(props) => props.theme.colors.bgColorLight}!important;
`;

export const CancelButton = styled.button`
  color: ${(props) => props.theme.colors.txtColor} !important;
  background-color: ${(props) => props.theme.colors.bgColor} !important;
`;


