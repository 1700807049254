import React, { useEffect, useState } from 'react';
import { FaRupeeSign } from "react-icons/fa";
import QR from "../../../assets/payment/qr.png";
import { refraceTokenService } from 'src/global-functions/refreshToken';
import { applycoupon, getLoyalityVoucher } from 'src/services/CouponPayment';
import SweetAlert from 'react-bootstrap-sweetalert';
import PaymodesListSkeleton from 'src/skeletons/PaymodesListSkeleton';

const PrivilegePlus = ({sendDataForPayment}) => {
  const [loyalityVouchers, setloyalityVouchers] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passportCheck, setpassportCheck] = useState(0);
  const theme = localStorage.getItem("theme");

  useEffect(() =>{
    loyalityVoucher();
  }, []);

  const getApply = async (type, couponId, item=null) => {
    //console.log(item);
    setLoading(true);
    const postData = {
      ccode: localStorage.getItem("theaterId"),
      bookingId: localStorage.getItem("bookingid"),
      transId: Number(localStorage.getItem("transid")),
      couponId: 0,
      bookType: localStorage.getItem("booking_type")
        ? localStorage.getItem("booking_type")
        : "BOOKING",
      coupon: type == "PROMOCODE" ? searchText : couponId,
      couponType: type,
      qr: localStorage.getItem("isQR") && isMobile ? "YES" : "NO",
      cardType: "",
      //"qrs": false,
      card: "",
      mobile: item?.dependent?item?.dependentMobile:loginInfo?.data?.mob,
      otp: item?.dependent?mobileOtp:"",
      unlock: false,
    };
    //removeCoupon
    applycoupon(postData)
      .then((res) => {
        setLoading(false);
        const dt = res.data;
        if (dt.result === "success") {
          dt.voucheName = "PRIVILEGE PLUS";
          console.log(dt);
          sendDataForPayment(dt);
          //setSendotp(false);
          
            //console.log(passportVouchers, passportitem);
            let uvouchers = loyalityVouchers.map(obj =>
              obj.voucher == item.voucher? { ...obj, verification: true } : obj
            );
            console.log(uvouchers);
            setloyalityVouchers(uvouchers);
        } else {
          setErrorMsg(dt.msg);
          setpassportCheck(0);
          setShowAlert(true);
          
        }
      })
      .catch((err) => {
        setpassportCheck(0);
        console.log("error", err);
        setLoading(false);
      });
    };

  const loyalityVoucher = () => {
    setLoading(true);
    getLoyalityVoucher()
      .then((res) => {
        setLoading(false);
        const dt = res.data;
        if (dt.result === "success") {
          let uvouchers = dt?.output?.map(obj =>
            ({ ...obj, verification: false })
          );
          setloyalityVouchers(uvouchers);
        } else {
        }
      })
      .catch((error) => {
        setLoading(false);
        const err = error?.response;
        console.log(err);
        if (err?.status === 403) {
          refraceTokenService();
          //profileRequest();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error.message);
        }
      });
  }


  return (
    <>
     <SweetAlert
        error
        show={showAlert}
        title={errorMsg}
        onConfirm={() => {
          setShowAlert(false);
          // console.log('confirm')
          //navigate('/')
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnCssClass={
          theme === "inoxTheme"
            ? "confirm-btn-inox border-0"
            : "confirm-btn-pvr border-0"
        }
      >
        {/* {errorMsg} */}
      </SweetAlert>
    {loading?<PaymodesListSkeleton/>:
    loyalityVouchers.length > 0?
    <div className='payment-form'>
      <div className='paywithdebit'>
        <div className='debit-card'>
          <h6 className='privilege-head'>PRIVILEGE PLUS VOUCHERS</h6>
        </div>
      </div>
      <div className='payment-privilege'>
        <div className='row'>
            {/* <div className='col-md-12 col-sm-12'>
              <div className='privilege-head'>
                <h4>PRIVILEGE PLUS VOUCHERS</h4>
              </div>
            </div> */}
            {loyalityVouchers.map((item, idx) => {
              return (
              <div className='col-md-4 pointer' onClick={() => { 
                !loading && !item.verification ?getApply("KOTAKPLUS", item.voucher, item):"";
                setpassportCheck(idx+1)
              }}>
                <div className='privilege-voucher'>
                  <div className='privilege-voucher-show-details'>
                  
                  <div className='vail-date-privi'>
                    <div className='privilege-qr'>
                    <h5>{item?.amountUsed}</h5>
                      <img src={QR} alt='' className='img-fluid'/>
                    </div>
                    <div className='privilege-id'>
                      <h6>{item?.voucher}</h6>
                      <p>Vaild till {item?.voucherExpiry}</p>
                    </div>
                  </div>
                  </div>
                  <div className="redmee-auto-fil">
                    <div className="passport-voucher">
                      <div className="tick-cricle-passport">
                        <label>
                          <input type="checkbox" 
                          checked={
                            passportCheck == idx + 1 || item.verification
                          }
                          disabled={
                            item.verification
                          }
                          readOnly
                          />
                          <div className="custom-checkbox">
                            <div className="tick"></div>
                          </div>
                        </label>
                      </div>
                      <div className="passport-redmee">
                        {!item.verification?
                        <p>Redeem</p>
                        : <p>Redeemed</p>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              );
            })}
            {/* <div className='col-md-4'>
              <div className='privilege-voucher'>
                <div className='privilege-voucher-show-details'>
                
                <div className='vail-date-privi'>
                  <div className='privilege-qr'>
                  <h5>100</h5>
                    <img src={QR} alt='' className='img-fluid'/>
                  </div>
                  <div className='privilege-id'>
                    <h6>TESTNUH</h6>
                    <p>Vaild till 02 Mar 2024 23:50</p>
                  </div>
                </div>
                </div>
                <div className="redmee-auto-fil">
                  <div className="passport-voucher">
                    <div className="tick-cricle-passport">
                      <label>
                        <input type="checkbox" />
                        <div className="custom-checkbox">
                          <div className="tick"></div>
                        </div>
                      </label>
                    </div>
                    <div className="passport-redmee">
                      <p>Redmee</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='privilege-voucher'>
                <div className='privilege-voucher-show-details'>
                
                <div className='vail-date-privi'>
                  <div className='privilege-qr'>
                  <h5>100</h5>
                    <img src={QR} alt='' className='img-fluid'/>
                  </div>
                  <div className='privilege-id'>
                    <h6>TESTNUH</h6>
                    <p>Vaild till 02 Mar 2024 23:50</p>
                  </div>
                </div>
                </div>
                <div className="redmee-auto-fil">
                  <div className="passport-voucher">
                    <div className="tick-cricle-passport">
                      <label>
                        <input type="checkbox" />
                        <div className="custom-checkbox">
                          <div className="tick"></div>
                        </div>
                      </label>
                    </div>
                    <div className="passport-redmee">
                      <p>Redmee</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='privilege-voucher'>
                <div className='privilege-voucher-show-details'>
                
                <div className='vail-date-privi'>
                  <div className='privilege-qr'>
                  <h5>100</h5>
                    <img src={QR} alt='' className='img-fluid'/>
                  </div>
                  <div className='privilege-id'>
                    <h6>TESTNUH</h6>
                    <p>Vaild till 02 Mar 2024 23:50</p>
                  </div>
                </div>
                </div>
                <div className="redmee-auto-fil">
                  <div className="passport-voucher">
                    <div className="tick-cricle-passport">
                      <label>
                        <input type="checkbox" />
                        <div className="custom-checkbox">
                          <div className="tick"></div>
                        </div>
                      </label>
                    </div>
                    <div className="passport-redmee">
                      <p>Redmee</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='privilege-voucher'>
                <div className='privilege-voucher-show-details'>
                
                <div className='vail-date-privi'>
                  <div className='privilege-qr'>
                  <h5>100</h5>
                    <img src={QR} alt='' className='img-fluid'/>
                  </div>
                  <div className='privilege-id'>
                    <h6>TESTNUH</h6>
                    <p>Vaild till 02 Mar 2024 23:50</p>
                  </div>
                </div>
                </div>
                <div className="redmee-auto-fil">
                  <div className="passport-voucher">
                    <div className="tick-cricle-passport">
                      <label>
                        <input type="checkbox" />
                        <div className="custom-checkbox">
                          <div className="tick"></div>
                        </div>
                      </label>
                    </div>
                    <div className="passport-redmee">
                      <p>Redmee</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='privilege-voucher'>
                <div className='privilege-voucher-show-details'>
                
                <div className='vail-date-privi'>
                  <div className='privilege-qr'>
                  <h5>100</h5>
                    <img src={QR} alt='' className='img-fluid'/>
                  </div>
                  <div className='privilege-id'>
                    <h6>TESTNUH</h6>
                    <p>Vaild till 02 Mar 2024 23:50</p>
                  </div>
                </div>
                </div>
                <div className="redmee-auto-fil">
                  <div className="passport-voucher">
                    <div className="tick-cricle-passport">
                      <label>
                        <input type="checkbox" />
                        <div className="custom-checkbox">
                          <div className="tick"></div>
                        </div>
                      </label>
                    </div>
                    <div className="passport-redmee">
                      <p>Redmee</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        </div>
      </div>
    </div>
    :
    <div className='payment-form'>
      <div className='paywithdebit'>
        <div className='debit-card'>
          <h6 className='privilege-head'>PRIVILEGE PLUS VOUCHERS</h6>
        </div>
      </div>
      <div className='payment-privilege'>
        <p>No Vouchers is available</p>
      </div>
    </div>}
    </>
  )
}

export default PrivilegePlus