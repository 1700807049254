import React, { useState } from "react";
import { Chips } from "primereact/chips";
import explore from "../../../assets/payment/explore.svg";
import offerRight from "../../../assets/payment/offer-right-arrow.svg";
import OtpInput from "react-otp-input";
import { useFormik } from "formik";
import { ProceedButton } from "../../common/login/loginTags";

const PaytmPostpaid = () => {
  const [value6, setValue6] = useState(null);
  const [mobileOtp, setMobileOtp] = useState("");

  const sendOTPFormik = useFormik({
    initialValues: {
      mobileInput: "",
    },

    validate: (data) => {
      let errors = {};

      if (!data.mobileInput) {
        errors.mobileInput = "Please enter mobile no.";
      } else if (data.mobileInput.length < 10) {
        errors.mobileInput = "Mobile no. must be in 10 digits.";
      }
      // console.log('errors', errors)
      return errors;
    },

    onSubmit: (data) => {
      // sendOTPService(data);
      // console.log("loginData", data);
    },
  });
  
  return (
    <>
      <div className="">
        <div className="payment-form">
          <div className="paywithdebit">
            <div className="debit-card show-in-desktop-view">
              <h6>Paytm Postpaid</h6>
            </div>
            <div className="to-paid">
              {/* <p>
                To be Paid:
                <span className="price-cross">₹8888</span>{" "}
                <span className="price-blod">₹8888</span>
              </p> */}
            </div>
          </div>
          <div className="cinema-house payment-postpaytm">
            <h1>Enter OTP</h1>
            <p className="otp-content">You need to enter the OTP received</p>
            <h6>+91 {sendOTPFormik.values.mobileInput}</h6>
            <div className="otp-feild">
              <OtpInput
                value={mobileOtp}
                onChange={setMobileOtp}
                numInputs={6}
                inputStyle={{width:'60px'}}
                // renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} className="otpfeild" />}
              />
            </div>
            <div className="register-btn">
              <ProceedButton className="btn-proceeds" type="submit">
              Verify & Pay
              </ProceedButton>
            </div>
            <div className="resend-otp">
              <h6
                style={{ cursor: "pointer" }}
                // onClick={() => reSendOTPService()}
              >
                Resend OTP
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaytmPostpaid