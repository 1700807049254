import React, { useState } from "react";
import booking from "../../../../assets/profile/booking.svg";
import arrow from "../../../../assets/profile/navigatenext.svg";
import alert_icon from "../../../../assets/comingsoon/notification-black.svg";
import { Sidebar } from "primereact/sidebar";
import { useNavigate } from "react-router-dom";
import angle_left from "../../../../assets/mobilemoviedetail/arrow-b.svg";

const ProfileTncPrivacy = () => {
    const navigate = useNavigate()
  const [visibleRight, setVisibleRight] = useState(false);
  const [sidebarViewMode, setSidebarViewMode] = useState(0);
  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };
  return (
    <>
      <div
        className="profile-img border-line-profile border-none"
        onClick={() => {
          setVisibleRight(true);
          setSidebarViewMode(0);
        }}
      >
        <div className="profile-text profile-account">
          <div className="profile-text-info">
            <h2>
         T&C<span className="border-right-tnc">|</span>Privacy
            </h2>
          </div>
        </div>
      </div>
      <div>
        <Sidebar
          visible={visibleRight}
          showCloseIcon={false}
          className="Terms-condition-mobile"
          style={
            sidebarViewMode === 0 ? { width: "100vw" } : { height: "50vh" }
          }
          position={sidebarViewMode === 0 ? "right" : "bottom"}
          onHide={() => {
            setVisibleRight(false);
          }}
          dismissable={false}
          blockScroll={true}
          fullScreen
        >
          <>
            <div className="Account-view-show-mobile">
              <div className="account-angle-btn" onClick={() => setVisibleRight(false)}>
                <h4>
                <img src={angle_left} alt="" className="" />{" "} &nbsp; T&C | Privacy
                </h4>
              </div>
            </div>
            <div className="luxe-divider" style={{ margin: "3px 0" }}></div>
            <div className="policy-show-mobile">
              <div className="d-flex justify-content-between align-items-center"
              onClick={()=> {hitGa("footer_privacy_policy");
              navigate('/privacy-policy')}}
              >
                <div>Privacy</div>
                <div className="img-arrow">
                  <img src={arrow} alt="" className="" />
                </div>
              </div>
              <div className="offer-border mt-20"></div>
              <div 
              className="d-flex justify-content-between align-items-center"
              onClick={()=> {hitGa("footer_term_of_use");
              navigate('/terms-use')}}
              >
                <div>Terms of use</div>
                <div className="img-arrow">
                  <img src={arrow} alt="" className="" />
                </div>
              </div>
              <div className="offer-border mt-20"></div>
              <div 
              className="d-flex justify-content-between"
              onClick={()=> {hitGa("footer_term&condition");
              navigate('/terms-conditions/booking')}}
              >
                <div>Terms and Conditions</div>
                <div className="img-arrow">
                  <img src={arrow} alt="" className="" />
                </div>
              </div>
              <div className="offer-border mt-20"></div>
              <duv className='d-flex justify-content-center align-items-center'>
              {/* <p>Version 16.1</p> */}
              </duv>
            </div>
          </>
        </Sidebar>
      </div>
    </>
  );
};

export default ProfileTncPrivacy;
