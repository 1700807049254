import React from "react";
import { Skeleton } from "primereact/skeleton";

const FnbLandingSkeleton = () => {
	const rptno = Array.from({ length: 4 }, (_, index) => index + 1);
	const cardNum = Array.from({ length: 8 }, (_, index) => index + 1);

	return (
		<>
			<style>
				{`
				.flx {
					display: flex;
				}`
				}
			</style>
			<div style={{overflow: "hidden"}}>
				<div className="d-flex justify-content-between mt-2">
					{/* <Skeleton width="5rem" className="my-1"></Skeleton> */}
					<Skeleton width="100%" height="65px" className="mx-2"></Skeleton>
				</div>
				<div className="mt-2">
					<Skeleton width="60%" height="20px" className="p-2 mx-auto"></Skeleton>
				</div>
				<div className="repeatskltn flx mt-2 mx-1" style={{ width: 700 }}>
					{rptno && rptno?.map((item, index) => (
						<Skeleton key={index} width="250px" height="91px" className="mx-1"></Skeleton>
					))}
				</div>
				<div className="repeatskltn flx mt-2 mx-1" style={{ width: 900 }}>
					{rptno && rptno?.map((item, index) => (
						<Skeleton key={index} width="250px" height="60px" className="mx-1"></Skeleton>
					))}
				</div>
				<div className="repeatskltn flx mt-3 mx-1" style={{ width: 500 }}>
					{rptno && rptno?.map((item, index) => (
						<div key={index}>
							<Skeleton width="100px" height="40px" className="mx-1"></Skeleton>
						</div >
					))}
				</div>
				<div className="repeatskltn  mt-2 mx-1">
					{cardNum && cardNum?.map((item, index) => (
						<div key={index} className="flx mt-3">
							<div style={{ flexShrink: 0 }}><Skeleton width="100px" height="100px" className="mx-1"></Skeleton></div>
							<div style={{ width: "100%" }}>
								<Skeleton width="150px" height="20px" className="mx-1"></Skeleton>
								<Skeleton width="100px" height="20px" className="mx-1 mt-2"></Skeleton>
								<Skeleton width="50px" height="20px" className="mx-1 mt-2"></Skeleton>
							</div>
							<div style={{ flexShrink: 0 }}>
								<Skeleton width="70px" height="30px" className="mx-1 mt-2"></Skeleton>
							</div>

						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default FnbLandingSkeleton;
