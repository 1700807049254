import React, { useState, useRef, useEffect } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputText } from "primereact/inputtext";
import searchBlack from "../../../../assets/nav-icon/search-b.svg";
import { TabView, TabPanel } from "primereact/tabview";
import searchcinemas from "../../../../assets/search/searchcinema.png";
import way from "../../../../assets/search/ways.svg";
import place from "../../../../assets/search/place.svg";
import show from "../../../../assets/search/show.svg";
import "./search.css";
import axios from "axios";
import * as appConstants from "../../../../constants/index";
import miv_inox from "../../../../assets/default-images/vertical-poster-inox.png";
import miv_pvr from "../../../../assets/default-images/vertical-poster-pvr.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { setMovieDetailsViewMode } from "../../../../redux/actions/movieDetailsAction";
import { Sidebar } from "primereact/sidebar";
import { useSelector, useDispatch } from "react-redux";
// import MovieDetails from "../../movie-details/MovieDetails";
import mih_inox from "../../../../assets/default-images/horizontal-poster-inox.png";
import mih_pvr from "../../../../assets/default-images/horizontal-poster-pvr.svg";
import {
  handleMiHImageError,
  handleMivImageError,
} from "src/global-functions/imageErrorSrc";
import { getSplashData } from "src/services/OtherServices";
import gnr_default from '../../../../assets/default-images/gnrPLaceHolder.png'


const Search = () => {
  const op = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  const theme = localStorage.getItem("theme");
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [movieList, setMovieList] = useState([]);
  const [filterMovieData, setFilterMovieData] = useState([]);
  const [cinemaList, setCinemaList] = useState([]);
  const [filterCinemaData, setFilterCinemaData] = useState([]);
  const [experienceList, setExperienceList] = useState([]);
  const [filterExperienceData, setFilterExperienceData] = useState([]);
  const currentPath = location.pathname.split("/");
  const sidebarViewMode = useSelector(
    (state) => state.movieDetailsViewMode.value
  );
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [showSearchUI, setShowSearchUI] = useState(false);
  const [spalshData, setSpalshData] = useState(null);

  // console.log("currentPath", currentPath);

  // == splace request =======
  useEffect(() => {
    getSplashData()
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          setSpalshData(dt.output);
        } else {
          setSpalshData({})
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  const getSearchData = async () => {
    setLoading(true);
    const postData = {
      city: localStorage.getItem("city"),
      lat: localStorage.getItem("lat")|| "0.000",
      lng: localStorage.getItem("long")|| "0.000",
      type: "HOME",
    };

    await axios
      .post(`${appConstants.base_url}v1/booking/content/search`, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          const ndt = dt.output;
          //====== movie list filter ==============
          setMovieList([...ndt.ns]);
          setFilterMovieData(ndt?.ns);

          // ====== cinema list filter =============
          setCinemaList([...ndt.cinemas]);
          const filterCinemaData = ndt.cinemas && ndt.cinemas;
          const groupedData = filterCinemaData.reduce((acc, cinema) => {
            const cityName = cinema.cityName;
            const existingCity = acc.find((city) => city.cityName === cityName);

            if (existingCity) {
              existingCity.cinemas.push(cinema);
            } else {
              acc.push({ cityName, cinemas: [cinema] });
            }

            return acc;
          }, []);
          setFilterCinemaData(groupedData);

          //====== exprience list filter ============
          setExperienceList([...ndt.experience]);
          setFilterExperienceData(ndt.experience);
          setLoading(false);
          // console.log("searchData==>", ndt);
        } else if (res.data.code === 12001) {
          // alert(res.data.msg);
          setLoading(false);
        } else {
          console.log(res.data.msg);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  const hideOverlay = (e) => {
    setIsOverlayVisible(false);
    op.current.hide(e);
    setActiveIndex(0);
    // console.log("serchUI hide");
  };
  const hitSearchGA = (n) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: "header_search_bar_click",
      header_search_bar: n
    });
  };
  const finalGnrUI = (gnrArr) => {
    const finalGnr =
      gnrArr?.length > 0
        ? gnrArr?.map((item, idx) => {
            return (
              <img
                key={idx}
                src={`${spalshData?.cdn}${item.toLowerCase()}.png`}
                // height={12}
                onError={(e)=> e.target.src =  gnr_default}
                alt="gnrs"
                className="gnr-icon"
              />
            );
          })
        : "";
    return finalGnr;
  };
  const searchUI = () => {
    const searchMovies = () => {
      return (
        <div className="movies-search">
          {loading ? (
            <span>
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "2rem" }}
              ></i>{" "}
            </span>
          ) : (
            <div className="row">
              {filterMovieData.length > 0 ? (
                filterMovieData?.map((item, idx) => {
                  let url = `/moviesessions/ ${localStorage.getItem("city")}/${
                    encodeURIComponent(item.n)
                  }/${item.id}`;
                  let url2 = url.replace(/\s/g, "-");
                  return (
                    <div
                      className="col-md-6 pointer"
                      key={idx}
                      onClick={() => {
                        hitSearchGA(item.n+",movie");
                        if (item.movieType === "UPCOMING") {
                          setSelectedMovie(item);
                          // console.log('item', item)
                          dispatch(setMovieDetailsViewMode({ value: true }));
                          localStorage.setItem("movieDetailId", item.id);
                        } else {
                          navigate(url2);
                        }
                        if (currentPath[1] === "moviesessions") {
                          window.location.reload();
                        }
                      }}
                    >
                      <div className="search-all-movies">
                        <div className="search-movies-name">
                          <img
                            src={
                              item?.miv
                                ? item?.miv
                                : theme === "inoxTheme"
                                ? miv_inox
                                : miv_pvr
                            }
                            alt=""
                            className="img-fluid"
                            onError={handleMivImageError}
                          />
                        </div>
                        <div className="movies-search-details">
                          <h6>
                            {item.n}{" "}
                            <span
                              className={
                                theme === "inoxTheme"
                                  ? "search-new-inox"
                                  : "search-new-pvr"
                              }
                            >
                              {item?.rt}
                            </span>
                          </h6>
                          <ul>
                            <li>{item.ce}</li>
                            <li>
                              <span className="dot-search"></span>
                              {item?.mfs?.toString()}
                            </li>
                            <li>
                              <span className="dot-search"></span>
                              {item?.mlength}
                            </li>
                          </ul>
                          <ul>
                            <li>
                              {/* <span className="dot-search"></span> */}
                              {finalGnrUI(item?.grs)}
                            </li>
                          </ul>
                            {/* {item.format === "Normal" ||
                            item.format === null || item.format.length === 0 ? (
                              ""
                            ) : (
                              <div className="search-views">
                              <ul>
                                <li>{item.format}</li>
                              </ul>
                          </div>
                            )} */}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <span>No record found</span>
              )}
            </div>
          )}
        </div>
      );
    };
    const searchCinemas = () => {
      return (
        <div className="movies-search">
          {loading ? (
            <span>
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "2rem" }}
              ></i>{" "}
            </span>
          ) : (
            <div className="row">
              {filterCinemaData.length > 0 ? (
                filterCinemaData?.map((item, idx) => {
                  return (
                    <div className="col-md-6" key={idx}>
                      <div className="all-show-city">
                        <div className="search-city">
                          <h3>{item.cityName}</h3>
                        </div>
                        {item?.cinemas &&
                          item?.cinemas?.map((item2, idx2) => {
                            let url = `/cinemasessions/${localStorage.getItem(
                              "city"
                            )}/${item2.name}/${item2.theatreId}`;
                            let url2 = url.replace(/\s/g, "-");
                            return (
                              <div
                                className="search-all-movies pointer"
                                key={idx2}
                                onClick={() => {
                                  hitSearchGA(item2.name+",cinema");
                                  navigate(url2);
                                  if (currentPath[1] === "cinemasessions") {
                                    window.location.reload();
                                  }
                                }}
                              >
                                <div className="search-movies-cinemas">
                                  <img
                                    src={
                                      item2.miv
                                        ? item2.miv
                                        : theme === "inoxTheme"
                                        ? mih_inox
                                        : mih_pvr
                                    }
                                    onError={handleMiHImageError}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="movies-search-details">
                                  <h6>{item2.name}</h6>
                                  <p>
                                    {item2.address1 +
                                      " " +
                                      item2?.address2 +
                                      " " +
                                      item2?.address3}
                                  </p>
                                  <div className="may-cinemas">
                                    <div className="direction-search">
                                      <a
                                        href={`https://maps.google.com/?q=${item2.latitude},${item2.longitude}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={()=>{  let dataLayer=window['dataLayer'] ||[];
                                        dataLayer.push({
                                          'event': 'booking_movie_direction'
                                          });}}
                                      >
                                        <p>
                                          <span className="search-icon">
                                            <img
                                              src={way}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </span>
                                          Directions
                                        </p>
                                      </a>
                                    </div>
                                    <div className="direction-search">
                                      <p>
                                        <span className="search-icon">
                                          <img
                                            src={place}
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </span>
                                        {(item2?.distance / 1000).toFixed(2)}km
                                        away
                                      </p>
                                    </div>
                                    <div className="direction-search">
                                      <p>
                                        <span className="search-icon">
                                          <img
                                            src={show}
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </span>
                                        {item2?.showCount} Shows
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })
              ) : (
                <span>No record found</span>
              )}
            </div>
          )}
        </div>
      );
    };
    const searchExperiences = () => {
      return (
        <div className="movies-search">
          {loading ? (
            <span>
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "2rem" }}
              ></i>{" "}
            </span>
          ) : (
            <div className="row">
              {filterExperienceData.length > 0 ? (
                filterExperienceData?.map((item, idx) => {
                  return (
                    <div className="col-md-4" key={idx}>
                      <div className="all-show-city">
                        <div className="search-city">
                          <h3> {item?.experienceRe?.name}</h3>
                        </div>
                        {item?.experienceCinemas &&
                          item?.experienceCinemas?.map((item2, idx2) => {
                            return (
                              <div
                                key={idx2}
                                className="movies-search-details pointer"
                                onClick={() => {
                                  hitSearchGA(item2.name+","+item?.expKey);

                                  navigate(
                                    `/experience/${localStorage.getItem(
                                      "city"
                                    )}/${item?.expKey}`
                                  );
                                  if (currentPath[1] === "experience") {
                                    window.location.reload();
                                  }
                                }}
                              >
                                <h6 style={{ marginBottom: "5px" }}>
                                  {item2.name}
                                </h6>
                                <p>
                                  {item2.address1}, {item2.cityName}
                                </p>
                              </div>
                            );
                          })}
                        {/* <div className="movies-search-details search-viewed">
                          <h6>PVR Select City IMAX Laser</h6>
                          <p>Mumbai, Maharashtra</p>
                        </div> */}
                      </div>
                    </div>
                  );
                })
              ) : (
                <span>No record found</span>
              )}
              {/* <div className="col-md-4">
              <div className="all-show-city">
                <div className="search-city">
                  <h3>IMAX</h3>
                </div>
                <div className="movies-search-details">
                  <h6>PVR Select City IMAX Laser</h6>
                  <p>Mumbai, Maharashtra</p>
                </div>
                <div className="movies-search-details search-viewed">
                  <h6>PVR Select City IMAX Laser</h6>
                  <p>Mumbai, Maharashtra</p>
                </div>
              </div>
            </div> */}
            </div>
          )}
        </div>
      );
    };
    return (
      <>
      <div className="search-dropdown">
        <div
          className={
            theme === "inoxTheme"
              ? "now-showing-filter-inox"
              : "now-showing-filter-pvr"
          }
        >
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header="Movies">{searchMovies()}</TabPanel>
            <TabPanel header="Cinemas">{searchCinemas()}</TabPanel>
            <TabPanel header="Experiences">{searchExperiences()}</TabPanel>
          </TabView>
        </div>
      </div>
      <div className="search-colse-btn">
            <span onClick={() => setShowSearchUI(false)}>
              <i
                className="pi pi-times"
                style={{ color: "#000", cursor: "pointer", marginTop:"5px" }}
              ></i>
            </span>
          </div>
      </>
    );
  };

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchText(val);
    if (val.length > 0) {
      // setShowSearchUI(true);
      // showOverlay(e);
      // ==== movie filtering ===============
      const filterMovieData =
        movieList &&
        movieList?.filter((item) =>
          item?.n?.toLowerCase()?.includes(val?.toLowerCase())
        );
      setFilterMovieData([...filterMovieData]);

      // ========= cinema filtering ===========
      const filterCinemaData =
        cinemaList &&
        cinemaList?.filter((item) =>
          item?.name?.toLowerCase()?.includes(val?.toLowerCase())
        );

      const groupedData = filterCinemaData.reduce((acc, cinema) => {
        const cityName = cinema.cityName;
        const existingCity = acc.find((city) => city.cityName === cityName);

        if (existingCity) {
          existingCity.cinemas.push(cinema);
        } else {
          acc.push({ cityName, cinemas: [cinema] });
        }

        return acc;
      }, []);
      setFilterCinemaData(groupedData);

      // ====== experience filtering ===========
      const filterExpData =
        experienceList &&
        experienceList?.filter((item) =>
          item?.expKey?.toLowerCase()?.includes(val?.toLowerCase())
        );
      setFilterExperienceData(filterExpData);

      //   console.log("filterData", groupedData);
    } else if (val.length < 1) {
      // hideOverlay(e);
      setFilterMovieData([...movieList]);
      setFilterExperienceData([...experienceList]);
      // setShowSearchUI(false);
      const groupedData = cinemaList.reduce((acc, cinema) => {
        const cityName = cinema.cityName;
        const existingCity = acc.find((city) => city.cityName === cityName);

        if (existingCity) {
          existingCity.cinemas.push(cinema);
        } else {
          acc.push({ cityName, cinemas: [cinema] });
        }

        return acc;
      }, []);
      setFilterCinemaData(groupedData);
    }
  };

  return (
    <>
      <div 
      className="search-main-box pointer"
      onClick={(e) => {
        getSearchData();
        setShowSearchUI(true);
        // showOverlay(e);
      }}
      >
        <span
          className="p-input-icon-left pointer"
          style={{ position: "relative", top: "5px" }}
        >
          <InputText
            value={searchText}
            onChange={(e) => {
              handleSearch(e);
            }}
            placeholder="Search..."
          />
        </span>
        <div className="search-icon-main-nav">
          <img src={searchBlack} alt="" className="img-fluid" />
        </div>
      </div>
      {showSearchUI && (
          <div className={`search-content ${showSearchUI ? "show" : ""}`}>
            {searchUI()}
          </div>
        )}
    </>
  );
};

export default Search;
