import AddButton from "./AddButton";
import ellipse from "../../../assets/food/Ellipse.svg";
import vegIcon from "../../../assets/food/veg.svg";
import nonvegIcon from "../../../assets/food/nonveg.svg";
import noImg from '../../../assets/food/noimagesqr.png';


const RecommendeCard = ({
  data,
  handleFoodInfoShow,
  addedItems,
  cartItemIds,
  updateAddedItems,
  handleCustomizfoShow,
  removeFromCart,
  totalItems,
}) => {
  const handleCardClick = (e) => {
    isViewItemDetails(data?.id);
    if (!e.target.closest(".Customfb-btnDiv")) {
      handleFoodInfoShow();
    }
  };
  // debugger

  const isViewItemDetails = (productID) => {
    salesForceEvent({
      interaction: {
        name: SalesforceInteractions.CatalogObjectInteractionName.ViewCatalogObjectDetail,
        catalogObject: {
          type: "Product",
          id: productID
        },
      },
    })
  }

  const handleImageError = (e) => {
    e.target.src = noImg; // Set fallback image if the original image fails
  };
  return (
    <div onClick={handleCardClick} key={data.id} className="Reco-card-main">
      <div className="Reco-card-inn">
        <div className="Reco-item-view">
          <img
            className="Reco-item-viewImg"
            src={
              data?.i
                ? data?.i
                : data?.imgData
                  ? `data:image/jpeg;base64,${data?.imgData}`
                  : noImg
            }
            // src={data.i || noImg}
            width="84"
            alt={data.itemName || "No image available"}
            onError={handleImageError}
          />

          {data.foodType === 1 ?
            <svg className="Reco-itemVorN" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
              <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
            </svg>
            : data.foodType === 0 ?
              <svg className="Reco-itemNon" width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                <clipPath id="clip0_3452_1183">
                  <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                </clipPath>
              </svg>
              : ""
          }
        </div>
        <div className="Reco-item-desc">
          <p className="Reco-itemName">{data.h.toLowerCase()}</p>
          <p className="Reco-itemPrice">
            ₹{(data.dp) / 100} {data.addOnItems.length > 0 && <img src={ellipse} alt="dot" />}
            {data.addOnItems.length > 0 && <span>Customizable</span>}
          </p>
          <div className="Customfb-btnDiv">
            <AddButton
              itemId={data.id}
              addedItems={addedItems}
              cartItemIds={cartItemIds}
              updateCount={updateAddedItems}
              handleCustomizfoShow={() => handleCustomizfoShow(data)}
              data={data}
              removeFromCart={removeFromCart}
              totalItems={totalItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendeCard;
