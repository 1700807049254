import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

const SweetAlertUtility = ({ type, title, message, show, onConfirm, onCancel }) => {
    // Get the theme from local storage
    const theme = localStorage.getItem("theme"); // This can be null if not set

    if (!show) return null;

    // Common button style based on theme
    const confirmButtonStyle = {
        backgroundColor: theme === "pvrTheme" ? "#FFCB05" : '#234B9E',
        color: theme === "pvrTheme" ? "#000" : '#fff',
        borderColor: 'transparent',
    };

    let alertType = null;

    switch (type) {
        case 'success':
            alertType = (
                <SweetAlert
                    success
                    title={title}
                    onConfirm={onConfirm}
                    confirmBtnStyle={confirmButtonStyle}
                >
                    {message}
                </SweetAlert>
            );
            break;
        case 'error':
            alertType = (
                <SweetAlert
                    danger
                    title={title}
                    onConfirm={onConfirm}
                    confirmBtnStyle={confirmButtonStyle}
                >
                    {message}
                </SweetAlert>
            );
            break;
        case 'warning':
            alertType = (
                <SweetAlert
                    warning
                    showCancel
                    title={title}
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    confirmBtnStyle={confirmButtonStyle}
                >
                    {message}
                </SweetAlert>
            );
            break;
        default:
            alertType = (
                <SweetAlert
                    info
                    title={title}
                    onConfirm={onConfirm}
                    confirmBtnStyle={confirmButtonStyle}
                >
                    {message}
                </SweetAlert>
            );
    }

    return alertType;
};

export default SweetAlertUtility;
