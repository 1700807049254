import styled from "styled-components";

export const AddBtn = styled.button`
  color: ${(props) => props.theme.colors.txtColor};
  background-color: ${(props) => props.theme.colors.bgColor};
`;

export const ProceedBtn = styled.button`
  color: ${(props) => props.theme.colors.txtColor};
  background-color: ${(props) => props.theme.colors.bgColor};
`;

export const AlsoAddBtn = styled.button`
  background-color: ${(props) => props.theme.colors.bgColorLight};
`;