import axios from "axios";
import * as appConstants from "../constants/index";
import { refraceTokenService } from "src/global-functions/refreshToken";
import swal from "sweetalert";

const theme = localStorage.getItem('theme');


const headers = {
  Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
  chain: theme === "inoxTheme" ? "INOX" : "PVR",
  city: localStorage.getItem("city"),
  appVersion: appConstants.av,
  platform: appConstants.pt,
  country: appConstants.cname,
  "Content-Type": "application/json",
};

export const postDataService = async (endpoint, postData) => {
  try {
    const response = await axios.post(
      `${appConstants.base_url}/${endpoint}`,
      postData,
      { headers: headers }
    );
    const dt = response.data;
    if (dt.result === "success") {
      return response.data;
    } else if (dt.result === "error") {
      swal({
        title: "Error",
        text: dt.msg,
        timer: 2500,
        icon: "error",
        buttons: false,
      });
    }
  } catch (error) {
    const err = error?.response;
    if (err?.status === 403) {
      refraceTokenService();
      console.log(
        "Forbidden: You do not have permission to access this resource."
      );
    } else {
      console.log("Error:", error?.message);
      swal({
        title: "Error",
        text: error?.message,
        timer: 2500,
        icon: "error",
        buttons: false,
      });
    }
    console.error("Error making API request:", error);
    throw error;
  }
};
