import React, { useEffect, useState } from 'react'
import FbHeader from './component/FbHeader'
import { Modal } from 'react-bootstrap';
import expand from '../../assets/food/expand.svg'
import check from '../../assets/food/check.svg'
import closeIcon from "../../assets/food/close.svg";
import checkInox from '../../assets/food/checkinox.png'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as appConstants from "../../constants/index";
import SweetAlertUtility from './component/SweetAlertUtility';
import { format } from 'date-fns';
import FnbSeatDetailSkeleton from 'src/skeletons/FnbSeatDetailSkeleton';
import Navbar from '../common/navbar/Navbar';
import { getResponsiveClasses } from './component/ResponsiveClasses';


const seatNum = [
	"A", "B", "C", "D", "E", "F", "G", "H", "I",
	"J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T",
	"U", "V", "W", "X", "Y", "Z"
];
const seatcount = Array.from({ length: 100 }, (_, i) => i + 1);
const getCurrentTimestamp = () => new Date().getTime();

const SeatDetails = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [movieFetchDetails, setMovieFetchDetails] = useState();
	const [movieDetails, setMovieDetails] = useState();
	const [cinemaDetails, setCinemaDetails] = useState();
	const [showDate, setShowDate] = useState();
	const [showSelectSeat, setShowSelectSeat] = useState(false);
	const [showSelectCount, setShowSelectCount] = useState(false);
	const [userSelectedFood, setUserSelectedFood] = useState();
	const [pageErrMsg, setPageErrMsg] = useState(false);
	const [seatErrMsg, setSeatErrMsg] = useState(false);
	const theme = localStorage.getItem("theme")

	const [selectedSeat, setSelectedSeat] = useState(null);
	const [selectedMovie, setSelectedMovie] = useState(null);
	const [selectedFilmUrl, setSelectedFilmUrl] = useState(null);
	const [selectedGenres, setSelectedGenres] = useState(null);
	const [selectedLanguages, setSelectedLanguages] = useState(null);
	const [selectedTime, setSelectedTime] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [rows, setRows] = useState(null);
	const [selectedAudi, setSelectedAudi] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedScreenID, setSelectedScreenID] = useState(null);
	const [selectedfilmId, setSelectedfilmId] = useState(null);

	const [movieError, setMovieError] = useState(false);
	const [timeError, setTimeError] = useState(false);
	const [audiError, setAudiError] = useState(false);
	const [rowError, setRowError] = useState(false);
	const [seatError, setSeatError] = useState(false);
	const [alertDetails, setAlertDetails] = useState({ show: false, type: '', title: '', message: '' });

	const [errorFocus, setErrorFocus] = useState(false);

	const handleSelectSeatClose = () => setShowSelectSeat(false);
	const handleSelectCountClose = () => setShowSelectCount(false);

	const theatreId = localStorage.getItem("TheatreId");
	const authToken = "Bearer " + localStorage.getItem("token");
	const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
	const selectedMovieData = JSON.parse(localStorage.getItem("selectedMovie")) || null;

	const { currentClasses, getWindowWidth } = getResponsiveClasses();
	// useEffect(() => {
	// 	// Check if data exists
	// 	if (storedDataString) {
	// 		// Convert JSON string to object
	// 		const storedData = JSON.parse(storedDataString);
	// 		setUserSelectedFood(storedData);

	// 	} else {
	// 		console.log("No data found in local storage");
	// 	}
	// }, [storedDataString]);

	useEffect(() => {
		// if (userSelectedFood) {
		// }
		handleCard();
	}, []);

	// useEffect(() => {
	// 	handleCard();
	// }, []);

	const handleCard = async (e) => {
		setLoading(true);

		const formData = {
			cid: theatreId,
			cineType: "",
			cineTypeQR: "",
			city: localStorage.getItem("city") || "",
			dated: "NA",
			lat: localStorage.getItem("lat"),
			lng: localStorage.getItem("long"),
			qr: localStorage.getItem("fnb_qr") ? "YES" : "NO"
		};

		try {
			const response = await axios.post(
				`${appConstants.base_url}v1/booking/content/csessions`,
				formData,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: authToken,
						chain: localStorage.getItem("theme") === "inoxTheme" ? "INOX" : "PVR",
						city: localStorage.getItem("city"),
						country: appConstants.cname,
						appVersion: appConstants.av,
						platform: appConstants.pt,
					},
				}
			);
			if (response.data.result === "success") {
				// const dattaaa = groupByVenue(response?.data?.moviesDetails || []);

				const resData = response?.data?.output;
				const currentDate = format(new Date(), "yyyy-MM-dd");
				const cinemaExp = resData.cinemaMovieSessions.flatMap(session =>
					session.experienceSessions?.flatMap(exp => exp.shows || []) || []
				);

				const movieExp = resData.cinemaMovieSessions.flatMap(item => item.movieRe || []);
				const showsForCurrentDate = cinemaExp?.filter(show => currentTimestamp > show.showTimeStamp && currentTimestamp < show.endTimeStamp - (selectedMovieData?.hideShowBeforeEndTimeinMin * 60 * 1000));
				const movieIdList = showsForCurrentDate.map(show => show.movieId);
				const MovieData = movieExp.filter(movie => movie.filmIds.some(filmId => movieIdList.includes(filmId)));

				if (MovieData?.length === 0) setPageErrMsg("No shows are running currently!")
				setMovieFetchDetails(response.data.output);
				setMovieDetails(MovieData);
				setCinemaDetails(cinemaExp);

			} else {
				console.error('No movie details found.');
				setPageErrMsg("No shows are running currently!")
			}
		} catch (error) {
			sweetAlertError('Something went wrong!');
			console.error('Failed to fetch movie details:', error);
		} finally {
			setLoading(false);
		}
	};

	// Handle validation for buttons
	const seatLayoutAPI = async (screenID) => {
		setLoading(true);

		try {
			const response = await axios.get(
				`${appConstants.base_url}v1/fnb/fnb-manual-seat-selection?theaterId=${theatreId}&screenId=${screenID}`,
				{},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: authToken,
						chain: localStorage.getItem("theme") === "inoxTheme" ? "INOX" : "PVR",
						city: localStorage.getItem("city"),
						country: appConstants.cname,
						appVersion: appConstants.av,
						platform: appConstants.pt,
					},
				}
			);

			if (response.data.result === "success") {
				setSeatErrMsg(false);
				const resData = response?.data?.output;
				if (resData.length > 0 && resData) {
					setRows(resData)
					console.log(resData, "updatedRowData");
				} else {
					setSeatErrMsg(true);
					sweetAlertError("Delivery unavailable. Please order from the counter.");
				}
			} else {
				console.error(response.data.msg);
				setSeatErrMsg(true);
				sweetAlertError("Delivery unavailable. Please order from the counter.");
				// setSeatErrMsg(response.data.msg);
			}
		} catch (error) {
			sweetAlertError('Something went wrong!');
			console.error('Failed to fetch movie details:', error);
		} finally {
			setLoading(false);
		}
	};

	const handleMovieClick = (name, filmId, filmUrl, genres, languages) => {
		// const selectedFilmIds = cinemaDetails?.find((showDetail) => filmId.includes(showDetail.movieId))?.movieId;
		const selectedFilmIds = cinemaDetails?.filter((showDetail) =>
			filmId.includes(showDetail.movieId) &&
			currentTimestamp > showDetail.showTimeStamp &&
			currentTimestamp < showDetail.endTimeStamp - (selectedMovieData?.hideShowBeforeEndTimeinMin * 60 * 1000)
		);
		setSelectedMovie(name);
		setSelectedfilmId(selectedFilmIds[0].movieId);
		setSelectedFilmUrl(filmUrl);
		setSelectedGenres(genres);
		setSelectedLanguages(languages);
		setSelectedTime('');
		setSelectedDate('');
		setSelectedAudi('');
		setSelectedRow('');
		setSelectedSeat('');
		setSelectedScreenID('');
		resetErr();
	};

	const handleTimeClick = (date, time) => {
		if (!selectedMovie) {
			setTimeError(true);
			return;
		}
		setSelectedTime(time);
		setSelectedDate(date);
		setSelectedAudi('');
		setSelectedRow('');
		setSelectedSeat('');
		setSelectedScreenID('');
		resetErr();
	};

	const handleAudiClick = (audi, screenID) => {
		if (!selectedTime) {
			setAudiError(true);
			return;
		}
		setSelectedAudi(audi);
		seatLayoutAPI(screenID)
		setSelectedScreenID(screenID);
		setSelectedRow('');
		setSelectedSeat('');
		resetErr();
	};

	const handleSelectSeatShow = () => {
		if (!selectedAudi) {
			setRowError(true);
			return;
		}
		setShowSelectSeat(true);
		setSelectedSeat('');
		resetErr();
	};

	const handleSelectCountShow = () => {
		if (!selectedRow) {
			setErrorFocus(true);
			return;
		}
		setShowSelectCount(true);
		resetErr();
	};

	const validation = () => {
		let isValid = true;

		resetErr();

		if (!selectedMovie) {
			setMovieError(true);
			isValid = false;
		}

		if (!selectedTime) {
			setTimeError(true);
			isValid = false;
		}

		if (!selectedAudi) {
			setAudiError(true);
			isValid = false;
		}

		if (!selectedRow) {
			setRowError(true);
			isValid = false;
		}

		if (!selectedSeat) {
			setSeatError(true);
			isValid = false;
		}

		return isValid;
	};

	const handleSubmit = () => {
		if (!validation()) {
			return;
		}
		// const time = format(new Date(), "hh:mm a"); "pickUpDate":"2024-10-25 11:20:00"

		const seatdetailsData = JSON.parse(localStorage.getItem("seatdetails")) || null;
		const seatdetails = {
			manualseat: true,
			filmName: selectedMovie || "",
			showTime: new Date() || "",
			pickUpDate: `${selectedDate}T${selectedTime}` || "",
			movieImageUrl: selectedFilmUrl || "",
			filmId: selectedfilmId || "",
			screenId: selectedScreenID || "",
			screenType: seatdetailsData?.screenType,
			screenName: selectedAudi || "",
			seatrow: selectedRow || "",
			seatno: selectedSeat || "",
			genres: selectedGenres || "",
			languages: selectedLanguages || "",
		}
		localStorage.setItem("seatdetails", JSON.stringify(seatdetails))
		navigate("/fnb-checkout");

		let dataLayer = window["dataLayer"] || [];
		const foodNames = cartItems.map(item => item.ItemName).join(", ");
		dataLayer.push({
			event: "food_deliver_to_my_seat",
			item_name: selectedMovie || "",
			showtime: `${selectedDate} ${selectedTime}` || "",
			seat_no: `${selectedRow}${selectedSeat}` || "",
			cinema_name: movieFetchDetails?.cinemaRe?.name,
			food_name: foodNames
		});
	}

	const resetErr = () => {
		// Reset all error states
		setMovieError(false);
		setTimeError(false);
		setAudiError(false);
		setRowError(false);
		setSeatError(false);
	}

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({
				top: document.body.scrollHeight,
				behavior: 'smooth',
			});
		}, 300);
	}, [selectedAudi, selectedRow]);

	const sweetAlertSuccess = (msg) => {
		setAlertDetails({
			show: true,
			type: 'success',
			title: 'Success!',
			message: msg,
		});
	};

	const sweetAlertError = (msg) => {
		setAlertDetails({
			show: true,
			type: 'error',
			title: 'Error!',
			message: msg,
		});
	};

	const hideAlert = () => {
		setAlertDetails({ show: false });
	}
	const [currentTimestamp, setCurrentTimestamp] = useState(getCurrentTimestamp());

	useEffect(() => {
		// Update the current timestamp every minute
		const timer = setInterval(() => {
			setCurrentTimestamp(getCurrentTimestamp());
		}, 60000); // Updates every minute

		return () => clearInterval(timer);
	}, []);

	return (
		<>
			{getWindowWidth &&
				<div className="bg-white show-in-desktop-view" style={{ position: "sticky", top: "0", zIndex: "999" }}>
					<Navbar />
				</div>
			}
			<div className={currentClasses.fnbdeskdiv}>
				<div className={currentClasses.fnbdeskdivInn}>
					{loading ? (
						<FnbSeatDetailSkeleton />
					) : (
						<>
							<div className="sd-cin-maindiv">
								<FbHeader headname="Seat Details" />
								{pageErrMsg ?
									<div className='nodatamsg'>{pageErrMsg}</div> :
									(movieDetails?.length > 0) &&
									<>
										<div className="sd-cin-addOut">
											<div className="sd-cin-add">
												<p className="sd-cin-head">{movieFetchDetails?.cinemaRe?.name}</p>
												<p className="sd-cin-addp">
													{movieFetchDetails?.cinemaRe.address1} {movieFetchDetails?.cinemaRe.address2} {movieFetchDetails?.cinemaRe?.address3}
												</p>
											</div>
										</div>
										<div className="sb-movielist">
											<div className="sb-movielistInn">
												<p className="sb-movieh">Movie</p>
												<div className="sb-listofMov">
													{movieDetails?.map((movie, index) => (
														<button
															key={index}
															className={`sb-listofBtn ${movieError ? "errorFocus" : ""} ${selectedMovie === movie.filmName ? 'active' : ''}`}
															onClick={() => handleMovieClick(movie.filmName, movie.filmIds, movie.miv, movie.othergenres, movie.otherlanguages)}
														>
															{movie.filmName}
														</button>
													))}
												</div>
											</div>
										</div>

										{/* Showtimes List - Display only if a movie is selected */}
										{selectedMovie && (
											<div className="sb-movielist">
												<div className="sb-movielistInn">
													<p className="sb-movieh">Showtime</p>
													<div className="sb-listofMov">
														{cinemaDetails?.filter((showDetail) =>
															showDetail.movieId === selectedfilmId && (currentTimestamp > showDetail.showTimeStamp && currentTimestamp < showDetail.endTimeStamp - (selectedMovieData?.hideShowBeforeEndTimeinMin * 60 * 1000)))
															?.reduce((uniqueShows, showDetail) => {
																if (!uniqueShows.some((item) => item.showTime === showDetail.showTime)) {
																	uniqueShows.push(showDetail);
																}
																return uniqueShows;
															}, [])?.map((showDetail, index) => (
																<button
																	key={index}
																	className={`sb-listofBtn ${timeError ? "errorFocus" : ""} ${selectedTime === showDetail.showTime ? 'active' : ''}`}
																	onClick={() => handleTimeClick(showDetail.showDate, showDetail.showTime, showDetail.encrypted)}
																>
																	{showDetail.showTime}
																</button>
															))}
													</div>
												</div>
											</div>
										)}

										{/* Audi List - Display only if a showtime is selected */}
										{selectedTime && (
											<div className="sb-movielist">
												<div className="sb-movielistInn">
													<p className="sb-movieh">Audi</p>
													<div className="sb-listofMov">
														{cinemaDetails?.filter(showDetail => showDetail.movieId === selectedfilmId && showDetail.showTime === selectedTime).map((showDetail, index) => (
															<button
																key={index}
																className={`sb-listofBtn ${audiError ? "errorFocus" : ""} ${selectedAudi === showDetail.screenName ? 'active' : ''}`}
																onClick={() => handleAudiClick(showDetail.screenName, showDetail.screenId)}
															>
																{showDetail.screenName}

															</button>
														))}
													</div>
												</div>
											</div>
										)}

										{!seatErrMsg &&
											<>
												{/* Row Selection - Display only if an Audi is selected */}
												{selectedAudi && (
													<div className="sb-movielist">
														<div className="sb-movielistInn">
															<p className="sb-movieh">Row</p>
															<div className="sb-listofMov">
																<button
																	onClick={handleSelectSeatShow}
																	className={`sb-listofBtn ${rowError ? "errorFocus" : ""} ${selectedRow ? 'active' : ''}`}
																>
																	{selectedRow ? `${selectedRow}` : 'Select'}
																</button>
															</div>
														</div>
													</div>
												)}

												{/* Seat Count Selection - Display only if a row is selected */}
												{selectedRow && (
													<div className="sb-movielist">
														<div className="sb-movielistInn">
															<p className="sb-movieh">Seats</p>
															<div className="sb-listofMov">
																<button
																	onClick={handleSelectCountShow}
																	className={`sb-listofBtn ${selectedSeat ? 'active' : ''} ${seatError ? "errorFocus" : ""}`}
																>
																	{selectedSeat ? `${selectedSeat}` : 'Select'}
																</button>
															</div>
														</div>
													</div>
												)}
											</>
										}
									</>
								}
							</div>

							<div className={currentClasses.applyButton}><button className="cmnbtn w100" onClick={handleSubmit}>{loading ? 'Processing...' : 'Deliver to My Seat'}</button></div>

						</>
					)}
				</div>
			</div>


			<Modal
				className={`${currentClasses.centerModal} p0`}
				show={showSelectSeat}
				onHide={handleSelectSeatClose}
				animation={false}
				keyboard={false}
				style={{ backgroundColor: "rgba(0,0,0,0.7)", overflow: "hidden" }}
			>
				<div className="ss-mdiv">

					<div className="m-cus-head">
						<span className="ss-srp">Select Row</span>
						<img
							onClick={handleSelectSeatClose}
							src={closeIcon}
							alt="close"
							style={{ cursor: "pointer" }}
						/>
					</div>
					<div
						style={{
							overflowY: "scroll",
							width: "100%",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							maxHeight: "50vh",
						}}
					>
						{rows?.map((item, index) =>
							<div
								onClick={() => {
									setSelectedRow(item.name);
									handleSelectSeatClose();
								}}
								key={`seat-row-${index}`}
								className="ss-choose-seat"
							>
								<p className="ss-sname">{item.name}</p>
								{selectedRow === item.name && (
									<img
										src={theme === "pvrTheme" ? check : checkInox}
										alt="Selected"
									/>
								)}
							</div>
						)}
					</div>
				</div>
			</Modal>

			<Modal
				className={`${currentClasses.centerModal} p0`}
				show={showSelectCount}
				onHide={handleSelectCountClose}
				animation={false}
				keyboard={false}
				style={{ backgroundColor: "rgba(0,0,0,0.7)", overflow: "hidden" }}
			>
				<div className="ss-mdiv">
					<div className="m-cus-head">
						<span className="ss-srp">Select Seat No.</span>
						<img
							onClick={handleSelectCountClose}
							src={closeIcon}
							alt="close"
							style={{ cursor: "pointer" }}
						/>
					</div>
					<div
						style={{
							overflowY: "scroll",
							width: "100%",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							maxHeight: "50vh",
						}}
					>
						{rows?.map((item, rowIndex) =>
							item.name === selectedRow ? (
								<React.Fragment key={`row-${rowIndex}`}>
									{item.seats?.map((seat, seatIndex) =>
										seat > 0 && (
											<div
												key={`seat-${seatIndex}`}
												onClick={() => {
													setSelectedSeat(seat);
													handleSelectCountClose();
												}}
												className="ss-choose-seat"
											>
												<p className="ss-sname">{seat}</p>
												{selectedSeat === seat && (
													<img
														src={theme === "pvrTheme" ? check : checkInox}
														alt="Selected"
													/>
												)}
											</div>
										)
									)}
								</React.Fragment>
							) : null
						)}
					</div>
				</div>
			</Modal>

			<SweetAlertUtility type={alertDetails.type} title={alertDetails.title} message={alertDetails.message} show={alertDetails.show} onConfirm={hideAlert} />
		</>
	);
};

export default SeatDetails