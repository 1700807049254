import React, { useEffect, useState } from "react";
import savedCardIcon from "../../../../assets/payment/savecard.svg";
import arrow from "../../../../assets/profile/navigatenext.svg";
import { Sidebar } from "primereact/sidebar";
import angle_left from "../../../../assets/mobilemoviedetail/arrow-b.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import { postDataService } from "src/services/postApiService";
import master_card_icon from "../../../../assets/payment/master.png";
import giftCardIcon from "../../../../assets/dropdowIcon/card_giftcard.svg";
import visa_card_icon from "../../../../assets/payment/visa.png";
import { useFormik } from "formik";
import swal from "sweetalert";
import { giftCardBallance } from "src/services/OtherServices";
import { InputText } from "primereact/inputtext";
import { DarkButton } from "src/styled-components/customButtons";

const ProfileGiftCardBalance = () => {
  const theme = localStorage.getItem("theme");
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  const [visibleRight, setVisibleRight] = useState(false);
  const [favouriteCinemasList, setFavouriteCinemasList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertMsg, setShowAlertMsg] = useState(null);
  const [selectedCinema, setSelectedCinema] = useState(null);
  const [loading, setLoading] = useState(false);
  const [savedCardList, setSavedCardList] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [giftCard, setGiftCard] = useState(null);

  useEffect(() => {
    setGiftCard(null);
    giftcarddetails.resetForm();
  }, []);

  const dateformat = (data) =>{
    let date = new Date(data);
    var dd = date.getDate();
    var mm = date.getMonth() + 1; //January is 0!

    var yyyy = date.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    date =  dd + "-" + mm + "-" + yyyy;
    return date.toString();
  }
  const giftcarddetails = useFormik({
    initialValues: {
      "orderId": "",
      "cardNumber": "",
      "cardPin": ""
    },
    validate: (data) => {
      let errors = {};
      console.log(data);
      if(!data.cardNumber) errors.cardNumber = "Please enter card number";
      if(data.cardNumber && data.cardNumber.length < 10) errors.cardNumber = "Please enter correct card number";
      if(!data.cardPin) errors.cardPin = "Please enter card pin";
      if(data.cardPin && data.cardPin.length < 5) errors.cardPin = "Please enter correct card pin";
      return errors;
    },
    onSubmit: (data) => {
      // editProfileRequest(data);
      // console.log("formikData", data);
      // window.alert("form submit");
      checkBallance(data);

    },
  });

  const checkBallance = async (data) => {
    setGiftCard(null);
    setLoading(true);
    try {
      giftCardBallance(data).then((res) => {
        setLoading(false);
        const dt = res.data;
        console.log(dt);
        if (dt.result === "success") {
          let ndt = dt.output;
          setGiftCard(ndt);
        } else {
          giftcarddetails.resetForm();
        //   swal({
        //     text: dt.msg,
        //     timer: 2000,
        //     icon: "error",
        //     // buttons: {
        //     //   cancel: false,
        //     //   confirm: true,
        //     // },
        //     buttons: false
        //   });
        setShowAlert(true);
        setShowAlertMsg(dt);
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const isPersonalDetailsFormFieldValid = (name) =>
    !!(
      giftcarddetails.touched[name] && giftcarddetails.errors[name]
    );
  const getPersonalDetailsFormErrorMessage = (name) => {
    return (
      isPersonalDetailsFormFieldValid(name) && (
        <small className="p-error">{giftcarddetails.errors[name]}</small>
      )
    );
  };

  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };

  return (
    <>
      <div
        className="profile-img border-line-profile"
        onClick={() => {
          setVisibleRight(true);
          setGiftCard(null);
          giftcarddetails.resetForm();
        }}
      >
        <div className="profile-text profile-account">
          <h4>
            <span className="avtar">
              <img src={giftCardIcon} alt="" className="" />
            </span>
          </h4>
          <div
            className="profile-text-info"
            onClick={() => hitGa("gift_cards")}
          >
            <h2 onClick={() => hitGa("gift_cards")}>Gift Card Balance</h2>
          </div>
        </div>
        <div className="img-arrow" onClick={() => hitGa("gift_cards")}>
          <img src={arrow} alt="" className="" />
        </div>
      </div>
      <div>
        <Sidebar
          visible={visibleRight}
          showCloseIcon={false}
          className="watchlist-show-m"
          style={{ width: "100vw" }}
          position={"right"}
          onHide={() => {
            setVisibleRight(false);
          }}
          dismissable={false}
          blockScroll={true}
          fullScreen
        >
          <>
            <div
              className="bg-white show-in-mobile-view"
              style={{ position: "sticky", top: "0", zIndex: "999" }}
            >
              <div className="watch-list-mobile-box">
                <span onClick={() => setVisibleRight(false)}>
                  <img src={angle_left} alt="" className="" />{" "}
                </span>
                <h4>Gift Card Balance</h4>
              </div>
            </div>
            <div>
              {loading ? (
                <span>
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "2rem" }}
                  />
                </span>
              ) : (
                <>
                    <div className="card p-3">
                        <div className="row">
                        <div className="col-md-12">
                            {/* <div className="conatct-head">
                            <div className="contact-title">
                                <h3>Gift Card Balance</h3>
                            </div>
                            <div className="edit-details pointer"></div>
                            </div> */}
                            <div className="profile-divider"></div>
                            <form onSubmit={giftcarddetails.handleSubmit}>
                            <div className="row">
                            {!giftCard?<>
                                <div className="col-md-6">
                                <div className="mobile">
                                    <span className="p-float-label">
                                    <InputText
                                        id="cardNumber"
                                        value={giftcarddetails.values.cardNumber}
                                        onChange={giftcarddetails.handleChange}
                                        maxLength={20}
                                        keyfilter={/^\d*$/}
                                    />
                                    <label htmlFor="ssn_input">Card Number</label>
                                    </span>
                                    {/* <div className="advertise-icon">
                                    <img src={profile} alt="" className="img-fluid" />
                                    </div> */}
                                    {getPersonalDetailsFormErrorMessage("cardNumber")}
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div className="mobile">
                                    <span className="p-float-label">
                                    <InputText
                                        id="cardPin"
                                        value={giftcarddetails.values.cardPin}
                                        onChange={giftcarddetails.handleChange}
                                        maxLength={20}
                                        keyfilter={/^\d*$/}
                                    />
                                    <label htmlFor="ssn_input">Pin Number</label>
                                    </span>
                                    {/* <div className="advertise-icon">
                                    <img src={mail} alt="" className="img-fluid" />
                                    </div> */}
                                    {getPersonalDetailsFormErrorMessage("cardPin")}
                                </div>
                                </div>
                                </>:""}
                                {giftCard?
                                <div className="col-md-6">
                                <div className="mobile">
                                    <div className="personal-details-profile">
                                    <div className="filed-details">
                                        <h6>Gift Card Balance:</h6>
                                    </div>
                                    <div className="fill-details">
                                        <h6>{giftCard?.balance}</h6>
                                    </div>
                                    </div>
                                    <div className="personal-details-profile">
                                    <div className="filed-details">
                                        <h6>Gift Card Expiry:</h6>
                                    </div>
                                    <div className="fill-details">
                                        <h6>{dateformat(giftCard?.expiry)}</h6>
                                    </div>
                                    </div>
                                    {/* <p className="p-1">Gift Card Ballance: {giftCard?.balance}</p>
                                    <p className="p-1">Gift Card Expiry: {dateformat(giftCard?.expiry)}</p> */}
                                </div>
                                </div>
                                :""}
                                {!giftCard?
                                <div className="col-md-12">
                                <div className="edit-divider"></div>
                                <div className="edit-profile">
                                    {/* <div className="save-edit-btn">
                                    <AlsoAddBtn
                                        className="cancel-btn-edit"
                                        type="button"
                                        onClick={() => {
                                        setViewMode1(0);
                                        personalDetailsFormik.resetForm();
                                        }}
                                    >
                                        Cancel
                                    </AlsoAddBtn>
                                    </div> */}
                                    <div className="save-btn-edits">
                                    <DarkButton
                                        className={
                                        loading ? "mobile-save-btn-edit btn-opacity" : "mobile-save-btn-edit"
                                        }
                                        type="submit"
                                    >
                                        check
                                        {loading?
                                        <i
                                        className="pi pi-spin pi-spinner"
                                        style={{ fontSize: "2rem" }}
                                        />
                                        :""}
                                    </DarkButton>
                                    </div>
                                </div>
                                </div>
                                :""}
                            </div>
                            </form>
                        </div>
                        </div>
                    </div>
                </>
              )}
            </div>
            <div>
              <SweetAlert
                warning
                show={showAlert}
                title={""}
                onConfirm={() => setShowAlert(false)}
                onCancel={() => setShowAlert(false)}
                confirmBtnText="ok"
                confirmBtnCssClass={
                  theme === "inoxTheme"
                    ? "confirm-btn-inox border-0"
                    : "confirm-btn-pvr border-0"
                }
                showCancel={false}
                showConfirm={true} //not Display the "OK" button
                timeOut={3000}
                className="sucess-popup"
              >
                {showAlertMsg?.msg}
              </SweetAlert>
            </div>
          </>
        </Sidebar>
      </div>
    </>
  );
};

export default ProfileGiftCardBalance;
