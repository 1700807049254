import React, { useEffect, useState } from 'react'
import FbHeader from 'src/views/food-and-beverage/component/FbHeader'
import batmaan from "../../../../assets/food/batmaan-inside.png";
import FoodOffers from "../../../../assets/food/foodoffers.png";
import noImage from "../../../../assets/food/noimage.png";
import { useLocation, useNavigate } from 'react-router-dom';
import { format, isValid, parse } from 'date-fns';
import Navbar from 'src/views/common/navbar/Navbar';
import { getResponsiveClasses } from 'src/views/food-and-beverage/component/ResponsiveClasses';
import html2pdf from 'html2pdf.js';

const FoodBookingDetails = () => {
    const navigate = useNavigate();
    const { currentClasses, getWindowWidth } = getResponsiveClasses();
    const location = useLocation();

    const historyIdData = location?.state?.historyIdData?.[0];
    // const ftrIndex = location?.state?.filterIndex;
    // console.log(historyIdData, "historyIdDatahistoryIdData");


    const handleImageError = (e) => {
        e.target.src = noImage;
    };

    const showTimeStr = historyIdData.showTimeStr;
    const bookingTime = historyIdData?.bookingTime;
    const showTime = historyIdData?.orderTicket?.showTime;
    const parsedDateTime = historyIdData?.deliveryMode === "ONSEAT" ? parse(showTimeStr, "EEE, dd MMM, hh:mm a", new Date()) : parse(bookingTime, "yyyy-MM-dd HH:mm:ss", new Date());

    const formattedShowTimeDate =
        parsedDateTime && isValid(parsedDateTime)
            ? format(parse(showTime, "EEE, MMM dd, hh:mm a", new Date()), "eee, MMM dd, hh:mm a")
            : format(parse(bookingTime, "yyyy-MM-dd HH:mm:ss", new Date()), "eee, MMM dd, hh:mm a");

    const formattedDate = parsedDateTime && isValid(parsedDateTime) ? format(parsedDateTime, "eee, MMM dd") : "-";
    const formattedTime = parsedDateTime && isValid(parsedDateTime) ? format(parsedDateTime, "hh:mm a") : "-";
    // console.log(historyIdData)

    const gaTagViewStatus = (item) => {
        let dataLayer = window["dataLayer"] || [];
        const foodNames = item?.orderFood?.foods?.map(item => item.name).join(", ");
        const foodTypes = item?.orderFood?.foods?.map(item => item.foodType).join(", ");
        const prices = item?.orderFood?.foods?.map(item => (item.price / 100)).join(", ");
        dataLayer.push({
            event: 'view_food_order_status',
            food_name: foodNames,
            food_type: foodTypes == 0 ? "Non Veg" : foodTypes == 1 ? "Veg" : "",
            price: prices
        });
    }
    return (
        <>
            {getWindowWidth &&
                <div className="bg-white show-in-desktop-view" style={{ position: "sticky", top: "0", zIndex: "999" }}>
                    <Navbar />
                </div>
            }
            <div className={currentClasses.fnbdeskdiv}>
                <div className={currentClasses.fnbdeskdivInn}>
                    <FbHeader className="inrHdrPage" headname="Your Order" filterIndex={location?.state?.filterIndex} />
                    <div id='foodbooking-content' className='contentPage' >
                        <div className="bkngdlts">

                            {(historyIdData?.orderFilmCinema?.language || historyIdData?.orderFilmCinema?.genre || historyIdData?.orderFilmCinema?.filmName || historyIdData?.orderFilmCinema?.posterVert) && (
                                <div className="bdmovdts">
                                    <div className="movieImg">
                                        <img
                                            src={historyIdData?.orderFilmCinema?.posterVert || noImage}
                                            width={getWindowWidth ? 120 : 84}
                                            alt={historyIdData?.orderFilmCinema?.filmName || 'No image available'}
                                            onError={handleImageError}
                                        // crossOrigin="anonymous"
                                        />
                                    </div>
                                    <div className="bdmovdets">
                                        <div className='bdmovdets'>
                                            <h3 className="movTitle">{historyIdData?.orderFilmCinema?.filmName}</h3>
                                            <ul className="movTags">
                                                {historyIdData?.orderFilmCinema?.certificate && <li>{historyIdData?.orderFilmCinema?.certificate || ""}</li>}
                                                {historyIdData?.orderFilmCinema?.genre && <li>{historyIdData?.orderFilmCinema?.genre || ""}</li>}
                                            </ul>
                                            <p className='cmnfnt mvlang'>
                                                {historyIdData?.orderFilmCinema?.language} {historyIdData?.orderFilmCinema?.format && `(${historyIdData?.orderFilmCinema?.format})`}
                                            </p>
                                        </div>
                                        <p className="cmnfnt movAdds">
                                            {historyIdData?.orderFilmCinema?.theaterName},<br /> {historyIdData?.orderFilmCinema?.cityName}
                                            {/* {historyIdData?.cinemaLocation} */}
                                        </p>
                                    </div>
                                </div>
                            )}

                            {/* {!showView && */}
                            <div className="vwcontent">
                                {historyIdData?.deliveryMode === "PICKUP" && <h5 className='pickTheater'>{historyIdData?.orderFilmCinema?.theaterName}, {historyIdData?.orderFilmCinema?.cityName}</h5>}
                                <div className="movdtme">
                                    <div className='mvdt'>
                                        <p className='inrTitle'>{historyIdData?.deliveryMode === "PICKUP" ? "Order Date" : "Date"}</p>
                                        <h5>{formattedDate}</h5>
                                    </div>
                                    <div className='mvdt'>
                                        <p className='inrTitle'>{historyIdData?.deliveryMode === "PICKUP" ? "Order Time" : "Time"}</p>
                                        <h5>{formattedTime}</h5>
                                    </div>
                                    <div className='mvdt'>
                                        {(historyIdData?.orderFilmCinema?.language || historyIdData?.orderFilmCinema?.genre || historyIdData?.orderFilmCinema?.filmName || historyIdData?.orderFilmCinema?.posterVert) && (
                                            <>
                                                <p className='inrTitle'>Seats Info</p>
                                                <h5>
                                                    {historyIdData?.orderTicket?.audi}
                                                    {historyIdData?.screenType && ` - ${historyIdData?.screenType?.toLowerCase() === "i" ? "Insignia" : "Normal"}`}
                                                </h5>
                                                <ul className='seatul'>
                                                    {historyIdData?.orderTicket?.seats?.split(",").map((seat) => (
                                                        <li>{seat}</li>
                                                    ))}
                                                </ul>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="fdcnter">
                                    <h3>{historyIdData?.deliveryMode !== "PICKUP" ? "Deliver to Seat" : "Picked up from Counter"}</h3>
                                    {historyIdData?.orderFood?.foods?.length > 0 && (
                                        <p>
                                            {historyIdData?.orderFood?.foods.reduce((total, item) => {
                                                const itemQuantity = item.quantity || 0;
                                                // const addonQuantity = item?.addons?.reduce((addonTotal, addon) => {
                                                //     return addonTotal + (addon.quantity || 0);
                                                // }, 0);
                                                return total + itemQuantity;// + addonQuantity;
                                            }, 0)}{" "}
                                            Food Items Ordered
                                        </p>
                                    )}
                                    {historyIdData?.bookingId && <div className="ordid">Booking ID: <span className='tckid'>{historyIdData?.bookingId}</span></div>}
                                    {historyIdData?.deliveryMode === "PICKUP" && formattedShowTimeDate && <div className="ordpckup">Pick-up time: {formattedShowTimeDate}</div>}
                                </div>
                                <div className='smrttl'>
                                    <table width="100%">
                                        {historyIdData?.orderFood?.foods.flatMap((fooditems) => [
                                            fooditems,
                                            ...(fooditems?.upsell || []),
                                        ]).map((fooditems, index) => {
                                            const addOnTotal = fooditems?.addons?.reduce((total, addon) => {
                                                return total + addon?.price * addon?.quantity;
                                            }, 0);
                                            const itemTotal = (fooditems?.quantity * fooditems?.price) + addOnTotal;
                                            return (
                                                <tr key={`${fooditems?.name}-${index}`}>
                                                    <td className="flxtd">
                                                        {/* {fooditems?.veg ? (
                                                            <svg
                                                                width="14"
                                                                height="14"
                                                                viewBox="0 0 14 14"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <rect
                                                                    x="0.5"
                                                                    y="0.5"
                                                                    width="13"
                                                                    height="13"
                                                                    rx="1.5"
                                                                    stroke="#149752"
                                                                />
                                                                <circle
                                                                    cx="6.99984"
                                                                    cy="6.99935"
                                                                    r="4.08333"
                                                                    fill="#149752"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                width="14"
                                                                height="14"
                                                                viewBox="0 0 12 12"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <rect
                                                                    x="0.961426"
                                                                    y="0.964844"
                                                                    width="10.0769"
                                                                    height="10.0769"
                                                                    rx="1.5"
                                                                    stroke="#87001B"
                                                                />
                                                                <path
                                                                    d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z"
                                                                    fill="#87001B"
                                                                />
                                                            </svg>
                                                        )} */}

                                                        {fooditems?.foodType === 1 ?
                                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                                                                <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                                                            </svg>
                                                            : fooditems?.foodType === 0 ?
                                                                <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                                                    <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                                                    <clipPath id="clip0_3452_1183">
                                                                        <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                                                                    </clipPath>
                                                                </svg>
                                                                :
                                                                <p className="emtyIcon"></p>
                                                        }

                                                        <div className="prdtitle">
                                                            <span>{fooditems?.quantity} x {fooditems?.name?.toLowerCase()}</span>
                                                            {fooditems?.addons?.length > 0 && (
                                                                <div className="addons">
                                                                    <em className="fnb-c-addons">
                                                                        Add-ons:
                                                                        {fooditems?.addons.map((addonsItems, idx) => (
                                                                            <p
                                                                                key={`${addonsItems?.name}-${idx}`}
                                                                                className="cart-addonP"
                                                                            >
                                                                                <span>
                                                                                    {addonsItems?.quantity} x {addonsItems?.name?.toLowerCase()}
                                                                                </span>
                                                                                <span className="os-statusAdd">
                                                                                    ₹{((Number(addonsItems?.price) * addonsItems?.quantity) / 100).toFixed(2)}
                                                                                </span>
                                                                            </p>
                                                                        ))}
                                                                    </em>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>₹{((fooditems?.price * fooditems?.quantity) / 100).toFixed(2)}</td>
                                                </tr>
                                            );
                                        })}
                                    </table>

                                </div>
                            </div>
                        </div>
                        <div className="totalsmry">
                            <table width="100%">
                                <tr>
                                    <td>Sub Total</td>
                                    {/* <td>{(historyIdData?.orderFood?.fbTotalPrice - historyIdData?.orderTicket?.ticketTax / 100)?.toFixed(2) || "-"}</td> */}
                                    <td className='prcfont'>
                                        ₹{parseFloat(historyIdData?.orderFood?.fbTotalPrice.replace(/,/g, "")).toFixed(2) || "-"}
                                    </td>
                                </tr>
                                {historyIdData?.orderTicket?.discount > 0 &&
                                    <tr>
                                        <td style={{ color: "green" }}>Discount</td>
                                        <td className='prcfont' style={{ color: "green" }}>-₹{parseFloat(historyIdData?.orderTicket?.discount).toFixed(2)}</td>
                                    </tr>
                                }
                                <tr>
                                    <th>Total</th>
                                    <th className='prcfont'>
                                        ₹{(historyIdData?.orderTicket?.discount > 0 ? parseFloat(historyIdData?.orderTicket?.balanceAmount) : parseFloat(historyIdData?.orderFood?.fbTotalPrice.replace(/,/g, ""))).toFixed(2) || "-"}
                                    </th>
                                </tr>
                                {/* <tr>
                                    <td colSpan={2} className='txtitle'>Taxes Fees</td>
                                </tr>
                                <tr>
                                    <td>GST {historyIdData?.orderTicket?.gstInvoiceNo && `(GSTIN-${historyIdData?.orderTicket?.gstInvoiceNo})`}</td>
                                    <td>{(historyIdData?.orderTicket?.ticketTax / 100)?.toFixed(2) || "-"}</td>
                                </tr> */}
                                {/* <tr>
                                    <td>Order ID</td>
                                    <td className='tckid'>{historyIdData?.bookingId}</td>
                                </tr> */}
                            </table>
                        </div>

                        <div className={`${currentClasses.dskfd} fixbtmbtn `}>
                            <button onClick={(e) => {
                                e.stopPropagation();
                                gaTagViewStatus(historyIdData);
                                localStorage.setItem("viewstatus", true);
                                navigate(`/order-status?bookingid=${historyIdData.en_bid}`, { state: { mobHistory: "mobHistory", filterIndex: location?.state?.filterIndex } });
                            }}
                                className="ordstatus">View Order Status</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default FoodBookingDetails

