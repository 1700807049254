import React, { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import "./QrCodeView.css";
import arrowback from "../../../../assets/passport/mdi_arrow_back.png";
import { getVoucherDetails } from "src/services/CouponPayment";
import CinemasessionSkeleton from "src/skeletons/CinemasessionSkeleton";

function VoucherHistory({ visible, content, setVisible }) {
  const [vdata, SetVdata] = useState(null);
  const [msg, SetMsg] = useState(null);
  const [loading, SetLoading] = useState(false);
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  useEffect(() => {
    if (content) {
      SetLoading(true);
      getVoucherDetails(content).then((res) => {
        const dt = res.data;
        console.log(dt);
        SetLoading(false);
        if (dt.result === "success") {
          SetVdata(dt.output);
          SetMsg(dt.msg);
        } else {
          SetVdata(null);
        }
      });
    }
  }, [content]);
  return (
    <div>
      <Sidebar
        visible={visible}
        //   icons={proceedbtn}
        showCloseIcon={false}
        style={isMobile ? { height: "100%", width: "100%" } : {}}
        position={isMobile ? "center" : "right"}
        className="voucher-details-popup"
        // className="flexi-terms-cond"
        onHide={() => {
          setVisible(false);
        }}
        dismissable={true}
      >
        <div className="vhistory-container">
          <div className="passport-right show-in-mobile-view">
            <div className="colse-btn pvr-inox-heading">
              <div className="payment-mobile-header">
                <div className="payment-header-item">
                  <div
                    className="arrow-img"
                    onClick={() => {
                      setVisible(false);
                    }}
                  >
                    <img src={arrowback} alt="Big Image" />
                  </div>
                  <div className="inox-passport">
                    <h2>Voucher Details</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* mobile view */}
          {/*desktop view  */}
          <div className="passport-right show-in-desktop-view">
            <div className="colse-btn pvr-inox-heading">
              <div className="row">
                <div className="col-10">
                  <h3>Voucher Details</h3>
                </div>
                <div className="col-2">
                  <span
                    onClick={() => {
                      setVisible(false);
                    }}
                  >
                    <i
                      className="pi pi-times"
                      style={{ color: "#000", cursor: "pointer" }}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <CinemasessionSkeleton />
          ) : (
            <>
              <div className="historuy-voucher">
                {vdata &&
                  vdata.map((item, idx) => {
                    return (
                      <>
                        {/* mobile view */}

                        <div className="vhistory-box">
                          <div className="vhistory-item">
                            <span className="vhistory-label">
                              Redeemed Time
                            </span>
                            <span className="vhistory-value">
                              {item?.transactionTime}
                            </span>
                          </div>
                          <div className="vhistory-item">
                            <span className="vhistory-label">
                              Validity (1 Day)
                            </span>
                            <span className="vhistory-value">
                              {item?.expiryDate}
                            </span>
                          </div>
                          <div className="vhistory-item">
                            <span className="vhistory-label">Location</span>
                            <span className="vhistory-value">
                              {item?.theatreName}
                            </span>
                          </div>
                          <div className="vhistory-item">
                            <span className="vhistory-label">
                              Amount Redeemed
                            </span>
                            <span className="vhistory-value">
                              ₹{item?.discount}
                            </span>
                          </div>
                          <div className="vhistory-item">
                            <span className="vhistory-label">
                              Balance Amount
                            </span>
                            <span className="vhistory-value">
                              ₹{item?.balAmount}
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  })}

                {vdata && vdata?.length == 0 ? (
                  <>
                    <div className="vhistory-box">
                      <div className="fail-msg-box">
                        <div className="row">
                          <div className="col d-flex justify-content-center align-items-center">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: msg,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </div>
      </Sidebar>
    </div>
  );
}

export default VoucherHistory;
