import React, { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import filterM from "../../../assets/nav-icon/filter-m.svg";
import FNBICON from "../../../assets/nav-icon/fnb-home-icon.svg";
import FilterBlack from "../../../assets/static-page/filter-black.svg";
import arrowUp from "../../../assets/brand/filter-icon.svg";
import arrowDown from "../../../assets/brand/arrow_down.svg";
import home from "../../../assets/brand/Home_grey.png";
import HomeBlack from "../../../assets/brand/Home_black1.png";
import { useNavigate } from "react-router-dom";
import cinemas from "../../../assets/brand/cinema.svg";
import CinemasBlack from "../../../assets/brand/Cinemas_black.png";
import star from "../../../assets/brand/star.svg";
import StartBlack from "../../../assets/brand/star_black.png";
import OfferStroke from "../../../assets/brand/offerStroke.svg";
import OfferBlack from "../../../assets/brand/offerblack.svg";
import comingsoon from "../../../assets/brand/movie.svg";
import Comingsoon_black from "../../../assets/brand/coming_soon_black.png";
import more from "../../../assets/brand/more.svg";
import InvestorBlack from "../../../assets/nav-icon/investor-black.svg";
import promoteEarn from "../../../assets/nav-icon/Promote.svg";
import Screenit_icon from "../../../assets/nav-icon/screenit_logo.png";
import filmFestival from "../../../assets/nav-icon/Film-festival-1.png";
import ODRBlack from "../../../assets/nav-icon/odr-black.svg";
import "./mobilefooter.css";
import { DarkButton, LightButton } from "src/styled-components/customButtons";
import giftCardIcon from "../../../assets/dropdowIcon/card_giftcard.svg";
import kotak from "../../../assets/dropdowIcon/koktacredit.svg";
import advertiseIcon from "../../../assets/dropdowIcon/newspaper_black.svg";
import pristineIcon from "../../../assets/dropdowIcon/auto_awesome.svg";
import pvrcareIcon from "../../../assets/dropdowIcon/care.svg";
import merchandiseIcon from "../../../assets/dropdowIcon/local_mall_black.svg";
import movieOnDemand from "../../../assets/dropdowIcon/theaters_black.svg";
import { setMobileFilterValues } from "../../../redux/actions/mobileFilterAction";
import { useDispatch, useSelector } from "react-redux";
import { setLoginViewMode } from "src/redux/actions/loginViewAction";
import * as appConstants from "../../../constants/index";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import Login from "src/views/common/login/Login";
import { getSplashData } from "src/services/OtherServices";
import MoreDropdown from "src/views/common/navbar/more-dropdown/MoreDropdown";
import homeIcon from "../../../assets/mobile-footer-icon/home.svg";
import homeActiveIcon from "../../../assets/mobile-footer-icon/home-active.svg";
import cinemIcon from "../../../assets/mobile-footer-icon/cinema_icon.svg";
import cinemaInoxActiveIcon from "../../../assets/mobile-footer-icon/inoxActive.svg";
import cinemaPvrActiveIcon from "../../../assets/mobile-footer-icon/cinemaActive.svg";
import comingSoonIcon from "../../../assets/mobile-footer-icon/comingsoon.svg";
import comingSoonActiveIcon from "../../../assets/mobile-footer-icon/comingsoon-active.svg";
import passportIcon from "../../../assets/mobile-footer-icon/passport.svg";
import passportActiveIcon from "../../../assets/mobile-footer-icon/passport-active.svg";
import moreIcon from "../../../assets/mobile-footer-icon/more.svg";

const MobileFooter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = localStorage.getItem("theme");
  const [visibleBottom, setVisibleBottom] = useState(false);
  const currentPath = window.location.pathname.split("/");
  const [showLanguage, setShowLanguage] = useState(true);
  const [showGenre, setShowGenre] = useState(false);
  const [showAccessiblity, setShowAccessiblity] = useState(true);
  const [selectedLanguageIds, setSelectedLanguageIds] = useState([]);
  const [selectedGenreIds, setSelectedGenreIds] = useState([]);
  const [selectedAccessiblityId, setSelectedAccessiblityId] = useState(false);
  const [filterUIViewMode, setFilterUIViewMode] = useState(0);
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  const [spalshData, setSpalshData] = useState(null);
  const loginVewMode = useSelector((state) => state.loginViewMode.value);
  const countForSearch = useSelector(
    (state) => state?.mobileFilterReduce.status
  );
  const filterLanguage = JSON.parse(localStorage.getItem("m-filter-lng"));
  const filterGeners = JSON.parse(localStorage.getItem("m-filter-grs"));
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const activeCurrentPathVal =
    currentPath[1] === ""
      ? 0
      : currentPath[1] === "cinemas"
      ? 1
      : currentPath[1] === "mobile-map"
      ? 1
      : currentPath[1] === "offers"
      ? 2
      : currentPath[1] === "coming-soon"
      ? 3
      : 4;

  const [viewMode, setViewMode] = useState(activeCurrentPathVal);

  const accessiblityList = ["Subtitle"];

  useEffect(() => {
    profileRequest();
  }, [localStorage.getItem("city")]);

  const findActiveLanguageFilter = (lngId) => {
    const isPresent = selectedLanguageIds.some((x) => x === lngId);
    if (isPresent) {
      const filterId = selectedLanguageIds.filter((item) => {
        return item !== lngId;
      });
      setSelectedLanguageIds([...filterId]);
    } else {
      setSelectedLanguageIds([lngId, ...selectedLanguageIds]);
    }
  };

  const findActiveGenreFilter = (lngId) => {
    const isPresent = selectedGenreIds.some((x) => x === lngId);
    if (isPresent) {
      const filterId = selectedGenreIds.filter((item) => {
        return item !== lngId;
      });
      setSelectedGenreIds([...filterId]);
    } else {
      setSelectedGenreIds([lngId, ...selectedGenreIds]);
    }
  };

  const findActiveAccessiblityFilter = (lngId) => {
    setSelectedAccessiblityId(!selectedAccessiblityId);
  };

  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };
  const hitMoreGa = (click_text) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: "top_navi_more_click",
      click_text: click_text,
    });
  };

  const profileRequest = async () => {
    getSplashData()
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          setSpalshData(dt.output);
        } else {
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const moreUI = () => {
    return (
      <>
        <div className="more-show-drawer">
          <ul>
            {spalshData?.pvrlive ? (
              <li
                className="more-item"
                onClick={() => {
                  hitMoreGa("Live Movies");

                  if (currentPath === "/live-movies") {
                    window.location.href = "/live-movies";
                  } else {
                    navigate("/live-movies"); // Use your navigation function here
                  }
                }}
              >
                <img src={filmFestival} alt="" className="mx-2" />
                {spalshData?.pvrlive}
              </li>
            ) : (
              ""
            )}
            <li
              className="more-item"
              onClick={() => {
                const currentPath = window.location.pathname;
                hitMoreGa("Gift Cards");

                if (currentPath === "/gift-cards") {
                  window.location.href = "/gift-cards";
                } else {
                  navigate("/gift-cards");
                }
              }}
            >
              <img src={giftCardIcon} alt="" className="mx-2" />
              Gift Card
            </li>
            {spalshData?.passport == "true" ? (
              <li
                className="more-item"
                onClick={() => {
                  setViewMode(2);
                  hitGa("top_navi_offers");
                  const currentPath = window.location.pathname;
                  if (currentPath === "/offers") {
                    window.location.href = "/offers";
                  } else {
                    navigate("/offers"); // Use your navigation function here
                  }
                }}
              >
                <img
                  src={viewMode === 2 ? OfferBlack : OfferStroke}
                  alt=""
                  width={20}
                  className="mx-2"
                />
                Offer
              </li>
            ) : (
              // <li
              //   className="more-item"
              //   onClick={() => {
              //     // const currentPath = window.location.pathname;
              //     // if (currentPath === "/passport") {
              //     //   window.location.href = "/passport";
              //     // } else {
              //     //   if (loginInfo?.data?.mob) {
              //     //     navigate("/passport");
              //     //   } else {
              //     //     dispatch(setLoginViewMode({ value: true }));
              //     //   }
              //     // }
              //     window.open("https://passport.pvrinox.com/", '_blank').focus();
              //   }}
              // >
              //   <img src={StartBlack} alt="" width={20} className="mx-2" />
              //   Passport
              // </li>
              ""
            )}
            <li
              className="more-item"
              onClick={() => {
                const currentPath = window.location.pathname;
                hitMoreGa("Advetise");

                if (currentPath === "/advertise") {
                  window.location.href = "/advertise";
                } else {
                  navigate("/advertise"); // Use your navigation function here
                }
              }}
            >
              <img src={advertiseIcon} alt="" className="mx-2" />
              Advertise
            </li>
            <li
              className="more-item"
              onClick={() => {
                hitMoreGa("Merchandise");

                window.open(
                  theme === "inoxTheme"
                    ? "https://inox.macmerise.com/"
                    : "https://pvr.macmerise.com/?user_agent=pvr/",
                  "_blank"
                );
              }}
            >
              <img src={merchandiseIcon} alt="" className="mx-2" />
              Merchandise
            </li>
            <li
              className="more-item"
              onClick={() => {
                hitMoreGa("Investor Section");

                if (currentPath === "/investors-section") {
                  window.location.href = "/investors-section";
                } else {
                  navigate("/investors-section"); // Use your navigation function here
                }
              }}
            >
              <img src={InvestorBlack} alt="" className="mx-2" />
              Investor Section
            </li>
            <li
              className="more-item"
              onClick={() => {
                hitMoreGa("Merchandise");
                window.open("https://smartodr.in/login", "_blank").focus();
              }}
            >
              <img src={ODRBlack} alt="" className="mx-2" />
              ODR Portal
            </li>
            <li
              className="more-item"
              onClick={() => {
                hitGa("screenit_click");
                if (currentPath === "/screenit") {
                  window.location.href = "/screenit";
                } else {
                  navigate("/screenit"); // Use your navigation function here
                }
              }}
            >
              <img src={Screenit_icon} alt="" className="mx-2" />
              ScreenIT
            </li>
            <li
              className="more-item"
              onClick={() => {
                hitMoreGa("/promote-earnt");

                if (currentPath === "/promote-earn") {
                  window.location.href = "/promote-earn";
                } else {
                  navigate("/promote-earn");
                }
              }}
            >
              <img src={promoteEarn} alt="" className="mx-2" />
              Promote & Earn
            </li>

            <li>
              <div className="menu-divider"></div>
            </li>
            <li
              className="more-item mx-2"
              onClick={() => {
                hitMoreGa("Feedback");
                hitGa("feedback");
                const currentPath = window.location.pathname;
                if (currentPath === "/feedback") {
                  window.location.href = "/feedback";
                } else {
                  navigate("/feedback");
                }
              }}
            >
              Customer Experience
            </li>
            {theme === "inoxTheme" ? (
              ""
            ) : (
              
              <li
                className="more-item mx-2"
                onClick={() => {
                  hitMoreGa("Privilege Plus");

                  const currentPath = window.location.pathname;
                  if (currentPath === "/kotak-pp-plus") {
                    window.location.href = "/kotak-pp-plus";
                  } else {
                    navigate("/kotak-pp-plus"); // Use your navigation function here
                  }
                }}
              >
                Privilege Plus
              </li>
            )}
            <li
              className="more-item mx-2"
              onClick={() => {
                hitMoreGa("About us");

                const currentPath = window.location.pathname;
                if (currentPath === "/about-us") {
                  window.location.href = "/about-us";
                } else {
                  navigate("/about-us");
                }
              }}
            >
              About us
            </li>
            <li
              className="more-item mx-2"
              onClick={() => {
                hitGa("experiences");
                hitMoreGa("Experiences");

                const currentPath = window.location.pathname;
                if (currentPath === "/cinema-format") {
                  window.location.href = "/cinema-format";
                } else {
                  navigate(`/experience/${localStorage.getItem("city")}`);
                }
              }}
            >
              Experiences
            </li>
            <li
              className="more-item mx-2"
              onClick={() => {
                hitGa("coroporate-booking");
                hitMoreGa("coroporate-booking");

                const currentPath = window.location.pathname;
                if (currentPath === "/bulkbooking/coroporate-booking") {
                  window.location.href = "/bulkbooking/coroporate-booking";
                } else {
                  navigate(`/bulkbooking/coroporate-booking`);
                }
              }}
            >
              Corporate Booking
            </li>
            <li
              className="more-item mx-2"
              onClick={() => {
                hitGa("oscar");
                hitMoreGa("oscar");

                const currentPath = window.location.pathname;
                if (currentPath === "/oscar") {
                  window.location.href = "/oscar";
                } else {
                  navigate(`/oscar`);
                }
              }}
            >
              Oscar
            </li>
            <li
              className="more-item mx-2"
              onClick={() => {
                hitMoreGa("FAQ");

                const currentPath = window.location.pathname;
                if (currentPath === "/faq") {
                  window.location.href = "/faq";
                } else {
                  navigate("/faq");
                }
              }}
            >
              FAQs
            </li>
            <li
              className="more-item mx-2"
              onClick={() => {
                hitMoreGa("Career");
                hitGa("career");

                const currentPath = window.location.pathname;
                if (currentPath === "/careers-us") {
                  window.location.href = "/careers-us";
                } else {
                  navigate("/careers-us");
                }
              }}
            >
              Career
            </li>
            
            <li
              className="more-item mx-2"
              onClick={() => {
                const currentPath = window.location.pathname;
                if (currentPath === "/news") {
                  window.location.href = "/news";
                } else {
                  navigate("/news");
                }
              }}
            >
              News
            </li>
            <li
              className="more-item mx-2"
              onClick={() => {
                const currentPath = window.location.pathname;
                if (currentPath === "/investors-section") {
                  window.location.href = "/investors-section";
                } else {
                  navigate("/investors-section");
                }
              }}
            >
              Investors Section
            </li>
            <li
              className="more-item mx-2"
              onClick={() => {
                hitMoreGa("NVSP");

                window.open(
                  theme === "inoxTheme"
                    ? "https://voters.eci.gov.in/"
                    : "https://voters.eci.gov.in/",
                  "_blank"
                );
              }}
            >
              NVSP
            </li>
            <li
              className="more-item mx-2"
              onClick={() => {
                hitMoreGa("NEST");

                window.open("https://pvrnest.godaddysites.com/home", "_blank");
              }}
            >
              NEST
            </li>
          </ul>
        </div>
      </>
    );
  };

  const filterUI = () => {
    return (
      <>
        <div className="filter-box">
          <div className="filter-head">
            <h3>Filters</h3>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="languages-filter">
                <div
                  className="languages-box"
                  onClick={() => {
                    setShowLanguage(!showLanguage);
                  }}
                >
                  <div className="languages-show">
                    <h2>Languages</h2>
                  </div>
                  <div className="languages-icon">
                    <img
                      src={showLanguage ? arrowUp : arrowDown}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className={showLanguage ? "languages-perfer" : "d-none"}>
                  <ul>
                    {filterLanguage &&
                      filterLanguage.map((item, idx) => (
                        <li
                          className={
                            !selectedLanguageIds.some((x) => x === item)
                              ? ""
                              : theme === "inoxTheme"
                              ? "active-languages-inox"
                              : "active-languages-pvr"
                          }
                          key={idx}
                          onClick={() => {
                            findActiveLanguageFilter(item);
                          }}
                        >
                          {item}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="languages-filter">
                <div
                  className="languages-box"
                  onClick={() => {
                    setShowGenre(!showGenre);
                  }}
                >
                  <div className="languages-show">
                    <h2>Genre</h2>
                  </div>
                  <div className="languages-icon">
                    <img
                      src={showLanguage ? arrowUp : arrowDown}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className={showGenre ? "languages-perfer" : "d-none"}>
                  <ul>
                    {filterGeners &&
                      filterGeners.map((item, idx) => (
                        <li
                          className={
                            !selectedGenreIds.some((x) => x === item)
                              ? ""
                              : theme === "inoxTheme"
                              ? "active-languages-inox"
                              : "active-languages-pvr"
                          }
                          key={idx}
                          onClick={() => findActiveGenreFilter(item)}
                        >
                          {item}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            {viewMode === 0 ? (
              <div className="col-sm-12">
                <div className="languages-filter">
                  <div
                    className="languages-box"
                    onClick={() => {
                      setShowAccessiblity(!showAccessiblity);
                    }}
                  >
                    <div className="languages-show">
                      <h2>Accessibility</h2>
                    </div>
                    <div className="languages-icon">
                      <img
                        src={showLanguage ? arrowUp : arrowDown}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div
                    className={showAccessiblity ? "languages-perfer" : "d-none"}
                  >
                    <ul>
                      {accessiblityList.map((item, idx) => (
                        <li
                          className={
                            !selectedAccessiblityId
                              ? ""
                              : theme === "inoxTheme"
                              ? "active-languages-inox"
                              : "active-languages-pvr"
                          }
                          key={idx}
                          onClick={() => findActiveAccessiblityFilter(item)}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="languages-filter"></div>

          <div className="apply-filter">
            <div className="reset">
              <LightButton
                className="reset-btn"
                onClick={() => {
                  dispatch(
                    setMobileFilterValues({
                      lng: [],
                      grs: [],
                      accsblity: false,
                      status: 0,
                    })
                  );
                  setSelectedLanguageIds([]);
                  setSelectedGenreIds([]);
                  setSelectedAccessiblityId(false);
                  setVisibleBottom(false);
                }}
              >
                Reset
              </LightButton>
            </div>
            <div className="Filter">
              <DarkButton
                className="apply-filters"
                onClick={() => {
                  dispatch(
                    setMobileFilterValues({
                      lng: [...selectedLanguageIds],
                      grs: [...selectedGenreIds],
                      accsblity: selectedAccessiblityId,
                      status: countForSearch + 1,
                    })
                  );
                  setVisibleBottom(false);
                }}
              >
                Apply Filter
              </DarkButton>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {spalshData ? (
        <div className="footer-tab-mobile">
          <div className="app-view-footer">
            <div
              className={
                viewMode === 0 && theme === "pvrTheme"
                  ? "home-icon home-icon-pvr-active"
                  : viewMode === 0 && theme === "inoxTheme"
                  ? "home-icon home-icon-inox-active"
                  : "home-icon"
              }
              onClick={() => {
                navigate("/");
                setViewMode(0);
              }}
            >
              <div className="icon-img">
                <img
                  src={viewMode === 0 ? homeActiveIcon : homeIcon}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <p>Home</p>
            </div>
            <div
              className={
                viewMode === 1 && theme === "pvrTheme"
                  ? "home-icon home-icon-pvr-active"
                  : viewMode === 1 && theme === "inoxTheme"
                  ? "home-icon home-icon-inox-active"
                  : "home-icon"
              }
              onClick={() => {
                setViewMode(1);
                hitGa("top_navi_cinemas");
                hitGa("cinemas_click");

                navigate(`/cinemas/${localStorage.getItem("city")}`);
              }}
            >
              <div className="icon-img">
                <img
                  src={
                    viewMode === 1 && theme === "inoxTheme"
                      ? cinemaInoxActiveIcon
                      : viewMode === 1 && theme === "pvrTheme"
                      ? cinemaPvrActiveIcon
                      : cinemIcon
                  }
                  alt=""
                  
                  className="img-fluid"
                />
              </div>
              <p>Cinemas</p>
            </div>
            <div
              className={
                viewMode === 2 && theme === "pvrTheme"
                  ? "home-icon home-icon-pvr-active"
                  : viewMode === 2 && theme === "inoxTheme"
                  ? "home-icon home-icon-inox-active"
                  : "home-icon"
              }
              onClick={() => {
                if (spalshData?.passport == "true") {
                  window
                    .open("https://passport.pvrinox.com/", "_blank")
                    .focus();
                } else {
                  setViewMode(2);
                  hitGa("top_navi_offers");
                  const currentPath = window.location.pathname;
                  if (currentPath === "/offers") {
                    window.location.href = "/offers";
                  } else {
                    navigate("/offers"); // Use your navigation function here
                  }
                }
              }}
            >
              <div className="icon-img">
                <img
                  src={
                    spalshData?.passport == "true"
                      ? passportIcon
                      : viewMode === 2
                      ? passportActiveIcon
                      : OfferStroke
                  }
                  alt=""
                  className="img-fluid"
                />
              </div>
              <p>{spalshData?.passport == "true" ? "Passport" : "Offer"}</p>
            </div>
            <div
              className={
                viewMode === 3 && theme === "pvrTheme"
                  ? "home-icon home-icon-pvr-active"
                  : viewMode === 3 && theme === "inoxTheme"
                  ? "home-icon home-icon-inox-active"
                  : "home-icon"
              }
              onClick={() => {
                setViewMode(3);
                hitGa("coming_soon_click");

                navigate("/coming-soon");
              }}
            >
              <div className="icon-img">
                <img
                  src={viewMode === 3 ? comingSoonActiveIcon : comingSoonIcon}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <p>Coming Soon</p>
            </div>
            <div
              className="home-icon"
              onClick={() => {
                setVisibleBottom(true);
                // hitGa("top_navi_more_click")

                setFilterUIViewMode(0);
              }}
            >
              <div className="icon-img">
                <img src={moreIcon} alt="" className="img-fluid" />
              </div>
              <p>More</p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {viewMode === 0 || viewMode === 3 ? (
        <>
          <div
            className="filter-mobile"
            style={{
              backgroundColor: theme === "inoxTheme" ? "#234B9E" : "#FFCB05",
            }}
            onClick={() => {
              setVisibleBottom(true);
              setFilterUIViewMode(1);
            }}
          >
            <img
              src={theme === "inoxTheme" ? filterM : FilterBlack}
              alt=""
              className="img-fluid"
            />
            {selectedAccessiblityId === true ||
            selectedGenreIds.length > 0 ||
            selectedLanguageIds.length > 0 ? (
              <div className="circle-tab"></div>
            ) : (
              ""
            )}
          </div>
          {/* {viewMode === 0 ? (
            <div
              className="filter-mobile"
              style={{
                backgroundColor: theme === "inoxTheme" ? "#234B9E" : "#FFCB05",
                bottom: "120px",
              }}
              onClick={() => {
                // setVisibleBottom(true);
                // setFilterUIViewMode(1);
                navigate("/insta-food");
              }}
            >
              <img src={FNBICON} alt="" className="img-fluid" />
            </div>
          ) : (
            ""
          )} */}
        </>
      ) : (
        ""
      )}
      <div>
        <Sidebar
          visible={visibleBottom}
          onHide={() => setVisibleBottom(false)}
          position="bottom"
          className="mobile-filter-app"
          dismissable={true}
          showCloseIcon={false}
          blockScroll={true}
        >
          <>{filterUIViewMode === 0 ? moreUI() : filterUI()}</>
        </Sidebar>
      </div>
      <div className="">
        <Dialog
          header=""
          visible={loginVewMode}
          showHeader={false}
          showCloseIcon={false}
          onHide={() => dispatch(setLoginViewMode({ value: false }))}
          className="register-mobile"
          blockScroll={true}
        >
          <div className="row">
            <Login />
          </div>
        </Dialog>
      </div>
      <div style={{ display: "none" }}>
        <MoreDropdown />
      </div>
    </>
  );
};

export default MobileFooter;
