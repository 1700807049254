import React, { useState, useCallback } from "react";
import * as appConstants from "../../constants/index";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import pvrinoxlogo from "../../assets/food/pvrinoxlogo.svg";
import pvrinoxlogowhite from "../../assets/food/inoxPVRwhite.svg";
import atcinema from "../../assets/food/atcinema.svg";
import notatcinema from "../../assets/food/notatcinema.svg";
import backbtn from "../../assets/food/backBtnn.svg";
import backbtnwhite from "../../assets/food/backwhite.svg";
import SweetAlertUtility from "./component/SweetAlertUtility";
import { getResponsiveClasses } from "./component/ResponsiveClasses";
import CinemaInOutSkeleton from "src/skeletons/CinemaInOutSkeleton";
import { format, isValid, parse } from "date-fns";

const CinemaInOut = () => {
	const [loadingAtCinema, setLoadingAtCinema] = useState(false);
	// const [loadingNotAtCinema, setLoadingNotAtCinema] = useState(false);
	const [alertDetails, setAlertDetails] = useState({ show: false, type: '', title: '', message: '' });
	const [seatDetailData, setSeatDetailData] = useState(null);
	const [formattedEndTime, setFormattedEndTime] = useState(null);
	const [formattedShowTime, setFormattedShowTime] = useState(null);
	const [show, setShow] = useState(false);
	const [visiblemapview, setVisiblemapview] = useState(false);
	const { currentClasses, getWindowWidth } = getResponsiveClasses();
	const navigate = useNavigate();
	const theme = localStorage.getItem("theme");
	const theatreId = localStorage.getItem("theaterId");
	localStorage.removeItem("cartItems");
	localStorage.removeItem("cinema_name");



	// Handle card click based on cinema type
	const handleCinemaSelection = async (type) => {
		localStorage.setItem("TransactionType", type);
		if (type === "InCinemaFB") {
			navigate("/select-food")
		} else {
			if (type === "OnlyFB") {
				try {
					const response = await seatDetailsAPI();
					if (response?.length > 0 && response?.some(item => item?.theaterId === theatreId)) {
						setShow(true);
						setLoadingAtCinema(false);
					} else {
						navigate("/fnbdatepicker");
					}
				} catch (error) {
					console.error("Error fetching seat details:", error);
					navigate("/fnbdatepicker");
				}
			} else {
				navigate("/fnbdatepicker");
			}
		}
		setLoadingAtCinema(true);
		cinemaGaTag(type);
	};


	const seatDetailsAPI = async () => {
		setLoadingAtCinema(true);

		try {
			const response = await axios.post(
				`${appConstants.base_url}v1/fnb/getExistingBookings?upcomingMovies=true`,
				{},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${localStorage.getItem("token") || " "}`,
						chain: localStorage.getItem("theme") === "inoxTheme" ? "INOX" : "PVR",
						appVersion: appConstants.av,
						platform: appConstants.pt,
						country: appConstants.cname,
					},
				}
			);
			if (response.data.result === "success" && response.data.output.length > 0) {
				setSeatDetailData(response.data.output);
				formattedShowEndTime(response.data.output);
				setShow(true);
				// setLoadingAtCinema(false);	
				return response.data.output;
			} else {
				// setVisiblemapview(true)
				return {};
			}
		} catch (error) {
			sweetAlertError("Something went wrong!");
			console.error("Failed to fetch seat details:", error);
			// setVisiblemapview(false);
			return {};
		} finally {
			setLoadingAtCinema(false);
		}
	};

	const formattedShowEndTime = (data) => {
		if (data.length > 0) {
			const showTime = parse(data?.[0]?.showTime, "yyyy-MM-dd HH:mm:ss.S", new Date());
			const endTime = parse(data?.[0]?.endTime, "yyyy-MM-dd HH:mm:ss.S", new Date());
			const formattedShowTime = showTime && isValid(showTime) ? format(showTime, "hh:mm a") : "-";
			const formattedEndTime = endTime && isValid(endTime) ? format(endTime, "hh:mm a") : "-";
			setFormattedShowTime(formattedShowTime);
			setFormattedEndTime(formattedEndTime);
		}
	}


	const handleUpcomingMovie = (data) => {
		const selectedTheater = JSON.parse(localStorage.getItem("selectedTheater"));
		const { showTime, theaterId } = data;
		const currentTime = new Date();
		const showTimeDate = new Date(showTime);
		const timeToUse = currentTime < showTimeDate ? showTimeDate : currentTime;
		data.fnbDelivery = false;
		data.name = data.theaterName;
		data.fbDeliveryOnSeat = selectedTheater.fbDeliveryOnSeat;
		data.isPosAvailable = selectedTheater.isPosAvailable;
		localStorage.setItem("selectedTheater", JSON.stringify(data));
		localStorage.setItem("booking_type", "FOOD");
		localStorage.setItem("theaterId", theaterId);
		localStorage.setItem("screenType", "N");
		localStorage.setItem("FoodPickupDateTime", format(timeToUse, "dd MMM yy, hh:mm a"));
		navigate("/select-food", { state: { orderDateTime: format(timeToUse, "yyyy-MM-dd HH:mm:00") } });
		localStorage.setItem("addFoodItems", JSON.stringify(data));
		hitGaUpcomingMovies(data);
	}

	let dataLayer = window["dataLayer"] || [];
	const hitGaUpcomingMovies = (data) => {
		dataLayer.push({
			event: "food_movie_date_cinema_proceed",
			item_name: data?.filmName,
			food_date: data?.showTime,
			cinema_name: data?.name,
		});
	};

	const cinemaGaTag = (event) => {
		let dataLayer = window["dataLayer"] || [];
		const fnbCinemaInOut = event === "InCinemaFB" ? "food_booking_at_cinema" : event === "OnlyFB" ? "food_booking_not_at_cinema" : "";
		dataLayer.push({
			'event': fnbCinemaInOut,
		});
	}

	// Handle modal close and redirection on skip
	const handleLocationModalClose = useCallback((skipbtn) => {
		setShow(false);
		setLoadingAtCinema(false);
		if (skipbtn === "skipbtn") {
			localStorage.removeItem("userLocation");
			navigate("/fnbdatepicker");
		}
	}, [navigate]);

	// Display error alert
	const sweetAlertError = (msg) => {
		setAlertDetails({
			show: true,
			type: 'error',
			title: 'Error!',
			message: msg,
		});
	};

	// Hide error alert
	const hideAlert = () => {
		setAlertDetails({ show: false });
	};

	const handleHide = () => {
		if (!getWindowWidth) {
			setShow(false);
			setVisiblemapview(false);
			setLoadingAtCinema(false);
			// navigate("/fnbdatepicker");
		}
	};
	const handleImageError = (e) => {
		e.target.src = noImage;
	};

	return (
		<>
			{loadingAtCinema
				? <CinemaInOutSkeleton />
				:
				<div className="cinemainout">
					<div className="fnbinout-back">
						<img
							onClick={() => navigate("/select-cinema-for-fnb")}
							src={theme === "pvrTheme" ? backbtn : backbtnwhite}
							alt="Back"
						/>
					</div>
					<div className="pvrlogo">
						<img
							src={theme === "pvrTheme" ? pvrinoxlogo : pvrinoxlogowhite}
							height={38}
							alt="PVR Inox Logo"
						/>
					</div>

					<div className="cinmacard" onClick={() => handleCinemaSelection("InCinemaFB")}>
						<div className="icons">
							<img src={atcinema} width={80} height={80} alt="At Cinema Icon" />
						</div>
						<p>I'm at Cinema</p>
					</div>

					<div className="cinmacard" onClick={() => handleCinemaSelection("OnlyFB")}>
						<div className="icons">
							<img src={notatcinema} width={80} height={80} alt="Not at Cinema Icon" />
						</div>
						<p>I'm not at Cinema</p>
					</div>
				</div>
			}



			{seatDetailData?.length > 0 && (
				<Modal
					className={`${currentClasses.centerModal} upcmngModal`}
					show={show}
					onHide={handleHide}
					animation={false}
					keyboard={false}
					dialogClassName={show ? "modal-dialog modal-hide" : "modal-dialog"}
				>
					<div className="modalbody">
						<h3 className="upTitle">

							{getWindowWidth && <Link to="/?tab=food" className="bckarw">
								<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M6 6.75H12V5.25H6V0L0 6L6 12V6.75Z" fill="black" />
								</svg>
							</Link>}
							Your upcoming movie bookings
						</h3>
						<p className="dislc">Select a movie to pre-order food</p>

						<div className="upcmnglist">
							<div className="upcmnglistinr">

								{seatDetailData?.filter(item => item?.theaterId === theatreId)?.map((item, index) => (
									<div key={`upcomingMovies${index}`} className="movieupcmngdetails" onClick={() => handleUpcomingMovie(item)}>
										{item?.filmName && (
											<div className="movieImg">
												<img
													src={item?.filmImg || noImage}
													width={getWindowWidth ? 60 : 50}
													alt={item?.filmName || "No image available"}
													onError={handleImageError}
												/>
											</div>
										)}
										<div className="upcmovdets">
											{item?.filmName && (
												<h3 className="movUpTitle">{item?.filmName}</h3>
											)}

											{item?.showTime && (
												<p className="cmnUpfnt">{format(item?.showTime, "eee, MMM dd, hh:mm a ")}</p>
											)}
											<div className="addflx">
												{item?.theaterName && (
													<p className="cmnUpfnt">{item?.theaterName}</p>
												)}
											</div>
										</div>
										{getWindowWidth ?
											<div className="upbtnmain">
												<button className="upcmnbtn">Select</button>
											</div>
											:
											<svg className="rgtarwup" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M8.7809 5.33327L5.2049 1.75726L6.1477 0.814453L11.3332 5.99993L6.1477 11.1853L5.2049 10.2425L8.7809 6.6666H0.666504V5.33327H8.7809Z" fill="black" />
											</svg>
										}
									</div>
								))}
							</div>
						</div>

						{/* <div className="flxbtn">
                  {getWindowWidth && <Link to="/?tab=food" className="skipUpcmng">Back</Link>} */}
						<p onClick={() => handleLocationModalClose("skipbtn")} className="skipUpcmng">Skip</p>
						{/* </div> */}
					</div>
				</Modal>
			)}

			{/* Error Alert */}
			<SweetAlertUtility
				type={alertDetails.type}
				title={alertDetails.title}
				message={alertDetails.message}
				show={alertDetails.show}
				onConfirm={hideAlert}
			/>
		</>
	);
};

export default CinemaInOut;
