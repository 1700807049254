import React, { useEffect, useState, useRef } from "react";
import { Chips } from "primereact/chips";
import explore from "../../../assets/payment/explore.svg";
import offerRight from "../../../assets/payment/offer-right-arrow.svg";
import { usePaymentInputs } from "react-payment-inputs";
import { Checkbox } from 'primereact/checkbox';
import axios from "axios";
import * as appConstants from "../../../constants/index";
import { DonateButton } from "../paymentTags";
import SweetAlert from "react-bootstrap-sweetalert";
import master from "../../../assets/payment/master.png";
import visa from "../../../assets/payment/visa.png";
import { setRetryPayment } from "src/services/CouponPayment";
import { getPassportData } from "src/services/PassportServices";

const CreditCard = (props) => {
  const btnRef = useRef(null);
  const bookingid = localStorage.getItem("bookingid");
  const theme = localStorage.getItem("theme");
  const subscription = localStorage.getItem('subscription');
  const passportData = getPassportData();
  const [mandatepayment, setmandatepayment] = useState("none");
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [validation, setValidation] = useState(false);
  const [mmvalidation, setmmvalidation] = useState(false);
  const [ddvalidation, seddtvalidation] = useState(false);
  const [cvvvalidation, setcvvvalidation] = useState(false);
  const [namevalidation, setnamevalidation] = useState(false);
  const [cardBrand, setCardBrand] = useState('none');

  const paymentOptions = parseInt(localStorage.getItem("paymentOptions") || 0);
  const gaTagData = JSON.parse(localStorage.getItem("gaTagData"));
  useEffect(() => {
    // Call the juspayInit method when the component mounts
    //juspayInit();
    //juspayHmac();
    initJuspay();
    if (subscription == "true" && localStorage.getItem("booking_type") === "PASSPORT") {
      setChecked(true);
    }
  }, []); // Use an empty dependency array to run the effect once when the component mounts

  // payWithJustpay = () => {
  //   if (!this.acceptJustPayDebitCondition) return this.showErrorMsg("Please accept terms and conditions");
  //   if (this.acceptJustpayDebitOptin) this.enrollWhatsAppHandler();
  //   this.getJustpaySignature();
  //   try {
  //     let dataLayer = window['dataLayer'] || [];
  //     if (dataLayer) {
  //       dataLayer.push({
  //         'event': 'checkoutOption',
  //         'ecommerce': {
  //           'checkout': {
  //             'actionField': { 'step': 3, 'option': 'make payment' },
  //           }
  //         }
  //       });
  //     }
  //   } catch (ex) { }
  // }

  // getJustpaySignature = () => {
  //   let obj = {
  //     userid: this.user.id,
  //     bookingid: this.cinemaDetails.bookingId,
  //     transid: this.cinemaDetails.transId,
  //     unpaid: false,
  //     booktype: this.cinemaDetails.booktype
  //   };
  //   this.loading = true;
  //   this.paymentService.getJustpaySignature(obj).subscribe((resp: any) => {
  //     this.loading = false;
  //     if (resp.error) return this.showErrorMsg(resp.error);
  //     window.location.href = resp.callingurl;
  //   });
  // }

  const initJuspay = async () => {
    let attr;
    if (props.cardNum) {
      attr = {
        placeholder: "Enter Your Card Number",
        value: props.cardNum,
        disabled: "disabled"
      }
    } else {
      attr = {
        placeholder: "Enter Your Card Number",
      }
    }
    console.log('working');
    let self = this;
    let form = 'credit_card_form';
    console.log(form);

    //var frm = $(form);
    //console.log(frm);

    var juspay_form = Juspay.Setup({

      payment_form: "#" + form,

      success_handler: function (status) { },

      error_handler: function (

        error_code,

        error_message,

        bank_error_code,

        bank_error_message,

        gateway_id

      ) { },

      card_bin_digit_count: 6,

      customer: {

        customer_id: props.juspayinit ? props.juspayinit.customer_id : '',// change to relevant customer id value

        client_auth_token: props.juspayinit ? props.juspayinit.client_auth_token : '',// populate the active client_auth_token value fetched from Create Order/Create Customer API

      },

      second_factor_window_closed_handler: function () {

        //$("#common_pay_btn").removeAttr("disabled").removeClass("disabled");

      },

      iframe_elements: {

        card_number: {

          container: ".card_number_div",

          /* Field Attributes */

          attributes: attr,

        },

        name_on_card: {

          container: ".name_on_card_div",

          /* Field Attributes */

          attributes: {

            placeholder: "Name on the card",

          },

        },

        card_exp_month: {

          container: ".card_exp_month_div",

          /* Field Attributes */

          attributes: {

            placeholder: "MM",

          },

        },

        card_exp_year: {

          container: ".card_exp_year_div",

          /* Field Attributes */

          attributes: {

            placeholder: "YY",


          },

        },

        security_code: {

          container: ".security_code_div",

          /* Field Attributes */

          attributes: {

            placeholder: "CVV",

          },

        },

      },
      auto_tab_enabled: true,
      styles: {

        input: {
          "font-family": "",
          "outline": "none !important"
        },

        ".card_number": {
          "padding": "0px 12px",
          "outline": "none !important"
        },

        ".name_on_card": {
          "padding": "0px 12px",
          "outline": "none !important"
        },

        ".card_exp_month": {
          "padding": "0px 12px",
          "outline": "none !important"
        },

        ".card_exp_year": {
          "padding": "0px 12px",
          "outline": "none !important"
        },

        ".security_code": {
          "padding": "0px 12px",
          "outline": "none !important"
        },

      },

      iframe_element_callback: function (event) {

        //console.log(event);

        switch (event.target_element) {

          case "card_number":
            //self.check(event.card_brand);
            //self.cardNumber = false;
            setValidation(false);
            setCardBrand(event?.card_brand ? event?.card_brand : "none")
            //console.log(event);
            if (event.hasOwnProperty('mandate_support')) {
              setmandatepayment(event.mandate_support ? "TRUE" : "FALSE");
            } else {
              setmandatepayment("none");
            }
            var element = document.getElementById("card-number");
            if (event.empty) {
              //console.log("invalid");
              //element.classList.add("invalid");
              //frm.find(".card_number_div").addClass("invalid");

            } else if (event.valid) {
              //self.cardNumber = true;;
              setValidation(true);
              //element.classList.remove("invalid");
              juspay_form.get_card_fingerprint({

                success_handler: function (response) {
                  //console.log("got success_handler response", response);
                },

                error_handler: function (response) {
                  //element.classList.add("invalid");
                  //console.log("got error_handler response", response);

                },

              });

            } else if (event.partially_valid) {
              //console.log('paritialy valid');
              //element.classList.remove("invalid");

            } else {
              //console.log('else valid');
              //element.classList.remove("invalid");
            }

            break;

          case "name_on_card":
            //self.cardHolder = false;
            setnamevalidation(false);
            //var element = document.getElementById("card-holder-name");
            if (event.empty) {
              //element.classList.add("invalid");
            } else if (event.valid) {
              //element.classList.remove("invalid");
              setnamevalidation(true);
              //self.cardHolder = true;
            } else {
              //element.classList.add("invalid");
            }

            break;

          case "card_exp_month":
            //self.cardExpiryMonth = false;
            setmmvalidation(false);
            //var element = document.getElementById("card_exp_month");
            if (event.empty) {
              //element.classList.add("invalid");
              //frm.find(".card_exp_month_div").addClass("invalid");

            } else if (event.valid) {
              //element.classList.remove("invalid");
              //self.cardExpiryMonth = true;
              setmmvalidation(true);
            } else {
              //element.classList.add("invalid");

            }

            break;

          case "card_exp_year":
            seddtvalidation(false);
            //var element = document.getElementById("card_exp_year");
            if (event.empty) {
              //element.classList.add("invalid");
              //frm.find(".card_exp_year_div").addClass("invalid");

            } else if (event.expiry_valid) {
              //element.classList.remove("invalid");
              seddtvalidation(true);
              //self.cardExpiryYear = true;
              //frm.find(".card_exp_year_div").removeClass("invalid");

              //frm.find(".card_exp_month_div").removeClass("invalid");

            } else {
              //element.classList.add("invalid");
              //frm.find(".card_exp_year_div").addClass("invalid");

              //frm.find(".card_exp_month_div").addClass("invalid");

            }

            break;

          case "security_code":
            setcvvvalidation(false);
            //var element = document.getElementById("cvv");
            if (event.empty) {
              //element.classList.add("invalid");
              //frm.find(".security_code_div").addClass("invalid");

            } else if (event.valid) {
              //element.classList.remove("invalid");
              setcvvvalidation(true);
              //self.cardCvv = true;
              //frm.find(".security_code_div").removeClass("invalid");
            } else {
              //element.classList.add("invalid");
              //frm.find(".security_code_div").addClass("invalid");
            }

            break;
        }

      },

    });
  }
  const gethmac = async () => {
    if (localStorage.getItem("booking_type") === "PASSPORT" && subscription == "true" && mandatepayment == "FALSE") {
      setErrorMsg("card is not valid for subscription");
      setShowAlert(true);
      return "";
    }
    if (!validation) {
      setErrorMsg("Please enter valid card number!");
      setShowAlert(true);
      return "";
    } else if (!mmvalidation) {
      setErrorMsg("Please enter valid expire month!");
      setShowAlert(true);
      return "";
    } else if (!ddvalidation) {
      setErrorMsg("Please enter valid expire year!");
      setShowAlert(true);
      return "";
    } else if (!cvvvalidation) {
      setErrorMsg("Please enter valid CVV!");
      setShowAlert(true);
      return "";
    } else if (!namevalidation) {
      setErrorMsg("Please enter valid card holder name!");
      setShowAlert(true);
      return "";
    }
    setLoading(true);
    const postData = {
      transId: localStorage.getItem("transid"),
      bookingId: localStorage.getItem("bookingid"),
      bookType: localStorage.getItem("booking_type"),
      paytype: "CC",
      pgtype: "JUSPAY",
      mandate: localStorage.getItem("booking_type") === "PASSPORT" ? subscription == "true" ? true : false : false,
    };

    const vouDesc = localStorage.getItem("vouDesc") || "";
    const vouId = localStorage.getItem("vouId") || "";
    const discValue = localStorage.getItem("discValue") || "";
    const validity = localStorage.getItem("validity") || "";
    const itemCategory5 = `${vouDesc}, ${vouId}, ${discValue}, ${validity}`;


    await axios
      .post(`${appConstants.base_url}v1/payment/juspay/hmac`, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          localStorage.removeItem('pgused');
          localStorage.setItem('pgused', 'CREDITCARD');
          setRetryPayment(props.payMode, "retry-CREDITCARD" + localStorage.getItem("bookingid"));
          const url = window.location.href;

          localStorage.setItem("cinema_name", url.split('/').pop().replaceAll('%20', ' '));
          window.dataLayer = window.dataLayer || [];
          if (localStorage.getItem("booking_type") == "GIFTCARD") {
            window.dataLayer.push({
              event: "gift_card_add_payment_info",
              'item_brand': appConstants.cinemaChain,
              'item_category': 'Gift Card', // this will be static
              'item_category2': localStorage.getItem("city"),
              'item_category3': new Date().toISOString().split('T')[0],
              'gift_card_type': localStorage.getItem("giftCardDatatype"),
              'gift_card_amount': 'Gift Card amount will be dynamic',
              'gift_card_amount': localStorage.getItem('giftcardamount'),
              'gift_card_payment_type': 'CREDITCARD'

            });
          }
          else {
            let item_category = '';
            if (localStorage.getItem("tickettype") && localStorage.getItem("tickettype") === "food") {
              item_category = 'ticket,f&b';
            }
            else {
              item_category = 'ticket' + "," + vouDesc;
              paymentOptions === 1
                ?
                window.dataLayer.push({
                  event: 'add_payment_info',
                  ecommerce: {
                    currency: 'INR',
                    value: Number(res.data.output.amount),
                    affiliation: 'CREDITCARD',
                    items: [{
                      item_brand: appConstants.cinemaChain,
                      item_name: gaTagData?.movieName || false,
                      item_id: gaTagData?.movieId || false,
                      item_category: localStorage.getItem("booking_type") || "FOOD",
                      item_category2: gaTagData?.theaterName || false,
                      item_category3: gaTagData?.selectedDate ? `${gaTagData?.selectedDate} / ${gaTagData?.selectedSeats || ""}` : format(new Date(), "yyyy-MM-dd hh:mm a"),
                      item_category4: gaTagData?.genre && gaTagData?.languages ? `${gaTagData?.genre} / ${gaTagData?.languages}` : false,
                      item_category5: false,
                      item_variant: gaTagData?.variant || false,
                      quantity: gaTagData?.qty || false,
                      price: Number(res.data.output.amount),
                    }]
                  }
                })
                :
                window.dataLayer.push({
                  event: 'add_payment_info',
                  ecommerce: {
                    currency: 'INR',
                    value: Number(res.data.output.amount),
                    affiliation: 'CREDITCARD',
                    items: [{
                      item_brand: appConstants.cinemaChain,
                      item_name: localStorage.getItem('movie_name'),
                      item_id: localStorage.getItem('movieid'),
                      item_category: item_category,
                      item_category2: localStorage.getItem('cinema_name') + "/" + localStorage.getItem('city'),
                      item_category3: localStorage.getItem('selecteddate') + "," + localStorage.getItem('selectedseats'),                      //( selected date & seat number)
                      item_category4: localStorage.getItem('genre'),
                      item_category5: itemCategory5,
                      item_variant: localStorage.getItem('format'),
                      quantity: localStorage.getItem('quantity'),
                      price: Number(res.data.output.amount),
                    }]
                  }
                });
            }
          }
          // setTicketSummary(ndt);
          //document.getElementById("payment_form_NB").submit();
          localStorage.setItem("paymentid", dt.output.paymentId);
          localStorage.setItem("payment_type", "nonwallet");
          console.log("bankList==>", dt);
          btnRef.current.click();
        } else {
          console.log(res.data.msg, "aLL DATA");
          setLoading(true);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };
  return (
    <>
      <SweetAlert
        error
        show={showAlert}
        title={errorMsg}
        onConfirm={() => {
          setShowAlert(false);
          // console.log('confirm')
          //navigate('/')
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnCssClass={
          theme === "inoxTheme"
            ? "confirm-btn-inox border-0"
            : "confirm-btn-pvr border-0"
        }
      >
        {errorMsg}
      </SweetAlert>
      <div className="">
        <div className="payment-form">
          <div className="paywithdebit">
            <div className="debit-card show-in-desktop-view">
              <h6>Pay via Credit Card</h6>
            </div>
            <div className="to-paid">
              {/* <p>
                To be Paid:
                <span className="price-cross">₹8888</span>{" "}
                <span className="price-blod">₹8888</span>
              </p> */}
            </div>
          </div>
          <div className="payment-debitcard">
            <form className="form-horizontal" role="form" id="credit_card_form">

              <input type="hidden" className="redirect" value="true" />

              <input type="hidden" className="merchant_id" value={props.juspayinit ? props.juspayinit.merchant_id : ''} />
              <input type="hidden" className="order_id" value={bookingid} />
              <input style={{ "display": "none" }} type="checkbox" className="tokenize" checked={checked ? true : false} onChange={e => setChecked(e.checked)} />
              <input style={{ "display": "none" }} type="checkbox" className="juspay_locker_save" checked={checked ? true : false} onChange={e => setChecked(e.checked)} />
              {subscription == "true" && localStorage.getItem("booking_type") === "PASSPORT" ?
                <input style={{ "display": "none" }} type="checkbox" className="should_create_mandate" checked={true} value={true} />
                : ""}
              <input type="hidden" className="payment_method_type" value="CARD" />
              <div className="cc-left clearfix">
                <div className="row">
                  <div className="col-sm-12 form-group visa-type">
                    {/* <label for="" className="control-label">Card Number</label> */}
                    <div className="card_number_div" id="card-number" placeholder="Card Number"></div>
                    {cardBrand == "none" ?
                      ''
                      :
                      <div className="type-card-show-payment">
                        <img src={cardBrand == "VISA" ? visa : (cardBrand == "MASTER" || cardBrand == "MASTERCARD") ? master : ''} alt="" className="img-fluid" />
                      </div>
                    }
                  </div>
                </div>
                {localStorage.getItem("booking_type") === "PASSPORT" && subscription == "true" && (mandatepayment == "FALSE" || mandatepayment == "TRUE") ?
                  <div className="row pb-3">
                    <div className="col-sm-12 col-md-12">
                      {mandatepayment == "FALSE" ?
                        <div className="passport-error-msg">
                          <p>This card does not support recurring payment, hence cannot be used to purchase Passport</p>
                        </div>
                        : mandatepayment == "TRUE" ?
                          <div className="passport-success-msg">
                            <p>This card supports recurring payment mandate</p>
                          </div>
                          : ""}

                    </div>
                  </div>
                  : ""
                }
                <div className="row">
                  <div className="col-sm-4 col-6 form-group" id="validation_column">
                    {/* <label for="" className="control-label">Valid Thru</label> */}
                    <div className="cardexp">
                      <div className="card_exp_month_div" id="card_exp_month" placeholder="MM"></div>
                    </div>
                  </div>
                  <div className="col-sm-4 col-6">
                    {/* <label for="" className="control-label"></label> */}
                    <div className="card_exp_year_div" id="card_exp_year" placeholder="YY"></div>
                  </div>
                  <div className="col-sm-4 form-group">
                    {/* <label for="" className="control-label">CVV</label> */}
                    <div className="security_code_div" id="cvv" placeholder="CVV"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 form-group no-margin">
                    <div className="name_on_card_div" id="card-holder-name" placeholder="Name on Card"></div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-sm-12 form-group no-margin">
                    <div className="name_on_card_div" id="card-holder-name" placeholder="Card Nickname"></div>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="terms-payment">
                      <div className={
                        theme === "inoxTheme"
                          ? " check-payment-inox"
                          : " check-payment-pvr"
                      }>
                        <Checkbox inputId="binary" disabled={localStorage.getItem("booking_type") === "PASSPORT" && subscription == "true" ? "disable" : ""} checked={checked} onChange={e => setChecked(e.checked)} />
                      </div>
                      <div className="terms-condition-payment">
                        <p>Securely save this card for future use</p>
                      </div>
                    </div>
                    {localStorage.getItem("booking_type") === "PASSPORT" && subscription == "true" ?
                      <>
                        <div className="col-md-12 pt-2">
                          <div className="month-msg">
                            {passportData?.mandateText ?
                              <p dangerouslySetInnerHTML={{ __html: passportData?.mandateText }}></p>
                              :
                              ""
                            }
                            {/* <h6>You can cancel the subscription anytime</h6> */}
                          </div>
                        </div>
                      </> : ""}
                  </div>
                  <div className="col-md-12">
                    <div className="verfiy-pay">
                      <DonateButton
                        className={loading ? "verfiy-payment btn-opacity" : "verfiy-payment"}
                        type="button"
                        onClick={!loading ? gethmac : ''}
                      >
                        Verify & Pay
                        {loading ? (
                          <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "1.5em" }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </DonateButton>
                      <button ref={btnRef} type="submit" style={{ "display": "none" }}>
                        Pay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditCard;