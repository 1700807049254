import React, { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Accordion, AccordionTab } from "primereact/accordion";
import uparrow from "../../../../assets/static-page/uparrow.svg";
import downarrow from "../../../../assets/static-page/down.svg";
import liiMAGE from "../../../../assets/family/li-blue.png";
import coupon from "../../../../assets/family/coupon-line-1.png";
import question from "../../../../assets/family/question-line-1.png";

import "./QrCodeView.css";

function QrCodeView({ visible, content, setVisible }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);
  // useEffect(() => {
  //   console.log(content ,"sfsdf");
  //   let dataLayer = window["dataLayer"] || [];  
  //   dataLayer.push({
  //     event: "family_bundle_view_qr_code",
  //     voucher_name: content?.familyBundle_data?.voucherDesc,
  //     voucher_id: content?.familyBundle_data?.id,
  //     price: content?.familyBundle_data?.totalPrice ,
  //     valid_date: content?.familyBundle_data?.validity
  //   });

  // }, [content]);
  const accordionHeader = (item) => {
    return (
      <div className="d-flex align-items-center">
        <div className="faq-question">
          <h4>
            <img
              src={item == "Voucher Details" ? coupon : question}
              alt=""
              className="img-fluid "
            />{" "}
            {item}
          </h4>
          <span>
            {item == "Voucher Details"
              ? "Know details about this voucher"
              : "Know how to avail this voucher"}
          </span>
        </div>
      </div>
    );
  };
  const theme = localStorage.getItem("theme");
  return (
    <div>
      <Sidebar
        visible={visible}
        //   icons={proceedbtn}
        showCloseIcon={true}
        style={
          !isMobile
            ? { height: "84%", width: "50%" }
            : { height: "100%", width: "100%" }
        }
        position={"center"}
        // className="flexi-terms-cond"
        onHide={() => {
          setVisible(false);
        }}
        dismissable={true}
      >
        <div className="QRContainer">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="Qrcode-popup">
                <div className="row">
                  <div className="col-md-12">
                    <div className="Qr-img">
                      <img
                        src={
                          "data:image/png;base64," +
                          content?.familyBundle_data?.vouchersQR
                        }
                        className="qucodeimg"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="location">
                      <h6>Location:</h6>
                    </div>
                  </div>
                  <div className="col-md-8 col-6">
                    <div className="location-details">
                      <p>{content?.orderFilmCinema?.theaterName}</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="location">
                      <h6>Voucher code:</h6>
                    </div>
                  </div>
                  <div className="col-md-8 col-6">
                    <div className="location-details">
                      <p>{content?.familyBundle_data?.vouchers}</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="location">
                      <h6>Validity:</h6>
                    </div>
                  </div>
                  <div className="col-md-8 col-6">
                    <div className="location-details">
                      <p>{content?.familyBundle_data?.validity}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <table className="QRCodeColTable">
                <tr>
                  <td
                    style={{ width: "50%" }}
                    valign="top"
                    className="QRCodeColLeft"
                  >
                    <b>Location:</b>
                  </td>
                  <td style={{ width: "50%" }}>
                    {content?.orderFilmCinema?.theaterName}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ width: "50%" }}
                    valign="top"
                    className="QRCodeColLeft"
                  >
                    <b>Voucher code:</b>
                  </td>
                  <td style={{ width: "50%" }}>
                    {content?.familyBundle_data?.vouchers}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ width: "50%" }}
                    valign="top"
                    className="QRCodeColLeft"
                  >
                    <b>Validity:</b>
                  </td>
                  <td style={{ width: "50%" }}>
                    {content?.familyBundle_data?.validity}
                  </td>
                </tr>
              </table> */}
            </div>
          </div>

          <div className="Qr-Voucher">
            <div className="accordion" id="fb-voucherAccordion">
              <Accordion
                expandIcon={
                  <img
                    src={downarrow}
                    alt="Expand Icon"
                    className="img-fluid custom-cricle-arrow"
                  />
                }
                collapseIcon={
                  <img
                    src={uparrow}
                    alt="Collapse Icon"
                    className="img-fluid custom-cricle-arrow"
                  />
                }
                // onTabClose={() => setIsAccordionTabOpen(false)}
                // onTabOpen={() => setIsAccordionTabOpen(true)}
                activeIndex={0}
              >
                <AccordionTab header={accordionHeader("Voucher Details")}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="faq-answer">
                        <ul>
                          {content &&
                            content?.familyBundleData?.voucherDetails?.map(
                              (item, index) => {
                                return <li>{item}</li>;
                              }
                            )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </AccordionTab>
                <AccordionTab header={accordionHeader("How It Works?")}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="faq-answer">
                        <div>
                          <ul
                            className={
                              theme === "inoxTheme"
                                ? "hiw-steps-inox"
                                : "hiw-steps-pvr"
                            }
                          >
                            {content &&
                              content?.familyBundleData?.hiw?.map(
                                (item, index) => {
                                  return (
                                    <li className="hiw-step">
                                      <span
                                        className={
                                          theme === "inoxTheme"
                                            ? "hiw-step-icon-inox"
                                            : "hiw-step-icon-pvr"
                                        }
                                      ></span>{" "}
                                      <span className="hiw-step-text">
                                        {!item?.header ? (
                                          ""
                                        ) : (
                                          <>
                                            <strong className="hiw-section-title">
                                              {item?.header}
                                            </strong>
                                            <br />
                                          </>
                                        )}
                                        {item?.text}
                                      </span>{" "}
                                    </li>
                                  );
                                }
                              )}
                          </ul>
                        </div>
                        {/* <div className="hiw-container">
                          <div className="hiw-section-title">How to buy</div>
                          <ul className="hiw-steps">
                            <li className="hiw-step">
                              <span className="hiw-step-icon"></span>
                              <span className="hiw-step-text">
                                Select the Family Bundle Offer when booking your
                                movie tickets.
                              </span>
                            </li>
                            <li className="hiw-step">
                              <span className="hiw-step-icon"></span>
                              <span className="hiw-step-text">
                                Choose your preferred Value Voucher from the
                                available options.
                              </span>
                            </li>
                            <li className="hiw-step">
                              <span className="hiw-step-icon"></span>
                              <span className="hiw-step-text">
                                Pay for the F&B Bundle Voucher along with your
                                movie tickets.
                              </span>
                            </li>
                            <li className="hiw-step">
                              <span className="hiw-step-icon"></span>
                              <span className="hiw-step-text">
                                The Food voucher will be activated.
                              </span>
                            </li>
                            <li className="hiw-step">
                              <span className="hiw-step-icon"></span>
                              <span className="hiw-step-text">
                                View your voucher in My Bookings.
                              </span>
                            </li>
                          </ul>

                          <div className="hiw-section-title">
                            How to Redeem Online:
                          </div>
                          <ul className="hiw-steps">
                            <li className="hiw-step">
                              <span className="hiw-step-icon"></span>
                              <span className="hiw-step-text">
                                Open the app and go to ORDER F&B.
                              </span>
                            </li>
                            <li className="hiw-step">
                              <span className="hiw-step-icon"></span>
                              <span className="hiw-step-text">
                                Search for your Cinema and Arrival Time.
                              </span>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default QrCodeView;
