import React from "react";
import { Skeleton } from "primereact/skeleton";

const FnbSeatDetailSkeleton = () => {
    return (
        <>
            <style>
                {`
                .flx {
                    display: flex;
                }
                `}
            </style>
            <div style={{overflow: "hidden"}}>
                <div className="d-flex justify-content-between mt-2">
                    <Skeleton width="100%" height="65px" className="mx-2"></Skeleton>
                </div>
                <div className="repeatskltn mt-3 px-2" style={{ width: "100%" }}>
                    <Skeleton width="100%" height="140px" className="mb-4"></Skeleton>
                    <Skeleton width="100px" height="20px" className="mx-1"></Skeleton>
                </div>
                <div className="repeatskltn flx mt-3 px-2" style={{ width: "100%" }}>
                    <Skeleton width="33.33%" height="40px" className="mx-1"></Skeleton>
                    <Skeleton width="33.33%" height="40px" className="mx-1"></Skeleton>
                    <Skeleton width="33.33%" height="40px" className="mx-1"></Skeleton>
                </div>
            </div>
        </>
    );
};

export default FnbSeatDetailSkeleton;
