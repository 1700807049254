import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import orderRecived from '../../../assets/food/orderrecived.svg'

const OrderReceived = () => {
  const navigate = useNavigate();
  const bookingId = localStorage.getItem("bookingid");
  const orderId = localStorage.getItem("orderId");
  const en_bid = window.location.search.includes("bookingId") ? new URLSearchParams(window.location.search).get('bookingId') : localStorage.getItem("en_bid");

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(`/order-status?bookingid=${en_bid}`);
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="or-maindiv">
      <img src={orderRecived} alt="" />
      <p className="or-ptag">Order Received</p>
    </div>
  );
};

export default OrderReceived;
