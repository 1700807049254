import axios from "axios";
import * as appConstants from "../constants/index";
const theme = localStorage.getItem("theme");

const passportpayment = {}; 
var passportServicedata;

export const setStorePassportData = (data) =>{
  //console.log(data);
  passportServicedata = data;
}

export const getPassportData = () =>{
  return passportServicedata;
}

export const passportList = async (postData) => {
  //console.log('localStorage.getItem("token")', localStorage.getItem("token"));
  try {
    const token = localStorage.getItem("token") || "";
    // console.log("Token:", token);

    const response = await axios.post(
      `${appConstants.base_url}v1/passport/list`,
      postData,
      {
        headers: {
          Authorization: `Bearer ` + token,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error making API request:", error);
    throw error;
  }
};
export const passportProfile = async (postData) => {
    //console.log('localStorage.getItem("token")', localStorage.getItem("token"));
    try {
      const response = await axios
      .post(
        `${appConstants.base_url}v1/user/profile`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        }
      );
  
      return response;
    } catch (error) {
      console.error("Error making API request:", error);
      throw error;
    }
};
export const passportSaveRequest = async (postData) => {
  postData.dob = postData.dob1.replace( /\//g , "-");
  postData.dob = reformatDate(postData.dob);
    //console.log(postData.dob);
    try {
      const response = await axios
      .post(
        `${appConstants.base_url}v1/passport/save`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        }
      );
  
      return response;
    } catch (error) {
      console.error("Error making API request:", error);
      throw error;
    }
};
export const passportMember = async (postData) => {
  //console.log('localStorage.getItem("token")', localStorage.getItem("token"));
  try {
    const token = localStorage.getItem("token") || "";
    // console.log("Token:", token);

    const response = await axios.post(
      `${appConstants.base_url}v1/passport/member`,
      postData,
      {
        headers: {
          Authorization: `Bearer ` + token,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error making API request:", error);
    throw error;
  }
};
export const passportCancelRequest = async (postData) => {
    //console.log('localStorage.getItem("token")', localStorage.getItem("token"));
    try {
      const response = await axios
      .post(
        `${appConstants.base_url}v1/passport/cancel`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        }
      );
  
      return response;
    } catch (error) {
      console.error("Error making API request:", error);
      throw error;
    }
};
export const passportUnsubscribeRequest = async (postData) => {
  //console.log('localStorage.getItem("token")', localStorage.getItem("token"));
  try {
    const response = await axios
    .post(
      `${appConstants.base_url}v1/passport/revoke`,
      postData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error making API request:", error);
    throw error;
  }
};
export const setDataForPaymet = (data) =>{
    localStorage.setItem("transid", 0);
    localStorage.setItem("bookingid", data?.orderId);
    localStorage.setItem("booking_type", data?.bookType);
    localStorage.setItem("subscription", data?.subscription);
    localStorage.setItem('passportOrder', JSON.stringify(data));
};
export const getDataForPaymet = () =>{
    let storedVariable = JSON.parse(localStorage.getItem('passportOrder'));
    localStorage.removeItem('passportOrder');
    return storedVariable;
};
export const kotakPpplus = async (postData) => {
  // console.log('localStorage.getItem("token")', localStorage.getItem("token"));
  try {
    const response = await axios
    .post(
      `${appConstants.base_url}v1/payment/coupon/kotak/list`,
      postData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") || " "}`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error making API request:", error);
    throw error;
  }
};
export const isValidDateOfBirth = (dateString) => {
  // Regular expression for DD/MM/YYYY format
  const dateFormatRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d\d$/;

  // Check if the input matches the MM/DD/YYYY format
  if (!dateFormatRegex.test(dateString)) {
    return false;
  }

  // Extract month, day, and year from the input
  const [day, month, year] = dateString.split('/').map(Number);
  //console.log(day, month, year);

  // Validate the day, month, and year
  if (month < 1 || month > 12) {
    return false; // Invalid month
  }

  if (day < 1 || day > new Date(year, month, 0).getDate()) {
    return false; // Invalid day
  }

  // Create a Date object from the extracted values
  const inputDate = new Date(year, month - 1, day); // Note: months are 0-indexed
  const today = new Date();
  // Calculate the age difference in years
  let ageDifference = today.getFullYear() - inputDate.getFullYear();
  const monthDiff = today.getMonth() - inputDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < inputDate.getDate())) {
    ageDifference = ageDifference-1;
  }
  // Check if the person is at least 18 years old
  return ageDifference;
}
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  
  if (isNaN(date.getTime())) {
    return false;
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}
export const hitGa = (param) =>{
  let dataLayer = window["dataLayer"] || [];
    dataLayer.push(
      param
    );
}
export const reformatDate = (inputDate)=>{
  // Regular expression to match "dd-mm-yyyy" format
  var regex = /(\d{2})-(\d{2})-(\d{4})/;
  
  // Use replace() with a callback function
  return inputDate.replace(regex, function(match, day, month, year) {
    // Rearrange the matched parts and separate them with "-"
    return year + '-' + month + '-' + day;
  });
}
