import React from "react";
import { Skeleton } from "primereact/skeleton";

const PaymodesListSkeleton = () => {
  return (
    <div className="card custom-skeleton mx-1">
      <div className="card">
        <Skeleton width="100%" height="2rem" className="my-1"></Skeleton>
        <Skeleton width="100%" height="2rem" className="my-1"></Skeleton>
        <Skeleton width="100%" height="2rem" className="my-1"></Skeleton>
        <Skeleton width="100%" height="2rem" className="my-1"></Skeleton>
        <Skeleton width="100%" height="2rem" className="my-1"></Skeleton>
        <Skeleton width="100%" height="2rem" className="my-1"></Skeleton>
        <Skeleton width="100%" height="2rem" className="my-1"></Skeleton>
        <Skeleton width="100%" height="2rem" className="my-1"></Skeleton>
        <Skeleton width="100%" height="2rem" className="my-1"></Skeleton>
        <Skeleton width="100%" height="2rem" className="my-1"></Skeleton>
        <Skeleton width="100%" height="2rem" className="my-1"></Skeleton>
        <Skeleton width="100%" height="2rem" className="my-1"></Skeleton>
        <Skeleton width="100%" height="2rem" className="my-1"></Skeleton>
        <Skeleton width="100%" height="2rem" className="my-1"></Skeleton>
        <Skeleton width="100%" height="2rem" className="my-1"></Skeleton>
        <Skeleton width="100%" height="2rem" className="my-1"></Skeleton>
      </div>
    </div>
  );
};

export default PaymodesListSkeleton;
