import styled from "styled-components";


export const DonateButton = styled.button`
background-color: ${(props) => props.theme.colors.bgColor};
color:${(props) => props.theme.colors.txtColor};
border: 0;
`;

export const CancelButton = styled.button`
background-color: ${(props) => props.theme.colors.bgColorLight};
border: 0;
`;