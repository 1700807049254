import styled from "styled-components";

export const Sections = styled.section`
  background-color: ${(props) => props.theme.colors.bgColor};
  color:${(props)=>props.theme.colors.txtColor}
`;

export const ExtndButton = styled.button`
background-color: white;
color: #000}
`;
