import styled from "styled-components";

export const CitySpan = styled.span`
  color: ${(props) => props.theme.colors.txtColor};
  background-color: ${(props) => props.theme.colors.bgColor};
  padding: 5px 8px;
  border-radius: 7px;
`;

export const CityName = styled.span`
position: absolute;
bottom: 10px;
left: 10px;
font-family: 'Poppins';
font-size: 14px;
font-weight: 700;
color: ${(props) => props.theme.colors.bgColor};
`;
