import * as appConstants from "../constants/index";
export const monthsName = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const IscustomEvent = (event)=>{
  // if (appConstants.env == "stage") {
  
    Evergage.init({
      account: appConstants.ISaccount,
      dataset: appConstants.ISdataset,
    });
    Evergage.sendEvent({
      action: event,
    });
  // }
}
export const IsEvent = (event)=>{
  // if (appConstants.env == "stage" || appConstants.env == "preprod") {
    Evergage.init({
      account: appConstants.ISaccount,
      dataset: appConstants.ISdataset,
    });
    Evergage.sendEvent(
      event
    );
  // }
}
export const salesForceEvent = (event)=>{
  // if (appConstants.env == "stage" || appConstants.env == "preprod") {
    SalesforceInteractions.init({
      account: appConstants.ISaccount,
      dataset: appConstants.ISdataset,
    });
    SalesforceInteractions.sendEvent(event);
  // }
}

export const formatDateOnProfile = (dateString) => {
  const date = new Date(dateString);
  
  const options = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  return date.toLocaleString('en-US', options);
};
export const convertToNumeric = (time) => {
  const [hours, minutes] = time.split(":");
  return parseInt(hours, 10) + parseInt(minutes, 10) / 60;
};
export const convertToTime = (value) => {
  const hours = Math.floor(value);
  const minutes = Math.round((value - hours) * 60);
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes}`;
};
export  const getCurrentDate = (val) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JavaScript
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};