import React, { useState, useEffect, useRef } from "react";
import * as appConstants from "../../../constants/index";
import { useFormik } from "formik";
import { ProceedButton } from "../../common/login/loginTags";
import OtpInput from "react-otp-input";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

const Phonepe = (props) => {  

  const bookingid = localStorage.getItem("bookingid");
  const theme = localStorage.getItem("theme");
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  const [loading, setLoading] = useState(false);
  const [instaData, setInstaData] = useState(null);
  const [mobileOtp, setMobileOtp] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [timeCounter, setTimeCounter] = useState(null);

  useEffect(() => {
    //getNetBankingList1();
    // console.log(props);
    //initJuspay();
    InstaPayMember();
  }, []);

  useEffect(() => {
    if (timeCounter && timeCounter > 0) {
      setTimeout(() => {
        setTimeCounter(timeCounter - 1);
      }, 1000);
    }
  }, [timeCounter]);

  const InstaPayMember = async()=> {
    setLoading(true);
    const postData = {
      "transId": localStorage.getItem("transid"),
      "bookingId": localStorage.getItem("bookingid"),
      "bookType": localStorage.getItem("booking_type") === "GIFTCARD" ? 'GIFTCARD' : localStorage.getItem("booking_type") === "FOOD" ? 'FOOD' : 'BOOKING',
      "paytype": "WALLET",
      "pgtype": "JUSPAY"
    };

    await axios
      .post(`${appConstants.base_url}v1/giftcard/member`, postData, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") || ""
          }`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        const dt = res.data;
        setLoading(false);
        if (dt.result === "success") {
          console.log(dt);
          setInstaData(dt.output);
          // setTimeout(() => {
            InstaPayOTP(dt.output?.cardnumber);  
          // }, 1000);
          
          // setTicketSummary(ndt);
          //document.getElementById("payment_form_NB").submit();
          // localStorage.setItem("paymentid", dt.output.paymentId);
          // localStorage.setItem("payment_type", "nonwallet");
          // console.log("bankList==>", dt);
          // formRef.current.click(); 
        } else {
          // console.log(res.data.msg);
          setErrorMsg(res.data.msg);
          setShowAlert(true);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  }

  const InstaPayOTP = async(cardnum, sendotp = null)=> {
    setLoading(true);
    console.log(instaData || "not coming");
    const postData = {
      "transId": localStorage.getItem("transid"),
      "bookingId": localStorage.getItem("bookingid"),
      "bookType": localStorage.getItem("booking_type") === "GIFTCARD" ? 'GIFTCARD' : localStorage.getItem("booking_type") === "FOOD" ? 'FOOD' : 'BOOKING',
      "gccardnum": cardnum,
      "gcpinnum": sendotp?mobileOtp:null,
      "type": "INSTAPAY"
    };

    await axios
      .post(`${appConstants.base_url}v1/payment/giftcard/ipay`, postData, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") || ""
          }`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        const dt = res.data;
        setLoading(false);
        if (dt.result === "success") {
          setTimeCounter(60);
          const ndt = dt.output;

          if(ndt?.otp_require == "true")
          {
            setTimeCounter(60);
          } else{
            dt.voucheName = "InstaPay";
            props.sendDataForPayment(dt);
          }
          // setTicketSummary(ndt);
          //document.getElementById("payment_form_NB").submit();
          // localStorage.setItem("paymentid", dt.output.paymentId);
          // localStorage.setItem("payment_type", "nonwallet");
          // console.log("bankList==>", dt);
          // formRef.current.click(); 
        } else {
          setErrorMsg(res.data.msg);
          setShowAlert(true);
          console.log(res.data.msg);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  }

  return (
    <>
     <SweetAlert
        error
        show={showAlert}
        title={"Oops"}
        onConfirm={() => {
          setShowAlert(false);
          // console.log('confirm')
          //navigate('/')
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnCssClass={theme === 'inoxTheme' ? 'confirm-btn-inox border-0' : 'confirm-btn-pvr border-0'}
      >
        {errorMsg}
      </SweetAlert>
      <div className="">
        <div className="payment-form">
          <div className="paywithdebit">
            <div className="debit-card show-in-desktop-view">
              <h6>Insta Pay</h6>
            </div>
            <div className="to-paid">
              {/* <p>
                To be Paid:
                <span className="price-cross">₹8888</span>{" "}
                <span className="price-blod">₹8888</span>
              </p> */}
            </div>
          </div>
          {loading?
              <span>
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "2rem" }}
              />
            </span>  
          :instaData?
          <div className="cinema-house payment-postpaytm">
            <h1>Enter PIN</h1>
            <p className="otp-content">You need to enter the PIN received</p>
            <h6>Card Number:  {instaData.cardnumber}</h6>
            <h6>Card Balance:  {instaData.balance}</h6>
            <div className="otp-feild">
              <OtpInput
                value={mobileOtp}
                onChange={setMobileOtp}
                numInputs={6}
                inputStyle={{width:'60px'}}
                // renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} className="otpfeild" />}
              />
            </div>
            <div className="resend-otp">
              {timeCounter > 0 ? (
                <h6>{`00:${timeCounter < 10 ? `0${timeCounter}` : timeCounter
                  }`}</h6>
              ) : (
                <h6
                  className="show-in-mobile-views"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setMobileOtp("");
                    InstaPayOTP(instaData.cardnumber)
                  }}
                >
                  Resend OTP
                </h6>
              )}
            </div>
            <div className="register-btn">
              <ProceedButton className="btn-proceeds verfiy-paytm"  onClick={() => {InstaPayOTP(instaData.cardnumber, mobileOtp)}}>
              Verify 
              </ProceedButton>
            </div>
          </div>
          :
          <div className="cinema-house payment-postpaytm">
            <h1></h1>
            <p className="otp-content">You don't have account in instapay</p>
            <h6></h6>
            
          </div>
          }
        </div>
      </div>
    </>
  )
}

export default Phonepe