import React from 'react'

const OfferCard = ({ handleOfferfoShow, items }) => {
	const formattedDate = new Date(items?.validTo)?.toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })
	return (
		<>
			<div className="fb-o-card">
				<div className="fb-o-abs">
					<div className={`fb-o-cardInn ${items?.bankVouType?.toLowerCase() === "binbased" ? "bankstatus" : "newstatus"}`}>
						<div className='bankTag'>
							{items?.bankVouType?.toLowerCase() === "binbased" ?
								(
									<>
										<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11 5V8.5C11 8.89783 10.842 9.27936 10.5607 9.56066C10.2794 9.84197 9.89782 10 9.5 10H2.5C2.10218 10 1.72064 9.84197 1.43934 9.56066C1.15804 9.27936 1 8.89783 1 8.5V5H11ZM9 7H7.5C7.36739 7 7.24021 7.05268 7.14645 7.14645C7.05268 7.24022 7 7.36739 7 7.5C7 7.63261 7.05268 7.75979 7.14645 7.85355C7.24021 7.94732 7.36739 8 7.5 8H9C9.13261 8 9.25978 7.94732 9.35355 7.85355C9.44732 7.75979 9.5 7.63261 9.5 7.5C9.5 7.36739 9.44732 7.24022 9.35355 7.14645C9.25978 7.05268 9.13261 7 9 7ZM9.5 2C9.89782 2 10.2794 2.15804 10.5607 2.43934C10.842 2.72064 11 3.10218 11 3.5V4H1V3.5C1 3.10218 1.15804 2.72064 1.43934 2.43934C1.72064 2.15804 2.10218 2 2.5 2H9.5Z" fill="#BF9804" />
										</svg>
										Bank
									</>

								) :
								(
									<>
										<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11.9998 6.01175C8.78125 6.20585 6.2057 8.7815 6.0116 12H5.9881C5.794 8.7815 3.21845 6.20585 0 6.01175V5.98825C3.21845 5.79415 5.794 3.2186 5.9881 0H6.0116C6.2057 3.2186 8.78125 5.79415 11.9998 5.98825V6.01175Z" fill="#146922" />
										</svg>
										New
									</>
								)}

						</div>
						<div className="fb-o-hdr">
							<p className="o-off">{items?.vouDesc}</p>
							{formattedDate !== "Invalid Date" && <p className="off-vaild">Valid till {formattedDate}</p>}
						</div>
						{items?.tnc && <p className="off-vaild"> {items?.tnc} </p>}
						<div className="fboffer-more">
							<button
								onClick={() => handleOfferfoShow(items?.vouId, items?.vouDesc, items?.bankVouType, items?.category, items?.type, items?.tnc)}
								className="moreLink" >
								{items?.tnc && items.tnc.length > 0 && "+ More"}
							</button>

							<button onClick={() => handleOfferfoShow(items?.vouId, items?.vouDesc, items?.bankVouType, items?.category, items?.type, items?.tnc)} className="o-applyBtn">
								+ Apply
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default OfferCard