import React, { useState, useEffect } from "react";
import "./cities.css";
import * as appConstants from "../../../constants/index";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import location from "../../../assets/images/loaction.png";
import { useDispatch, useSelector } from "react-redux";
import { CityName, CitySpan } from "./cityTags";
import { setViewMode } from "../../../redux/actions/viewModeActions";
import { AutoComplete } from "primereact/autocomplete";
import dummyCityImg from "../../../assets/dummy-images/dummyCityImg.jpg";
import map from "../../../assets/nav-icon/location-new.svg";
// import map from "../../../assets/cinemas/location.svg";
import custoArrowB from "../../../assets/cities/black-cities.svg";
import { useNavigate } from "react-router-dom";
import { setCityChangeData as reduxCity } from "src/redux/actions/cityAction";
import { getDelSplashData } from "src/services/OtherServices";

export default function Cities() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  const [otherCity, setOtherCity] = useState([]);
  const [city, setCity] = useState([]);
  const [visible, setVisible] = useState(false);
  const theme = localStorage.getItem("theme");
  const [locationPermission, setLocationPermission] = useState(null);
  let selectedCity = localStorage.getItem("city");
  const [selectedCityData, setSelectedCityData] = useState(
    localStorage.getItem("city")
  );
  const [cityChangeData, setCityChangeData] = useState("");
  const [filteredCities, setFilteredCities] = useState(null);
  const [currentCity, setCurrentCity] = useState(null);
  const [isSubCity, setIsSubCity] = useState(null);
  const currentPath = window.location.pathname.split("/");
  const citychange = useSelector((state) => state.cityChangeData);
  // console.log(citychange);
  const staticPageUrl = [
    "about-us",
    "advertise",
    "faq",
    "careers-us",
    "feedback",
    "privacy-policy",
    "terms-conditions",
    "terms-use",
    'donation-tnc',
    'seatlayout-wchat',
    'seatlayout'
  ];

  // console.log("currentPath", currentPath);
  useEffect(() => {
    if(citychange?.change){
      requestLocationPermission();
    }
  },[citychange])

  useEffect(() => {
    if (localStorage.getItem("city")) {
      setVisible(false);
    }
   
    getCityData();

    if (!localStorage.getItem("city") && !staticPageUrl.includes(currentPath[1]) && !localStorage.getItem("city")) {
      requestLocationPermission();

      // console.log('locationPermission', locationPermission);
      // if (
      //   locationPermission === null &&
      //   localStorage.getItem("long") === null
      // ) {
      //   setVisible(true);
      // } else if (
      //   locationPermission === "denied" &&
      //   localStorage.getItem("long") === null
      // ) {
      //   setVisible(true);
      // } else if (locationPermission === "granted") {
      //   currentCity && handleChangeCity(currentCity);
      //   setVisible(false);
      //   navigator.geolocation.getCurrentPosition(function (position) {
      //     let lat = position.coords.latitude;
          
      //     localStorage.setItem("lat", lat);
      //     let long = position.coords.longitude;
      //     console.log(lat, long);
      //     localStorage.setItem("long", long);
      //     currentCity &&
      //       handleChangeCity(currentCity, { lat: lat, long: long });
      //     getCityData();
      //   });
      // }
    }
  }, []);
  useEffect(()=>{
    if(locationPermission == "granted"){
      getCityData();
    }

  },[locationPermission])

  const requestLocationPermission = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocationPermission("granted");
        console.log(position);
        let lat = position.coords.latitude;
        localStorage.setItem("lat", lat);
        let long = position.coords.longitude;
        localStorage.setItem("long", long);
        // setVisible(false);
        // getCityData()
        // navigator.geolocation.getCurrentPosition(function (position) {
        //   let lat = position.coords.latitude;
        //   localStorage.setItem("lat", lat);
        //   let long = position.coords.longitude;
        //   localStorage.setItem("long", long);
        // });
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          setLocationPermission("denied");
        } else {
          setLocationPermission("prompt");
        }
      }
    );
  };

  // === get city list ========
  const getCityData = async () => {
    const postData = {
      lat: localStorage.getItem("lat"),
      lng: localStorage.getItem("long"),
    };
    await axios
      .post(`${appConstants.base_url}v1/booking/content/city`, postData, {
        headers: {
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city") || "",
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
          flow: "PVRINOX",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          setCity(dt.output.pc);
          setOtherCity(dt.output.ot);
          if (!localStorage.getItem("city") && !staticPageUrl.includes(currentPath[1])) {
             setVisible(true);
          }
          setCurrentCity(dt.output.cc);
          // console.log(localStorage.getItem("city"), locationPermission);
          // if(localStorage.getItem("mapenable")){}
          
          if(!localStorage.getItem("city") && locationPermission == "granted"){
            setVisible(false);
            if(dt.output.cc){
              // localStorage.setItem("city", dt.output.cc.name);
              handleChangeCity(dt.output.cc, { lat: localStorage.getItem("lat"), long: localStorage.getItem("long") })
            }
          }
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const handleChangeCity = (data, latLang) => {
    // console.log("latLang", latLang);
    dispatch(
      reduxCity({
          city: data.name,
          change: false
      })
    );
    getDelSplashData();
    localStorage.setItem("city", data.name);
    setSelectedCityData(data.name);
    setCityChangeData("");
    localStorage.setItem("lat", latLang?.lat || "0.00");
    localStorage.setItem("long", latLang?.long || "0.00");
    setVisible(false);
    setIsSubCity(null);
    dispatch(setViewMode(Math.floor(10000 + Math.random() * 90000)));
    if (!isMobile && currentPath[1]==='cinemas') {
      window.location.reload();
    }else if(!isMobile){
      navigate("/");
    }
    hitDynamicGa("home_location", data.name);
  };
  const hitDynamicGa = (event, city) => {
    let dataLayer = window["dataLayer"] || [];

    dataLayer.push({
      event: event,
      location: city,
    });
  };

  const searchCity = (event) => {
    setTimeout(() => {
      let _filteredCities;
      if (!event.query.trim().length) {
        _filteredCities = [...otherCity];
      } else {
        _filteredCities = otherCity.filter((city) => {
          return city.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
      console.log("_filteredCities", _filteredCities);

      setFilteredCities(_filteredCities);
    }, 250);
  };

  return (
    <>
      <div className="show-desktop-view">
        <Dropdown
          aria-label="All Cities List"
          onMouseDown={() => setVisible(true)}
          placeholder={
            <span className="cities-placed">
              <img src={map} alt="" className="img-fluid" />{" "}
              {citychange?.city?citychange?.city:localStorage.getItem("city")}
            </span>
          }
          className="custom-icon"
          style={{ position: "relative", top: "5px" }}
        />
        <div className="custom-dropdown-arrow">
          <img src={custoArrowB} alt="" className="" />
        </div>
      </div>
      <div
        onClick={() => {
          setVisible(true);
        }}
        className="show-mobile-view"
      >
        <h6>
          {citychange?.city?citychange?.city:localStorage.getItem("city")}
          <span className="down-arrow">
            <img src={custoArrowB} alt="" className="img-fluid" />
          </span>
        </h6>
      </div>

      <Sidebar
        header="Header"
        visible={visible}
        position="right"
        className="custom-sidebar"
        onHide={() => {
          setVisible(false);
          setCityChangeData("");
          setIsSubCity(null);
        }}
        dismissable={false}
        showCloseIcon={false}
        blockScroll={true}
      >
        <div className="row mobile-mt-50 ">
          <div className="col-lg-12 col-sm-12">
            <div className="cities-fixed-mobike">
              <div className="cities-show">
                <div className="select-city">
                  <h4>Select City</h4>
                </div>
                <div className="colse-btn">
                  {selectedCityData || selectedCity ? (
                    <span onClick={() => setVisible(false)}>
                      <i
                        className="pi pi-times"
                        style={{ color: "#000", cursor: "pointer" }}
                      ></i>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="search-current">
                {/* <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText placeholder="Search City" />
              </span> */}
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-search" />
                  </span>
                  <AutoComplete
                    id="city"
                    value={cityChangeData}
                    suggestions={filteredCities}
                    style={{ zIndex: "99999999" }}
                    completeMethod={searchCity}
                    field="name"
                    aria-label="Cities"
                    dropdownAriaLabel="Select City"
                    placeholder="Search for city"
                    onChange={(e) => {
                      setCityChangeData(e.value);
                      setSelectedCityData(e.value);
                      // console.log('val', e.value);
                    }}
                    onSelect={(e) => {
                      const val = e.value;
                      handleChangeCity(val, { lat: val.lat, long: val.lng });
                      // console.log('val', val)
                    }}
                  />
                </div>
                <div
                  className="current-location pointer"
                  onClick={() => {
                    currentCity &&
                      handleChangeCity(currentCity, {
                        lat: currentCity.lat,
                        long: currentCity.lng,
                      });
                  }}
                >
                  <img src={location} alt="" />
                </div>
              </div>
            </div>
          </div>
          {city?.map((item, idx) => {
            return (
              <>
                <div className="col-lg-6 col-6 plr-cities" key={idx}>
                  <div
                    className="cities-names"
                    onClick={() => {
                      if (item.hasSubCities) {
                        const itemNewval = {
                          ...item,
                          subcities: [
                            {
                              id: item.name,
                              name: "All",
                            },
                            ...item.subcities,
                          ],
                        };
                        // console.log("itemNewval", itemNewval);
                        setIsSubCity(itemNewval);
                      } else {
                        handleChangeCity(item, {
                          lat: item.lat,
                          long: item.lng,
                        });
                      }
                    }}
                  >
                    <img
                      src={
                        item?.image?.length !== 0 ? item?.image : dummyCityImg
                      }
                      alt=""
                      className="img-fluid pointer"
                      style={
                        item.name === selectedCity && theme === "inoxTheme"
                          ? { border: "2px solid #234B9E" }
                          : item.name === selectedCity && theme === "pvrTheme"
                          ? { border: "2px solid #FFCB05" }
                          : {}
                      }
                    />
                    <div className="cities-overlay cities-active"></div>
                    {item.name === selectedCityData ? (
                      <CityName className="pointer">{item.name}</CityName>
                    ) : (
                      <h6 className="cities-name pointer">{item.name}</h6>
                    )}
                  </div>
                </div>
                {isSubCity && isSubCity?.name === item?.name ? (
                  <div className="col-lg-12 col-12 plr-cities my-2">
                    <div
                      className={`sub-cities-list rounded ${
                        theme === "inoxTheme"
                          ? "border-dark-inox"
                          : "border-dark-pvr"
                      }`}
                    >
                      <ul>
                        {isSubCity?.subcities?.map((cItem, cIdx) => (
                          <li
                            className="pointer my-1"
                            key={cIdx}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (cItem.name === "All") {
                                handleChangeCity(
                                  {
                                    id: item.name,
                                    name: item.name,
                                  },
                                  { lat: item.lat, long: item.lng }
                                );
                              } else {
                                handleChangeCity(cItem, {
                                  lat: item.lat,
                                  long: item.lng,
                                });
                              }
                              setVisible(false);
                            }}
                          >
                            {cItem.name === selectedCityData ? (
                              <CitySpan>{cItem.name}</CitySpan>
                            ) : cItem.id === selectedCityData ? (
                              <CitySpan>{cItem.name}</CitySpan>
                            ) : (
                              <span>{cItem.name}</span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          })}
          <div className="col-lg-12 col-sm-12">
            <div className="cities-box">
              <div className="cities-part">
                <div className="cities-head">
                  <h3>
                    <span className="other-cities">Other Cities</span>
                  </h3>
                </div>
                <div className="other-cities-list">
                  <ul>
                    {otherCity?.map((item, idx) => {
                      return (
                        <>
                          <li
                            className="pointer"
                            onClick={() => {
                              handleChangeCity(item, {
                                lat: item.lat,
                                long: item.lng,
                              });
                              setVisible(false);
                            }}
                            key={idx}
                          >
                            {item.name === selectedCityData ? (
                              <CitySpan>{item.name}</CitySpan>
                            ) : (
                              <span>{item.name}</span>
                            )}
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
}
