import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Constants from "../../../constants/index";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { Divider } from "primereact/divider";
import arrowback from "../../../assets/passport/mdi_arrow_back.png";
import { getCurrentDate, gnrUi } from "src/global-functions/globalFunctions";
import acess from "../../../assets/moviesession/acess.svg";
import subtitles from "../../../assets/moviesession/subtitles.svg";
import { Dialog } from "primereact/dialog";
import { DarkButton, LightButton } from "src/styled-components/customButtons";
import { ProceedBtn } from "src/views/get-food/getfoodTags";
// import "./SessionSideBar.css";


const SessionSidebar = ({movieSession, showMovie, changeShowMovie, arrayposition=0}) => {
    const navigate = useNavigate();
    const [movieSidebar, setMovieSidebar] = useState(false);
    const isMobile = window.matchMedia("(max-width: 575px)").matches;
    const [seatlayoutUrl, setSeatLayoutUrl] = useState("");
    const [alertTextValue, setAlertTextValue] = useState("");
    const [showMovieAlert, setShowMovieAlert] = useState(false);
    const [alertTextViewMode, setAlertTextViewMode] = useState(0);
    const [singlemovieSession, setSinglemovieSession] = useState([]);

    useEffect(()=>{
        setMovieSidebar(showMovie);
        localStorage.setItem("seatLayoutDate", getCurrentDate(""));
    },[showMovie])
    useEffect(()=>{
        if(movieSession){
            // console.log([movieSession?.cinemaMovieSessions[arrayposition]], movieSession);
            setSinglemovieSession([movieSession?.cinemaMovieSessions[arrayposition]]);
        }
    }, [movieSession, arrayposition])

    const hitGa = (event) => {
        let dataLayer = window["dataLayer"] || [];
        dataLayer.push({
          event: event,
        });
      };

    const gnrUi = (data) => {
        const regex = /\(([^)]+)\)/;
        const hasParentheses = regex.test(data);
        if (hasParentheses) {
          const match = data.match(regex);
          return match ? `(${match[1]})` : `(${data})`;
        } else {
          return `(${data})`;
        }
      };

    return (
        <>
            <Sidebar
                header="Header"
                visible={movieSidebar}
                position={isMobile?"bottom":"center"}
                className="custom-map-sidebar"
                // style={isMobile?{ height: "86%" }:""}
                onHide={() =>
                {setMovieSidebar(false); changeShowMovie(false)}}
                dismissable={true}
                showCloseIcon={false}
                blockScroll={true}
            >
                <div className="passport-right show-in-mobile-view">
                    <div className="colse-btn pvr-inox-heading">
                    <div className="pvr-inox-passport-info">
                        {/* <div className="arrow-img" onClick={() => {setMovieSidebar(false); changeShowMovie(false)}}>
                            <img src={arrowback} alt="Big Image" />
                        </div> */}

                        <div className="inox-passport">
                        
                            <h2 className="heading-movie">{singlemovieSession[0]?.movieRe?.filmName}</h2>
                            <div className="show-divide-line">
                                <Divider />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* mobile view */}
                {/*desktop view  */}
                <div className="passport-right show-in-desktop-view">
                    <div className="colse-btn pvr-inox-heading">
                        <div className='row'>
                            <div className='col-12'>
                                <h3 className="heading-movie">{singlemovieSession[0]?.movieRe?.filmName}</h3>
                                <div className="show-divide-line">
                                    <Divider />
                                </div>
                            </div>
                            {/* <div className='col-2'>
                                <span onClick={() => {setMovieSidebar(false); changeShowMovie(false)}}>
                                    <i
                                    className="pi pi-times"
                                    style={{ color: "#000", cursor: "pointer" }}
                                    ></i>
                                </span>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="row p-3" style={{overflowY:'scroll'}}>
                    <div className="col-12">
                       
                        {singlemovieSession.map((item2) => {
                            return (
                            <>
                                <div className="col-lg-12">
                                <div className="movie-name">
                                    <h4>
                                    {/* {item2.movieRe.n}{" "}
                                    {gnrUi(item2.movieRe.ce)}
                                    
                                    <span className="dot-cs" />
                                    {item2.movieRe.mlength}{" "}
                                    <span className="dot-cs" />{" "}
                                    {item2.movieRe.mfs.length > 0
                                        ? item2.movieRe.mfs.join(", ")
                                        : item2.movieRe.mfs}{" "}
                                    <span className="dot-cs" />
                                    {item2.movieRe.grs} */}
                                    {movieSession?.cinemaRe?.name}
                                    </h4>
                                </div>
                                </div>
                                <div className="row">
                                {item2?.experienceSessions?.map((item3) => {
                                    return (
                                    <>
                                        <div className="showtimes-cinemas-name-map">
                                        <h2 className="fromat-type">
                                            {item3?.experience === "PXL"
                                            ? "P[XL]"
                                            : item3?.experience}
                                        </h2>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center"></div>
                                        {item3.shows.map((item4) => {
                                        let url2 = `/seatlayout/${item4.encrypted}`;

                                        return (
                                            <>
                                            {item4.status === 0 ? (
                                                <div className="box-slot-moviesession-map show-spaces pointer">
                                                <div className="time-select map-time-select seat-color-disable pointer">
                                                    <div
                                                    className={
                                                        item4.handicap
                                                        ? "show-details-icon-map"
                                                        : "show-details"
                                                    }
                                                    >
                                                    <div className="show-times">
                                                        <h5>
                                                        {item4.showTime}
                                                        </h5>
                                                        <p>
                                                        {!item4?.filmFormat?.includes(
                                                            "3D"
                                                        )
                                                            ? item4?.filmFormat?.substr(
                                                                3
                                                            )
                                                            : ""}
                                                        </p>
                                                    </div>
                                                    <div className="icon-subtitles-map">
                                                        <div className="accesss chair-icon">
                                                        {item4.handicap ? (
                                                            <img
                                                            src={acess}
                                                            alt=""
                                                            className="img-fluid"
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                        </div>
                                                        {item4.subtitle ? (
                                                        <div className="accesss">
                                                            <img
                                                            src={subtitles}
                                                            alt=""
                                                            className="img-fluid"
                                                            />
                                                        </div>
                                                        ) : (
                                                        ""
                                                        )}
                                                    </div>
                                                    </div>
                                                    <div className="eng-map">
                                                    <h6>
                                                        {item4.language}{" "}
                                                        {item4?.filmFormat?.includes(
                                                        "3D"
                                                        )
                                                        ? "-3D"
                                                        : ""}
                                                    </h6>
                                                    </div>
                                                </div>
                                                </div>
                                            ) : item4.status === 1 ? (
                                                <div className="box-slot-moviesession-map show-spaces pointer">
                                                <div
                                                    className="time-select map-time-select"
                                                    onClick={() => {
                                                    hitGa("book_show_time");
                                                    setSeatLayoutUrl(url2);
                                                    setAlertTextValue({
                                                        adultAlert:
                                                        item2?.movieRe
                                                            ?.adult,
                                                        cinemaAlertMsg:
                                                        movieSession?.cinemaRe
                                                            .alertTxt,
                                                        showAlertMsg:
                                                        item4.alertTxt,
                                                    });

                                                    if (
                                                        item2?.movieRe?.adult
                                                    ) {
                                                        setShowMovieAlert(
                                                        true
                                                        );
                                                    } else if (
                                                        movieSession?.cinemaRe.alertTxt
                                                    ) {
                                                        setShowMovieAlert(
                                                        true
                                                        );
                                                        setAlertTextViewMode(
                                                        1
                                                        );
                                                    } else if (
                                                        item4.alertTxt
                                                    ) {
                                                        setShowMovieAlert(
                                                        true
                                                        );
                                                        setAlertTextViewMode(
                                                        2
                                                        );
                                                    } else {
                                                        navigate(url2);
                                                    }

                                                    localStorage.setItem(
                                                        "filmId",
                                                        item2?.movieRe.id
                                                    );
                                                    localStorage.setItem(
                                                        "sessionId",
                                                        item4?.sessionId
                                                    );
                                                    }}
                                                >
                                                    <div
                                                    className={
                                                        item4.handicap
                                                        ? "show-details-icon-map"
                                                        : "show-details"
                                                    }
                                                    >
                                                    <div className="show-times">
                                                        <h5>
                                                        {item4.showTime}
                                                        </h5>
                                                        <p>
                                                        {item4?.filmFormat && !item4?.filmFormat?.includes(
                                                            "3D"
                                                        )
                                                            ? item4?.filmFormat?.substr(
                                                                3
                                                            )
                                                            : ""}
                                                        </p>
                                                    </div>
                                                    <div className="icon-subtitles-map">
                                                        <div className="accesss chair-icon">
                                                        {item4.handicap ? (
                                                            <img
                                                            src={acess}
                                                            alt=""
                                                            className="img-fluid"
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                        </div>
                                                        {movieSession?.subtitle ? (
                                                        <div className="accesss">
                                                            <img
                                                            src={subtitles}
                                                            alt=""
                                                            className="img-fluid"
                                                            />
                                                        </div>
                                                        ) : (
                                                        ""
                                                        )}
                                                    </div>
                                                    </div>
                                                    <div className="eng-map">
                                                    <h6>
                                                        {item4.language}{" "}
                                                        {item4?.filmFormat?.includes(
                                                        "3D"
                                                        )
                                                        ? "-3D"
                                                        : ""}
                                                    </h6>
                                                    </div>
                                                </div>
                                                </div>
                                            ) : item4.status === 2 ? (
                                                <div className="box-slot-moviesession-map  show-spaces">
                                                <div
                                                    className="time-select map-time-select seat-color-yellow"
                                                    onClick={() => {
                                                    setSeatLayoutUrl(url2);
                                                    hitGa("book_show_time");
                                                    setAlertTextValue({
                                                        adultAlert:
                                                        item2?.movieRe
                                                            ?.adult,
                                                        cinemaAlertMsg:
                                                        movieSession?.cinemaRe
                                                            .alertTxt,
                                                        showAlertMsg:
                                                        item4.alertTxt,
                                                    });

                                                    if (
                                                        item2?.movieRe?.adult
                                                    ) {
                                                        setShowMovieAlert(
                                                        true
                                                        );
                                                    } else if (
                                                        movieSession?.cinemaRe.alertTxt
                                                    ) {
                                                        setShowMovieAlert(
                                                        true
                                                        );
                                                        setAlertTextViewMode(
                                                        1
                                                        );
                                                    } else if (
                                                        item4.alertTxt
                                                    ) {
                                                        setShowMovieAlert(
                                                        true
                                                        );
                                                        setAlertTextViewMode(
                                                        2
                                                        );
                                                    } else {
                                                        navigate(url2);
                                                    }

                                                    localStorage.setItem(
                                                        "filmId",
                                                        item2?.movieRe.id
                                                    );
                                                    localStorage.setItem(
                                                        "sessionId",
                                                        item4?.sessionId
                                                    );
                                                    }}
                                                >
                                                    <div
                                                    className={
                                                        item4.handicap
                                                        ? "show-details-icon-map"
                                                        : "show-details"
                                                    }
                                                    >
                                                    <div className="show-times">
                                                        <h5>
                                                        {item4.showTime}
                                                        </h5>
                                                        <p>
                                                        {!item4?.filmFormat?.includes(
                                                            "3D"
                                                        )
                                                            ? item4?.filmFormat?.substr(
                                                                3
                                                            )
                                                            : ""}
                                                        </p>
                                                    </div>
                                                    <div className="icon-subtitles-map">
                                                        <div className="accesss chair-icon">
                                                        {item4.handicap ? (
                                                            <img
                                                            src={acess}
                                                            alt=""
                                                            className="img-fluid"
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                        </div>
                                                        {item4.subtitle ? (
                                                        <div className="accesss">
                                                            <img
                                                            src={subtitles}
                                                            alt=""
                                                            className="img-fluid"
                                                            />
                                                        </div>
                                                        ) : (
                                                        ""
                                                        )}
                                                    </div>
                                                    </div>
                                                    <div className="eng-map">
                                                    <h6>
                                                        {item4.language}{" "}
                                                        {item4?.filmFormat?.includes(
                                                        "3D"
                                                        )
                                                        ? "-3D"
                                                        : ""}
                                                    </h6>
                                                    </div>
                                                </div>
                                                </div>
                                            ) : (
                                                <div className="box-slot-moviesession-map show-spaces ">
                                                <div className="time-select map-time-select seat-color-red">
                                                    <div
                                                    className={
                                                        item4.handicap
                                                        ? "show-details-icon-map"
                                                        : "show-details"
                                                    }
                                                    >
                                                    <div className="show-times">
                                                        <h5>
                                                        {item4.showTime}
                                                        </h5>
                                                        <p>
                                                        {!item4?.filmFormat?.includes(
                                                            "3D"
                                                        )
                                                            ? item4?.filmFormat?.substr(
                                                                3
                                                            )
                                                            : ""}
                                                        </p>
                                                    </div>
                                                    <div className="icon-subtitles-map">
                                                        <div className="accesss chair-icon">
                                                        {item4.handicap ? (
                                                            <img
                                                            src={acess}
                                                            alt=""
                                                            className="img-fluid"
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                        </div>
                                                        {item4.subtitle ? (
                                                        <div className="accesss">
                                                            <img
                                                            src={subtitles}
                                                            alt=""
                                                            className="img-fluid"
                                                            />
                                                        </div>
                                                        ) : (
                                                        ""
                                                        )}
                                                    </div>
                                                    </div>
                                                    <div className="eng-map">
                                                    <h6>
                                                        {item4.language}{" "}
                                                        {item4?.filmFormat?.includes(
                                                        "3D"
                                                        )
                                                        ? "-3D"
                                                        : ""}
                                                    </h6>
                                                    </div>
                                                </div>
                                                </div>
                                            )}
                                            </>
                                        );
                                        })}
                                    </>
                                    );
                                })}
                                </div>
                                <div className="luxe-divider"></div>
                            </>
                            );
                        })}
                    </div>
                </div>
                <ProceedBtn
                    className="btn-proceeded map-bottom"
                    onClick={() => {
                        hitGa("book_show_time");
                        hitGa("view_all_movies_at_cinema");
                        let url = `/cinemasessions/${localStorage.getItem("city")}/${
                            movieSession?.cinemaRe?.name
                          }/${movieSession?.cinemaRe.theatreId}`;
                          let url2 = url.replace(/\s/g, "-");
                          navigate(url2);
                    }}
                    // disabled={saveFoodLoading}
                  >
                    View all movies at the cinema
                  </ProceedBtn>

            </Sidebar>
            <div className="card flex justify-content-center">
                <Dialog
                visible={showMovieAlert}
                showHeader={false}
                showCloseIcon={false}
                className="popup_rate"
                onHide={() => setShowMovieAlert(false)}
                blockScroll={true}
                >
                <div className="card py-2 px-3">
                    {alertTextValue.adultAlert === true && alertTextViewMode === 0 ? (
                    <>
                        <div>
                        <p className="adult-terms">
                            This movie has been rated 'A' and is for audiences above the
                            age of 18. Please carry a valid photo ID/age proof to the
                            theater. No refund of tickets once bought.
                        </p>
                        </div>
                        <div className="d-flex justify-content-center">
                        <LightButton
                            className="reject-terms"
                            onClick={() => {
                            setShowMovieAlert(false);
                            setAlertTextViewMode(0);
                            }}
                        >
                            Close
                        </LightButton>
                        <DarkButton
                            className="reject-terms"
                            onClick={() => {
                            if (
                                alertTextValue.cinemaAlertMsg ||
                                alertTextValue.showAlertMsg
                            ) {
                                setAlertTextViewMode(1);
                            } else {
                                navigate(seatlayoutUrl);
                            }
                            }}
                        >
                            Accept
                        </DarkButton>
                        </div>
                    </>
                    ) : alertTextValue.cinemaAlertMsg && alertTextViewMode === 1 ? (
                    <>
                        <div>
                        <p className="mt-3">{alertTextValue.cinemaAlertMsg}</p>
                        </div>
                        <div className="d-flex justify-content-center">
                        <LightButton
                            className="reject-terms"
                            onClick={() => {
                            setShowMovieAlert(false);
                            setAlertTextViewMode(0);
                            }}
                        >
                            Close
                        </LightButton>
                        <DarkButton
                            className="py-2 px-3 rounded border-0"
                            onClick={() => {
                            if (alertTextValue.showAlertMsg) {
                                // setShowMovieAlert(false);
                                setAlertTextViewMode(2);
                            } else {
                                navigate(seatlayoutUrl);
                            }
                            // setShowMovieAlert(false);
                            }}
                        >
                            Accept
                        </DarkButton>
                        </div>
                    </>
                    ) : alertTextValue.showAlertMsg && alertTextViewMode === 2 ? (
                    <>
                        <div>
                        <p className="mt-3">{alertTextValue.showAlertMsg}</p>
                        </div>
                        <div className="d-flex justify-content-end">
                        <LightButton
                            className="reject-terms"
                            onClick={() => {
                            setShowMovieAlert(false);
                            setAlertTextViewMode(0);
                            }}
                        >
                            Close
                        </LightButton>
                        <DarkButton
                            className="reject-terms"
                            onClick={() => {
                            navigate(seatlayoutUrl);
                            }}
                        >
                            Accept
                        </DarkButton>
                        </div>
                    </>
                    ) : (
                    () => setShowMovieAlert(false)
                    )}
                </div>
                </Dialog>
            </div>
        </>
    );
};
export default SessionSidebar;