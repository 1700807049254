import React, { useEffect, useState } from "react";
import axios from "axios";
import * as appConstants from "../../../constants/index";
import ExtendTime from "src/views/common/extend-time/ExtendTime";
import cancelTicket from "../../../assets/food/cancel-ticket.svg";
import miv_inox from "../../../assets/default-images/vertical-poster-inox.png";
import miv_pvr from "../../../assets/default-images/vertical-poster-pvr.svg";
import cinemaScreen from "../../../assets/food/cinema-seat.svg";
import noSack from "../../../assets/food/no-snack.svg";
import priceArrow from "../../../assets/food/price-arrow.svg";
import nonveg from "../../../assets/food/nonveg.svg";
import veg_icon from "../../../assets/food/veg-icon.svg";
import BookingSummarySkeleton from "src/skeletons/BookingSummarySkeleton";
import { setViewMode as setviewmodeup } from "src/redux/actions/viewModeActions";
import fastFood from "../../../assets/seatlayout/fastfood.svg";
import peplaceholer from "../../../assets/seatlayout/promote-earn-placeholder.svg";

import activity from "../../../assets/seatlayout/activity.svg";
import { DarkButton } from "src/styled-components/customButtons";
import { Dialog } from "primereact/dialog";
import "./ticketsummary.css";
import "../../seat-layout/seatlayout.css";
import { handleMivImageError } from "src/global-functions/imageErrorSrc";
import { Link, useNavigate } from "react-router-dom";
import { FaRupeeSign } from "react-icons/fa";
import { setGrandTotalamount } from "src/redux/actions/gradTotalAction";
import { useDispatch } from "react-redux";
import PasssportSummary from "./PasssportSummary";
import FlexiPayPopup from "../flexipay/FlexiPayPopup";
import FlexiTnc from "../flexipay/FlexiTnc";
import FlexiHowItWork from "../flexipay/FlexiHowItWork";
import OnTime from "../../../assets/flexi/FLEXISHOW-LOGO-WHITE.svg";
import InfoFlexi from "../../../assets/flexi/info.svg";
import Tick from "../../../assets/flexi/tick.svg";
import FlexiHowTouse from "../flexipay/FlexiHowTouse";
import FamilyBundle from "../family-bundle/FamilyBundle";
import { familyBundleRemove } from "src/services/CouponPayment";
import swal from "sweetalert";

const TicketSummary = ({
  discount,
  ticketSummaryData,
  removeApply,
  applyData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = localStorage.getItem("theme");
  const token = localStorage.getItem("token");
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  const [ticketSummary, setTicketSummary] = useState(null);
  const [discountData, setdiscountData] = useState(null);
  const [showPopup, setShowPopup] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showConvFee, setShowConvFee] = useState(false);
  const [donationPrice, setDonationPrice] = useState(0);
  const [donation, setDonation] = useState(true);
  const [flexiVal, setFlexiVal] = useState(0);
  const [numofTicket, setNumofTicket] = useState(1);
  const [showAutoVoucher, setShowAutoVoucher] = useState(false);
  const [vouchersData, setAutoVouchersData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [passportSummary, setPassportSummary] = useState(null);
  const [showflexi, setShowflexi] = useState(false);
  const [showflexitnc, setShowflexiTnc] = useState(false);
  const [showflexihiw, setShowflexiHiw] = useState(false);
  const [ticketSummaryFb, setTicketSummaryFb] = useState(false);
  const [showflexihowitwork, setShowflexiHowitwork] = useState(false);
  const [familyBundle, setFamilyBundle] = useState(false);
  const paymentOptions = parseInt(localStorage.getItem("paymentOptions") || 0);


  const onloadpushToDataLayer = (event, voucherName, voucherId, price, validDate) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
      voucher_name: voucherName,
      voucher_id: voucherId,
      price: price,
      valid_date: validDate
    });
  };
  
  

  useEffect(() => {
    if (ticketSummary && flexiVal == 0) {
      setShowPopup(false);
      if (showPopup) {
        if (ticketSummary?.familyBundleShow)
          setFamilyBundle(ticketSummary?.familyBundleShow);
        if (ticketSummary?.familyBundleVouchers?.length > 0) {
          const voucher = ticketSummary.familyBundleVouchers[0];
          onloadpushToDataLayer(
            "family_bundle_view",
            voucher.vouDesc,
            voucher.vouId,
            voucher.discValue,
            voucher.validity
          );
        }

        else setShowflexi(ticketSummary?.orderTicket?.flexiApplicable);
      }
    }
  }, [ticketSummary]);

  // Function to push event to dataLayer
  const pushToDataLayer = (event, voucherName, voucherId, price, validDate) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
      voucher_name: voucherName,
      voucher_id: voucherId,
      price: price,
      valid_date: validDate
    });
  };




  useEffect(() => {
    if (flexiVal > 0) {
      getTicketSummaryData(donation, false);
    }
  }, [flexiVal]);
  useEffect(() => {
    if (ticketSummaryFb) {
      getTicketSummaryData(donation, false);
    }
  }, [ticketSummaryFb]);

  const removevoucher = async () => {
    removeApply();
  };
  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };
  const hitBeginCheckout = (
    event,
    currency,
    value,
    item_brand,
    item_name,
    item_id,
    item_category,
    item_category2,
    item_category3,
    item_category4,
    item_category5,
    item_variant,
    quantity,
    price
  ) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
      ecommerce: {
        currency: currency,
        value: value,
        items: [
          {
            item_brand: item_brand,
            item_name: item_name,
            item_id: item_id,
            item_category: item_category, //(selected ticket, gift card & passport)
            item_category2: item_category2, //(selected f&b & city name)
            item_category3: item_category3, //( selected date & seat number)
            item_category4: item_category4, //( Filim type)
            item_category5: item_category5, //( family bundle details)
            item_variant: item_variant, //( selected format)
            quantity: quantity,
            price: price,
          },
        ],
      },
    });
  };
  useEffect(() => {
    if (token) {
      getTicketSummaryData(true);
    }
  }, [token]);
  useEffect(() => {
    if (discount) {
      let discountData = JSON.parse(discount);
      setdiscountData(discountData);
      dispatch(
        setGrandTotalamount({
          cutPrice: Number(
            discountData?.ft != discountData?.it
              ? Number(discountData?.it.replace(/,/g, ""))
              : 0
          ),
          finalPrice: discountData?.ft
            ? Number(discountData?.ft.replace(/,/g, ""))
            : 0,
        })
      );
      if (discountData?.totalAmount) {
        getTicketSummaryData(true);
      }
    }
  }, [discount]);
  const Remove_bundle_hitGa = () => {
    if (ticketSummary?.familyBundle_data) {
      const voucher = ticketSummary.familyBundle_data;
      console.log("testdata", voucher);
      pushToDataLayer(
        "family_bundle_remove",
        voucher.voucherDesc,
        voucher.id,
        voucher.totalPrice,
        voucher.validity
      );

    }
  };

  const removeBundle = () => {
    Remove_bundle_hitGa();
    let postdata = {
      qr:
        localStorage.getItem("isQR") && isMobile
          ? "YES"
          : localStorage.getItem("fnb_qr") && isMobile
            ? "YES"
            : "NO",
      bookType: localStorage.getItem("booking_type"),
      transId: localStorage.getItem("transid"),
      bookingId: localStorage.getItem("bookingid"),
      ccode: localStorage.getItem("theaterId"),

      cvpId: ticketSummary?.familyBundle_data?.id,
      // quantity: currentValue - 1,
      // increment: currentValue - 1,
    };
    familyBundleRemove(postdata)
      .then((res) => {
        console.log(res);
        let data = res.data;
        // setFamilyBundle(false);
        getTicketSummaryData(donation, false);
        if (data?.output) {
        }
      })
      .catch((error) => {
        const err = error?.response;
        if (err?.status === 403) {
          // refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error?.message);
        }
        // setLoading(false);
      });
  };

  const getTicketSummaryData = async (donate, val = true) => {
    setLoading(true);
    const postData = {
      qr:
        localStorage.getItem("isQR") && isMobile
          ? "YES"
          : localStorage.getItem("fnb_qr") && isMobile
            ? "YES"
            : "NO",
      bookType: localStorage.getItem("booking_type"),
      transId: localStorage.getItem("transid"),
      bookingId: localStorage.getItem("bookingid"),
      ccode: localStorage.getItem("theaterId"),
      donate: localStorage.getItem("unpaidMode") === "true" ? false : donate,
      flexi: val ? 0 : flexiVal,
    };

    await axios
      .post(`${appConstants.base_url}v1/booking/ticketing/summary`, postData, {
        headers: {
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          Authorization: `Bearer ${token || ""}`,
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          const ndt = dt.output;
          setTicketSummary(ndt);
          setDonationPrice(ndt?.orderTicket?.donation);
          localStorage.setItem("grandVal", ndt?.finalAmount);
          if (ndt?.flexiApplyMsg) {
            swal({
              title: ndt?.cvp_data ? "Error" : "Success",
              text: ndt?.flexiApplyMsg,
              timer: 3000,
              icon: ndt?.cvp_data ? "error" : "success",
              buttons: false,
            });
            
          }
          dispatch(
            setGrandTotalamount({
              cutPrice: Number(
                ndt?.finalAmount != ndt?.totalAmount ? ndt?.totalAmount : 0
              ),
              finalPrice: Number(ndt?.finalAmount.replace(/,/g, "")),
            })
          );
          // console.log('ndt?.finalAmount', ndt?.finalAmount)
          setLoading(false);
          // console.log("ticketSummary==>", ndt);
          localStorage.setItem("quantity", ndt.orderTicket.numOfSeats);
          localStorage.setItem("price", ndt.totalAmount);
          if (ndt?.orderTicket?.flexiApply) {
            // setFlexiVal(1);
            dispatch(setviewmodeup(Math.floor(10000 + Math.random() * 90000)));
          }
          if (ndt?.familyBundle_data) {
            // setFlexiVal(1);
            dispatch(setviewmodeup(Math.floor(10000 + Math.random() * 90000)));
          }
          ticketSummaryData(ndt);
          hitGa("fnb_add_checkout");
          if (paymentOptions !== 1) {
            hitBeginCheckout(
              "begin_checkout",
              "INR",
              Number(ndt.totalAmount),
              appConstants.cinemaChain,
              localStorage.getItem("movie_name"),
              localStorage.getItem("movieid"),
              "ticket" + "," + ndt?.familyBundle_data?.voucherDesc,
              localStorage.getItem("city") +
              "," +
              localStorage.getItem("cinema_name"),
              ndt.orderTicket.seats +
              " / " + ndt.orderTicket.showTime,
              ndt?.orderFilmCinema?.language + " / " + ndt?.orderFilmCinema?.genre,
              ndt?.familyBundle_data?.voucherDesc +
              "," + ndt?.familyBundle_data?.id + "," + ndt?.familyBundle_data?.discountPrice
              + "," + ndt?.familyBundle_data?.validity,
              localStorage.getItem("format"),
              ndt.orderTicket.numOfSeats,
              Number(ndt.totalAmount)
            );
          }

          fbq("track", "InitiateCheckout", {
            content_ids: "8767867868",
            content_type: "Movie",
            content_name: localStorage.getItem("movie_name"),
            value: ndt.totalAmount,
            currency: "INR",
          });
        }
      })
      .catch((error) => {
        const err = error?.response;
        if (err?.status === 403) {
          // refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error?.message);
        }
        setLoading(false);
      });
  };

  const hitCancelTicket = () => {
    let dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "booking_now_with_ticket_cancellation",
      ecommerce: {
        items: [
          {
            item_brand: appConstants.cinemaChain, //(pvr/inox)
            item_name: localStorage.getItem("movie_name"),
            item_id: localStorage.getItem("movieid"),
            item_category: "ticket", //(selected ticket, gift card & passport)
            quantity: localStorage.getItem("quantity"),
            price: localStorage.getItem("seatPrice"),
          },
        ],
      },
    });
  };

  const calculateTotalPrice = () => {
    const bookingType = localStorage.getItem("booking_type");

    // Convert ticket price to a number
    const ticketPrice =
      (Number(ticketSummary?.orderTicket?.totalTicketPrice.replace(/,/g, "")) *
        100) /
      100 || 0;
    const flexiprice =
      (Number(
        ticketSummary?.orderTicket?.flexiTotalTicketPrice.replace(/,/g, "")
      ) *
        100) /
      100 || 0;

    let foodPrice;
    if (localStorage.getItem("is_cvp_apply")) {
      // Convert discount price to a number
      foodPrice = Number(ticketSummary?.cvp_data?.discountPrice) || 0;
    } else {
      // Convert food prices to numbers
      foodPrice =
        Number(
          ticketSummary?.orderFood?.foods?.reduce((acc, cur) => {
            const totalPrice = cur.price * cur.quantity
            const addonPrice = (cur?.addons || []).reduce((addonTotal, addon) => {
              return addonTotal + (addon?.price * addon?.quantity || 0);
            }, 0);
            return acc + totalPrice + addonPrice;
          }, 0)
        ) / 100 || 0;
    }
    let price = ticketSummary?.orderTicket?.flexiApply
      ? flexiprice
      : ticketPrice;
    // Return the total price as a formatted string
    return bookingType === "BOOKING"
      ? (price + foodPrice).toFixed(2)
      : foodPrice.toFixed(2);
  };

  return (
    <>
      {loading ? (
        <BookingSummarySkeleton />
      ) : (
        <div className="all-summary p-2">
          {localStorage.getItem("booking_type") === "BOOKING" &&
            !localStorage.getItem("unpaidMode") === "true" ? (
            <ExtendTime />
          ) : (
            ""
          )}
          <div className="booking-summary show-in-desktop-view">
            <div className="book-head">
              <h3>Booking Summary</h3>
            </div>
            {ticketSummary?.orderFood?.foods.length === 0 && (
              <div className="ticket-cancel">
                <img
                  src={cancelTicket}
                  alt=""
                  className="img-fluid"
                  onClick={() => {
                    hitCancelTicket();
                    setVisible(true);
                  }}
                />
              </div>
            )}
          </div>
          {localStorage.getItem("booking_type") === "BOOKING" ? (
            <>
              <div className="movies-summary">
                <div className="summary-movies-photo">
                  <img
                    src={
                      ticketSummary?.orderFilmCinema?.posterVert
                        ? ticketSummary.orderFilmCinema.posterVert
                        : theme === "inoxTheme"
                          ? miv_inox
                          : miv_pvr
                    }
                    alt=""
                    onError={handleMivImageError}
                    className="img-fluid"
                    width={50}
                    height={100}
                  />
                </div>
                <div className="summary-movies-content">
                  <h5>{ticketSummary?.orderFilmCinema?.filmName}</h5>
                  <ul>
                    <li>{ticketSummary?.orderFilmCinema?.certificate}</li>
                    {ticketSummary?.orderFilmCinema?.genre ? (
                      <li>
                        <span className="dot-c"></span>
                        {ticketSummary?.orderFilmCinema?.genre}
                      </li>
                    ) : (
                      ""
                    )}

                    {ticketSummary?.orderFilmCinema?.language ? (
                      <li>
                        <span className="dot-c"></span>
                        {ticketSummary?.orderFilmCinema?.language}
                      </li>
                    ) : (
                      ""
                    )}
                    {ticketSummary?.orderFilmCinema?.format ? (
                      <li>
                        <span className="dot-c"></span>
                        {ticketSummary?.orderFilmCinema?.format}
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                  <p>{ticketSummary?.showTimeStr}</p>
                  <p>{ticketSummary?.orderFilmCinema?.theaterName}</p>
                </div>
              </div>
              <div className="select-seat" style={{ display: "none" }}>
                <div className="no-seat-select">
                  <img src={cinemaScreen} alt="" className="img-fluid" />
                  <h5>No Seat Selected</h5>
                  <p>Select seats to proceed</p>
                </div>
              </div>
              {ticketSummary?.orderTicket?.seats ? (
                <div className="seat-info">
                  <h6>Seat Info</h6>
                  <p>
                    {ticketSummary?.orderTicket?.audi}
                    {ticketSummary?.orderTicket?.showClassName
                      ? " - " + ticketSummary?.orderTicket?.showClassName
                      : ""}
                  </p>
                  <div className="select-seat-number">
                    {ticketSummary?.orderTicket?.seats
                      .split(",")
                      .map((item, idx) => (
                        <div
                          className="seat-number"
                          style={
                            theme === "inoxTheme"
                              ? { backgroundColor: "#DCE2EF" }
                              : { backgroundColor: "#FFF0D6" }
                          }
                          key={idx}
                        >
                          <p>{item}</p>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          <div className="pe-holder seat-info">
            {ticketSummary?.ph?.map((item, index) => (
              <a href={item?.redirectUrl || "#"} target="_blank">
                <img className="promotioin-placeholder" src={item?.imageUrl} alt={`Promote-earn ${index}`} />
              </a>
            ))}
          </div>

          <div className="ticket-prices">
            <>
              {ticketSummary?.orderTicket?.numOfSeats &&
                !ticketSummary?.orderTicket?.flexiApply > 0 ? (
                <>
                  <h6>Tickets</h6>
                  <div className="ticket-value px-2">
                    <div className="tickets-count">
                      <h6>
                        {ticketSummary?.orderTicket?.numOfSeats} <b>x</b>{" "}
                        {ticketSummary?.orderTicket?.showClassName}
                      </h6>
                    </div>
                    <div className="ticket-price">
                      <p>
                        <FaRupeeSign
                          style={{
                            fontSize: "10px",
                            fontWeight: "500",
                            marginBottom: "2px",
                          }}
                        />
                        {ticketSummary?.orderTicket?.totalTicketPrice}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {ticketSummary?.orderTicket?.flexiApplicable ? (
                <>
                  <div className="upgrade-main-box">
                    <div className="upgrade-pay">
                      <div className="flexi-ticket-up">
                        <div className="upgrade-view-flex">
                          <p>
                            <span className="on-time">
                              <img src={OnTime} alt="" className="img-fluid" />
                            </span>{" "}
                            Upgrade to Flexi Ticket{" "}
                            <span className="info-flexi">
                              <img
                                src={InfoFlexi}
                                onClick={() => setShowflexiHowitwork(true)}
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                          </p>
                        </div>
                        <div className="flexi-ticket-price">
                          <p>
                            {ticketSummary?.orderTicket?.numOfSeats} <b>x</b>{" "}
                            <FaRupeeSign
                              style={{
                                fontSize: "10px",
                                fontWeight: "500",
                                marginBottom: "2px",
                              }}
                            />
                            {ticketSummary?.orderTicket?.flexiTicketPrice}
                          </p>
                        </div>
                      </div>
                      <p>Pay only for the time you watch, and get a refund! </p>
                    </div>
                    <div className="upgrade-tc">
                      <div className="main-upgrade-terms">
                        <div className="howto-work-flexi">
                          <button
                            className="how-it-work"
                            onClick={() => setShowflexiHiw(true)}
                          >
                            How it works
                          </button>
                          <button
                            className="tc"
                            onClick={() => setShowflexiTnc(true)}
                          >
                            T&C
                          </button>
                        </div>
                        <div className="upgrade-flexi-up">
                          {ticketSummary?.orderTicket?.flexiApply ? (
                            <>
                              <button className="upgraded-btn">
                                <span className="tick-upgrade">
                                  <img
                                    src={Tick}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </span>
                                Upgraded!
                              </button>
                              <button
                                className="upgraded-btn"
                                // onClick={() => setFlexiVal(2)}
                                onClick={() => {
                                  setFlexiVal(2)
                                  hitGa("flexishow_ticket_remove");
                                }}
                                style={{
                                  color: "red",
                                  border: "1px solid red",
                                }}
                              >
                                Remove
                              </button>
                            </>
                          ) : (
                            <button
                              className="upgrade-btn"
                              // onClick={() => setFlexiVal(1)}
                              onClick={() => {
                                setFlexiVal(1)
                                hitGa("flexishow_ticket_upgrade");
                              }} >

                              Upgrade
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {ticketSummary?.orderTicket?.flexiApply ? (
                <>
                  <h6 className="pt-3">FLEXI TICKET</h6>
                  <div className="ticket-value px-2">
                    <div className="tickets-count">
                      <h6>
                        {ticketSummary?.orderTicket?.numOfSeats} <b>x</b>{" "}
                        {"FLEXI TICKET"}
                      </h6>
                    </div>
                    <div className="ticket-price">
                      <p>
                        <FaRupeeSign
                          style={{
                            fontSize: "10px",
                            fontWeight: "500",
                            marginBottom: "2px",
                          }}
                        />
                        {ticketSummary?.orderTicket?.flexiTotalTicketPrice}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="no-snack" style={{ display: "none" }}>
                <img src={noSack} alt="" className="img-fluid" />
                <p>No snacks added</p>
              </div>
            </>

            {localStorage.getItem("is_cvp_apply") ? (
              <div className="food-beverages">
                <h6>Food & BEVERAGES</h6>
                <div className="regular-food">
                  <div className="veg-food">
                    <h6>
                      {ticketSummary?.cvp_data?.quantity} X{" "}
                      {ticketSummary?.cvp_data?.quantity > 1
                        ? "VOUCHERS"
                        : "VOUCHER"}
                    </h6>
                  </div>
                  <div className="veg-price">
                    <div className="cut-price-show">
                      <h6>
                        <span className="cut-price">
                          <FaRupeeSign
                            style={{
                              fontSize: "10px",
                              fontWeight: "500",
                              marginBottom: "2px",
                            }}
                          />
                          {parseFloat(
                            ticketSummary?.cvp_data?.actualPrice
                          ).toFixed(2)}
                        </span>
                        {"  "}
                        <span>
                          <FaRupeeSign
                            style={{
                              fontSize: "10px",
                              fontWeight: "500",
                              marginBottom: "2px",
                            }}
                          />
                          {parseFloat(
                            ticketSummary?.cvp_data?.discountPrice
                          ).toFixed(2)}
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            ) : ticketSummary?.orderFood?.foods?.length > 0 ? (
              <div className="food-beverages">
                {isMobile ? (
                  <>
                    {localStorage.getItem("fnb_qr") &&
                      localStorage.getItem("booking_type") == "FOOD" ? (
                      <>
                        <h2 className="p-2 ">Pickup From Counter</h2>
                        <h3 className="p-2">
                          {ticketSummary?.orderFilmCinema?.theaterName}
                        </h3>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                <h6>Food & BEVERAGES</h6>
                {ticketSummary?.orderFood?.foods?.map((item, idx) => (
                  <React.Fragment key={idx}>

                    <div className="buyItem-list paymentpage">
                      <div className="buyItem-listInn">
                        <div className="co-itemName">
                          {/* <img
                            className="co-vNv"
                            src={
                              item?.foodType == 1
                                ? veg_icon
                                : item?.foodType == 0
                                  ? nonveg
                                  : ""
                            }
                            alt={item.veg}
                          /> */}

                          {item?.veg ?
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                              <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                            </svg>
                            :
                            <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                              <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                              <clipPath id="clip0_3452_1183">
                                <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                              </clipPath>
                            </svg>
                          }

                          <p className="co-itemNameP">
                            {item?.name || item?.itemName}
                            {` X ${item?.quantity}`}
                          </p>
                        </div>
                        <div className="co-itemName">
                          <p className="co-pricep">
                            <FaRupeeSign style={{ fontSize: "10px", fontWeight: "400", marginBottom: "2px" }} />{((item?.price * item?.quantity) / 100).toFixed(2)}
                          </p>
                        </div>
                      </div>
                      <div className="co-addon-div">
                        {item?.addons?.map((addon, index) => {
                          return (
                            <div key={index} className="co-addon-inndiv">
                              <span style={{ paddingLeft: "25px" }}>
                                <em>{`Add ons : ${addon?.name} x ${addon?.quantity}`}</em>
                              </span>
                              <span>
                                <FaRupeeSign style={{ fontSize: "10px", fontWeight: "400", marginBottom: "2px" }} />{((addon?.price * addon?.quantity) / 100).toFixed(2)}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-md-8 col-9">
                        <div className="veg-food">
                          <h6>
                            <span className="nonveg-icon">
                              <img
                                src={
                                  item?.foodType == 1
                                    ? veg_icon
                                    : item?.foodType == 0
                                      ? nonveg
                                      : ""
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            {item?.name}
                          </h6>
                          <div className="co-addon-div">
                            {item.addons.map((addon, index) => {
                              return (
                                <div key={index} className="co-addon-inndiv">
                                  <span style={{ paddingLeft: "25px" }}>
                                    <em>{`Add ons : ${addon?.name} x ${addon?.quantity}`}</em>
                                  </span>
                                  <span>
                                    <FaRupeeSign style={{ fontSize: "10px", marginBottom: "2px", }} />
                                    {(addon?.price) / 100?.toFixed(2) * addon?.quantity}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-3">
                        <div className="veg-price">
                          <p>
                            {item.quantity} X
                            <FaRupeeSign
                              style={{
                                fontSize: "10px",
                                fontWeight: "500",
                                marginBottom: "2px",
                              }}
                            />
                            {item.price / 100}
                          </p>
                          <p>
                            {item.quantity} X
                            <FaRupeeSign
                              style={{
                                fontSize: "10px",
                                fontWeight: "500",
                                marginBottom: "2px",
                              }}
                            />
                            {item.price / 100}
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </React.Fragment>
                ))}
              </div>
            ) : (
              ""
            )}
            {/* <div className="add-more-sneks bg-light-pvr rounded show-in-mobile-view">
              <div className="grab-bite">
                <div>
                  <span>
                    <b>Grab a bite</b>
                  </span>
                </div>
                <div>
                  <span>
                    <DarkButton
                      onClick={() => navigate(-1)}
                      className="add-more-sneks-btn border-0"
                    >
                      Add Snacks
                    </DarkButton>
                  </span>
                </div>
              </div>
            </div> */}
          </div>

          {discountData && discountData?.ft ? (
            <>
              <div>
                {localStorage.getItem("unpaidMode") === "true" ? (
                  ""
                ) : (
                  <div className="applied-info">
                    <p>
                      <span>
                        <b>{applyData?.voucheName}</b>
                      </span>{" "}
                      Applied
                    </p>
                    <div className="save-remove">
                      <div className="saved-info">
                        <p>
                          <FaRupeeSign
                            style={{
                              fontSize: "10px",
                              fontWeight: "500",
                              marginBottom: "2px",
                            }}
                          />
                          {discountData?.di} Saved
                        </p>
                      </div>
                      {applyData?.voucheName == "GIFTCARD" ||
                        applyData?.voucheName == "PASSPORT" ||
                        applyData?.voucheName == "FNBVOUCHER" ||
                        applyData?.voucheName == "PRIVILEGE PLUS" ? (
                        ""
                      ) : (
                        <div
                          className="remove-text pointer"
                          onClick={() => {
                            removevoucher();
                          }}
                        >
                          <p>Remove</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            ""
          )}

          {ticketSummary?.familyBundle_data ? (
            <>
              <div className="applied-info">
                <h6>VOUCHER</h6>
                <p>
                  <span>
                    <b>
                      {ticketSummary?.familyBundle_data?.quantity} <b>x</b>{" "}
                      {ticketSummary?.familyBundle_data?.voucherDesc}
                    </b>
                  </span>
                </p>
                <div className="save-remove">
                  <div className="saved-info">
                    <p>
                      <span className="familt-cut-price">
                        <FaRupeeSign
                          style={{
                            fontSize: "10px",
                            fontWeight: "500",
                            marginBottom: "2px",
                          }}
                        />
                        {ticketSummary?.familyBundle_data?.totalPrice}
                      </span>
                      <FaRupeeSign
                        style={{
                          fontSize: "10px",
                          fontWeight: "500",
                          marginBottom: "2px",
                        }}
                      />
                      {ticketSummary?.familyBundle_data?.discountPrice}
                    </p>
                  </div>

                  <div
                    className="remove-text pointer"
                    onClick={() => {
                      removeBundle();
                    }}
                  >
                    <p>Remove</p>
                  </div>
                </div>
              </div>
              {/* <h6 className="pt-3">VOUCHER</h6>
              <div className="ticket-value px-2">
                <div className="tickets-count">
                  <h6>
                    {ticketSummary?.familyBundle_data?.quantity} <b>x</b>{" "}
                    {ticketSummary?.familyBundle_data?.voucherDesc}
                  </h6>
                </div>
                <div className="ticket-price">
                  <p>
                    <FaRupeeSign
                      style={{
                        fontSize: "10px",
                        fontWeight: "500",
                        marginBottom: "2px",
                      }}
                    />
                    {ticketSummary?.familyBundle_data?.discountPrice}
                  </p>
                </div>
              </div> */}
            </>
          ) : (
            ""
          )}
          <div className="bill-details">
            <h6>PAYMENT DETAILS</h6>
            <div className="grand-price">
              <div className="bill-amount">
                <div className="sub-total">
                  <h6>Sub Total</h6>
                </div>
                <div className="tax-fees">
                  <h6>
                    <FaRupeeSign
                      style={{
                        fontSize: "10px",
                        fontWeight: "500",
                        marginBottom: "2px",
                      }}
                    />{" "}
                    {calculateTotalPrice()}
                  </h6>
                </div>
              </div>

              {ticketSummary?.orderTicket?.convTotal > 0 ? (
                <div className="bill-amounts">
                  <div className="sub-total">
                    <h6>
                      Taxes & Fees
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowConvFee(!showConvFee);
                        }}
                      >
                        <img src={priceArrow} alt="" className="img-fluid" />
                      </span>
                    </h6>
                  </div>
                  <div className="tax-fees">
                    <h6>
                      <FaRupeeSign
                        style={{
                          fontSize: "10px",
                          fontWeight: "500",
                          marginBottom: "2px",
                        }}
                      />
                      {ticketSummary?.orderTicket?.convTotal}
                    </h6>
                  </div>
                </div>
              ) : (
                ""
              )}

              {showConvFee ? (
                <>
                  <div className="bill-amounts">
                    <div className="gst">
                      <h6>Convenience Fee</h6>
                    </div>
                    <div className="tax-gst">
                      <h6>
                        <FaRupeeSign
                          style={{
                            fontSize: "10px",
                            fontWeight: "500",
                            marginBottom: "2px",
                          }}
                        />
                        {ticketSummary?.orderTicket?.conv}
                      </h6>
                    </div>
                  </div>
                  {ticketSummary?.orderFilmCinema?.hsnSacCode ? (
                    <div className="bill-amounts">
                      <div className="gst">
                        <h6>
                          (Sac Code :{" "}
                          {ticketSummary?.orderFilmCinema?.hsnSacCode})
                        </h6>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="bill-amounts">
                    <div className="gst">
                      <h6>GST</h6>
                    </div>
                    <div className="tax-gst">
                      <h6>
                        <FaRupeeSign
                          style={{
                            fontSize: "10px",
                            fontWeight: "500",
                            marginBottom: "2px",
                          }}
                        />
                        {ticketSummary?.orderTicket?.convGst}
                      </h6>
                    </div>
                  </div>
                  {ticketSummary?.orderFilmCinema?.stateGSTN ? (
                    <div className="bill-amounts">
                      <div className="gst">
                        <h6>GST NUMBER</h6>
                      </div>
                      <div className="tax-gst">
                        <h6>{ticketSummary?.orderFilmCinema?.stateGSTN}</h6>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {localStorage.getItem("booking_type") === "BOOKING" ? (
                <>
                  <div className="border-dashed"></div>
                  {donationPrice !== "0.00" ? (
                    <>
                      {localStorage.getItem("unpaidMode") === "true" ? (
                        ""
                      ) : (
                        <div>
                          <div className="cauvery-call">
                            <div className="ticket-call">
                              <h5>
                                {ticketSummary?.donationText}
                                <span className="info"></span>
                              </h5>
                            </div>
                            <div className="price-call">
                              <p>
                                <FaRupeeSign
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "500",
                                    marginBottom: "2px",
                                  }}
                                />
                                {donationPrice}
                              </p>
                            </div>
                          </div>
                          <div className="cauvery-call">
                            <div className="ticket-call">
                              <p>
                                <FaRupeeSign
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "500",
                                    marginBottom: "2px",
                                  }}
                                />
                                {ticketSummary?.donationPrice / 100}/ticket
                              </p>
                            </div>
                            <div className="price-call">
                              {localStorage.getItem("isOfferAppplied") ? (
                                ""
                              ) : (
                                <p
                                  className="remove"
                                  onClick={() => {
                                    getTicketSummaryData(false);
                                    setDonation(false);
                                    hitGa("book_remove_donation");
                                  }}
                                >
                                  Remove
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="cauvery-call">
                      {localStorage.getItem("unpaidMode") === "true" ? (
                        ""
                      ) : (
                        <div className="price-call">
                          <p
                            className="text-success pointer"
                            onClick={() => {
                              getTicketSummaryData(true);
                              setDonation(true);
                              hitGa("book_add_donation");
                            }}
                          >
                            Add Donation
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                ""
              )}

              {discountData && discountData?.ft ? (
                <>
                  <div className="sub-view rudec-view">
                    <div className="sub-total">
                      <h6>Discount</h6>
                    </div>
                    <div className="sub-price">
                      <h6 className="disc-offer">
                        -
                        <FaRupeeSign
                          style={{
                            fontSize: "10px",
                            fontWeight: "500",
                            marginBottom: "2px",
                          }}
                        />
                        {discountData?.di}
                      </h6>
                      {/* <p
                        className="remove"
                        onClick={() => {
                          removevoucher();
                        }}
                      >
                        Remove
                      </p> */}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {discountData && discountData?.surcharge?.length > 0 ? (
                <>
                  {discountData?.surcharge.map((item, idm) => {
                    return (
                      <div className="sub-view rudec-view">
                        <div className="sub-total">
                          <h6>
                            <span className="format-view-gap-passport">
                              {item.text}{" "}
                            </span>
                          </h6>
                        </div>
                        <div className="sub-price">
                          <h6 className="disc-offer">
                            +
                            <FaRupeeSign
                              style={{
                                fontSize: "10px",
                                fontWeight: "500",
                                marginBottom: "2px",
                              }}
                            />
                            {item.amount}{" "}
                            {discountData?.countVoucher &&
                              discountData?.countVoucher > 1
                              ? "X " + discountData?.countVoucher
                              : ""}
                          </h6>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="all-grand">
            <div className="grand-amount">
              <div className="grand-total">
                <h6>To be Paid </h6>
              </div>
              <div className="grand-prices">
                {!discountData ? (
                  <>
                    <h6>
                      {localStorage.setItem(
                        "grandVal",
                        parseFloat(
                          Number(ticketSummary?.totalAmount.replace(/,/g, ""))
                        ).toFixed(2)
                      )}
                      <FaRupeeSign
                        style={{
                          fontSize: "10px",
                          fontWeight: "500",
                          marginBottom: "2px",
                        }}
                      />
                      {parseFloat(Number(ticketSummary?.totalAmount.replace(/,/g, ""))).toFixed(2)}
                    </h6>
                  </>
                ) : (
                  <>
                    <h6>
                      {discountData?.ft ? (
                        <span className="price-cut">
                          <FaRupeeSign
                            style={{
                              fontSize: "10px",
                              fontWeight: "500",
                              marginBottom: "2px",
                            }}
                          />
                          {parseFloat(Number(discountData?.it)).toFixed(2)}
                        </span>
                      ) : (
                        ""
                      )}{" "}
                      <FaRupeeSign
                        style={{
                          fontSize: "10px",
                          fontWeight: "500",
                          marginBottom: "2px",
                        }}
                      />
                      {parseFloat(
                        discountData?.ft
                          ? Number(discountData?.ft)
                          : discountData?.totalAmount / 100
                      ).toFixed(2)}
                      {applyData?.voucheName == "PASSPORT" ? (
                        <i
                          className="pi pi-info-circle"
                          onClick={() => {
                            setShowSummary(true);
                          }}
                          style={
                            theme === "inoxTheme"
                              ? {
                                backgroundColor: "#DCE2EF",
                                marginLeft: "10px",
                              }
                              : {
                                backgroundColor: "#F8F6F6",
                                marginLeft: "10px",
                              }
                          }
                        />
                      ) : (
                        ""
                      )}
                    </h6>
                  </>
                )}
              </div>
            </div>
          </div>
          {ticketSummary?.familyBundle_data ?
            <div
              className={
                theme === "inoxTheme"
                  ? "ticket-cancel-allowed-inox show-in-mobile-view"
                  : "ticket-cancel-allowed-pvr show-in-mobile-view"
              }
            >
              <p>Ticket Cancellation is not allowed for this transaction</p>
            </div>
            : ""}
        </div>
      )}
      <div className="card flex justify-content-center">
        <Dialog
          visible={showAutoVoucher}
          onHide={() => setShowAutoVoucher(false)}
          showHeader={false}
          showCloseIcon={false}
          className="seat-terms-condition-flow"
          blockScroll={true}
        >
          <div className="card px-3 py-2">
            <div className="mt-3">
              <div className="d-flex justify-content-center">
                <h3>Auto Vouchers</h3>
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  <ul>
                    {vouchersData &&
                      vouchersData?.map((item, idx) => {
                        return <li key={idx}>{item.vouDesc}</li>;
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="accpet-btn-flow-seat">
              <DarkButton
                className="reject-terms"
                onClick={() => {
                  setShowAutoVoucher(false);
                }}
              >
                OK
              </DarkButton>
            </div>
          </div>
        </Dialog>
      </div>
      <div className="card flex justify-content-center">
        <Dialog
          header=""
          visible={visible}
          modal={false}
          showHeader={false}
          showCloseIcon={false}
          style={{ width: "50vw" }}
          onHide={() => setVisible(false)}
          className="ticke-cancel-modal"
          blockScroll={true}
        >
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="cross-icon">
                <span
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  <i className="pi pi-times"></i>
                </span>
              </div>
              {ticketSummary?.familyBundle_data ? <>
                <div className="ticket-terms">
                  <h4>Ticket Cancellation is not allowed for this transaction.</h4>
                </div>
              </> :
                <div className="ticket-terms">
                  <h1>Ticket Cancellation</h1>
                  <div className="refund-food">
                    <h4>
                      <span className="iconfood">
                        <img src={fastFood} alt="" className="img-fluid" />
                      </span>
                      100% Refund on F&B
                    </h4>
                  </div>
                  <div className="refund-movies">
                    <h4>
                      <span className="iconfood">
                        <img src={activity} alt="" className="img-fluid" />
                      </span>
                      Up to 75% Refund for Tickets
                    </h4>
                    <p>75% refund until 2 hours before show start time.</p>
                    <p>
                      50% refund between 2 hours and 20 minutes before show start
                      time.
                    </p>
                  </div>
                  <div className="refund-voucher">
                    <p>
                      *Refund not available for Convenience fees, Vouchers, Gift
                      Cards, Taxes etc.
                    </p>
                    <p className="cancellation">
                      *No Cancellation within 20 minutes of show start time.
                    </p>
                  </div>
                  <div className="view-terms">
                    <Link to="/terms-conditions/booking">
                      {" "}
                      <p>View Terms & Conditions</p>
                    </Link>
                  </div>
                </div>
              }
            </div>
          </div>
        </Dialog>
      </div>
      <PasssportSummary
        summary={discountData}
        showModal={showSummary}
        hideModal={setShowSummary}
      />
      <FlexiPayPopup
        visible={showflexi}
        content={ticketSummary?.content}
        setVisible={setShowflexi}
      />
      <FlexiTnc
        visible={showflexitnc}
        content={ticketSummary?.content}
        setVisible={setShowflexiTnc}
      />
      <FlexiHowItWork
        visible={showflexihiw}
        content={ticketSummary?.content}
        setVisible={setShowflexiHiw}
      />
      <FlexiHowTouse
        visible={showflexihowitwork}
        content={ticketSummary?.content}
        setVisible={setShowflexiHowitwork}
      />
      <FamilyBundle
        familyBundle={familyBundle}
        content={ticketSummary}
        setFamilyBundle={setFamilyBundle}
        ticketSummaryCalled={setTicketSummaryFb}
      />
    </>
  );
};

export default TicketSummary;
