import React, { useState, useEffect } from "react";
import direction from "../../../assets/cinemas/direction.svg";
import location from "../../../assets/cinemas/location.svg";
import imglist from "../../../assets/cinemas/list.svg";
import imglistInox from "../../../assets/static-page/nav-list-white.svg";
import imgmap from "../../../assets/cinemas/new-map-icon.svg";
import inoximgmap from "../../../assets/static-page/nav-map-white.svg";
import imgCinema from "../../../assets/cinemas/pvr-cinema.png";
import imgCinemaPlay from "../../../assets/cinemas/pop-play.svg";
import imgCinemaPlace from "../../../assets/cinemas/place.svg";
import imgTimeIcon from "../../../assets/cinemas/time.svg";
import imgDistanceIcon from "../../../assets/cinemas/distance.svg";
import "./SessionSideBar.css";


import { Carousel } from "primereact/carousel";
import axios from "axios";
import * as Constants from "../../../constants/index";
import { Link, useNavigate, useParams } from "react-router-dom";
import miv_inox from "../../../assets/default-images/vertical-poster-inox.png";
import miv_pvr from "../../../assets/default-images/vertical-poster-pvr.svg";
import mih_pvr from "../../../assets/default-images/cinema-pvr.svg";
import mih_inox from "../../../assets/default-images/cinema-inox.png";
import marker from "../../../assets/static-page/marker.svg"
import inoxmarker from "../../../assets/static-page/marker-inox.svg"
import selectedMarker from "../../../assets/static-page/selected-marker-pvr.svg"
import inoxselectedMarker from "../../../assets/static-page/selected-marker-inox.svg"
import CinemaSkeleton from "../../../skeletons/CinemaSkeleton";
import img_location from "../../../assets/cinemas/location.png";

import { refraceTokenService } from "../../../global-functions/refreshToken";
import { handleMivImageError } from "../../../global-functions/imageErrorSrc";
import LikeIcon from "../../../assets/moviesession/heart.svg";
import heart_pvr from "../../../assets/mobilemoviedetail/yellowheart.svg";
import heart_inox from "../../../assets/mobilemoviedetail/blueheart.svg";
import { useSelector, useDispatch } from "react-redux";
import { setLoginViewMode } from "src/redux/actions/loginViewAction";
import acess from "../../../assets/moviesession/acess.svg";
import acessRamp from "../../../assets/moviesession/Vector.png";
import swal from "sweetalert";
import { InputText } from "primereact/inputtext";
import { IscustomEvent, convertToNumeric, convertToTime } from "src/global-functions/globalFunctions";
import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';
import { Slider } from "primereact/slider";
import SessionSidebar from "./SessionSidebar";
import "../../home/now-showing/nowshowing.css";
import { Sidebar } from "primereact/sidebar";
import { formatTime, getSplashData } from "src/services/OtherServices";
import SweetAlert from "react-bootstrap-sweetalert";
import { setCityChangeData } from "src/redux/actions/cityAction";

const CinemaList = ({mapview}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const minValue = convertToNumeric("0:00");
  const maxValue = convertToNumeric("24:00");
  const [minDistance, setMinDistance] = useState(0);
  const [maxDistance, setmaxDistance] = useState(30);
  const [showTime, setShowTime] = useState([0, 24]);
  const [distance, setDistance] = useState([0, 20]);
  const [showsession, setShowsession] = useState(false);
  // const {city} = useParams();
  const city = localStorage.getItem("city");
  const lat = localStorage.getItem("lat");
  const long = localStorage.getItem("long");
  const [cinemasData, setCinemasData] = useState([]);
  const theme = localStorage.getItem("theme");
  const [loading, setLoading] = useState(false);
  const [loadingbtn, setLoadingBtn] = useState(false);
  const [cinemaList, setCinemaList] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [filterCinemaList, setFilterCinemaList] = useState([]);
  const [mapCinemaList, setMapCinemaList] = useState([]);
  const [selected, setSelected] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [movieIndex, setMovieIndex] = useState(0);
  const [movieID, setMovieID] = useState(null);
  const [firstcall, setFirstcall] = useState(0);
  const [visiblemapview, SetVisiblemapview] = useState(false);
  const [mapMsg, setMapMsg] = useState(null);
  const [showmap, setshowmap] = useState(false);
  const [spalshData, setSpalshData] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [locationPermission, setLocationPermission] = useState(false);
  const [mapfirstVisit, setMapfirstVisit] = useState(true);

  const loadingall = (
    <div className="pt-3 d-flex justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div>
        <i className="pi pi-spin pi-spinner" style={{'fontSize': '5em', color: theme === "inoxTheme" ? "#234B9E" : "#FFCB05" }}></i>
        </div>
    </div>
  );

  useEffect(()=>{
    profileRequest();
    
  }, [localStorage.getItem('city')])

  const timeSelectFormat = (hour)=>{
    if (hour !== undefined && hour !== null) {
      if (hour < 0 || hour > 24) {
        return "Invalid hour";
      }
      if(hour == 24){
        return "11:59 PM";
      }
      const period = hour >= 12 ? "PM" : "AM";
      const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
      return `${formattedHour.toString().padStart(2, "0")}:00 ${period}`;
    } else {
      return "";
    }
  }
  const profileRequest = async () => {
    getSplashData().then((res) => {
      const dt = res.data;
      if (dt.result === "success") {
        setSpalshData(dt.output)
      } else {
      }
    })
      .catch((err) => {
        console.log('error', err)
      })
  };

  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };
  useEffect(() => {
     CinemasRequest(firstcall == 0?false:true);
    if (city) {
      localStorage.setItem("city", city);
    }
    mapview(!cinemaList);
  }, [cinemaList]);
  useEffect(()=>{
    selectedMovie()
  }, [selected])

  const zoomMap = (strMaxDistance = null) =>{
    let zoomLevel;
    // console.log(strMaxDistance);
    if (parseFloat(strMaxDistance) <= 5000) {
        zoomLevel = 13.0
    } else if (parseFloat(strMaxDistance) >= 5001 && parseFloat(strMaxDistance) <= 10000) {
        zoomLevel = 12.2
    } else if (parseFloat(strMaxDistance) >= 10001 && parseFloat(strMaxDistance) <= 15000) {
        zoomLevel = 11.8
    } else {
        zoomLevel = 10.8
    }
    // console.log(zoomLevel);
    return parseFloat(zoomLevel);
  }

  const CinemasRequest = async (val = false ) => {
    
    let postData, endpoint;
    setLoading(true);
    if(cinemaList){
      endpoint = "v1/booking/content/cinemas";
      postData = {
        city: city,
        lat: localStorage.getItem("lat") || "0.000",
        lng: localStorage.getItem("long") || "0.000",
        text: "",
      };
    } else{
      setFirstcall(1);
      setActiveIndex(0);
      setSelected(null);
      setMapCinemaList([]);
      endpoint = "v1/booking/content/cinemasessions";
      postData = {
        ccode: "",
        lat: localStorage.getItem("lat") || "0.000",
        lng: localStorage.getItem("long") || "0.000",
        text: "",
        minDistance: val?distance.length>0 ?parseFloat(distance[0])*1000:0:0,
        maxDistance: val?distance.length>1 ?parseFloat(distance[1])*1000:0:0,
        time: `${convertToTime(showTime[0])}-${convertToTime(showTime[1])}`
      };
    }
    // try {
    await axios
      .post(Constants.base_url + endpoint, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: city,
          country: Constants.cname,
          appVersion: Constants.av,
          platform: Constants.pt,
        },
      })
      .then((resp) => {
        if (resp.status === 200 && resp.data.code === 10001) {
          if(cinemaList){
            let cinemas = resp?.data?.output?.c.map((obj) => ({
              ...obj,
              lat: obj.latitude,
              lng: obj.longitude
            }));
            setCinemasData(cinemas);
            setFilterCinemaList(cinemas);
            if(mapfirstVisit && resp.data.output?.defaultMap){
            // SetVisiblemapview(resp?.data?.output?.map?true:false);
              requestLocationPermission();
              setMapfirstVisit(false);
            }
            setshowmap(resp?.data?.output?.map?true:false);
            setMapMsg(resp?.data?.output?.fmsg)
            setLoading(false);
            IscustomEvent("Cinemas page View");
          } else{
            setLoading(false);
            if(!val)
            {
                setDistance([0, resp?.data?.output?.defaultDistance])
                setmaxDistance(resp?.data?.output?.maxDistance)
            }
            setMapCinemaList(resp?.data?.output?.showTimeSessions);
            if(resp?.data?.output?.showTimeSessions.length > 0){
              setSelected(resp?.data?.output?.showTimeSessions[0]);
            }
            // setmaxDistance(resp?.data?.output?.maxDistance)
          }
          // setProducts(resp.data.output.c.m)
        } else {
          console.log(resp);
          setErrorMsg(resp?.data?.msg);
          setShowErrorAlert(true);
          setCinemasData([]);
          setMapCinemaList([]);
          setLoading(false);
          if(!cinemaList){
            setDistance([0, 5])
          }
        }

        // console.log("cinema", resp);
      })
      .catch((error) => {
        setLoading(false);
        const err = error?.response;
        if (err?.status === 403) {
          refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error.message);
          setLoading(false);
        }
      });
  };

  const postPrefrences = async (data) => {
    setLoading(true);
    const postData = {
      city: data.cityName,
      id: data.theatreId,
      like: data.like === "false" ? true : false,
      type: "t",
      did: "",
    };

    console.log("postData postData", postData);

    await axios
      .post(`${Constants.base_url}v1/user/prefrences/set`, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: city,
          appVersion: Constants.av,
          platform: Constants.pt,
          country: Constants.cname,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setLoading(false);
        const dt = res.data;
        // console.log('res res res res res res ', res);
        if (dt.result === "success") {
          CinemasRequest();
        } else if (dt.result === "error" || dt.result === "dialog") {
          console.log("error");
        }
        // console.log("movieSessionData", dt)
      })
      .catch((error) => {
        const err = error?.response;
        if (err?.status === 403) {
          refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error.message);
        }
      });
  };

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 1, // Show 5 items at a time
      numScroll: 1,
    },
    {
      breakpoint: "768px",
      numVisible: 1, // Show 3 items at a time
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 1, // Show 1 item at a time (for smaller screens)
      numScroll: 1,
    },
  ];

  const productTemplate = (product) => {
    // console.log('askdfhaskdf', product)
    let url = "/moviesessions/" + city + "/" + encodeURIComponent(product.n) + "/" + product.id;
    let url2 = url.replace(/\s/g, "-");
    return (
      <div className="border-1 surface-border border-round mx-1 text-center">
        <div className="mb-3">
          <Link to={url2}>
            <img
              src={
                product?.miv
                  ? product.miv
                  : theme === "inoxTheme"
                  ? miv_inox
                  : miv_pvr
              }
              alt={product.n}
              className="w-6 shadow-2"
              onError={handleMivImageError}
              width={70}
              height={110}
            />
          </Link>
        </div>
      </div>
    );
  };

  const productTemplateSm = (product) => {
    // console.log("nowShowingSm", product);
    let url = `/cinemasessions/${localStorage.getItem("city")}/${
      product?.cinemaRe.name
    }/${product?.cinemaRe.theatreId}`;
    let url2 = url.replace(/\s/g, "-");
    return (
      <div className="cinema-pop-container p-2">
        <div className="cinema-pop-detail">
          <div className="cinema-pop-top-section">
            <div className="cinema-pop-top-section-img">
              <img src={product?.cinemaRe.mih} onError={imgCinema} alt="" 
              // onClick={() => {navigate(url2)}}
              />
            </div>
            <div className="cinema-pop-top-content" style={{cursor: "pointer"}} 
            // onClick={() => {navigate(url2)}}
            >
                <h2>{product?.cinemaRe.name}</h2>
                {/* <p>{product?.cinemaRe.address1}</p> */}
            </div>
          </div>
        
        <div className="cinema-pop-bottom-content"
        style={theme === "inoxTheme"
            ? {  border: "1px solid #234b9e" }
            : {  border: "1px solid #FFCB05" }
        }
        >
          <div className="cinema-pop-bottom-content-left">
            {/* <img src={imgCinemaPlay} alt=""/> {product?.cinemaRe?.showCount} shows &nbsp;&nbsp; */}
            <img src={imgCinemaPlace} alt=""/> {product?.cinemaRe?.distanceText}
          </div>
          <div className="cinema-pop-bottom-content-right">
            <a href="javascript:void(0)" 
            style={theme === "inoxTheme"
                ? { backgroundColor: "#234b9e", color: "#fff", border: "1px solid #234b9e" }
                : { backgroundColor: "#FFCB05", color: "#000", border: "1px solid #FFCB05" }
            }
            className="cinema-pop-button" title="View Showtimes" onClick={()=>{
              setShowsession(true)
              hitGa("view_showtimes");
              }}>View Showtimes</a>
          </div>
        </div>
        </div>
      </div>
      
    );
  };
  const hitDynamicGa = (event, cinema, distance, page) => {
    let dataLayer = window["dataLayer"] || [];

    dataLayer.push({
      event: event,
      directions_cinema: cinema,
      directions_distance: distance,
      directions_page_url: page,
    });
  };

  const requestLocationPermission = () => {
    setLoadingBtn(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // setshowmap(true);
        let lat = position.coords.latitude;
        localStorage.setItem("lat", lat);
        let long = position.coords.longitude;
        localStorage.setItem("long", long);
        // localStorage.setItem("mapenable", "working");
        
        // console.log("checking working");
        getCityData()
        // getCityData(lat, long);
        // navigator.geolocation.getCurrentPosition(function (position) {
        //   let lat = position.coords.latitude;
        //   localStorage.setItem("lat", lat);
        //   let long = position.coords.longitude;
        //   localStorage.setItem("long", long);
          
        // });
      },
      (error) => {
        // setshowmap(false);
        setLoadingBtn(false);
        SetVisiblemapview(true);
        if (error.code === error.PERMISSION_DENIED) {
          // alert('failed'+error);
          setLocationPermission(true);
        } else {
          setLocationPermission(true);
        }
      }
    );
  };
  const getCityData = async () => {
    const postData = {
      lat: localStorage.getItem("lat"),
      lng: localStorage.getItem("long"),
    };
    await axios
      .post(`${Constants.base_url}v1/booking/content/city`, postData, {
        headers: {
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city") || "",
          appVersion: Constants.av,
          platform: Constants.pt,
          country: Constants.cname,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
            dispatch(
                setCityChangeData({
                    city: dt.output.cc.name,
                    change: true
                })
            );
            setLoadingBtn(false);
            localStorage.setItem("city", dt.output.cc.name);
            setCinemaList(false);
            SetVisiblemapview(false);
            

        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const selectedMovie = (template = null, idx=0)=>{
    if(!template){
      // console.log(selected?.movieSession, movieID);
      if(movieID){
        let idf =selected?.cinemaMovieSessions.findIndex((temp, id)=>{
          if(temp?.movieRe?.id == movieID){
            return id;
          } 
        })
        // console.log(idf, movieID, selected);
        if(idf<0){
            setMovieIndex(idf<0?0:idf)
            setMovieID(selected?.cinemaMovieSessions[0].movieRe?.id)
        } else{
            setMovieIndex(idf<0?0:idf)
            setMovieID(selected?.cinemaMovieSessions[idf].movieRe?.id);
        }
      } else{
        setMovieIndex(0)
        // console.log( movieID, selected);
      }
      // if(!find) setMovieIndex(0);
      // setMovieIndex(idx);
    } else{
      setMovieID(template?.movieRe?.id);
      setMovieIndex(idx);
    }
    // setMovieIndex(idx);
  }
  const mapOptions = {
    zoomControl: false,          // Remove zoom control
    fullscreenControl: false,    // Remove full-screen control
    mapTypeControl: false,       // Optional: Remove map type control (satellite view, etc.)
    streetViewControl: false,    // Optional: Remove street view control
  };

  return (
    <>
      <section style={{background:'#EDE8E9'}}>
        <div className="container view-container" >
          <div className="desktop-view-filter">
      
              <div className={`d-flex justify-content-between py-2 ${!selected? "endcontent":""}`} >
                <div className="d-flex justify-content-between py-3 crousal-box" style={{overflow:"auto"}}>
                  {!cinemaList && mapCinemaList.length >0?
                  <>    
                    <div className="crousel-container">
                      
                      
                      {selected?
                      // <Carousel
                      //   value={selected?selected?.cinemaMovieSessions:[]}
                      //   numVisible={Math.ceil((selected?selected?.cinemaMovieSessions:[]).length / 6)}
                      //   numScroll={5}
                      //   responsiveOptions={responsiveOptions}
                      //   itemTemplate={formatTemplate}
                      //   className="formats-prev-dynamic"
                      // />
                      <>
                        {selected?.cinemaMovieSessions.map((template, idx) => (
                          <div
                              className="experiences-icon pointer"
                              onClick={() => {
                                selectedMovie(template, idx);
                              }}
                            >
                              {template.movieRe ? (
                                <span
                                style={
                                  movieIndex == idx? theme === "inoxTheme"
                                    ? { backgroundColor: "#234b9e", color: "#fff", border: "1px solid #234b9e" }
                                    : { backgroundColor: "#FFCB05", color: "#000", border: "1px solid #FFCB05" }
                                  :theme === "inoxTheme"?{border: "1px solid #234b9e"}:{border: "1px solid #FFCB05"}
                                }
                                >{template?.movieRe?.n}</span>
                              ) : (
                                ""
                              )}
                          </div>
                        ))}
                      </>
                      :""}
                      
                    </div>
                  </>
                  :""}
                </div>
                {showmap && spalshData?.map == "true"?
                  <div style={{marginTop:'10px'}}>
                    <div className="all-links align-self-center map-icon-container">
                      <ul>
                        <li className={cinemaList?"scoller-icon-active":""}
                          style={cinemaList?theme === "inoxTheme"
                            ? { backgroundColor: "#234b9e", color: "#fff" }
                            : { backgroundColor: "#FFCB05", color: "#000" }:{}}
                         onClick={()=>{setCinemaList(true);}}>
                          <div className="nav-icons">   <img src={cinemaList? theme === "inoxTheme" ? imglistInox:imglist:imglist} alt="" />
                          </div>
                        <div className="useful-link">List</div>
                        </li>
                        <li className={!cinemaList?"scoller-icon-active":""}
                        style={!cinemaList?theme === "inoxTheme"
                          ? { backgroundColor: "#234b9e", color: "#fff" }
                          : { backgroundColor: "#FFCB05", color: "#000" }:{}}
                         onClick={()=>{requestLocationPermission();}}>
                          <div className="nav-icons">   <img src={imgmap } style={!cinemaList && theme === "inoxTheme" ?{filter: "brightness(0) invert(1)"}:{}} alt="" />
                            {loadingbtn ? (
                                <i
                                  className="pi pi-spin pi-spinner"
                                  style={{ fontSize: "1.5em" }}
                                ></i>
                              ) : (
                                ""
                            )}
                          </div>
                        <div className="useful-link">Map</div>
                        </li>

                      </ul>

                    </div>
                  </div>
                :""}
              </div>
            
            

          </div>
        </div>
      </section>
      <section>
        
        <div className={cinemaList?"container view-container":""}>
          
          {cinemaList?
            <div className="desktop-view-filter">
              <div className="card-fliter">
                <div className="row">
                  <div className="col-md-4">
                    <div className="fliter-search">
                      <span className="p-input-icon-left" style={{}}>
                        <i className="pi pi-search" />
                        <InputText
                          value={searchText}
                          onChange={(e) => {
                            setSearchText(e.target.value);
                            const val = e.target.value;
                            if (val.length > 0) {
                              const filterVal =
                                cinemasData &&
                                cinemasData?.filter((item) =>
                                  item?.name
                                    ?.toLowerCase()
                                    ?.includes(val?.toLowerCase())
                                );
                              setFilterCinemaList([...filterVal]);
                              // console.log('filterVal',filterVal)
                            } else if (val.length === 0) {
                              setFilterCinemaList([...cinemasData]);
                            }
                          }}
                          placeholder="Search for cinema"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-8 align-self-center"></div>
                </div>
              </div>
            </div>
          :""}
          {loading && cinemaList ? (
            cinemaList?
              <CinemaSkeleton/>
              : loadingall
          ) : cinemaList? (
            <div className="row ptb-34">
              {filterCinemaList?.length > 0
                ? filterCinemaList?.map((item, idx) => {
                    let url =
                      "/cinemasessions/" +
                      city +
                      "/" +
                      item.name +
                      "/" +
                      item.theatreId;
                    let url2 = url.replace(/\s/g, "-");
                    return (
                      <>
                        <div className="col-lg-12 col-sm-12" key={idx}>
                          <div className="cinema-list">
                            <div
                              className="row"
                              onClick={() =>
                                localStorage.setItem(
                                  "theaterId",
                                  item.theatreId
                                )
                              }
                            >
                              <div className="col-md-8">
                                <div className="row">
                                  <div className="col-md-5 col-sm-12">
                                    <div className="cinema-img">
                                      <Link to={url2} onClick={()=>{
                                           let dataLayer = window["dataLayer"] || [];

                                           dataLayer.push({
                                             event: "cinema_banner_click",
                                           });
                                      }}>
                                        {" "}
                                        <img

                                          src={
                                            item?.miv
                                              ? item.miv
                                              : theme === "inoxTheme"
                                              ? mih_inox
                                              : mih_pvr
                                          }
                                          alt=""
                                          className="img-fluid"
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="col-md-7 col-sm-12 direction-down">
                                    <div
                                      className="cinema-details"
                                      onClick={() => navigate(url2)}
                                    >
                                      {/* <Link to={url2}> */}{" "}
                                      <div className="cinema-fav">
                                        <div className="cinema-title-heart">
                                          <h2>{item.name}</h2>
                                        </div>
                                        <div className="icon-direction-cinemas pointer">
                                          <span
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              //e.preventDefault();
                                              if (
                                                localStorage.getItem("token")
                                              ) {
                                                postPrefrences(item);
                                              } else {
                                                dispatch(
                                                  setLoginViewMode({
                                                    value: true,
                                                  })
                                                );
                                              }
                                            }}
                                          >
                                            {/* <img src={like} alt="" className="img-fluid heat-d" /> */}
                                            <img
                                              src={
                                                item?.like === "true" &&
                                                theme === "inoxTheme"
                                                  ? heart_inox
                                                  : item?.like === "true" &&
                                                    theme === "pvrTheme"
                                                  ? heart_pvr
                                                  : LikeIcon
                                              }
                                              alt=""
                                              className="img-fluid"
                                              width="20px"
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <p>
                                        {`${item?.address1} ${item?.address2} ${item?.address3}`}{" "}
                                      </p>
                                      {/* </Link> */}
                                    </div>
                                    <div className="direction">
                                      <div className="icon-direction">
                                        <a
                                          href={`https://maps.google.com/?q=${item.latitude},${item.longitude}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          onClick={() =>

                                           {
                                            let dataLayer=window['dataLayer'] ||[];
                                            dataLayer.push({
                                              'event': 'booking_movie_direction'
                                              });
                                             hitDynamicGa(
                                              "cinemas_directions_click",
                                              item.name,
                                              item.distanceText,
                                              "https://maps.google.com/?q=" +
                                                item.latitude +
                                                "," +
                                                item.longitude
                                            )
                                          }
                                          }
                                        >
                                          <p>
                                            <span className="icon-gap">
                                              <img src={direction} alt="" />
                                            </span>
                                            Directions
                                          </p>
                                        </a>
                                      </div>
                                      <div className="icon-direction">
                                        <p>
                                          <span className="icon-gap">
                                            <img src={location} alt="" />
                                          </span>
                                          {item.distance === 0
                                            ? ""
                                            : item.distanceText}
                                        </p>
                                      </div>
                                      {/* <div className="icon-direction">
                                    <span
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        //e.preventDefault();
                                        if (localStorage.getItem("token")) {
                                          postPrefrences(item);
                                        } else {
                                          dispatch(
                                            setLoginViewMode({ value: true })
                                          );
                                        }
                                      }}
                                    >
                                      <img src={like} alt="" className="img-fluid heat-d" />
                                      <img
                                        src={
                                          item?.like === "true" &&
                                          theme === "inoxTheme"
                                            ? heart_inox
                                            : item?.like === "true" &&
                                              theme === "pvrTheme"
                                            ? heart_pvr
                                            : LikeIcon
                                        }
                                        alt=""
                                        className="img-fluid"
                                        width="20px"
                                      />
                                    </span>
                                  </div> */}
                                      <div className="icon-direction">
                                        <span
                                          className="mx-3 pointer"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            swal({
                                              text: item?.handicap && item?.handicapRamp ?"This auditorium is accessible with assistive equipment for manual wheelchairs.":"This auditorium has wheelchair friendly seats.",
                                              timer: 3000,
                                              icon: "info",
                                              buttons: false,
                                            });
                                          }}
                                        >
                                          {item?.handicap &&
                                          item?.handicapRamp ? (
                                            <img
                                              src={acessRamp}
                                              alt=""
                                              className="img-fluid"
                                              width={15}
                                            />
                                          ) : item?.handicap ? (
                                            <img
                                              src={acess}
                                              alt=""
                                              className="img-fluid"
                                              width={15}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 pl-0">
                                {item.movieRes.length > 4 ? (
                                  <div className="cinema-nowshowing">
                                    <p>Now Showing</p>
                                    <div className="card">
                                      <Carousel
                                        value={item.movieRes}
                                        numVisible={4}
                                        numScroll={1}
                                        responsiveOptions={responsiveOptions}
                                        itemTemplate={productTemplate}
                                        showIndicators={false}
                                        // showNavigators={false}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="cinema-nowshowing">
                                    {item.movieRes.length > 0 ? (
                                      <p>Now Showing</p>
                                    ) : (
                                      ""
                                    )}
                                    <div className="d-flex">
                                      {item.movieRes &&
                                        item.movieRes?.map((item, idx) => {
                                          let url =
                                            "/moviesessions/" +
                                            city +
                                            "/" +
                                            encodeURIComponent(item.n) +
                                            "/" +
                                            item.id;
                                          let url2 = url.replace(/\s/g, "-");

                                          return (
                                            <Link to={url2}>
                                              <div className="mx-1" key={idx}>
                                                {/* <span>{item.distance}</span> */}
                                                <img
                                                  src={
                                                    item?.miv
                                                      ? item.miv
                                                      : theme === "inoxTheme"
                                                      ? miv_inox
                                                      : miv_pvr
                                                  }
                                                  alt={item.n}
                                                  className="w-6 shadow-2"
                                                  onError={handleMivImageError}
                                                  width={70}
                                                  height={110}
                                                />
                                              </div>
                                            </Link>
                                          );
                                        })}
                                      {/* movie */}
                                      {/* <Carousel
                                    value={item.movieRes}
                                    numVisible={4}
                                    numScroll={1}
                                    responsiveOptions={responsiveOptions}
                                    itemTemplate={productTemplate}
                                    showIndicators={false}
                                  // showNavigators={false}
                                  /> */}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                : "Cinema not found"}
            </div>
          ): 
          <div className="row ptn-34">
             {!cinemaList?
                <>
                 
                  <div className={loading?"slider-desk disable-navbar":"slider-desk"} >
                    <div className={`mt-2 now-right showtime-slider-container width45left ${
                          theme === "inoxTheme"
                            ? "showtime-slider-inox"
                            : "showtime-slider-pvr"
                        }`}
                      >
                        <small className="showtime-label-container">
                        <img src={imgDistanceIcon} alt="distance" /> Distance
                        </small>
                        <span className="showtime-slider-box-label-time-left">{distance.length>0 ?parseFloat(distance[0]):0 } KM</span>
                        <span className="mt-2 showtime-slider-box">
                        
                          <Slider
                            id="showtime-slider"
                            min={minDistance}
                            max={maxDistance}
                            value={distance}
                            onChange={(e)=>{if(e.value[0]< e.value[1]){
                              setDistance(e.value);
                            }}}
                            onSlideEnd={()=>{CinemasRequest(true)}}
                            range
                          />
                        </span>
                        <span className="showtime-slider-box-label-time-right">{distance.length>1 ?parseFloat(distance[1]):0} KM</span>
                    </div>
                    <div className={`mt-2 now-right showtime-slider-container width45right ${
                          theme === "inoxTheme"
                            ? "showtime-slider-inox"
                            : "showtime-slider-pvr"
                        }`}
                      >
                        <small className="showtime-label-container">
                          <img src={imgTimeIcon} alt="" /> Time 
                        </small>
                        <span className="showtime-slider-box-label-distance-left">{timeSelectFormat(showTime.length > 0 ?showTime[0]:0)}</span>
                        <span className="mt-2 showtime-slider-box">
                          <Slider
                            id="showtime-slider"
                            min={minValue}
                            max={maxValue}
                            value={showTime}
                            onChange={(e)=>{if(e.value[0]< e.value[1]){
                              setShowTime(e.value);
                            }}}
                            onSlideEnd={()=>{CinemasRequest(true)}}
                            // onSlideEnd={(e) => {console.log(e); setShowTime(e.value);CinemasRequest(true);}}
                            range
                          />
                        </span>
                        <span className="showtime-slider-box-label-distance-right">{timeSelectFormat(showTime.length > 1 ?showTime[1]:24)}</span>
                    </div>
                  </div>
                  
                </>
                :""}
            <LoadScript
              googleMapsApiKey="AIzaSyC-3ZuzjJk0HgWT-a_SnjUj3JAa1EKihlU"
              // loadingElement={<div style={{ height: `100%` }} />}
            >
              <GoogleMap
                mapContainerStyle={{
                  width: '100%',
                  height: '550px'
                }}
                center={{lat:parseFloat(selected?selected.cinemaRe?.latitude:lat), lng:parseFloat(selected?selected.cinemaRe?.longitude:long)}}
                zoom={zoomMap(selected?.cinemaRe?.distance || 700) || 12}
                options={mapOptions}
              >
                {mapCinemaList.map((location, idx) => (
                  <Marker
                    key={location.cinemaRe?.theatreId}
                    position={{ lat: parseFloat(location.cinemaRe?.latitude), lng: parseFloat(location.cinemaRe?.longitude) }}
                    onClick={() => {
                      setSelected(location);

                      setActiveIndex(idx);
                    }}
                    icon={selected && selected.cinemaRe?.theatreId == location.cinemaRe?.theatreId?theme === "inoxTheme"? inoxselectedMarker:selectedMarker:theme === "inoxTheme"?inoxmarker:marker}
                  />
                ))}
                { /* Child components, such as markers, info windows, etc. */ }
                {selected ? (
                  ""
                ) : null}
                {loading?loadingall
                :""}
              </GoogleMap>
            </LoadScript>
            {selected ? (
            <div className="card card-view">
              <Carousel
                value={mapCinemaList}
                numVisible={1}
                numScroll={1}
                responsiveOptions={responsiveOptions}
                itemTemplate={productTemplateSm}
                // showIndicators={false}
                // containerStyle={{ width: "200px" }}
                page={activeIndex}
                // showNavigators={false}
                className="formats-prev-dynamic"
                onPageChange={(e) => { setActiveIndex(e.page); setSelected(mapCinemaList[e.page]);}}
              />
            </div>
            ) : null}
          </div>
          }
        </div>

        
      </section>

      <SessionSidebar movieSession={selected} showMovie={showsession} changeShowMovie={setShowsession} arrayposition={movieIndex} />
      <div>
        <Sidebar
          visible={visiblemapview}
          //   icons={proceedbtn}
          showCloseIcon={false}
          style={{ margin: "30%" }}
          position="center"
          onHide={() => {
            SetVisiblemapview(false);
          }}
          dismissable={true}
        >
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div  className="web-map-container">
                <div className="web-map-container-content">
                  {locationPermission?<>
                    <img src={img_location} alt="" /><br/>
                    <p><strong>Location permission is disabled</strong></p>
                    <p>{"Please enable your location permission to the browser to access the map view."}</p>
                    <a href="javascript:void(0)" 
                    style={theme === "inoxTheme"
                      ? { backgroundColor: "#234b9e", color: "#fff" }
                      : { backgroundColor: "#FFCB05", color: "#000" }}
                    onClick={()=>{ requestLocationPermission()
                      hitGa("map_view_enable_location");

                    }} className="map-view-button">Location is Disabled</a>
                    {/* <hr/> */}
                  </>:
                  <>
                    <img src={img_location} alt="" /><br/>
                    <p><strong>Introducing our new “Map View”</strong></p>
                    <p>{"Explore the map to find your preferred cinemas and their showtimes!"}</p>
                    <a href="javascript:void(0)" 
                    style={theme === "inoxTheme"
                      ? { backgroundColor: "#234b9e", color: "#fff" }
                      : { backgroundColor: "#FFCB05", color: "#000" }}
                    onClick={()=>{ requestLocationPermission()}} className="map-view-button">Go To Map View
                        {loadingbtn ? (
                            <i
                              className="pi pi-spin pi-spinner"
                              style={{ fontSize: "1.5em" }}
                            ></i>
                          ) : (
                            ""
                        )}
                    </a>
                   
                  </>}
                </div>
              </div>
            </div>
          </div>
        </Sidebar>
      </div>
      <div>
        <SweetAlert
          error
          show={showErrorAlert}
          title={"Oops"}
          onConfirm={() => {
            setShowErrorAlert(false);
            // navigate("/");
          }}
          onCancel={() => setShowErrorAlert(false)}
          showConfirm={true} // Display the "OK" button
          showCancel={false} // Hide the "Cancel" button
          confirmBtnText="OK"
          confirmBtnCssClass={
            theme === "inoxTheme"
              ? "confirm-btn-inox border-0"
              : "confirm-btn-pvr border-0"
          }
          closeOnClickOutside={false}
          closeOnEscape={false} // Prevent pressing the Escape key from closing the alert
          // timeout={3000}
        >
          {errorMsg}
        </SweetAlert>
      </div>
    </>
  );
};

export default CinemaList;
