import React from "react";
import { Skeleton } from "primereact/skeleton";

const FnbCheckoutSkeleton = () => {
	const rptno = Array.from({ length: 2 }, (_, index) => index + 1);
	const cardNum = Array.from({ length: 8 }, (_, index) => index + 1);

	return (
		<>
			<style>
				{`
				.flx {
					display: flex;
				}`
				}
			</style>
			<div style={{overflow: "hidden"}}>
				<div className="d-flex justify-content-between mt-2">
					{/* <Skeleton width="5rem" className="my-1"></Skeleton> */}
					<Skeleton width="100%" height="65px" className="mx-2"></Skeleton>
				</div>
				<div className="repeatskltn mt-3 px-2" style={{ width: "100%" }}>
					{rptno && rptno?.map((item, index) => (
						<>
							<Skeleton width="100%" height="70px" className="mb-4"></Skeleton>
							<Skeleton width="60%" height="20px" className="mb-4 mx-auto"></Skeleton>
						</>

					))}
				</div>
				<div className="repeatskltn mt-2 px-2" style={{ width: "100%" }}>
						<Skeleton width="100%" height="91px" className="mb-4"></Skeleton>
						<Skeleton width="60%" height="20px" className="mb-4 mx-auto"></Skeleton>
						<Skeleton width="100%" height="150px" className="mb-4"></Skeleton>
				</div>
			</div>
		</>
	);
};

export default FnbCheckoutSkeleton;
