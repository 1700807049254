import React, { useEffect, useRef, useState } from "react";
import minusPVR from "../../../assets/food/minus.svg";
import plusPVR from "../../../assets/food/plus.svg";
import plusINOX from "../../../assets/food/inoxPlus.svg";
import minusINOX from "../../../assets/food/inoxMinus.svg";
import SweetAlertUtility from "./SweetAlertUtility";
import { salesForceEvent } from "src/global-functions/globalFunctions";


const AddButton = ({
  isCart,
  data,
  addedItems,
  cartIndex,
  cartItemIds,
  updateCount,
  handleCustomizfoShow,
  handleUpgradeModalShow,
  upgradeModal,
  setTempCustomizations,
  removeFromCart,
  setAddedItems,
  cartStyle,
  cartBtn,
  totalItems
}) => {

  const theme = localStorage.getItem("theme");
  const bookType = localStorage.getItem("booking_type") || "FOOD";
  const seatCount = parseInt(localStorage.getItem("seatCount")) || 0;
  const [btnQuantity, setBtnQuantity] = useState(0);
  const [alertDetails, setAlertDetails] = useState({ show: false, type: '', title: '', message: '' });
  const itemInCart = useRef(0);
  const messages = JSON.parse(localStorage.getItem("messages"));

  useEffect(() => {
    itemInCart.current = addedItems?.length
      ? addedItems?.reduce((quantity, item) => {
        if (item?.ItemID === data?.id) {
          return quantity + item.quantity;
        }
        return quantity + 0;
      }, 0)
      : 0;

    const quantity = isCart ? data?.quantity : itemInCart.current;
    setBtnQuantity(quantity);
  }, [addedItems]);

  const handleIncrement = () => {
    if (upgradeModal && location.pathname.indexOf("/fnb-checkout") !== -1) {
      handleUpgradeModalShow(data)
    }
    if (!data) {
      // console.error("Data is undefined");
      return;
    }

    // if ((bookType === "BOOKING" && totalItems >= seatCount) || (bookType !== "BOOKING" && totalItems >= 10)) {
    // if (totalItems >= seatCount || totalItems >= 10) {
    //   sweetAlertError(messages?.itemQuantityLimit);
    //   return;
    // }

    if (totalItems >= seatCount) {
      sweetAlertError(messages?.itemQuantityLimit);
      return;
    }

    if (btnQuantity >= 10) {
      sweetAlertError("You can only order maximum 10 quantities for this item.");
      return;
    }

    hitGaAddfood("add_food", data?.h, data?.ct, data?.dp / 100);
    isViewItem(data?.id)
    if (data?.addOn) {
      handleCustomizfoShow(data);
    } else {
      if (isCart && data?.AddOnItem?.length > 0) {
        setAddedItems((prevItems) => {
          let stateCopy = [...prevItems];
          stateCopy[cartIndex].quantity++;
          stateCopy[cartIndex].AddOnItem.forEach((item) => item.quantity++);

          let totalQty = stateCopy.reduce((total, item) => {
            let addOnTotal = item.AddOnItem
              ? item.AddOnItem.reduce((sum, addOn) => sum + addOn.quantity, 0)
              : 0;
            return total + item.quantity + addOnTotal;
          }, 0);

          // if ((bookType === "BOOKING" && totalQty > seatCount) || (bookType !== "BOOKING" && totalQty > 10)) {
          if (totalQty > seatCount) {
            stateCopy[cartIndex].quantity--;
            stateCopy[cartIndex].AddOnItem.forEach((item) => item.quantity--);
            sweetAlertError(messages?.itemQuantityLimit);
            return prevItems;
          }

          // if (totalQty > 10) {
          //   stateCopy[cartIndex].quantity--;
          //   stateCopy[cartIndex].AddOnItem.forEach((item) => item.quantity--);

          //   const messages = JSON.parse(localStorage.getItem("messages"));
          //   sweetAlertError(messages?.itemQuantityLimit || "Maximum food items limit has been reached");
          //   return prevItems;
          // }
          return stateCopy;
        });
        return;
      }



      // if (totalItems >= 10) {
      //   const messages = JSON.parse(localStorage.getItem("messages"));
      //   sweetAlertError(messages.itemQuantityLimit || "Maximum food items limit has been reached");
      //   return;
      // }

      // if ((bookType === "BOOKING" && btnQuantity < seatCount) || (bookType !== "BOOKING" && btnQuantity < 10)) {
      // if (btnQuantity < seatCount || btnQuantity < 10) {
      setBtnQuantity((prev) => prev + 1);

      if (isCart) {
        setAddedItems((prevItems) => {
          let stateCopy = [...prevItems];
          stateCopy[cartIndex].quantity++;
          stateCopy[cartIndex].AddOnItem.forEach((item) => item.quantity++);
          return stateCopy;
        });
      } else if (data?.addOn) {
        handleCustomizfoShow(data);
      } else {
        updateCount(data);
      }
      // } else {
      //   sweetAlertError(messages?.itemQuantityLimit);
      // }
    }
  };

  const handleDecrement = () => {
    if (!data) {
      console.error("Data is undefined");
      return;
    }
    hitGaAddfood("remove_food", data?.h, data?.ct, data?.dp / 100);
    if (isCart) {
      setBtnQuantity((prev) => {
        return prev - 1;
      });
      setAddedItems((prevItem) => {
        let stateCopy = [...prevItem];
        stateCopy[cartIndex].quantity--;
        let addOns = [...stateCopy[cartIndex].AddOnItem];
        addOns.map((item) => {
          item.quantity--;
        });
        stateCopy[cartIndex].AddOnItem = addOns;
        if (stateCopy[cartIndex].quantity <= 0) {
          stateCopy.splice(cartIndex, 1);
        }
        return stateCopy;
      });
      return;
    }

    removeFromCart(data?.id || data?.ItemID);
  };

  const hitGaAddfood = (event, food_name, food_type, price) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
      food_name: food_name,
      food_type: food_type,
      price: price
    });
  };

  const isViewItem = (productID) => {
    salesForceEvent({
      interaction: {
        name: SalesforceInteractions.CatalogObjectInteractionName.ViewCatalogObject,
        catalogObject: {
          type: "Product",
          id: productID,
        },
      },
    })
  }

  const sweetAlertSuccess = (msg) => {
    setAlertDetails({
      show: true,
      type: "success",
      title: "Success!",
      message: msg,
    });
  };

  const sweetAlertError = (msg) => {
    setAlertDetails({
      show: true,
      type: "error",
      title: "Error!",
      message: msg,
    });
  };

  const hideAlert = () => {
    setAlertDetails({ show: false });
  };

  return (
    <>
      {btnQuantity > 0 ? (
        <div style={cartStyle} className="fb-c-div">
          <button className="fb-c-btn" onClick={handleDecrement}>
            <img
              src={
                cartBtn ? minusPVR : theme === "pvrTheme" ? minusPVR : minusINOX
              }
              alt="decrement"
            />
          </button>
          <span className="fb-c-span">{btnQuantity}</span>
          <button className="fb-c-btn" onClick={handleIncrement}>
            <img
              src={
                cartBtn ? plusPVR : theme === "pvrTheme" ? plusPVR : plusINOX
              }
              alt="increment"
            />
          </button>
        </div>
      ) : (
        <button className="fndAddBtn" onClick={handleIncrement}>
          Add
        </button>
      )}

      <SweetAlertUtility type={alertDetails.type} title={alertDetails.title} message={alertDetails.message} show={alertDetails.show} onConfirm={hideAlert} />
    </>
  );
};

export default AddButton;
