import axios from "axios";
import * as appConstants from "../constants/index";
const theme = localStorage.getItem("theme");


export const getRetryPayment = () => {
  let name =
    "retry-" +
    localStorage.getItem("pgused") +
    localStorage.getItem("bookingid");
  let retryPayment = localStorage.getItem(name);
  //console.log("getretrypayment=>", JSON.parse(retryPayment), name);
  if (retryPayment) {
    retryPayment = JSON.parse(retryPayment);
    if (
      retryPayment.retry &&
      retryPayment.retryCount >= retryPayment.retrycommit
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export const getPassport = async () => {
  // console.log('localStorage.getItem("token")', localStorage.getItem("token"))
  try {
    const token = localStorage.getItem("token");
    // console.log("Token:", token);

    const response = await axios.post(
      `${appConstants.base_url}v1/passport/vouchers`,
      {bookType: localStorage.getItem("booking_type")},
      {
        headers: {
          Authorization: `Bearer ` + token,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      }
    );
    return response;
  
  } catch (error) {
    console.error("Error making API request:", error);
    throw error;
  }

  // try {
  //   const response = await axios
  //   .post(`${appConstants.base_url}v1/payment/coupon/apply`, postData, {
  //     headers: headers,
  //   })
  //   return response;
  // } catch (error) {
  //   console.error("Error making API request:", error);
  //   throw error;
  // }
};
export const getVoucherDetails = async (vnumber) => {
  // console.log('localStorage.getItem("token")', localStorage.getItem("token"))
  try {
    const token = localStorage.getItem("token");
    // console.log("Token:", token);

    const response = await axios.post(
      `${appConstants.base_url}v1/history/voucher/details`,
      {vouNumber: vnumber},
      {
        headers: {
          Authorization: `Bearer ` + token,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      }
    );
    return response;
  
  } catch (error) {
    console.error("Error making API request:", error);
    throw error;
  }
};
export const getLoyalityVoucher = async () => {
  // console.log('localStorage.getItem("token")', localStorage.getItem("token"))
  try {
    const token = localStorage.getItem("token");
    // console.log("Token:", token);

    const response = await axios.post(
      `${appConstants.base_url}v1/payment/coupon/getloyaltyvoucher`,
      {},
      {
        headers: {
          Authorization: `Bearer ` + token,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      }
    );
    return response;
  
  } catch (error) {
    console.error("Error making API request:", error);
    throw error;
  }

  // try {
  //   const response = await axios
  //   .post(`${appConstants.base_url}v1/payment/coupon/apply`, postData, {
  //     headers: headers,
  //   })
  //   return response;
  // } catch (error) {
  //   console.error("Error making API request:", error);
  //   throw error;
  // }
};
export const applycoupon = async (postData) => {
  // console.log('localStorage.getItem("token")', localStorage.getItem("token"))
  try {
    const token = localStorage.getItem("token");
    // console.log("Token:", token);

    const response = await axios.post(
      `${appConstants.base_url}v1/payment/coupon/apply`,
      postData,
      {
        headers: {
          Authorization: `Bearer ` + token,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      }
    );
    let dataLayer = window["dataLayer"] || [];
    
if(response.data.result=='success')
    dataLayer.push({
      'event': 'success_coupon_code',
      'coupon_name':postData.couponType,
      'coupon_code':postData.coupon,
      'date': localStorage.getItem('selecteddate')
      });
    return response;
  
  } catch (error) {
    console.error("Error making API request:", error);
    throw error;
  }

  // try {
  //   const response = await axios
  //   .post(`${appConstants.base_url}v1/payment/coupon/apply`, postData, {
  //     headers: headers,
  //   })
  //   return response;
  // } catch (error) {
  //   console.error("Error making API request:", error);
  //   throw error;
  // }
};
export const removeCoupon = async (postData) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${appConstants.base_url}v1/payment/coupon/remove`,
      postData,
      {
        headers: {
          Authorization: `Bearer ` + token,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error making API request:", error);
    throw error;
  }
};
export const Gyftrapplycoupon = async (postData) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${appConstants.base_url}v1/payment/coupon/gyftr`,
      postData,
      {
        headers: {
          Authorization: `Bearer ` + token,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error making API request:", error);
    throw error;
  }
};
export const Giftcardapply = async (postData) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${appConstants.base_url}v1/payment/giftcard/redeem`,
      postData,
      {
        headers: {
          Authorization: `Bearer ` + token,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error making API request:", error);
    throw error;
  }
};
export const setRetryPayment = async (data, payId) => {
  //let bookingid = localStorage.getItem("bookingid");
  //let name = "retrymethod"+bookingid;
  // console.log("getretrypayment=>", data, payId);
  let retryPayment = localStorage.getItem(payId);
  let parsedData;
  if (retryPayment) {
    parsedData = JSON.parse(retryPayment);
    parsedData.retrycommit = parsedData.retrycommit + 1;
  } else {
    let sendingdata = data;
    sendingdata.retrycommit = 1;
    parsedData = sendingdata;
  }
  localStorage.setItem(payId, JSON.stringify(parsedData));
  return true;
};
export const familyBundleApply = async (postData) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${appConstants.base_url}v1/booking/ticketing/familyBundle/apply`,
      postData,
      {
        headers: {
          Authorization: `Bearer ` + token,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error making API request:", error);
    throw error;
  }
};
export const familyBundleRemove = async (postData) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${appConstants.base_url}v1/booking/ticketing/familyBundle/remove`,
      postData,
      {
        headers: {
          Authorization: `Bearer ` + token,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error making API request:", error);
    throw error;
  }
};
