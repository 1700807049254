import React, { useState, useEffect } from 'react'
import { Sidebar } from "primereact/sidebar";
import arrowback from "../../../assets/passport/mdi_arrow_back.png";
import './passportsummary.css';


export const  PasssportSummary = ({summary, showModal, hideModal}) =>{
    const [faqsidebar, setFaqsidebar] = useState(false);
    const [isAccordionTabOpen, setIsAccordionTabOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);
    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth <= 575);
        };
        window.addEventListener("resize", handleResize);
        return () => {
        window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(()=>{
        setFaqsidebar(showModal);
        // console.log(summary);
    },[showModal])
    return (
        <>
            <Sidebar
            header="Header"
            visible={faqsidebar}
            position={isMobile?"bottom":"right"}
            className="custom-sidebar"
            onHide={() =>
               {setFaqsidebar(false);hideModal(false);}}
            dismissable={()=>{setFaqsidebar(false);hideModal(false);}}
            showCloseIcon={false}
            blockScroll={true}
            style={isMobile?{ height: "75vh" }:{}}
         >
            {/* mobile view */}
            <div className="d-flex justify-content-center">
                <span style={{marginTop:'20px'}}><strong>Transaction Summary</strong></span>
                {!isMobile?
                    <span
                    className="pointer" style={{position:'absolute',right:'25px'}}
                    onClick={() => {setFaqsidebar(false);hideModal(false);}}
                    >
                    <i className="pi pi-times" />
                    </span>
                :""}
              </div>
              <div className="luxe-divider" style={{ margin: "5px 0" }}></div>
            {/* <div className="passport-right none-desktop">
                <div className="colse-btn pvr-inox-heading">
                <div className="pvr-inox-passport-info">
                    <div className="arrow-img" onClick={() => {setFaqsidebar(false);changefaq(false)}}>
                        <img src={arrowback} alt="Big Image" />
                    </div>
                    <div className="inox-passport">
                        <h2>Transaction Summary</h2>
                    </div>
                </div>
                </div>
            </div> */}
            {/* mobile view */}
            {/*desktop view  */}
            {/* <div className="passport-right none-mobile">
                <div className="colse-btn pvr-inox-heading">
                    <div className='row'>
                        <div className='col-10'>
                            <h3>Transaction Summary</h3>
                        </div>
                        <div className='col-2'>
                            <span onClick={() => {setFaqsidebar(false);}}>
                                <i
                                className="pi pi-times"
                                style={{ color: "#000", cursor: "pointer" }}
                                ></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='tkt-deatils'>
                <div className='row'>
                    <div className='col-7 tkt-label'>Ticket Price</div>
                    <div className='col-5 tkt-value'><span className='tkt-strike'>&#8377;{summary?.tp}</span> &#8377;{summary?.rtp}</div>
                </div>
                <div className='row'>
                    <div className='col-7 tkt-label'>Convenience Fees +</div>
                    <div className='col-5 tkt-value'>&#8377;{summary?.convgst}</div>
                </div>
                {summary && summary?.fp && summary?.fp != ".00"?
                    <div className='row'>
                        <div className='col-7 tkt-label'>F&B</div>
                        <div className='col-5 tkt-value'>&#8377;{summary?.fp}</div>
                    </div>
                :"" }
                {summary && summary?.donation && summary?.donation != ".00"?
                <div className='row'>
                    <div className='col-7 tkt-label'>Donation</div>
                    <div className='col-5 tkt-value'>&#8377;{summary?.donation}</div>
                </div>
                :""}
                {summary && summary?.sv && summary?.sv != ".00"?
                <div className='row'>
                    <div className='col-7 tkt-label'>Access Fees+</div>
                    <div className='col-5 tkt-value'>&#8377;{summary?.sv}</div>
                </div>
                :""}
                <div className='row'>
                    <div className='col-7 tkt-label'>Total Balance =</div>
                    <div className='col-5 tkt-value'>&#8377;{summary?.dp}</div>
                </div>
                <div className='row'>
                    <div className='col-7 tkt-label'>Passport Voucher Applied -</div>
                    <div className='col-5 tkt-value'>&#8377;{summary?.ptv}</div>
                </div>
                <div className='row'>
                    <div className='col-7 tkt-label'>To be paid +</div>
                    <div className='col-5 tkt-value'>&#8377;{summary?.ft}</div>
                </div>
            </div>
                  
         </Sidebar>
        </>
    )

}
export default PasssportSummary;