import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
// import offerPVR from "../../assets/food/coupen.svg";
// import offerINOX from "../../assets/food/offerInox.svg";
import foodOrderPVR from "../../assets/food/foodOrderpvr.svg";
import pen from "../../assets/food/penblack.svg";
import foodOrderINOX from "../../assets/food/foodOrderInox.svg";
import qrPVR from "../../assets/food/scanQRPVR.svg";
import qrINOX from "../../assets/food/scanQRInox.svg";
import backBtndesk from "../../assets/food/desktopBack.svg";
import { getResponsiveClasses } from "./component/ResponsiveClasses";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const FBOption = ({ handleToggle }) => {
  const navigate = useNavigate();
  const [prepareMultiOrderStatus, setPrepareMultiOrderStatus] = useState([]);

  localStorage.removeItem("cartItems");
  localStorage.removeItem("addFoodItems");
  localStorage.removeItem("userLocation");
  localStorage.removeItem("locationPermission");
  localStorage.setItem("upcomingMovieBack", true);
  // const bookingId = localStorage.getItem("bookingid");
  // const orderId = localStorage.getItem("orderId");
  // const remainingTime = JSON.parse(localStorage.getItem("remainingTime"));
  const onSeatType = localStorage.getItem("onSeatType");


  const { currentClasses, getWindowWidth } = getResponsiveClasses();
  const theme = localStorage.getItem("theme");

  const updateOrders = () => {
    const currentTime = new Date().getTime();
    const storedOrders =
      JSON.parse(localStorage.getItem("prepareMultiOrderStatus")) || [];

    // Filter out expired orders (older than 20 minutes)
    const filteredOrders = storedOrders.filter((order) => {
      const targetTime = new Date(order.targetTime).getTime();
      return targetTime > currentTime;
    });

    localStorage.setItem("prepareMultiOrderStatus", JSON.stringify(filteredOrders));
    setPrepareMultiOrderStatus(filteredOrders);
  };

  useEffect(() => {
    updateOrders();
    const cleanupInterval = setInterval(updateOrders, 1000);
    return () => clearInterval(cleanupInterval);
  }, []);


  let dataLayer = window["dataLayer"] || [];
  return (
    <>
      <div className={currentClasses.fnbdeskdiv}>
        <div className={currentClasses.fnbdeskdivInn}>
          {getWindowWidth && (
            <div>
              <img
                style={{
                  marginTop: "3px",
                  position: "absolute",
                  left: "15px",
                  top: "10px",
                  cursor: "pointer",
                }}
                src={backBtndesk}
                alt="backBtn"
                onClick={() => handleToggle("MOVIES")}
              />
              <h3 className="fnbTitle">Food & Beverages</h3>
            </div>
          )}
          {theme && (
            <div className={`outer-fbOption`}>
              <div
                className={`fb-op-card ${getWindowWidth ? "secDisabled" : ""}`}
                onClick={() => {
                  navigate("/scan-qr");
                  dataLayer.push({
                    'event': 'food_scan_qr_code',
                  });
                }}
              >
                <div className="fb-op-cardInn">
                  <div className="fb-o-imgdiv">
                    <img
                      className="fb-o-img"
                      src={theme === "pvrTheme" ? qrPVR : qrINOX}
                      width={70}
                      height={70}
                      alt="Scan QR Code"
                    />
                  </div>
                  <div className="fb-ptitle">
                    <p className="fb-o-qr-p">Scan QR Code</p>
                    {getWindowWidth && (
                      <p className="fb-prg">Available on PVR INOX Mobile App</p>
                    )}
                  </div>
                </div>
              </div>

              <div
                className={`fb-op-card`}
                onClick={() =>
                  {
                  navigate("/select-cinema-for-fnb");                    
                  dataLayer.push({
                    'event': 'food_order',
                  });
                  }
                }
              >
                <div className="fb-op-cardInn">
                  <div className="fb-o-imgdiv">
                    <img
                      className="fb-o-img"
                      src={theme === "pvrTheme" ? foodOrderPVR : foodOrderINOX}
                      width={70}
                      height={70}
                      alt="Order Food"
                    />
                  </div>
                  <div className="fb-ptitle">
                    <p className="fb-o-qr-p">Order Food</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {onSeatType === "true" && prepareMultiOrderStatus?.length > 0 && (
            <div className="vchrLanding">
              <div className="pyo-maindiv"
              // style={{
              //   margin: prepareMultiOrderStatus.filter(
              //     (item) => item.deliveryMode === "ONSEAT" && item.currentStatus
              //   ).length === 1 && getWindowWidth
              //     ? "0 20px"
              //     : "0",
              // }}
              >
                <Swiper
                  spaceBetween={
                    prepareMultiOrderStatus.filter(
                      (item) => item.deliveryMode === "ONSEAT" && item.currentStatus
                    ).length > 0
                      ? 5
                      : 0
                  }
                  slidesPerView={
                    prepareMultiOrderStatus.filter(
                      (item) => item.deliveryMode === "ONSEAT" && item.currentStatus
                    ).length > 1
                      ? getWindowWidth
                        ? 1
                        : 1.1
                      : 1.1
                  }
                  centeredSlides={true}
                  loop={false}
                  navigation
                  modules={[Navigation]}
                >
                  {prepareMultiOrderStatus
                    .filter(
                      (item) =>
                        item.deliveryMode === "ONSEAT" && item.currentStatus && item?.currentStatus !== "Delivered"
                    )
                    .map((item, index) => (
                      <SwiperSlide key={index}>
                        <div className="pyo-inndiv">
                          <img src={pen} alt="pen" />
                          <div className="fdDisc">
                            <p className="pyo-ptag">
                              {item?.currentStatus}
                              {/* {item.currentStatus === "Order Confirmed"
                                ? "Preparing Your Order"
                                : item.currentStatus} */}
                            </p>
                            <p className="fbitms">{item?.foodItems?.[0]?.name}</p>
                            {/* <p className="timer">
													{getOrderRemainingTime(item.targetTime)}
													</p> */}
                          </div>
                          <button
                            onClick={() =>
                              navigate(`/order-status?bookingid=${item.en_bid}`)
                            }
                            className="pyo-btn"
                          >
                            View Status
                          </button>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FBOption;
