import React from "react";
import { Skeleton } from "primereact/skeleton";

const FnbOfferCardSkeleton = () => {
	const rptno = Array.from({ length: 2 }, (_, index) => index + 1);
	const cardNum = Array.from({ length: 8 }, (_, index) => index + 1);

	return (
		<>
			<style>
				{`
				.flx {
					display: flex;
				}`
				}
			</style>
			<div style={{overflow: "hidden"}}>
				<div className="repeatskltn flx mt-3 px-2" style={{ width: "100%" }}>
					<Skeleton width="33.33%" height="40px" className="mx-1"></Skeleton>
					<Skeleton width="33.33%" height="40px" className="mx-1"></Skeleton>
					<Skeleton width="33.33%" height="40px" className="mx-1"></Skeleton>
				</div>
				<div className="repeatskltn mt-3 px-2" style={{ width: "100%" }}>
					{cardNum && cardNum?.map((item, index) => (
						<>
							<Skeleton width="100%" height="140px" className="mb-4"></Skeleton>
						</>
					))}
				</div>
			</div>
		</>
	);
};

export default FnbOfferCardSkeleton;
