import React, { useEffect, useState, useRef, useCallback } from "react";
import OtpInput from "react-otp-input";
import cinemaHouse from "../../../assets/comingsoon/Cinema House 1.gif";
import phone from "../../../assets/comingsoon/phone.svg";
import personRegister from "../../../assets/nav-icon/person-register.svg";
import email from "../../../assets/nav-icon/email.svg";
import inoxM from "../../../assets/brand/inox-logo.svg";
import pvrM from "../../../assets/brand/pvr-logo.svg";
import GoogleIcon from "../../../assets/nav-icon/google-icon.svg";
import FacebookIcon from "../../../assets/nav-icon/facebook-icon.svg";
import custoArrow from "../../../assets/cities/black-cities.svg";
import calendarIcon from "../../../assets/movie-on-demand/calnder.svg";
import "./login.css";
import "../../coming-soon/comingsoon.css";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as appConstants from "../../../constants/index";
import axios from "axios";
import { ProceedButton } from "./loginTags";
import { setLoginViewMode } from "../../../redux/actions/loginViewAction";
import { setViewMode as setviewmodeup } from "src/redux/actions/viewModeActions";
import { useNavigate } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import ExtendTime from "../../common/extend-time/ExtendTime";
import { refraceTokenService } from "../../../global-functions/refreshToken";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import {
  GoogleLoginButton,
  FacebookLoginButton,
} from "react-social-login-buttons";

import { GoogleLogin } from "@react-oauth/google";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import { isValidDateOfBirth } from "src/services/PassportServices";
import { jwtDecode } from "jwt-decode";
import { IsEvent } from "src/global-functions/globalFunctions";
import ConfirmMessageDialog from "src/global-functions/ConfirmMessageDialog";
import { getSplashData } from "src/services/OtherServices";
import ReCAPTCHA from "react-google-recaptcha";


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = localStorage.getItem("theme");
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  const [mobileOtp, setMobileOtp] = useState("");
  const [viewMode, setViewMode] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sentOTPData, setSentOTPData] = useState(null);
  const [reSentOTPData, setReSentOTPData] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const inputRef = useRef(null);
  const currentPath = window.location.pathname.split("/");
  const [timeCounter, setTimeCounter] = useState(null);
  const [showsocialbutton, SetShowsocialbutton] = useState(true);
  const [socialLogin, SetsocialLogin] = useState(false);
  const [LoginDetails, SetLoginDetails] = useState(null);
  const [spalshData, setSpalshData] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");


  //const REDIRECT_URI = window.location.href;
  const handleCaptchaChange = (value) => {
    setCaptchaValue(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();    
    if (!captchaValue) {
      setErrorMessage("Please complete the reCAPTCHA verification.");
      return;
    }    
    sendOTPFormik.handleSubmit();
  };
  const focusInput = () => {
    // Check if the ref is assigned to an element
    // if (inputRef.current) {
    //   inputRef.current.focus();
    // }
    registerFormik.submitForm();
  };

  const maritalOption = [
    { name: <span>Married</span>, code: "Married" },
    { name: <span>Unmarried</span>, code: "Unmarried" },
  ];
  const genderOption = [
    { name: <span>Male </span>, code: "Male" },
    { name: <span>Female</span>, code: "Female" },
    // { name: "Transgender", code: "Transgender" },
    { name: <span>Other </span>, code: "Other" },
  ];

  const handleSuccess = (credentialResponse) => {
    console.log(credentialResponse);
    SetLoginDetails(credentialResponse);

    // console.log(obj);
    sendSocialLogin(credentialResponse, "GOOGLE");
    // getUserInfo(credentialResponse.credential);
  };
  const handleSuccessAutoFill = (credentialResponse) => {
    // console.log(credentialResponse);
    var obj = jwtDecode(credentialResponse.credential);
    console.log(obj, "autofill");
    if (obj) {
      // SetFormErr(true);
      registerFormik.values.email = obj?.email ? obj.email : "";
      registerFormik.values.name = obj?.name ? obj.name : "";
      focusInput();
      // registerFormik.handleSubmit;
    }
    // SetLoginDetails(credentialResponse);

    // console.log(obj);
    // sendSocialLogin(credentialResponse, "GOOGLE");
    // getUserInfo(credentialResponse.credential);
  };

  // const loginGoogle = useGoogleLogin({
  //   onSuccess: (codeResponse) => getUserInfo(codeResponse),
  //   onError: (error) => console.log('Login Failed:', error)
  // });

  const handleError = () => {
    console.log("Login Failed");
  };
  useEffect(() => {
    profileRequest()
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      dispatch(setLoginViewMode({ value: false }));
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [dispatch]);

  useEffect(() => {
    if (showSuccessAlert) {
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 2500);
    }
  }, [showSuccessAlert]);

  useEffect(() => {
    if (timeCounter && timeCounter > 0) {
      setTimeout(() => {
        setTimeCounter(timeCounter - 1);
      }, 1000);
    }
  }, [timeCounter]);

  const sendOTPFormik = useFormik({
    initialValues: {
      mobileInput: "",
    },

    validate: (data) => {
      let errors = {};

      if (!data.mobileInput) {
        errors.mobileInput = "Please enter phone number.";
      } else if (data.mobileInput.length < 10) {
        errors.mobileInput = "Phone number must be in 10 digits.";
      }
      // console.log('errors', errors)
      return errors;
    },

    onSubmit: (data) => {
      if (!socialLogin) sendOTPService(data);
      else sendSocialLogin(LoginDetails, "GOOGLE", data.mobileInput);
      // console.log("loginData", data);
    },
  });
  const isMobileFormFieldValid = (name) =>
    !!(sendOTPFormik.touched[name] && sendOTPFormik.errors[name]);
  const getMobiletFormErrorMessage = (name) => {
    return (
      isMobileFormFieldValid(name) && (
        <small className="p-errors pt-2">{sendOTPFormik.errors[name]}</small>
      )
    );
  };

  const registerFormik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      gender: "",
      dob: "",
      maritalStatus: "",
      doa: "",
      type: "",
      token: "",
      otp: mobileOtp,
      autofill: false,
    },
    validate: (data) => {
      let errors = {};
      //console.log(data);

      let nameVal;
      if (data?.name?.length) {
        nameVal = data?.name?.split(" ");
        // console.log("name", nameVal?.length, nameVal);
      }

      if (!data.name) {
        errors.name = "Please enter name.";
      } else if (nameVal?.length == 1) {
        errors.name = "Please enter your full name";
      } else if (nameVal?.length > 1 && nameVal[1] === "") {
        errors.name = "Last Name is mandatory";
      }

      if (!data.phone) {
        errors.phone = "Please enter phone number";
      } else if (data.phone.length < 10) {
        errors.phone = "Phone number must be in 10 digits.";
      }

      if (!data.email) {
        errors.email = "Please enter email Id";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = "Invalid email address. E.g. example@email.com";
      }
      if (!data.gender) {
        errors.gender = "Please select gender";
      }
      if (!data.maritalStatus) {
        errors.maritalStatus = "Please select marital status";
      }
      const timeDifference =
        new Date(data?.doa)?.getTime() - new Date(data?.dob)?.getTime();
      const eighteenYearsInMillis = 18 * 365 * 24 * 60 * 60 * 1000;
      if (!data.dob) {
        errors.dob = "Please select date of birth";
      } else {
        let check = isValidDateOfBirth(data.dob.replace(/\-/g, "/"));
        console.log(check);
        if (!check) errors.dob = "Please select valid DOB.";
        else if (check < 0) errors.dob = "Please select valid DOB.";
        else if (check < 13)
          errors.dob = "You must be at least 13 year old to register into PVR.";
      }
      if (!data.doa && registerFormik.values.maritalStatus === "Married") {
        errors.doa = "Please select marriage anniversary";
      }
      if (data.doa && registerFormik.values.maritalStatus === "Married") {
        if (timeDifference < eighteenYearsInMillis) {
          errors.doa = "Anniversary date must be 18 years greater than DOB.";
        }
      }
      scrollToErrorField();
      return errors;
    },

    onSubmit: (data) => {
      // sentOTPData && sentOTPData?.nu === "true"?
      console.log(sendOTPFormik.values.mobileInput);
      if (mobileOtp) {
        data.otp = mobileOtp;
        registerUserService(data);
      } else if (sendOTPFormik.values.mobileInput) {
        setViewMode(1);
      } else {
        sendOTPService({ mobileInput: data.phone }, "regiter");
        // registerUserService(data);
      }

      // : verifyOTPService(mobileOtp);
      // console.log("loginData", data);
    },
  });
  const isLoginFormFieldValid = (name) =>
    !!(registerFormik.touched[name] && registerFormik.errors[name]);
  const getLogintFormErrorMessage = (name) => {
    return (
      isLoginFormFieldValid(name) && (
        <small className="p-errors">{registerFormik.errors[name]}</small>
      )
    );
  };

  const scrollToErrorField = () => {
    const errorFields = Object.keys(registerFormik.touched).filter(
      (name) => registerFormik.touched[name] && registerFormik.errors[name]
    );

    if (errorFields.length > 0) {
      const firstErrorField = errorFields[0];
      const errorFieldElement = document.getElementsByName(firstErrorField)[0];

      if (errorFieldElement) {
        errorFieldElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  // ===== send social login with mobile services ===========
  const sendSocialLogin = async (data, type, mobilenumber = "", otp = "") => {
    //console.log(otp,mobilenumber);
    setLoading(true);
    const postData = {
      //mobile: data.mobileInput,
      mobile: mobilenumber,
      type: type,
      token: data.credential,
      otp: otp,
    };

    await axios
      .post(`${appConstants.base_url}v1/auth/socialLogin`, postData, {
        headers: {
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          SetsocialLogin(true);
          if (dt.output?.nu == "false") {
            if (dt.output?.loginStatus == 2) {
              SetShowsocialbutton(false);
              setViewMode(0);
            } else if (dt.output?.loginStatus == 3) {
              setViewMode(1);
            }
          } else if (dt.output?.nu == "true") {
            if (mobilenumber) registerFormik.values.phone = mobilenumber;
            setViewMode(2);
            var obj = jwtDecode(data.credential);
            // console.log(obj, "autofill");
            if (obj) {
              registerFormik.values.email = obj?.email ? obj.email : "";
              registerFormik.values.name = obj?.name ? obj.name : "";
              focusInput();
              // registerFormik.handleSubmit;
            }
            // var data = JSON.stringify(obj);
          } else {
            if (dt.output?.data) {
              localStorage.setItem("loginInfo", JSON.stringify(dt.output));
              localStorage.setItem("token", dt.output?.access?.token);
              localStorage.setItem("refrace_token", dt.output?.refresh?.token);
              setTimeout(() => {
                dispatch(setLoginViewMode({ value: false }));
              }, 1000);
              dispatch(
                setviewmodeup(Math.floor(10000 + Math.random() * 90000))
              );
            }
          }
          // setTimeCounter(30);
          setSuccessMsg(dt);
          setShowSuccessAlert(true);
          setSentOTPData(dt.output);
          //setViewMode(1);
          setLoading(false);
          hitGa("login_with_otp", btoa(data.mobileInput));
        } else if (dt.result === "error" || dt.result === "dialog") {
          setErrorMsg(dt);
          setShowErrorAlert(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  function getTimestamp() {
    return Date.now();
  }
  const timestamp = getTimestamp(); 


async function generateHashText(mobile,platform) {  
  const secretKey = "tdy*gdr?diu(grui^j9utyf";  
  const hashText = `|${mobile}|${timestamp}|${secretKey}|${mobile.substring(0, 6)}|${platform}|`;
  //console.log(hashText, "hasmatch");
  const encoder = new TextEncoder();  
  const data = encoder.encode(hashText);  
  const hashBuffer = await crypto.subtle.digest("SHA-512", data);
  const hashArray = new Uint8Array(hashBuffer);
  const hashHex = Array.from(hashArray)
    .map(byte => byte.toString(16).padStart(2, '0')) 
    .join('');  
  return { hash: hashHex, timestamp };
}

  // ===== send otp with mobile services ===========
  const sendOTPService = async (data, register = null) => {
    setLoading(true);
    const { hash } = await generateHashText(data.mobileInput,"LOGIN");    
    const postData = {
      mobile: data.mobileInput,
      token: hash,
      timestamp:timestamp,
    }; 

    await axios
      .post(`${appConstants.base_url}v1/auth/login`, postData, {
        headers: {
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          if (register) {
            if (dt.output?.nu == "false") {
              let dt1 = {};
              dt1.otp = true;
              dt1.oldmsg = dt;
              dt1.result = "dialog";
              dt1.msg = "You are already registered with this mobile no.";
              setErrorMsg(dt1);
              setShowErrorAlert(true);
            }
            setLoading(false);
            setViewMode(1);
            setSentOTPData(dt.output);
            sendOTPFormik.values.mobileInput = data.mobileInput;
          } else {
            setTimeCounter(30);
            setSuccessMsg(dt);
            setShowSuccessAlert(true);
            setSentOTPData(dt.output);
            setViewMode(dt.output?.nu == "true" ? 2 : 1);

            dt.output?.nu == "true"
              ? (registerFormik.values.phone = data.mobileInput)
              : "";
            setLoading(false);
            hitGa("login_with_otp", btoa(data.mobileInput));
          }
        } else if (dt.result === "error" || dt.result === "dialog") {
          setErrorMsg(dt);
          setShowErrorAlert(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };
  const hitGa = (event, userID) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
      user_id: userID,
    });
  };

  const profileRequest = async () => {
    getSplashData().then((res) => {
      const dt = res.data;
      if (dt.result === "success") {
        setSpalshData(dt.output)
      } else {
      }
    })
      .catch((err) => {
        console.log('error', err)
      })
  };
  // ===== send otp with mobile services ===========
  const reSendOTPService = async () => {
    setLoading(true);
    const { hash } = await generateHashText(sendOTPFormik.values.mobileInput,"OTP"); 
    const postData = {
      mobile: sendOTPFormik.values.mobileInput,
      token: hash,
      timestamp:timestamp,
    };

    await axios
      .post(`${appConstants.base_url}v1/auth/otp`, postData, {
        headers: {
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          setLoading(false);
          setTimeCounter(30);
          setSuccessMsg(dt);
          setShowSuccessAlert(true);
          setReSentOTPData(dt.output);
          setViewMode(1);
        }
        if (dt.result === "error" || dt.result === "dialog") {
          setErrorMsg(dt);
          setShowErrorAlert(true);
          setLoading(false);
        }
        // console.log('userData', dt)
      })
      .catch((err) => {
        console.log("error", err);
        // showOTPSendMsg("error", err.response.data.error);
        setLoading(false);
      });
  };

  // ===== send otp with mobile services ===========
  const verifyOTPService = async (data) => {
    setLoading(true);
    var postData;
    if (socialLogin) {
      postData = {
        phone: sendOTPFormik.values.mobileInput,
        type: "GOOGLE",
        token: LoginDetails.credential,
        otp: data,
      };
    } else {
      postData = {
        mobile: sendOTPFormik.values.mobileInput,
        otp: data,
      };
    }
    let url = socialLogin
      ? `${appConstants.base_url}v1/auth/socialVerify`
      : `${appConstants.base_url}v1/auth/verify`;
    await axios
      .post(url, postData, {
        headers: {
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          setSuccessMsg(dt);
          setShowSuccessAlert(true);
          localStorage.setItem("loginInfo", JSON.stringify(dt.output));
          localStorage.setItem("token", dt.output?.access?.token);
          localStorage.setItem("refrace_token", dt.output?.refresh?.token);
          setTimeout(() => {
            dispatch(setLoginViewMode({ value: false }));
          }, 1500);
          dispatch(setviewmodeup(Math.floor(10000 + Math.random() * 90000)));
          hitGa("login_success_existing", btoa(dt.output.data.mob));

          let nameVal = dt.output.data.un?.split(" ");
          let attribute = {
            userEmailAddress: dt.output.data.em,
            userFirstName: nameVal.length > 0 ? nameVal[0] : "",
            userLastName: nameVal.length > 1 ? nameVal[1] : "",
            userPhone: "91" + dt.output.data.mob,
            userCity: localStorage.getItem("city"),
            userDOB: dt.output.data?.dob,
            userId: "VM" + dt.output.data.mob,
            "whatsAppOpt-in": dt.output.data?.wopt,
            gender: dt.output.data?.gd,
            preferedlanguage: "",
            preferedPaymentMethod: "",
            preferedCinema: "",
            preferedGenre: "",
            profileId: "VM" + dt.output.data.mob,
          };

          IsEvent({
            action: "Logged-In",
            user: {
              id: "VM" + dt?.output?.data?.mob,
              attributes: attribute,
            },
          });

          // console.log(attribute);

          // console.log("varifyLoginData", dt);
        } else if (dt.result === "error" || dt.result === "dialog") {
          setErrorMsg(dt);
          setShowErrorAlert(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  // ===== send otp with mobile services ===========
  const registerUserService = async (data) => {
    setLoading(true);
    // const postData = {
    //   name: data.name,
    //   email: data.email,
    //   mobile: sendOTPFormik.values.mobileInput,
    //   otp: mobileOtp,
    // };
    const postData = data;
    if (LoginDetails) {
      postData.token = LoginDetails.credential;
      postData.type = "GOOGLE";
    }
    await axios
      .post(`${appConstants.base_url}v1/auth/socialRegister`, postData, {
        headers: {
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          let nameVal = dt.output.data.un?.split(" ");
          let attribute = {
            userEmailAddress: dt.output.data.em,
            userFirstName: nameVal.length > 0 ? nameVal[0] : "",
            userLastName: nameVal.length > 1 ? nameVal[1] : "",
            userPhone: "91" + dt.output.data.mob,
            userCity: localStorage.getItem("city"),
            userDOB: dt.output.data?.dob,
            userId: "VM" + dt.output.data.mob,
            "whatsAppOpt-in": dt.output.data?.wopt,
            gender: dt.output.data?.gd,
            preferedlanguage: "",
            preferedPaymentMethod: "",
            preferedCinema: "",
            preferedGenre: "",
            profileId: "VM" + dt.output.data.mob,
          };

          IsEvent({
            action: "Registration",
            user: {
              id: "VM" + dt?.output?.data?.mob,
              attributes: attribute,
            },
          });
          setSuccessMsg(dt);
          setShowSuccessAlert(true);
          localStorage.setItem("loginInfo", JSON.stringify(dt.output));
          localStorage.setItem("token", dt.output?.access?.token);
          localStorage.setItem("refrace_token", dt.output?.refresh?.token);
          setTimeout(() => {
            dispatch(setLoginViewMode({ value: false }));
          }, 1000);
          dispatch(setviewmodeup(Math.floor(10000 + Math.random() * 90000)));
        } else if (dt.result === "error" || dt.result === "dialog") {
          // dt.phone = data.phone;
          // dt.userExist = dt.msg == "It seems this user already exists. Please check and try again."?true:false;
          // dt.msg = dt.msg == "It seems this user already exists. Please check and try again."?"You are already registered with this mobile no.":dt.msg;
          setErrorMsg(dt);
          setShowErrorAlert(true);
          setLoading(false);
          // if(dt.result === "dialog"){
          //   setTimeout(() => {
          //     sendOTPService({mobileInput: data.phone}, "regiter");
          //   }, 1000);
          // }
        }
        // console.log('registerUserData', dt)
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  const cancelTrnsService = async () => {
    const postData = {
      qr: localStorage.getItem("isQR") && isMobile ? "YES" : "NO",
      bookType: localStorage.getItem("booking_type"),
      transId: localStorage.getItem("transid"),
      bookingId: localStorage.getItem("bookingid"),
      //ccode: cinemacode,
      ccode: localStorage.getItem("theaterId"),
    };

    await axios
      .post(`${appConstants.base_url}v1/booking/ticketing/cancel`, postData, {
        headers: {
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        const dt = res.data;
        if (!isMobile) {
          localStorage.removeItem("isQR");
        }
      })
      .catch((error) => {
        const err = error?.response;
        if (err?.status === 403) {
          refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error.message);
        }
        setLoading(false);
      });
  };
  const onLoginStart = useCallback(() => {
    console.log(appConstants.REACT_APP_GG_APP_ID);
    // alert('login start')
  }, []);

  const signUpUi = () => {
    return (
      <div className="col-md-12 col-sm-12">
        <div className="social-signup">
          <div className="cross-icon mx-2">
            <span
              onClick={() => {
                dispatch(setLoginViewMode({ value: false }));
                navigate("/");
                if (localStorage.getItem("transid")) {
                  cancelTrnsService();
                }
              }}
            >
              <i className="pi pi-times"></i>
            </span>
          </div>
          <div className="sign-up-form-social">
            <h2>Let’s get to know you better</h2>
          </div>
          {!socialLogin ? (
            <div
              className="autofill"
              style={
                theme === "inoxTheme"
                  ? { backgroundColor: "#DCE2EF" }
                  : { backgroundColor: "#FFF0D6" }
              }
            >
              <div className="autofill-info">
                <h5>Autofill information with:</h5>
              </div>
              <div className="google-icon">
                <ul>
                  <li>
                    {/* <a href="#"><img src={GoogleIcon} alt="" className="img-fluid" /></a> */}
                    <GoogleLogin
                      onSuccess={handleSuccessAutoFill}
                      onError={handleError}
                      theme="filled_blue"
                      size="medium"
                      scope="profile email"
                      text="signup_with"
                      // width="50"
                      // useOneTap
                      flow="implicit"
                    />
                  </li>
                  {/* <li><a href="#"><img src={FacebookIcon} alt="" className="img-fluid" /></a></li> */}
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="form-socail-signup">
            <form onSubmit={registerFormik.handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mobile social-login">
                    <span className="p-float-label">
                      <InputText
                        id="name"
                        ref={inputRef}
                        value={registerFormik.values.name}
                        onChange={registerFormik.handleChange}
                      />
                      <label htmlFor="name">Name</label>
                    </span>
                    <div className="advertise-icon">
                      <img src="" alt="" className="img-fluid" />
                    </div>
                    {getLogintFormErrorMessage("name")}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mobile social-login">
                    <span className="p-float-label">
                      <InputText
                        id="phone"
                        value={registerFormik.values.phone}
                        onChange={registerFormik.handleChange}
                        maxLength={10}
                        keyfilter={/^\d*$/}
                        disabled={
                          sendOTPFormik.values.mobileInput ? true : false
                        }
                      />
                      <label htmlFor="phone">Phone</label>
                    </span>
                    <div className="advertise-icon">
                      <img src="" alt="" className="img-fluid" />
                    </div>
                    {getLogintFormErrorMessage("phone")}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mobile social-login">
                    <span className="p-float-label">
                      <InputText
                        id="email"
                        value={registerFormik.values.email}
                        onChange={registerFormik.handleChange}
                      />
                      <label htmlFor="email">Email</label>
                    </span>
                    <div className="advertise-icon">
                      <img src="" alt="" className="img-fluid" />
                    </div>
                    {getLogintFormErrorMessage("email")}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="organisation-feild now-right ">
                    <div className="mobiless">
                      <span className="p-float-label">
                        <Dropdown
                          id="gender"
                          options={genderOption}
                          value={registerFormik.values.gender}
                          onChange={registerFormik.handleChange}
                          optionLabel="name"
                          optionValue="code"
                        />
                        <div className="custom-dropdown-arrow">
                          <img src={custoArrow} alt="" className="" />
                        </div>
                        <label htmlFor="gender" className="dropdowns-type">
                          Gender
                        </label>
                      </span>
                      {getLogintFormErrorMessage("gender")}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="select-date-search">
                    <div className="fields-search">
                      <div className="mobile social-login">
                        <span className="p-float-label">
                          <InputMask
                            id="dob"
                            name="dob"
                            value={registerFormik.values.dob}
                            onChange={registerFormik.handleChange}
                            mask="99-99-9999"
                            placeholder="DD-MM-YYYY"
                          />
                          <label htmlFor="dob">Date Of Birth</label>
                        </span>
                        {getLogintFormErrorMessage("dob")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="organisation-feild now-right">
                    <span className="p-float-label">
                      <Dropdown
                        id="maritalStatus"
                        options={maritalOption}
                        value={registerFormik.values.maritalStatus}
                        onChange={registerFormik.handleChange}
                        optionLabel="name"
                        optionValue="code"
                      />
                      <div className="custom-dropdown-arrow">
                        <img src={custoArrow} alt="" className="" />
                      </div>
                      <label htmlFor="maritalStatus" className="dropdowns-type">
                        Marital Status
                      </label>
                    </span>
                    {getLogintFormErrorMessage("maritalStatus")}
                  </div>
                </div>
                {registerFormik.values.maritalStatus === "Married" ? (
                  <div className="col-md-12">
                    <div className="select-date-search">
                      <div className="fields-search">
                        <div className="mobile social-login">
                          <span className="p-float-label">
                            {/* <Calendar
                            id="doa"
                            // value={personalDetailsFormik.values.doa}
                            // onChange={personalDetailsFormik.handleChange}
                            dateFormat="dd-mm-yy"
                            // minDate={minAnniversaryDate} // Disable future dates
                            showIcon
                            icon={<img src={calendarIcon} alt="Calendar Icon" />}
                          /> */}
                            <InputMask
                              id="doa"
                              name="doa"
                              value={registerFormik.values.doa}
                              onChange={registerFormik.handleChange}
                              mask="99-99-9999"
                              placeholder="DD-MM-YYYY"
                            />
                            <label htmlFor="doa">Marriage Anniversary</label>
                          </span>
                          {getLogintFormErrorMessage("doa")}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-12">
                  <div className="signup-proceed-btn-social">
                    <ProceedButton className="social-proceed">
                      Proceed
                    </ProceedButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const signInUi = () => {
    return (
      <div className="col-md-12 col-sm-12">
        <div className="cross-icon mx-2">
          <span
            onClick={() => {
              dispatch(setLoginViewMode({ value: false }));
              navigate("/");
              if (localStorage.getItem("transid")) {
                cancelTrnsService();
              }
            }}
          >
            <i className="pi pi-times"></i>
          </span>
        </div>
        <div className="cinema-house mobile-login-M">
          <img
            src={cinemaHouse}
            alt=""
            className="img-fluid desktop-login-icon"
          />
          {spalshData?
            <img
              src={spalshData?spalshData?.logo:theme === "inoxTheme" ? inoxM : pvrM}
              alt=""
              className="img-fluid mobile-login-icon"
            />
          :""}
          <h1 className="desktop-view-login-head">Verify Phone Number</h1>
          <h1 className="Mobile-view-login-head">
            Verify<br></br> Phone Number
          </h1>
          <p className="desktop-view-login-content">
            Enter your phone number to proceed
          </p>
          <p className="Mobile-view-login-content">
            to continue, enter your phone number
          </p>
          <form onSubmit={handleSubmit}>
            <div className="mobile">
              <span className="p-float-label">
                <InputText
                  id="mobileInput"
                  value={sendOTPFormik.values.mobileInput}
                  onChange={sendOTPFormik.handleChange}
                  maxLength={10}
                  keyfilter={/^\d*$/}
                />
                <label htmlFor="mobileInput">Phone Number</label>
              </span>
              <div className="phone-icon">
                <img src={phone} alt="" className="img-fluid" />
              </div>
              {getMobiletFormErrorMessage("mobileInput")}
            </div>
            <div className="captcha">
            <ReCAPTCHA sitekey="6LcDoNAqAAAAAEwsR0gygztyoo3ArfFhiJ0J3raV" onChange={handleCaptchaChange} />
            {errorMessage && <div className="p-errors">{errorMessage}</div>}
            </div>
            <br/>
            <div className="register-btn">
              <ProceedButton
                className={
                  !loading ? "login-proceed-btn border-0 rounded" : "login-proceed-btn btn-opacity"
                }
                type="submit"
                disabled={!captchaValue} >
                Proceed
                {loading ? (
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "1.5em" }}
                  ></i>
                ) : (
                  ""
                )}
              </ProceedButton>
            </div>
          </form>
          {showsocialbutton ? (
            <>
              <div class="separator">
                <div class="side-line"></div>
                <h2>OR</h2>
                <div class="side-line"></div>
              </div>
              <div className="google-login">
                <div className="row">
                  <div className="col-md-12">
                    <GoogleLogin
                      onSuccess={handleSuccess}
                      onError={handleError}
                      theme="filled_blue"
                      size="large"
                      scope="profile email"
                      // useOneTap
                      flow="implicit"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="google-login">
                <LoginSocialFacebook
                  isOnlyGetToken={false}
                  appId={appConstants.REACT_APP_FB_APP_ID || ''}
                  onLoginStart={onLoginStart}
                  onResolve={({ provider, data }) => {
                    //setProvider(provider)
                    //setProfile(data)
                    console.log(provider, data);
                    sendSocialLogin({credential: data.accessToken}, "FACEBOOK");
                  }}
                  onReject={(err) => {
                    console.log(appConstants.REACT_APP_GG_APP_ID);
                    console.log(err);
                  }}
                >
                  <FacebookLoginButton />
                </LoginSocialFacebook>
              </div> */}
              <div className="account-sign-up">
                <p>
                  Don't have account?{" "}
                  <button
                    className="btn-sign-up"
                    onClick={() => {
                      setViewMode(2);
                    }}
                  >
                    Sign-Up
                  </button>{" "}
                </p>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const otpVerificationUI = () => {
    return (
      <div className="col-md-12 col-sm-12">
        <div className="cross-icon mx-2">
          <span
            onClick={() => {
              dispatch(setLoginViewMode({ value: false }));
              navigate("/");
              if (localStorage.getItem("transid")) {
                cancelTrnsService();
              }
            }}
          >
            <i className="pi pi-times"></i>
          </span>
        </div>
        <div className="cinema-house mobile-login-M Register-modal-desk register-track">
          <img
            src={cinemaHouse}
            alt=""
            className="img-fluid register-otp desktop-login-icon"
          />
          {spalshData?
          <img
            src={spalshData?spalshData?.logo:theme === "inoxTheme" ? inoxM : pvrM}
            alt=""
            className="img-fluid mobile-login-icon"
          />
          :""}
          <h1 className="desktop-view-login-head">Verify OTP</h1>
          <h1 className="Mobile-view-login-head">
            Verify<br></br> OTP Code
          </h1>
          <p className="otp-content desktop-view-login-content">
            We’ve sent a code to your phone number
          </p>
          <p className="Mobile-view-login-content">
            We’ve sent a code to your phone number
          </p>
          <h6 className="number-desktop">
            +91 {sendOTPFormik.values.mobileInput}
          </h6>
          <div className="otp-feild">
            <OtpInput
              value={mobileOtp}
              onChange={(e) => {
                const numericOtp = e.replace(/\D/g, "");
                setMobileOtp(numericOtp);
                // console.log(sentOTPData, socialLogin);
                if (sentOTPData?.nu === "false" && numericOtp?.length === 6) {
                  verifyOTPService(numericOtp);
                } else if (numericOtp?.length === 6) {
                  focusInput();
                }
              }}
              inputType="tel"
              numInputs={6}
              inputStyle={{ width: "60px" }}
              className="otp-box"
              isInputNum={true}
              shouldAutoFocus={true}
              renderInput={(props, index) => (
                <input {...props} className="otpfeild" />
              )}
            />
          </div>
          <div className="resend-otp">
            {timeCounter > 0 ? (
              <h6>{`00:${
                timeCounter < 10 ? `0${timeCounter}` : timeCounter
              }`}</h6>
            ) : (
              <h6
                className="show-in-mobile-views"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setMobileOtp("");
                  reSendOTPService();
                }}
              >
                Resend OTP
              </h6>
            )}
          </div>

          <form onSubmit={registerFormik.handleSubmit}>
            {/* {sentOTPData.nu === "false" ? (
              ""
            ) : (
              <div className="user-verfiy">
                <div className="mobile">
                  <span className="p-float-label">
                    <InputText
                      id="name"
                      value={registerFormik.values.name}
                      onChange={registerFormik.handleChange}
                    />
                    <label htmlFor="ssn_input">Name</label>
                  </span>
                  <div className="phone-icon">
                    <img src={personRegister} alt="" className="img-fluid" />
                  </div>
                  {getLogintFormErrorMessage("name")}
                </div>
                <div className="mobile">
                  <span className="p-float-label email-last">
                    <InputText
                      id="email"
                      value={registerFormik.values.email}
                      onChange={registerFormik.handleChange}
                    />
                    <label htmlFor="ssn_input">Email</label>
                  </span>
                  <div className="phone-icon">
                    <img src={email} alt="" className="img-fluid" />
                  </div>
                  {getLogintFormErrorMessage("email")}
                </div>
              </div>
            )} */}
            <div className="register-btn">
              <ProceedButton
                className={
                  !loading && mobileOtp && mobileOtp?.length === 6
                    ? "btn-proceeds"
                    : "btn-proceeds btn-opacity"
                }
                type="submit"
                disabled={
                  !loading && mobileOtp && mobileOtp?.length === 6
                    ? false
                    : true
                }
              >
                Proceed{" "}
                {loading ? (
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "1.5em" }}
                  ></i>
                ) : (
                  ""
                )}
              </ProceedButton>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <GoogleOAuthProvider clientId="745125406403-onfbm2d5pskskpld5gk4uuim1nquu0ke.apps.googleusercontent.com">
      <div>
        <div>
          <ConfirmMessageDialog
            icon={"success"}
            visible={showSuccessAlert}
            onHide={() => setShowSuccessAlert(false)}
            title={successMsg?.result}
            message={successMsg?.msg}
            showConfirmButton={true}
            onConfirm={() => setShowSuccessAlert(false)}
            timer={2000}
            showCloseIcon={false}
          />
        </div>
        <div>
          <ConfirmMessageDialog
            icon={"error"}
            visible={showErrorAlert}
            onHide={() => setShowErrorAlert(false)}
            title={errorMsg?.result === "dialog" ? "Oops" : errorMsg?.result}
            message={errorMsg?.msg}
            showConfirmButton={true}
            onConfirm={() => {
              if (errorMsg?.otp) {
                setTimeCounter(30);
                setSuccessMsg(errorMsg?.oldmsg);
                setShowSuccessAlert(true);
                setShowErrorAlert(false);
              } else setShowErrorAlert(false);
            }}
            timer={2000}
            showCloseIcon={false}
          />
        </div>
        <div className="card flex justify-content-center">
          {currentPath[1] === "payment" ? (
            <div className="w-75 shadow-lg rounded">
              <ExtendTime />{" "}
            </div>
          ) : (
            ""
          )}
          <div>
            {viewMode === 0
              ? signInUi()
              : viewMode === 1
              ? otpVerificationUI()
              : signUpUi()}
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default Login;
