import { constantsTypes } from "../reduxConstants";

const initialValue = 0;

export const showTimeViewMode = (state = initialValue, action) => {
  switch (action.type) {
    case constantsTypes.TIME_VIEW_MODE:
      return action.value;
    default:
      return state;
  }
};
