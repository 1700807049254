import axios from "axios";
import * as appConstants from "../constants/index";

export const refraceTokenService = async () => {
  const theme = localStorage.getItem('theme');
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  const refresh_token = loginInfo?.refresh?.token;

  const postData = {
    refreshToken: localStorage.getItem('refrace_token'),
  };

  await axios
    .post(`${appConstants.base_url}v1/auth/refresh-tokens`, postData, {
      headers: {
        chain: theme === "inoxTheme" ? "INOX" : "PVR",
        city: localStorage.getItem("city"),
        appVersion: appConstants.av,
        platform: appConstants.pt,
        country: appConstants.cname,
      },
    })
    .then((res) => {
      const dt = res.data;
      if (dt.result === "success") {
        const ndt = dt.output;
        localStorage.setItem('token', ndt?.access?.token);
        localStorage.setItem('refrace_token', ndt?.refresh?.token)
        window.location.reload();
        console.log("ticketSummary==>", ndt);
      } else {
        console.log('Refrace-Token-Error' ,res.data.msg);
        localStorage.removeItem("loginInfo");
        localStorage.removeItem('token');
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      }

      if (res.status === 403) {
        localStorage.removeItem("loginInfo");
        localStorage.removeItem('token');
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      }
    })
    .catch((err) => {
      console.log("error", err);
      localStorage.removeItem("loginInfo");
      localStorage.removeItem('token');
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
    });
};
