import React, { useEffect, useState } from "react";
import booking from "../../../../assets/profile/booking.svg";
import arrow from "../../../../assets/profile/navigatenext.svg";
import alert_icon from "../../../../assets/comingsoon/notification-black.svg";
import { Sidebar } from "primereact/sidebar";
import axios from "axios";
import * as appConstants from "../../../../constants/index";
import { useNavigate } from "react-router-dom";
import { DarkButton } from "src/styled-components/customButtons";
import { handleMivImageError } from "src/global-functions/imageErrorSrc";
import miv_pvr from "../../../../assets/default-images/vertical-poster-pvr.svg";
import miv_inox from "../../../../assets/default-images/vertical-poster-inox.png";
import { FaTrashAlt } from "react-icons/fa";
import setN from "../../../../assets/mobilemoviedetail/setn.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import angle_left from "../../../../assets/mobilemoviedetail/arrow-b.svg";

const ProfileMovieAlert = () => {
  const navigate = useNavigate();
  const theme = localStorage.getItem("theme");
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  const [visibleRight, setVisibleRight] = useState(false);
  const [sidebarViewMode, setSidebarViewMode] = useState(0);
  const [alertMovies, setAlertMovies] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedMovie, setSelectedMovie] = useState(null);


  useEffect(() => {
    getAlertMovies();
  }, []);

  const getAlertMovies = async () => {
    setLoading(true);
    const postData = {
      id: 0,
      mcode: "",
      ccodes: "",
      city: localStorage.getItem("city"),
    };

    // console.log("postData", postData)

    await axios
      .post(`${appConstants.base_url}v1/movie-alert/list`, postData, {
        headers: appConstants.apiHeader,
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          const ndt = dt.output;
          setAlertMovies(ndt);

          // console.log("alertMovies==>", ndt);
        } else {
          setAlertMovies([]);
          // console.log(res.data.msg);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  const removeAlertMovie = async () => {
    setLoading(true);
    const postData = {
      id: selectedMovie?.id,
      mcode: selectedMovie?.movieData?.id,
      ccodes: selectedMovie?.cinemaCodes,
      city: selectedMovie?.city,
    };

    // console.log("postData", postData);

    await axios
      .post(`${appConstants.base_url}v1/movie-alert/delete`, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          getAlertMovies();
          setShowAlert(false);
          const ndt = dt.output;
          // console.log("removeMovieAlert==>", ndt);
        } else if (dt.result === "error" || dt.result === "dialog") {
          setShowAlert(false);
          setShowErrorAlert(true);
          setErrMsg(dt.msg);
          // console.log(dt.msg);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  const cityCount = (data) => {
    if (data) {
      const val = data?.split(",")?.length;
      return val;
    } else {
      return "all";
    }
  };
  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };
  return (
    <>
      <div
        className="profile-img border-line-profile"
        onClick={() => {
          setVisibleRight(true);
          setSidebarViewMode(0);
        }}
      >
        <div className="profile-text profile-account">
          <h4>
            <span className="avtar">
              <img src={alert_icon} alt="" className="" />
            </span>
          </h4>
          <div className="profile-text-info"  onClick={()=>hitGa("movie_alert")}>
            <h2 onClick={()=>hitGa("movie_alert")}>Movie Alert</h2>
          </div>
        </div>
        <div className="img-arrow"  onClick={()=>hitGa("movie_alert")}>
          <img src={arrow} alt="" className="" />
        </div>
      </div>
      <div>
        <Sidebar
          visible={visibleRight}
          showCloseIcon={false}
          className="watchlist-show-m"
          style={
            sidebarViewMode === 0 ? { width: "100vw" } : { height: "50vh" }
          }
          position={sidebarViewMode === 0 ? "right" : "bottom"}
          onHide={() => {
            setVisibleRight(false);
          }}
          dismissable={false}
          blockScroll={true}
          fullScreen
        >
          <>
            <div
              className="bg-white show-in-mobile-view"
              style={{ position: "sticky", top: "0", zIndex: "999" }}
            >
              <div className="watch-list-mobile-box">
                <span onClick={() => setVisibleRight(false)}>
                  <img src={angle_left} alt="" className="" />{" "}
                </span>
                <h4>Watchlist</h4>
              </div>
              {/* <div className="luxe-divider"></div> */}
            </div>
            <div className="notify-soon">
              {alertMovies?.length > 0 ? (
                <small>
                  We'll notify as soon as the booking opens up in your preferred
                  theaters.
                </small>
              ) : (
                ""
              )}
            </div>
            <div>
              {alertMovies?.length > 0 ? (
                alertMovies?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="border rounded my-2"
                      onClick={() => {
                        if (isMobile) {
                          navigate(
                            `/m.movie-details/${item?.movieData?.id}/UP`
                          );
                        }
                      }}
                    >
                      <div className="d-flex m-alert">
                        <div className="w-25">
                          <img
                            src={
                              item?.movieData?.miv
                                ? item?.movieData?.miv
                                : theme === "inoxTheme"
                                  ? miv_inox
                                  : miv_pvr
                            }
                            onError={handleMivImageError}
                            alt=""
                            className="img-fluid rounded"
                          />
                        </div>
                        <div className="w-75 mx-2">
                          <div className="d-flex justify-content-between">
                            <div className="item-movie-name">{item?.movieData?.n}</div>
                            <div
                              className="mx-3 delete-btn-info"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedMovie(item);
                                setShowAlert(true);
                              }}
                            >
                              <FaTrashAlt />
                            </div>
                          </div>
                          <div>
                            {item?.movieData?.rt
                              ? item?.movieData?.rt
                              : item?.movieData?.rtt}
                          </div>
                          <div>{item?.movieData?.othergenres}</div>
                        </div>
                      </div>
                      {/* <div
                        className="luxe-divider"
                        style={{ margin: "5px 0" }}
                      ></div> */}
                      <div className="d-flex justify-content-between alert-set-info">
                        <div classname="d-flex">
                          <span>
                            <img src={setN} alt="" className="img-fload" />{" "}
                          </span>
                          <span className="alert-set-for">
                            Alert set for {cityCount(item?.cinemaCodes)} theaters
                            in {item?.city}
                          </span>
                        </div>
                        <span>
                          <i className="pi pi-arrow-right" />{" "}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="watchlistmobile-show">
                  <h4>Oops! Nothing there...</h4>
                  <p>Let's start adding movies to watch!</p>
                  <div className="addmovies-mobiles">
                    <DarkButton className="addmovies-mobile" onClick={() => navigate("/coming-soon")}>
                      Add Movies
                    </DarkButton>
                  </div>
                </div>
              )}
            </div>
            <div>
              <SweetAlert
                warning
                show={showAlert}
                showCancel
                confirmBtnText="Yes, Remove it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={() => {
                  // setShowAlert(false);
                  removeAlertMovie();
                }}
                onCancel={() => setShowAlert(false)}
                focusCancelBtn
                confirmBtnCssClass={
                  theme === "inoxTheme"
                    ? "confirm-btn-inox border-0"
                    : "confirm-btn-pvr border-0"
                }
              >
                You want to remove this movie from your alert list.
              </SweetAlert>
            </div>
          </>
        </Sidebar>
      </div>

    </>
  );
};

export default ProfileMovieAlert;
