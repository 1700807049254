import React from "react";
import { Skeleton } from "primereact/skeleton";

const CinemaInOutSkeleton = () => {
  return (
    <div>
      <div className="cineminout custom-skeleton mt-5">
        <Skeleton width="100%" height="250px"></Skeleton>
      </div>
      <div className="cineminout custom-skeleton mt-4 center">
        <Skeleton width="100%" height="250px"></Skeleton>
      </div>
    </div>
  );
};

export default CinemaInOutSkeleton;
