export const getResponsiveClasses = () => {
    const isDesk = {
        fnbdeskdiv: "fnbdeskdiv",
        fnbdeskdivInn: "fnbdeskdiv-inn",
        fbAddedDiv: "fnbDeskbtn chkbtn",
        centerModal: "centerModal",
        sidebarModal: {
            centerSiderbar: "centerSiderbar",
            positionSiderbar: "center",
        },
        locationModal: "enterModal",
        applyButton: "getbtmbtn",
        headerTitle: "fnb-os-pdesk",
        headerAddress: "loction-fnbdesk",
        headerSearch: "hdrsearchDesk",
        fnbSearchBack: "fnbSearchbackdesk",
        itemAddCart: "fb-added-divdesk",
        fnbButton: "fb-added-btndesk",
        itemAboutModal: {
            aboutModalMain: "aboutfnb-main",
            aboutModalInnerDiv: "above-m-divdesk"
        },
        offerDetailModal: "m-offer-maindesk",
        customizeModal: "m-cus-maindesk",
        cartModal: {
            cartModalMain: "fnb-m-cartdesk",
            cartModalInnerDiv: "cart-itemwrapperdesk",
            cartImgVeg: "cartV-Nvegdesk",
            cartTitle: "fnb-c-itemnamedesk"
        },
        vchrLanding: "vchrLanding",
        mncategories: "mncategories",
        dskfd: "dskfd"
    };

    const isMob = {
        fnbdeskdiv: "fnbmobdiv",
        fnbdeskdivInn: "fnbmobdiv-inn",
        fbAddedDiv: "fb-added-div",
        centerModal: "slideTopModal",
        sidebarModal: {
            centerSiderbar: "bottomSidebar",
            positionSiderbar: "bottom",
        },
        locationModal: "slideTopModal locationModal",
        applyButton: "fixbtmbtn",
        headerTitle: "fnb-os-p",
        headerAddress: "loction-fnb",
        headerSearch: "hdrsearch",
        fnbSearchBack: "fnbSearchback",
        itemAddCart: "fb-added-div",
        fnbButton: "fb-added-btn",
        itemAboutModal: {
            aboutModalMain: "",
            aboutModalInnerDiv: "above-m-div"
        },
        offerDetailModal: "m-offer-main",
        customizeModal: "m-cus-main",
        cartModal: {
            cartModalMain: "fnb-m-cart",
            cartModalInnerDiv: "cart-itemwrapper",
            cartImgVeg: "cartV-Nveg",            
            cartTitle: "fnb-c-itemname"
        },
        vchrLanding: "vchrLandingmob",
        mncategories: "mncategoriesmob",
        dskfd: "mobfd"
    };

    const getWindowWidth = window.innerWidth > 1024;

    return {
        currentClasses: getWindowWidth ? isDesk : isMob,
        getWindowWidth
    };
};
