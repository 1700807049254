import React, { useState, useEffect, useRef } from "react";
import { Chips } from "primereact/chips";
import explore from "../../../assets/payment/explore.svg";
import offerRight from "../../../assets/payment/offer-right-arrow.svg";
import OtpInput from "react-otp-input";
import axios from "axios";
import * as appConstants from "../../../constants/index";
import { useFormik } from "formik";
import { ProceedButton } from "../../common/login/loginTags";

const Mobikwik = (props) => {
  const formRef = useRef(null);
  const bookingid = localStorage.getItem("bookingid");
  const theme = localStorage.getItem("theme");
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  const [loading, setLoading] = useState(false);
  const [value6, setValue6] = useState(null);
  const [mobileOtp, setMobileOtp] = useState("");

  const paymentOptions = parseInt(localStorage.getItem("paymentOptions") || 0);
  const gaTagData = JSON.parse(localStorage.getItem("gaTagData"));
  useEffect(() => {
    //getNetBankingList1();
    console.log(props);
    initJuspay();
  }, []);

  const initJuspay = async () => {
    console.log('working');
    var juspay_form = Juspay.Setup({
      payment_form: "#payment_form_wallet",
      success_handler: function (status) {
        console.log(status);
      },
      error_handler: function (error_code, error_message, bank_error_code, bank_error_message, gateway_id) {
        console.log('error', error_code, error_message);
      },
      customer: {

        customer_id: props.juspayinit ? props.juspayinit.customer_id : '',// change to relevant customer id value

        client_auth_token: props.juspayinit ? props.juspayinit.client_auth_token : '',// populate the active client_auth_token value fetched from Create Order/Create Customer API

      },
    })
  }

  const sendOTPFormik = useFormik({
    initialValues: {
      mobileInput: "",
    },

    validate: (data) => {
      let errors = {};

      if (!data.mobileInput) {
        errors.mobileInput = "Please enter mobile no.";
      } else if (data.mobileInput.length < 10) {
        errors.mobileInput = "Mobile no. must be in 10 digits.";
      }
      // console.log('errors', errors)
      return errors;
    },

    onSubmit: (data) => {
      // sendOTPService(data);
      // console.log("loginData", data);
    },
  });
  const gethmac = async () => {
    // if(!value6){
    //   setErrorMsg("Please enter vpa!");
    //   setShowAlert(true);
    //   return '';
    // }
    setLoading(true);
    const postData = {
      "transId": localStorage.getItem("transid"),
      "bookingId": localStorage.getItem("bookingid"),
      "bookType": localStorage.getItem("booking_type") === "GIFTCARD" ? 'GIFTCARD' : localStorage.getItem("booking_type") === "FOOD" ? 'FOOD' : 'BOOKING',
      "paytype": "WALLET",
      "pgtype": "JUSPAY"
    };

    await axios
      .post(`${appConstants.base_url}v1/payment/juspay/hmac`, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") || ""
            }`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          localStorage.setItem('pgused', 'MOBIKWIK');
          const url = window.location.href;
          let item_category = '';
          if (localStorage.getItem("tickettype") && localStorage.getItem("tickettype") === "food") {
            item_category = 'ticket,f&b';
          }
          else
            item_category = 'ticket';

          localStorage.setItem("cinema_name", url.split('/').pop().replaceAll('%20', ' '));
          window.dataLayer = window.dataLayer || [];
          paymentOptions === 1
            ?
            window.dataLayer.push({
              event: 'add_payment_info',
              ecommerce: {
                currency: 'INR',
                value: Number(res.data.output.amount),
                affiliation: 'NETBANKING',
                items: [{
                  item_brand: appConstants.cinemaChain,
                  item_name: gaTagData?.movieName || false,
                  item_id: gaTagData?.movieId || false,
                  item_category: localStorage.getItem("booking_type") || "FOOD",
                  item_category2: gaTagData?.theaterName || false,
                  item_category3: gaTagData?.selectedDate ? `${gaTagData?.selectedDate} / ${gaTagData?.selectedSeats || ""}` : format(new Date(), "yyyy-MM-dd hh:mm a"),
                  item_category4: gaTagData?.genre && gaTagData?.languages ? `${gaTagData?.genre} / ${gaTagData?.languages}` : false,
                  item_category5: false,
                  item_variant: gaTagData?.variant || false,
                  quantity: gaTagData?.qty || false,
                  price: Number(res.data.output.amount),
                }]
              }
            })
            :
            window.dataLayer.push({
              event: 'add_payment_info',
              ecommerce: {
                currency: 'INR',
                value: Number(res.data.output.amount),                                 //(total amount)
                affiliation: 'MOBIKWIK',                   //(payment type)                                                
                items: [{
                  item_brand: appConstants.cinemaChain,                       // (pvr/inox)
                  item_name: localStorage.getItem('moviename'),                            // (ticket's name,passport's name,gift card's name)
                  item_id: localStorage.getItem('movieid'),                             //(ticket's id,passport's id,gift card's id)
                  //(total amount)
                  item_category: item_category,                       //(selected ticket, gift card & passport)
                  item_category2: localStorage.getItem('cinema_name') + "/" + localStorage.getItem('city'),
                  item_category3: localStorage.getItem('selecteddate') + "," + localStorage.getItem('selectedseats'),                         //( selected date & seat number)
                  item_category4: localStorage.getItem('genre'),                     //(language &  genre)
                  item_variant: localStorage.getItem('format'),                      //( selected format)   
                  quantity: localStorage.getItem('quantity'),
                  price: Number(res.data.output.amount),


                }]
              }
            });
          // setTicketSummary(ndt);
          //document.getElementById("payment_form_NB").submit();
          localStorage.setItem("paymentid", dt.output.paymentId);
          localStorage.setItem("payment_type", "nonwallet");
          console.log("bankList==>", dt);
          formRef.current.click();
        } else {
          console.log(res.data.msg);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  }
  return (
    <>
      <div className="">
        <div className="payment-form">
          <div className="paywithdebit">
            <div className="debit-card show-in-desktop-view">
              <h6>Paytm Postpaid</h6>
            </div>
            <div className="to-paid">
              {/* <p>
              To be Paid:
              <span className="price-cross">₹8888</span>{" "}
              <span className="price-blod">₹8888</span>
            </p> */}
            </div>
          </div>
          <div className="cinema-house payment-postpaytm">
            {/* <h1>Enter OTP</h1>
          <p className="otp-content">You need to enter the OTP received</p>
          <h6>+91 {sendOTPFormik.values.mobileInput}</h6>
          <div className="otp-feild">
            <OtpInput
              value={mobileOtp}
              onChange={setMobileOtp}
              numInputs={6}
              inputStyle={{width:'60px'}}
              // renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} className="otpfeild" />}
            />
          </div> */}
            <div className="register-btn">
              <ProceedButton className="btn-proceeds verfiy-paytm" onClick={() => gethmac()}>
                Continue Pay Mobikwik
              </ProceedButton>
            </div>
            {/* <div className="resend-otp">
            <h6
              style={{ cursor: "pointer" }}
              // onClick={() => reSendOTPService()}
            >
              Resend OTP
            </h6>
          </div> */}
          </div>
        </div>
        <form className="juspay_inline_form" id="payment_form_wallet" style={{ "display": "none" }}>
          <input type="hidden" className="merchant_id" value={props.juspayinit ? props.juspayinit.merchant_id : ''} />
          <input type="hidden" className="order_id" value={bookingid} />
          <input type="hidden" className="payment_method_type" value="WALLET" />
          <div className="">
            <div className="modal-tab-block">
              <div className="modal-tab-block">
                <div className="form-group">
                  <div className="select-box">
                    <select className="payment_method" id="walletselect">
                      <option value="MOBIKWIK" label="MOBIKWIK" selected>MOBIKWIK</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button ref={formRef} type="submit" className="make_payment">
            Pay
          </button>
          <input type="hidden" className="redirect" value="true" />
          <input type="hidden" className="payment_channel" value="WEB" />
          <input type="hidden" id="direct_wallet_token" className="direct_wallet_token" value="" />
        </form>
      </div>
    </>
  )
}

export default Mobikwik