import AddButton from "./AddButton";
import vegIcon from "../../../assets/food/veg.svg";
import nonvegIcon from "../../../assets/food/nonveg.svg";
import noImg from "../../../assets/food/noimagesqr.png";
import { salesForceEvent } from "src/global-functions/globalFunctions";

const FnBCard = ({
  data,
  handleFoodInfoShow,
  addedItems,
  cartItemIds,
  count,
  updateAddedItems,
  handleCustomizfoShow,
  updateCount,
  removeFromCart,
  totalItems,
}) => {
  const handleCardClick = (e) => {
    isViewItemDetails(data?.id);
    if (!e.target.closest(".Customfb-btnDiv") && !data.addOn) {
      handleFoodInfoShow();
    } else {
      handleCustomizfoShow(data);
    }
  };

  const isViewItemDetails = (productID) => {
    salesForceEvent({
      interaction: {
        name: SalesforceInteractions.CatalogObjectInteractionName.ViewCatalogObjectDetail,
        catalogObject: {
          type: "Product",
          id: productID
        },
      },
    })
  }

  let IsCurrIsAddonItem = 0;
  let IsCurrentStandardItemID = data.id;

  const handleImageError = (e) => {
    e.target.src = noImg; // Set fallback image if the original image fails
  };

  const addedItem = Array.isArray(addedItems)
    ? addedItems.find((item) => item.ItemID === data.id)
    : undefined;

  const itemCount = addedItem ? addedItem.quantity : 0;
  return (
    <div className="Reco-card-main">
      <div className="fb-card-inn">
        <div className="fb-card-left" onClick={handleCardClick}>
          <div className="Reco-item-view">
            <img
              className="Reco-item-vImg"
              src={
                data?.i
                  ? data?.i
                  : data?.imgData
                    ? `data:image/jpeg;base64,${data?.imgData}`
                    : noImg
              }
              // src={data?.i || noImg}
              width="84"
              alt={data?.h || "No image available"}
              onError={handleImageError}
            />
            {data.foodType === 1 ?
              <svg className="fnb-itemVorN" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
              </svg>
              : data.foodType === 0 ?
                <svg className="fnb-itemVorN" width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                  <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                  <clipPath id="clip0_3452_1183">
                    <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                  </clipPath>
                </svg>
                : ""
            }
          </div>
          <div className="Reco-item-desc">
            <p className="fb-itemName">{data?.h}</p>
            <p className="fb-itemPrice">{data.op > data.dp && (<><del style={{ marginRight: "3px" }}>₹{data?.op / 100}</del></>)}
              ₹{data?.dp / 100}
            </p>
          </div>
        </div>
        <div className="Customfb-btnDiv">
          <AddButton
            itemId={data.id}
            addedItems={addedItems}
            cartItemIds={cartItemIds}
            updateCount={updateAddedItems}
            handleCustomizfoShow={() => handleCustomizfoShow(data)}
            data={data}
            removeFromCart={removeFromCart}
            totalItems={totalItems}
          />
          {data.addOn && <span className="custom-btn">Customizable</span>}
        </div>
      </div>
    </div>
  );
};

export default FnBCard;
