import React, { useState } from "react";
import { Chips } from "primereact/chips";
import explore from "../../../assets/payment/explore.svg";
import offerRight from "../../../assets/payment/offer-right-arrow.svg";
import { usePaymentInputs } from "react-payment-inputs";
import { Checkbox } from "primereact/checkbox";
import { DonateButton } from "../paymentTags";

const HyattDining = () => {
  const [value6, setValue6] = useState(null);
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();
  const [cardNumber, setcardNumber] = useState();
  const [expiryDate, setexpiryDate] = useState();
  const [cvc, setcvc] = useState();
  const [checked, setChecked] = useState(false);
  return (
    <>
      <div className="">
        <div className="payment-form">
          <div className="paywithdebit">
            <div className="debit-card">
              <h6>
                color: var(--Black, #000); font-family: Poppins; font-size:
                14px; font-style: normal; font-weight: 600; line-height: 100%;
                /* 14px */
              </h6>
            </div>
            <div className="to-paid">
              {/* <p>
                To be Paid:
                <span className="price-cross">₹8888</span>{" "}
                <span className="price-blod">₹8888</span>
              </p> */}
            </div>
          </div>
          <div className="payment-debitcard">
            <form>
              <div className="row">
                {meta.isTouched && meta.error && (
                  <small className="text-danger">{meta.error}</small>
                )}
                <div className="col-md-12">
                  <div className="payment-feild">
                    <span className="p-float-label">
                      <Chips
                        id="chips"
                        value={value6}
                        onChange={(e) => setValue6(e.value)}
                      />
                      <label htmlFor="chips">Enter Voucher Code</label>
                    </span>
                    <p>We promise to keep your voucher safe</p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="gyftr-box">
                    <p>Promocode will not be refunded for cancellation</p>
                    <div className="divider-gyftr"></div>
                    <p>
                      *If there is an intent connectivity problem while booking
                      the free tickets using your coupon codes and the tickets
                      are not issued please wait for 30 minutes before trying to
                      use the Coupon Codes again.
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="verfiy-pay">
                    <DonateButton
                      className="verfiy-payment"
                      type="submit"
                      // onClick={setPayment}
                    >
                      Apply
                    </DonateButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default HyattDining;
