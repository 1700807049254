import React, { useState, useEffect, useRef } from "react";
import Election from "../../../assets/nav-icon/election.svg";
import "./navbar.css";
import inoxLogo from "../../../assets/brand/inox-logo.svg";
import pvrLogo from "../../../assets/brand/pvr-logo.svg";
import { FaBars } from "react-icons/fa";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import showtime from "../../../assets/nav-icon/showtime.svg";
import cinema from "../../../assets/nav-icon/Cinema.svg";
import filFestival from "../../../assets/nav-icon/film-festival-link.png";
import offerBlack from "../../../assets/nav-icon/offer_black.svg";
import offerWhite from "../../../assets/nav-icon/offer_white.svg";
import InvestorBlack from "../../../assets/nav-icon/investor-black.svg";
import InvestorWhite from "../../../assets/nav-icon/investor-white.svg";
import OdrBlack from "../../../assets/nav-icon/odr-black.svg";
import odrWhite from "../../../assets/nav-icon/odr-white.svg";
import home from "../../../assets/nav-icon/home.svg";
import homeB from "../../../assets/nav-icon/home_black.svg";
import PassportWhite from "../../../assets/nav-icon/passport_white.svg";
import PassportBlack from "../../../assets/nav-icon/passport_black.svg";
import showtime_White from "../../../assets/nav-icon/showtime_white.svg";
import cinema_White from "../../../assets/nav-icon/cinema_white.svg";
import menubar from "../../../assets/nav-icon/menubar.svg";
import searchm from "../../../assets/nav-icon/search.svg";
import profile from "../../../assets/nav-icon/profile-icon.svg";
import custoArrowB from "../../../assets/cities/black-cities.svg";
import Cities from "../cities/Cities";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "./navbar.css";
import { LoginButton } from "./navbarTags";
import Login from "../login/Login";
import { Dialog } from "primereact/dialog";
import { useSelector, useDispatch } from "react-redux";
import { setLoginViewMode } from "../../../redux/actions/loginViewAction";
import MoreDropdown from "./more-dropdown/MoreDropdown";
import Search from "./search/Search";
import MProfile from "../../mobile-views/mobile-profile/MobileProfile";
import user from "../../../assets/nav-icon/user.svg";
import * as appConstants from '../../../constants/index'
import axios from "axios";
import { getSplashData } from "src/services/OtherServices";
import Bell from "../../../assets/nav-icon/bell.svg";

const Navbar = ({ disable, citydisable }) => {
  // const isMobile = window.matchMedia('(max-width: 575px)').matches;
  const toast = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visibleNotificatio, setVisibleNotificatio] = useState(false);
  const token = localStorage.getItem("token");
  const theme = localStorage.getItem("theme");
  const trailerViewModde = useSelector((state) => state.trailerViewMode.value);
  const [visible, setVisible] = useState(trailerViewModde);
  const currentPath = window.location.pathname.split("/");
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  const loginVewMode = useSelector((state) => state.loginViewMode.value);
  const [spalshData, setSpalshData] = useState(null);

  // const [websiteSwitch, setWebsiteSwitch] = useState(true);

  // console.log("currentPath", currentPath);

  useEffect(() => {
    if (trailerViewModde !== 0) {
      setVisible(false);
    }
  }, [trailerViewModde]);

  useEffect(() => {
    profileRequest()
  }, [localStorage.getItem('city')]);

  const activeCurrentPathVal =
    currentPath[1] === ""
      ? 0
      : currentPath[1] === "show-timings"
        ? 1
        : currentPath[1] === "cinemas" ||
          currentPath[1] === "cinemasessions" ||
          currentPath[1] === "experience"
          ? 2
          : currentPath[1] === "offers"
            ? 3
            : currentPath[1] === "passport"
              ? 4
              : currentPath[1] === "moviesessions"
                ? 6
                : currentPath[1] === "seatlayout"
                  ? 7
                  : currentPath[1] === "investors-section"
                    ? 10
                    : currentPath[1] === "getfood"
                      ? 8
                      : currentPath[1] === "payment"
                        ? 9
                        : currentPath[1] === "gift-cards" ||
                          currentPath[1] === 'bulk-gift-cards' ||
                          currentPath[1] === 'bulkbooking' ||
                          currentPath[1] === "advertise" ||
                          currentPath[1] === "about-us" ||
                          currentPath[1] === "faq" ||
                          currentPath[1] === "careers-us" ||
                          currentPath[1] === "feedback" ||
                          currentPath[1] === "news" ||
                          currentPath[1] === "oscar"
                          ? 5
                          : null;
  const [buttonView, setButtonView] = useState(activeCurrentPathVal);
  const [menuHide, setMenuHide] = useState(false);
  const [visibleRight, setVisibleRight] = useState(false);

  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const profileRequest = async () => {
    getSplashData().then((res) => {
      const dt = res.data;
      if (dt.result === "success") {
        setSpalshData(dt.output)
      } else {
      }
    })
      .catch((err) => {
        console.log('error', err)
      })
  };

  return (
    <>
      {!isMobile ? (
        <div className="desktop-view-M show-in-desktop-view">
          <div style={{ height: "3.4rem" }}>
            <Toast ref={toast}></Toast>
            <div
              className={
                disable
                  ? "d-flex justify-content-between nav-center nav-mt disable-navbar"
                  : "d-flex justify-content-between nav-center nav-mt"
              }
            >
              <div className="d-flex mx-3 list-view">
                <span
                  onClick={() => {
                    if (currentPath[1] === "") {
                      window.location.reload();
                    }
                    navigate("/");
                  }}
                >
                  {spalshData?
                  <>
                  {theme === "inoxTheme" ? (
                    <img
                      src={spalshData?spalshData?.logo:inoxLogo}
                      alt="INOX Logo"
                      className="logos-inox"

                      width={80}
                      height={35}
                    />
                  ) : (
                    <img src={spalshData?spalshData?.logo:pvrLogo} alt="PVR Logo" 
                    width={80}
                      height={35}
                    className="logos-pvr" />
                  )}
                  </>
                  :""}
                </span>
                <div className="all-links align-self-center">
                  <ul>
                    <li
                      className={
                        buttonView === 0 && theme === "inoxTheme"
                          ? "active-nav-inox"
                          : buttonView === 0 && theme === "pvrTheme"
                            ? "active-nav-pvr"
                            : ""
                      }
                      onClick={() => {
                        setButtonView(0);
                        if (currentPath[1] === "") {
                          window.location.reload();
                        }
                        hitGa("home_click");

                        navigate("/");
                      }}
                    >
                      <div className="nav-icons">
                        {/* <img src={homeB} alt="" className="img-fluid" /> */}
                        {buttonView === 0 && theme === "inoxTheme" ? (
                          <img src={home} alt="" className="img-fluid" />
                        ) : (
                          <img src={homeB} alt="" className="img-fluid" />
                        )}
                      </div>
                      <div className="useful-link">Home</div>
                    </li>
                    <li
                      className={
                        buttonView === 1 && theme === "inoxTheme"
                          ? "active-nav-inox"
                          : buttonView === 1 && theme === "pvrTheme"
                            ? "active-nav-pvr"
                            : ""
                      }
                      onClick={() => {
                        setButtonView(1);
                        navigate("/show-timings");
                      }}
                    >
                      <div className="nav-icons">
                        {buttonView === 1 && theme === "inoxTheme" ? (
                          <img
                            src={showtime_White}
                            alt=""
                            className="img-fluid"
                          />
                        ) : (
                          <img src={showtime} alt="" className="img-fluid" />
                        )}
                      </div>
                      <div
                        className="useful-link"
                        onClick={() => hitGa("top_navi_showtime")}
                      >
                        Showtimings
                      </div>
                    </li>
                    <li
                      className={
                        buttonView === 2 && theme === "inoxTheme"
                          ? "active-nav-inox"
                          : buttonView === 2 && theme === "pvrTheme"
                            ? "active-nav-pvr"
                            : ""
                      }
                      onClick={() => {
                        setButtonView(2);
                        if (isMobile)
                          hitGa("top_navi_cinemas");

                        navigate(`/cinemas/${localStorage.getItem("city")}`);
                      }}
                    >
                      <div className="nav-icons">
                        {buttonView === 2 && theme === "inoxTheme" ? (
                          <img
                            src={cinema_White}
                            alt=""
                            className="img-fluid"
                          />
                        ) : (
                          <img src={cinema} alt="" className="img-fluid" />
                        )}
                      </div>
                      <div
                        className="useful-link"
                        onClick={() => {
                          hitGa("top_navi_cinemas");
                          hitGa("cinemas_click");
                        }} >                        
                        Cinemas
                      </div>
                    </li>
                    <li
                      className={
                        buttonView === 3 && theme === "inoxTheme"
                          ? "active-nav-inox"
                          : buttonView === 3 && theme === "pvrTheme"
                            ? "active-nav-pvr"
                            : ""
                      }
                      onClick={() => {
                        setButtonView(3);
                        hitGa("top_navi_offers");
                        navigate("/offers");
                      }}
                    >
                      <div className="nav-icons">
                        {buttonView === 3 && theme === "inoxTheme" ? (
                          <img src={offerWhite} alt="" className="img-fluid" />
                        ) : (
                          <img src={offerBlack} alt="" className="img-fluid" />
                        )}
                      </div>
                      <div
                        className="useful-link"
                        onClick={() => hitGa("top_navi_offers")}
                      >
                        Offers
                      </div>
                    </li>
                    {/*other */}
                    <li
                      className={
                        buttonView === 10 && theme === "inoxTheme"
                          ? "active-nav-inox"
                          : buttonView === 10 && theme === "pvrTheme"
                            ? "active-nav-pvr"
                            : ""
                      }
                      onClick={() => {
                        setButtonView(10);
                        hitGa("");
                        navigate("/investors-section");
                      }}
                    >
                      <div className="nav-icons">
                        {buttonView === 10 && theme === "inoxTheme" ? (
                          <img src={InvestorWhite} alt="" className="img-fluid" />
                        ) : (
                          <img src={InvestorBlack} alt="" className="img-fluid" />
                        )}
                      </div>
                      <div
                        className="useful-link"
                      >
                        Investor Section
                      </div>
                    </li>
                    {/* <li>
                      <a href="">
                        <img src={Election} alt="election-logo" className="img-fluid"/>
                      </a>
                    </li> */}
                    {/* <li
                      onClick={() => {
                        hitGa("top_navi_offers");
                        window.open("https://smartodr.in/login", '_blank').focus();
                      }}
                    >
                      <div className="nav-icons">
                        {theme === "inoxTheme" ? (
                          <img src={OdrBlack} alt="" className="img-fluid" />
                        ) : (
                          <img src={OdrBlack} alt="" className="img-fluid" />
                        )}
                      </div>
                      <div
                        className="useful-link"
                        onClick={() => {hitGa("ODR_PORTAL")}}
                      >
                       ODR Portal
                      </div>
                    </li> */}
                    {/* other */}
                    {spalshData?.passport == "true" ?
                      <li
                        className={
                          buttonView === 4 && theme === "inoxTheme"
                            ? "active-nav-inox"
                            : buttonView === 4 && theme === "pvrTheme"
                              ? "active-nav-pvr"
                              : ""
                        }
                        onClick={() => {
                          // if (loginInfo?.data?.mob) {
                          //   setButtonView(4);
                          //   navigate("/passport");
                          // } else {
                          //   dispatch(setLoginViewMode({ value: true }));
                          // }
                          window.open("https://passport.pvrinox.com/", '_blank').focus();
                        }}
                      >
                        <div className="nav-icons">
                          {buttonView === 4 && theme === "inoxTheme" ? (
                            <img
                              src={PassportWhite}
                              alt=""
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              src={PassportBlack}
                              alt=""
                              className="img-fluid"
                            />
                          )}
                        </div>
                        <div className="useful-link">Passport</div>
                      </li>
                      : ""}
                    <li
                      className={
                        buttonView === 5 && theme === "inoxTheme"
                          ? "active-nav-inox"
                          : buttonView === 5 && theme === "pvrTheme"
                            ? "active-nav-pvr"
                            : ""
                      }
                      onClick={() => {
                        setButtonView(5);
                        hitGa("top_navi_more_click")
                        setMenuHide(!menuHide);
                      }}
                    >
                      <MoreDropdown />
                    </li>
                    {/* <li className="">
                     
                        <img src={Election} alt="" className="img-fluid"/>
                     
                    </li> */}
                  </ul>
                </div>
              </div>
             
              {/* <div className="election-logo">
                    <a href="https://www.eci.gov.in/" target="_blank"><img src={Election} alt="" className="img-fluid" /> </a>
                  </div> */}
              <div className="d-flex search-view-h">
             
                <Search />
                  <div className={
                      citydisable
                        ? "mx-2 now-right disable-navbar"
                        : "mx-2 now-right"
                  }>
                      <Cities />
                  </div>
                {/* <div className="notification-bell">
                  <img src={Bell} onClick={() => setVisibleNotificatio(true)} alt="" className="img-fluid" />
                  <div className="notifi-number">
                    <h2>2</h2>
                  </div>
                </div> */}
                {/* bell sidebar */}

                {/* bell sidebar */}
                {/* <div className="d-flex film-fesival-nav"><a href="javascript:void(0)" onClick={()=>{navigate("/film-festival")}} ><img src={filFestival}  alt="Film Festival" className="img-fluid" /></a></div>   */}
                <div className="login-before">
                  <LoginButton
                    aria-label="Login Button"
                    className="before-login"
                    onClick={() => {
                      if (loginInfo?.data?.mob) {
                        hitGa("profile_personal_details");

                        navigate("/profile");
                      } else {
                        dispatch(setLoginViewMode({ value: true }));
                      }
                    }}
                  >
                    {loginInfo && loginInfo ? (
                      <label title={"Hey, " +
                        loginInfo?.data?.un?.length > 9
                        ? `${loginInfo?.data?.un?.substr(0, 9)}...`
                        : loginInfo?.data?.un
                      }>
                        Hey, {
                          loginInfo?.data?.un?.length > 9
                            ? `${loginInfo?.data?.un?.substr(0, 9)}...`
                            : loginInfo?.data?.un
                        }
                      </label>
                    ) : (
                      <label>
                        <span className="profile-icons">
                          <img src={profile} alt="profile icon" className="img-fluid" />
                        </span>
                        Login
                      </label>
                    )}
                  </LoginButton>
                </div>
                <div className="tablet-view">
                  <div className="card">
                    <div className="flex gap-2 justify-content-center">
                      <Button
                        icon="pi pi-bars"
                        onClick={() => setVisibleRight(true)}
                      />
                    </div>
                    <Sidebar
                      visible={visibleRight}
                      position="right"
                      onHide={() => setVisibleRight(false)}
                    >
                      <div className="tab-view-menu">
                        <ul>
                          <li
                            className={
                              buttonView === 0 && theme === "inoxTheme"
                                ? "active-nav-inox"
                                : buttonView === 0 && theme === "pvrTheme"
                                  ? "active-nav-pvr"
                                  : ""
                            }
                            onClick={() => {
                              setButtonView(0);
                              hitGa("home_click");
                              navigate("/");

                            }}
                          >
                            <div className="tabview-menu">
                              <div className="nav-icons">
                                {buttonView === 0 && theme === "inoxTheme" ? (
                                  <img
                                    src={home}
                                    alt="move to home"
                                    className="img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={homeB}
                                    alt="move to home"
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <div className="useful-link" >Home</div>
                            </div>
                          </li>
                          <li
                            className={
                              buttonView === 1 && theme === "inoxTheme"
                                ? "active-nav-inox"
                                : buttonView === 1 && theme === "pvrTheme"
                                  ? "active-nav-pvr"
                                  : ""
                            }
                            onClick={() => {
                              setButtonView(1);
                              navigate("/show-timings");
                            }}
                          >
                            <div className="tabview-menu">
                              <div className="nav-icons">
                                {buttonView === 1 && theme === "inoxTheme" ? (
                                  <img
                                    src={showtime_White}
                                    alt="Show timing"
                                    className="img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={showtime}
                                    alt="Show timing"
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <div className="useful-link">Showtimes</div>
                            </div>
                          </li>
                          <li
                            className={
                              buttonView === 2 && theme === "inoxTheme"
                                ? "active-nav-inox"
                                : buttonView === 2 && theme === "pvrTheme"
                                  ? "active-nav-pvr"
                                  : ""
                            }
                            onClick={() => {
                              setButtonView(2);
                              navigate(
                                `/cinemas/${localStorage.getItem("city")}`
                              );
                            }}
                          >
                            <div className="tabview-menu">
                              <div className="nav-icons">
                                {buttonView === 2 && theme === "inoxTheme" ? (
                                  <img
                                    src={cinema_White}
                                    alt=""
                                    className="img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={cinema}
                                    alt=""
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <div className="useful-link">Cinemas</div>
                            </div>
                          </li>
                          <li
                            className={
                              buttonView === 3 && theme === "inoxTheme"
                                ? "active-nav-inox"
                                : buttonView === 3 && theme === "pvrTheme"
                                  ? "active-nav-pvr"
                                  : ""
                            }
                            onClick={() => {
                              setButtonView(3);
                              navigate("/offers");
                            }}
                          >
                            <div className="tabview-menu">
                              <div className="nav-icons">
                                {buttonView === 3 && theme === "inoxTheme" ? (
                                  <img
                                    src={offerWhite}
                                    alt=""
                                    className="img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={offerBlack}
                                    alt=""
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <div className="useful-link">Offers</div>
                            </div>
                          </li>
                          <li
                            className={
                              buttonView === 4 && theme === "inoxTheme"
                                ? "active-nav-inox"
                                : buttonView === 4 && theme === "pvrTheme"
                                  ? "active-nav-pvr"
                                  : ""
                            }
                            onClick={() => {
                              setButtonView(4);
                              navigate("#");
                            }}
                          >
                            <div className="tabview-menu">
                              <div className="nav-icons">
                                {buttonView === 4 && theme === "inoxTheme" ? (
                                  <img
                                    src={offerWhite}
                                    alt=""
                                    className="img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={offerBlack}
                                    alt=""
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <div className="useful-link">Passport</div>
                            </div>
                          </li>
                          <li className="active-nav-pvr">
                            <div className="tabview-menu">
                              <MoreDropdown />
                              {buttonView === 5 && menuHide === true ? (
                                <img
                                  src={custoArrowB}
                                  alt=""
                                  className="more-side"
                                />
                              ) : (
                                <img
                                  src={custoArrowB}
                                  alt=""
                                  className="more-side"
                                />
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Sidebar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="head-mobile-view-m show-in-mobile-view">
          <div className="phone-header">
            <div className="profile">
              <div className="user-name">
                {loginInfo?.data?.un ? (
                  <h6>Hey, {loginInfo?.data?.un}</h6>
                ) : (
                  <h6>Hey !</h6>
                )}
              </div>
              {isMobile ? (
                <div className="city-dropdown show-in-mobile-view">
                  <Cities />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="side-icon">
              <div
                className="search-icon-m"
                onClick={() => {
                  navigate("/m.search");
                }}
              >
                <img src={searchm} alt="" className="img-fluid" />
              </div>
              <div className="search-icon-m">
                <div
                  onClick={() => {
                    if (token) {
                      navigate("/m.profile");
                    } else {
                      dispatch(setLoginViewMode({ value: true }));
                    }
                  }}
                >
                  <img src={user} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="">
        <Dialog
          header=""
          visible={loginVewMode}
          showHeader={false}
          showCloseIcon={false}
          onHide={() => dispatch(setLoginViewMode({ value: false }))}
          className="register-mobile"
          blockScroll={true}
        >
          <div className="row">
            <Login />
          </div>
        </Dialog>
      </div>
      <div>
        <Sidebar
        visible={visibleNotificatio}
        position="right"
        onHide={() => setVisibleNotificatio(false)}
        modal={false}
        >
          <h3 style={{color:"#000", fontSize:"18px",textAlign:"center",fontWeight:"600"}}>Notification Bell</h3>
        </Sidebar>
      </div>
    </>
  );
};

export default Navbar;
