import React, { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { DarkButton } from "src/styled-components/customButtons";
import axios from "axios";
import * as appConstants from "../../../../constants/index";
import { refraceTokenService } from "src/global-functions/refreshToken";
import swal from "sweetalert";
import angle_left from "../../../../assets/mobilemoviedetail/arrow-b.svg";

const ProfileContactUs = () => {
  const theme = localStorage.getItem("theme");
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  const [visibleRight, setVisibleRight] = useState(false);
  const [selectedType, setSelectedType] = useState({
    id: "1",
    name: "Feedback",
  });
  const [loading, setLoading] = useState(false);

  const contactTypeList = [
    { id: "1", name: "Feedback" },
    { id: "2", name: "Advertising/Corporate" },
    { id: "3", name: "Bulk Booking" },
  ];

  const mobileContactFormik = useFormik({
    initialValues: {
      mobile: "",
      email: "",
      message: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.mobile) {
        errors.mobile = "Please enter mobile number";
      }
      if (!data.email) {
        errors.email = "Please enter your email";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = "Invalid email address.";
      }
      if (!data.message) {
        errors.message = "Please enter your comment";
      } else if (data.message.length < 15) {
        errors.message = "Comment length must not less than 15 charaters";
      }

      // console.log("erroe", new Date(data?.dob)?.getTime()/1000000, new Date(data?.doa)?.getTime()/1000000, eighteenYearsInMillis);
      return errors;
    },
    onSubmit: (data) => {
      selectedType?.id === "1"
        ? postFeedback(data)
        : selectedType?.id === "2"
        ? postAdvertise(data)
        : swal({
          text: 'Bulk Booking is under maintenance',
          timer: 3000,
          icon: "info",
          buttons: false,
        });
      // updateProfieDetails(data);
      // console.log("formikData", data);
    },
  });
  const isMobileContactFormFieldValid = (name) =>
    !!(mobileContactFormik.touched[name] && mobileContactFormik.errors[name]);
  const getMobileConatctFormErrorMessage = (name) => {
    return (
      isMobileContactFormFieldValid(name) && (
        <small className="p-error">{mobileContactFormik.errors[name]}</small>
      )
    );
  };

  // ==== feed back request service ===========
  const postFeedback = (data) => {
    // console.log("selectedType", selectedType);
    setLoading(true);
    const postData = {
      mobile: data.mobile,
      email: data.email,
      name: loginInfo?.data?.un,
      type: selectedType.name,
      comments: data.message,
    };

    axios
      .post(`${appConstants.base_url}v1/web/feedback/add`, postData, {
        headers: appConstants.apiHeader,
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          // console.log("dt", res.data, dt);
          mobileContactFormik.resetForm();
          setLoading(false);
          swal({
            text: dt.msg,
            timer: 2000,
            icon: "success",
            buttons: false,
          });
          setVisibleRight(false);
        } else {
          setLoading(false);
          //swal(res.data.errors);
        }
      })
      .catch((error) => {
        setLoading(false);
        //console.log('error', error);
        const err = error?.response;
        if (err?.status === 403) {
          refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error?.message);
        }
      });
  };

  // ==== make advertise request services =====
  const postAdvertise = async (data) => {
    setLoading(true);
    const postData = {
      mobile: data.mobile,
      email: data.email,
      name: loginInfo?.data?.un,
      advertiseType: selectedType?.name?.split("/")[0],
      organisationType: selectedType?.name?.split("/")[1],
      location: localStorage.getItem("city"),
      comments: data.message,
    };
    await axios
      .post(`${appConstants.base_url}v1/web/advertise/add`, postData, {
        headers: appConstants.apiHeader,
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          setVisibleRight(false);
          setLoading(false);
          mobileContactFormik.resetForm();
          swal({
            text: dt.msg,
            timer: 2000,
            icon: "success",
            buttons: false,
          });
        } else {
          setLoading(false);
          // swal(res.data.msg);
        }
      })
      .catch((error) => {
        const err = error?.response;
        if (err?.status === 403) {
          refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error?.message);
        }
      });
  };

  return (
    <>
      <div
        className="profile-img border-line-profile border-none"
        onClick={() => {
          setVisibleRight(true);
          mobileContactFormik.setFieldValue("mobile", loginInfo?.data.mob);
          mobileContactFormik.setFieldValue("email", loginInfo?.data.em);
          setSelectedType({ id: "1", name: "Feedback" })
        }}
      >
        <div className="profile-text profile-account">
          <div className="profile-text-info">
            <h2>Contact Us</h2>
          </div>
        </div>
      </div>
      <div>
        <Sidebar
          visible={visibleRight}
          showCloseIcon={false}
          style={{ width: "100vw" }}
          className="contactus-mobileform"
          position="right"
          onHide={() => {
            setVisibleRight(false);
            mobileContactFormik.resetForm();
          }}
          dismissable={false}
          fullScreen
          blockScroll={true}
        >
          <>
            <div className="Account-view-show-mobile">
              <div
                className="account-angle-btn"
                onClick={() => {
                  setVisibleRight(false);
                  mobileContactFormik.resetForm();
                }}
              >
                <h4>
                <img src={angle_left} alt="" className="" />{" "} &nbsp; Contact Us
                </h4>
              </div>
            </div>
            <div className="luxe-divider"></div>
            <div className="Threetypeform-app">
              {contactTypeList?.map((item, idx) => (
                <div
                  className={`d-flex justify-content-between ${
                    selectedType?.id !== item?.id
                      ? "bg-light"
                      : theme === "inoxTheme"
                      ? "bg-light-inox border-dark-inox"
                      : "bg-light-pvr border-dark-pvr"
                  }  rounded bgset-app`}
                  key={idx}
                  onClick={() => {
                    setSelectedType(item);
                    mobileContactFormik.setFieldValue('message', '');
                  }}
                >
                  <span>{item.name}</span>
                  {selectedType?.id === item?.id ? (
                    <span
                      className={
                        theme === "inoxTheme"
                          ? "border rounded-circle-contact bg-dark-inox text-white"
                          : "border rounded-circle-contact bg-dark-pvr text-white"
                      }
                    >
                      <i className="pi pi-check" />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ))}
              <div></div>
              <div></div>
            </div>
            <div className="Allform-apps">
              <form onSubmit={mobileContactFormik.handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mobile">
                      <span className="p-float-label">
                        <InputText
                          id="mobile"
                          value={mobileContactFormik.values.mobile}
                          onChange={mobileContactFormik.handleChange}
                        />
                        <label htmlFor="ssn_input">Mobile No.</label>
                      </span>
                      <div>
                        <small className="text-muted">
                          We promise to keep your number safe
                        </small>
                      </div>
                      {getMobileConatctFormErrorMessage("mobile")}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mobile">
                      <span className="p-float-label">
                        <InputText
                          id="email"
                          value={mobileContactFormik.values.email}
                          onChange={mobileContactFormik.handleChange}
                        />
                        <label htmlFor="ssn_input">Email ID</label>
                      </span>
                      <div>
                        <small className="text-muted">
                          We promise to keep the noise low
                        </small>
                      </div>
                      {getMobileConatctFormErrorMessage("email")}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mobile">
                      <span className="p-float-label">
                        <InputTextarea
                          id="message"
                          value={mobileContactFormik.values.message}
                          onChange={mobileContactFormik.handleChange}
                          rows={3}
                          cols={30}
                        />
                        <label htmlFor="ssn_input">Personal Note</label>
                      </span>
                      <div>
                        <small className="text-muted">
                          Your message should have at least 15 characters
                        </small>
                      </div>
                      {getMobileConatctFormErrorMessage("message")}
                    </div>
                  </div>
                  <div className="col-md-6">
                    {selectedType?.id === "1" ? (
                      <div className="bg-light rounded p-2">
                        <span>You may directly reach out to us</span>
                        <div className="d-flex flex-column apps-contact-box">
                          <span>Email:</span>
                          <span>
                            {theme === "inoxTheme"
                              ? "contact@inoxmovies.com"
                              : "feedback@pvrcinemas.com"}
                          </span>
                        </div>
                        <div className="d-flex flex-column mt-3">
                          <span>Phone No.</span>
                          <span>
                            {theme === "inoxTheme"
                              ? "+91 8800900009"
                              : "+91 08800900009"}
                          </span>
                          <span className="text-muted">
                            9AM to 10PM, Mon-Sun
                          </span>
                        </div>
                        <div className="d-flex flex-column mt-3">
                          <span>WhatsApp:</span>
                          <span>
                            {theme === "q"
                              ? "+91 8800989898"
                              : "+91 8800989898"}
                          </span>
                          <span className="text-muted">
                            9AM to 7PM, Mon-Sun
                          </span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12">
                    <div className="edit-divider"></div>
                    <div className="mobile-edit-profile">
                      <div className="save-btn-edits">
                        <DarkButton
                          className={
                            loading
                              ? "mobile-save-btn-edit btn-opacity"
                              : "mobile-save-btn-edit"
                          }
                          type="submit"
                        >
                          Submit
                        </DarkButton>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </>
        </Sidebar>
      </div>
    </>
  );
};

export default ProfileContactUs;
