import React, { useState, useRef } from "react";
import axios from "axios";
import * as appConstants from "../../../constants/index";
import { useEffect } from "react";
import { Checkbox } from "primereact/checkbox";
import { DonateButton } from "../paymentTags";
import SweetAlert from "react-bootstrap-sweetalert";
import { refraceTokenService } from "../../../global-functions/refreshToken";
import { setRetryPayment } from "src/services/CouponPayment";
import { Sidebar } from "primereact/sidebar";
import { Divider } from "primereact/divider";

const NetBanking = (props) => {
  const formRef = useRef(null);
  const theme = localStorage.getItem("theme");
  const isMobile = window.matchMedia('(max-width: 575px)').matches;
  const [checked, setChecked] = useState(false);
  const [value6, setValue6] = useState(null);
  const [loading, setLoading] = useState(false);
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  const bookingid = localStorage.getItem("bookingid");
  const [netbankList, setNetbankList] = useState(null);
  const [hmacResponse, sethmacResponse] = useState(null);
  const [selectBank, setSelectBank] = useState(null);
  const [tncChecked, setTncChecked] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [bankName, setbankName] = useState("");
  const [showNtBankingTnC, setShowNtBankingTnC] = useState(false);

  const paymentOptions = parseInt(localStorage.getItem("paymentOptions") || 0);
  const gaTagData = JSON.parse(localStorage.getItem("gaTagData"));
  // console.log('netbankList', netbankList)

  useEffect(() => {
    // if(props.bankName){
    //   const filtebankName = netbankList?.filter((item)=>{
    //     return item.name.includes(props.bankName)
    //   })
    //   console.log('filtebankName', filtebankName)

    //   setbankName(filtebankName[0].code)
    // }
    // const filtebankName = netbankList?.filter((item)=>{
    //   return item.name.includes("ICICI")
    // })
    // console.log('filtebankName', filtebankName)

    //setbankName(filtebankName[0].code)
    getNetBankingList1();
    console.log(props);
    initJuspay();
  }, []);

  const initJuspay = async () => {
    console.log("working");
    var juspay_form = Juspay.Setup({
      payment_form: "#payment_form_NB",
      success_handler: function (status) {
        console.log(status);
      },
      error_handler: function (
        error_code,
        error_message,
        bank_error_code,
        bank_error_message,
        gateway_id
      ) {
        console.log("error", error_code, error_message);
      },
    });
  };

  const gethmac = async () => {
    if (!bankName && !props.bankName) {
      setErrorMsg("Please select bank!");
      setShowAlert(true);
      return "";
    }
    setLoading(true);
    const postData = {
      transId: localStorage.getItem("transid"),
      bookingId: localStorage.getItem("bookingid"),
      bookType: localStorage.getItem("booking_type"),
      paytype: "NB",
      pgtype: "JUSPAY",
    };
    const vouDesc = localStorage.getItem("vouDesc") || "";
    const vouId = localStorage.getItem("vouId") || "";
    const discValue = localStorage.getItem("discValue") || "";
    const validity = localStorage.getItem("validity") || "";
    const itemCategory5 = `${vouDesc}, ${vouId}, ${discValue}, ${validity}`;

    await axios
      .post(`${appConstants.base_url}v1/payment/juspay/hmac`, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        //setLoading(false);
        const dt = res.data;
        if (dt.result === "success") {
          localStorage.setItem("pgused", "NETBANKING");
          const url = window.location.href;

          localStorage.setItem(
            "cinema_name",
            url.split("/").pop().replaceAll("%20", " ")
          );
          window.dataLayer = window.dataLayer || [];

          if (localStorage.getItem("booking_type") == "GIFTCARD") {
            window.dataLayer.push({
              event: "gift_card_add_payment_info",
              'item_brand': appConstants.cinemaChain,
              'item_category': 'Gift Card', // this will be static
              'item_category2': localStorage.getItem("city"),
              'item_category3': new Date().toISOString().split('T')[0],
              'gift_card_type': localStorage.getItem("giftCardDatatype"),
              'gift_card_amount': 'Gift Card amount will be dynamic',
              'gift_card_amount': localStorage.getItem('giftcardamount'),
              'gift_card_payment_type': 'NETBANKING'

            });
          }
          else {
            let item_category = '';
            if (localStorage.getItem("tickettype") && localStorage.getItem("tickettype") === "food") {
              item_category = 'ticket,f&b';
            } else
              item_category = 'ticket' + "," + vouDesc;
            paymentOptions === 1
              ?
              window.dataLayer.push({
                event: 'add_payment_info',
                ecommerce: {
                  currency: 'INR',
                  value: Number(res.data.output.amount),
                  affiliation: 'NETBANKING',
                  items: [{
                    item_brand: appConstants.cinemaChain,
                    item_name: gaTagData?.movieName || false,
                    item_id: gaTagData?.movieId || false,
                    item_category: localStorage.getItem("booking_type") || "FOOD",
                    item_category2: gaTagData?.theaterName || false,
                    item_category3: gaTagData?.selectedDate ? `${gaTagData?.selectedDate} / ${gaTagData?.selectedSeats || ""}` : format(new Date(), "yyyy-MM-dd hh:mm a"),
                    item_category4: gaTagData?.genre && gaTagData?.languages ? `${gaTagData?.genre} / ${gaTagData?.languages}` : false,
                    item_category5: false,
                    item_variant: gaTagData?.variant || false,
                    quantity: gaTagData?.qty || false,
                    price: Number(res.data.output.amount),
                  }]
                }
              })
              :
              window.dataLayer.push({
                event: 'add_payment_info',
                ecommerce: {
                  currency: 'INR',
                  value: Number(res.data.output.amount),
                  affiliation: 'NETBANKING',
                  items: [{
                    item_brand: appConstants.cinemaChain,
                    item_name: localStorage.getItem('movie_name'),
                    item_id: localStorage.getItem('movieid'),
                    item_category: item_category,
                    item_category2: localStorage.getItem('cinema_name') + "/" + localStorage.getItem('city'),
                    item_category3: localStorage.getItem('selecteddate') + "," + localStorage.getItem('selectedseats'),                      //( selected date & seat number)
                    item_category4: localStorage.getItem('genre'),
                    item_category5: itemCategory5,
                    item_variant: localStorage.getItem('format'),
                    quantity: localStorage.getItem('quantity'),
                    price: Number(res.data.output.amount),
                  }]
                }
              });
          }
          // setTicketSummary(ndt);
          //document.getElementById("payment_form_NB").submit();
          setRetryPayment(
            props.payMode,
            "retry-NETBANKING" + localStorage.getItem("bookingid")
          );
          // let name = "retrymethod"+dt.output.paymentId;
          // let retryPayment = localStorage.getItem(name);
          // let parsedData;
          // if(retryPayment){
          //   parsedData = JSON.parse(retryPayment);
          //   parsedData.retrycommit = parsedData.retrycommit+1;
          // } else{
          //   let sendingdata = props.payMode;
          //   sendingdata.retrycommit = 1
          //   parsedData = sendingdata;
          // }
          //localStorage.setItem(name, JSON.stringify(parsedData));
          localStorage.setItem("paymentid", dt.output.paymentId);
          localStorage.setItem("payment_type", "nonwallet");
          console.log("bankList==>", dt);
          formRef.current.click();
        } else {
          setErrorMsg(res.data.msg);
          setShowAlert(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        setErrorMsg(err);
        setShowAlert(true);
        console.log("error", err);
        setLoading(false);
      });
  };

  const getNetBankingList1 = async () => {
    setLoading(true);
    const postData = {};

    // console.log("postData", postData)

    await axios
      .post(`${appConstants.base_url}v1/payment/juspay/banks`, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        setLoading(false);
        const dt = res.data;
        if (dt.result === "success") {
          const ndt = dt.output.nb;
          const ndt1 = ndt.map((item) => {
            return {
              name: item.description,
              code: {
                payment_method: item.payment_method,
                payment_method_type: item.payment_method_type,
              },
            };
          });
          setNetbankList(ndt1);
          // setTicketSummary(ndt);

          console.log(props.bankName);
          if (props.bankName) {
            console.log("bankname", props.bankName, ndt1);

            const filtebankName = ndt1?.filter((item) => {
              return item.name.includes(props.bankName);
            });
            console.log(
              "filtebankName",
              filtebankName[0].code.payment_method,
              filtebankName
            );

            setbankName(
              filtebankName ? filtebankName[0].code.payment_method : ""
            );
            console.log(bankName);
            setTimeout(() => {
              gethmac();
            }, 2000);
          }
        } else {
          console.log(res.data.msg);
        }
      })
      .catch((error) => {
        const err = error?.response;
        if (err?.status === 403) {
          refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error?.message);
        }
        setLoading(false);
      });
    // .catch((err) => {
    //   console.log("error", err);
    //   setLoading(false);
    // });
  };

  return (
    <>
      <SweetAlert
        error
        show={showAlert}
        title={errorMsg}
        onConfirm={() => {
          setShowAlert(false);
          // console.log('confirm')
          //navigate('/')
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnCssClass={
          theme === "inoxTheme"
            ? "confirm-btn-inox border-0"
            : "confirm-btn-pvr border-0"
        }
      >
        {errorMsg}
      </SweetAlert>
      <div className="">
        <div className="payment-form">
          <div className="paywithdebit">
            <div className="debit-card show-in-desktop-view">
              <h6>Net Banking</h6>
            </div>
            <div className="to-paid">
              {/* <p>
                To be Paid:
                <span className="price-cross">₹8888</span>{" "}
                <span className="price-blod">₹8888</span>
              </p> */}
            </div>
          </div>
          <div className="payment-netbanking">
            <form className="juspay_inline_form" id="payment_form_NB">
              <input
                type="hidden"
                className="merchant_id"
                value={props.juspayinit ? props.juspayinit.merchant_id : ""}
              />
              <input type="hidden" className="order_id" value={bookingid} />
              <input type="hidden" className="payment_method_type" value="NB" />
              <div className="row">
                <div className="col-lg-8">
                  <select
                    className="payment_method w-100"
                    value={bankName}
                    onChange={(e) => {
                      console.log("e.target.value", e.target.value);
                      setbankName(e.target.value);
                    }}
                  >
                    <option value="" disabled selected>
                      Select Bank
                    </option>
                    {netbankList?.map((item) => (
                      <option
                        value={item.code.payment_method}
                        label={item.name}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-4">
                  <DonateButton
                    ref={formRef}
                    type="submit"
                    className="make_payment"
                    style={{ display: "none" }}
                  >
                    Pay
                  </DonateButton>
                  <DonateButton
                    className={
                      checked
                        ? "btn-proceeds make_payment"
                        : "btn-proceeds make_payment btn-opacity"
                    }
                    type="button"
                    onClick={!loading ? gethmac : ""}
                  // className="make_payment"
                  >
                    Pay
                    {loading ? (
                      <i
                        className="pi pi-spin pi-spinner"
                        style={{ fontSize: "1.5em" }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </DonateButton>
                </div>
              </div>
              <input type="hidden" className="redirect" value="true" />
              <input type="hidden" className="payment_channel" value="WEB" />
            </form>
            <div className="terms-payment">
              <div
                className={
                  theme === "inoxTheme"
                    ? " check-payment-inox"
                    : " check-payment-pvr"
                }
              >
                <Checkbox
                  inputId="binary"
                  checked={checked}
                  onChange={(e) => setChecked(e.checked)}
                />
              </div>
              <div className="terms-condition-payment">
                <p>
                  I Have Read And Accepted The PVR INOX{" "}
                  <u className="pointer" onClick={() => setShowNtBankingTnC(true)}>
                    <b>Terms & Conditions</b>{' '}
                  </u>
                  of this Transaction
                </p>
              </div>
            </div>
            {/* <form className="juspay_inline_form" id="payment_form_NB">
              <div className="upi-flow">
                <div>
                  <Dropdown
                    id="bankName"
                    value={selectBank}
                    options={netbankList}
                    onChange={(e) => {
                      setSelectBank(e.value);
                      console.log("val", e.value);
                    }}
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Select bank"
                    className="w-100"
                  />
                </div>
                <div className="field-checkbox d-flex mt-3">
                  <Checkbox
                    id="tncCheck"
                    inputId="binary"
                    checked={tncChecked}
                    onChange={(e) => setTncChecked(e.checked)}
                  />
                  <label htmlFor="tncCheck">
                    I Have Read And Accepted The PVR{" "}
                    <span className="text-primary">Terms & Conditions</span> Of
                    This Transaction
                  </label>
                </div>
                <div>
                  <DonateButton
                    className={
                      tncChecked
                        ? "px-3 py-2 rounded"
                        : "px-3 py-2 rounded btn-opacity"
                    }
                    //  onClick={()=>{}}
                    disabled={tncChecked ? false : true}
                  >
                    Submit
                  </DonateButton>
                </div>
                {netbankList &&
                netbankList?.map((item, idx) => {
                  return (
                    <>
                    <div className="bank-list" key={idx}>
                    <div className="bank-name">
                    <h6>{item?.description}</h6>
                    </div>
                    <div className="bank-arrow">
                    <img src={arrowgrey} alt="" className="img-fluid" />
                    </div>
                    </div>
                    <div className="bank-divider"></div>
                    </>
                    );
                  })}
              </div>
            </form> */}
          </div>
        </div>
      </div>
      <div>
        <Sidebar
          visible={showNtBankingTnC}
          showCloseIcon={false}
          style={isMobile ? { height: "60vh" } : { width: "20vw" }}
          position={isMobile ? 'bottom' : "right"}
          onHide={() => {
            setShowNtBankingTnC(false);
          }}
        //  dismissable={false}
        >
          <div className="row">
            <div className="d-flex justify-content-between">
              <h3 className="net-terms">Terms & Conditions</h3>
              <span className="pointer" onClick={() => setShowNtBankingTnC(false)}><i className="pi pi-times" /> </span>
            </div>
            <Divider style={{ marginTop: "0px" }} />
            <p className="net-term">TnC</p>
          </div>
        </Sidebar>
      </div>
    </>
  );
};

export default NetBanking;
