import axios from "axios";
import React, { useEffect, useState } from "react";
import * as appConstants from "../../../constants/index";
import "./giftcardSummary.css";
import { Sidebar } from "primereact/sidebar";
import gift from "../../../assets/gift/gift.png";
import { FaRupeeSign } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setGrandTotalamount } from "src/redux/actions/gradTotalAction";

const GiftCardSummary = ({discount, removeApply, applyData}) => {
  const dispatch = useDispatch();
  const theme = localStorage.getItem("theme");
  const bookingType = localStorage.getItem("booking_type");
  const getdata = JSON.parse(localStorage.getItem('passportOrder'));
  const [discountData, setdiscountData] = useState(null);
  const [giftCardSummary, setGiftCardSummary] = useState(null);
  const [tnCVisibleRight, setTnCVisibleRight] = useState(false);
  const [loading, setLoading] = useState(false);
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));

  const removevoucher = async () => {
    removeApply();
  };

  useEffect(() => {
    if(bookingType == "PASSPORT") {
      dispatch(
        setGrandTotalamount({
          cutPrice: Number(
            getdata?.discount ? getdata?.discount : 0
          ),
          finalPrice: Number(getdata?.finalPrice.replace(/,/g, '')),
        })
      );
    }
    else getGiftCardSummary();
  }, []);

  
  useEffect(() => {
    // console.log("Ticket Summary", discount, JSON.parse(discount));
    if(discount) {
      let discountData = JSON.parse(discount); 
      setdiscountData(discountData);
      if(discountData?.ft){
        // getTicketSummaryData();
        dispatch(setGrandTotalamount({ cutPrice: Number(discountData?.it.replace(/,/g, '')), finalPrice: Number(discountData?.ft.replace(/,/g, '')) }));
      }
    } 
    
  }, [discount]);
  // ===== save gift card service ===========
  const getGiftCardSummary = async () => {
    setLoading(true);

    const postData = {
      orderId: localStorage.getItem("bookingid"),
    };

    // console.log("postData", postData);
    try {
      await axios
        .post(`${appConstants.base_url}v1/giftcard/summary`, postData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        })
        .then((resp) => {
          const dt = resp.data;
          if (dt.result === "success") {
            const ndt = dt?.output;
            setGiftCardSummary(ndt);
            setLoading(false);
            // localStorage.setItem("giftcard_amount", ndt?.finalPrice);
            localStorage.setItem("giftcard_tnc", ndt?.tnc);
            dispatch(
              setGrandTotalamount({
                cutPrice: Number(
                  ndt?.finalPrice != ndt?.totalPrice ? ndt?.totalPrice : 0
                ),
                finalPrice: Number(ndt?.finalPrice.replace(/,/g, '')),
              })
            );
            // console.log("saveGiftCard", ndt);
          }
        });
    } catch (err) {
      console.error(err);
      setLoading(true);
    }
  };
  return (
    bookingType == "PASSPORT"?
    <>
      <div className="container">
        <div className="order-summary mt-3 show-in-desktop-view">
          <h4>Order Summary</h4>
        </div>
        <div className="enter-amount summary-mobile-bg">
          <div className="recpient">
            <h6>Recipient Details</h6>
          </div>
          <div className="recpet-details">
            <div className="recepit-name">
              <h6>Mobile No.</h6>
            </div>
            <div className="recepit-user">
              <h6>{loginInfo?.data?.mob}</h6>
            </div>
          </div>
          <div className="recpet-details">
            <div className="recepit-name">
              <h6>Email ID</h6>
            </div>
            <div className="recepit-user">
              <h6>{loginInfo?.data?.em}</h6>
            </div>
          </div>
        </div>
        {discountData && discountData?.ft && false ? 
          <>         
                <div className="applied-info">
                  <p><span><b>{applyData?.voucheName}</b></span> Applied</p>
                  <div className="save-remove">
                    <div className="saved-info"><p><FaRupeeSign style={{fontSize:"10px", fontWeight:"500", marginBottom:"2px"}}/>{discountData?.di} Saved</p></div>
                    <div className="remove-text pointer" onClick={() => {
                          removevoucher();
                        }}><p>Remove</p></div>
                    
                  </div>
                </div>
          </>:""}
        <div className="shoow-coupon">
          <div className=" Summary-sub-total">
            <div className="sub-view">
              <div className="sub-total">
                <h6>Sub Total</h6>
              </div>
              <div className="sub-price">
                <h6>
                  <FaRupeeSign
                    style={{
                      fontSize: "10px",
                      fontWeight: "500",
                      marginBottom: "2px",
                    }}
                  />
                  {getdata?.finalPrice}
                </h6>
              </div>
            </div>
            <div className="dashed-line"></div>
            {getdata?.discount &&
            Number(getdata?.discount) > 0 ? (
              <div className="sub-view rudec-view">
                <div className="sub-total">
                  <h6>Discount</h6>
                </div>
                <div className="sub-price">
                  <h6 className="disc-offer">
                    -
                    <FaRupeeSign
                      style={{
                        fontSize: "10px",
                        fontWeight: "500",
                        marginBottom: "2px",
                      }}
                    />
                    {getdata?.discount}
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            {discountData && discountData?.ft  ? (
              <div className="sub-view rudec-view">
                <div className="sub-total">
                  <h6>Discount</h6>
                </div>
                <div className="sub-price">
                  <h6 className="disc-offer">
                    -
                    <FaRupeeSign
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        marginBottom: "2px",
                      }}
                    />
                    {discountData?.di}
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="divider-coupon"></div>
            <div className="tobepaid">
              <div className="grand-total-head">
                <h6>Total</h6>
              </div>
              <div className="coupon-grand-price ">
                <h6>
                  {discountData && discountData?.it ? (
                    <span className="price-cut">
                      <FaRupeeSign
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          marginBottom: "2px",
                        }}
                      />
                      {discountData?.it}
                    </span>
                  ) : (
                    ""
                  )}{" "}
                  <FaRupeeSign
                    style={{
                      fontSize: "10px",
                      fontWeight: "500",
                      marginBottom: "2px",
                    }}
                  />
                  {discountData?.ft?discountData?.ft:getdata?.finalPrice}
                </h6>
              </div>
            </div>
            <div className="divider-coupon show-in-desktop-view"></div>
          </div>
          <div className="divider-coupon"></div>
          <div className="coupon-grand show-in-desktop-view">
            <div className="grand-total-head">
              <p>To be Paid</p>
              <h6 className="price-lastest">
                <span className="rupees-icon">
                  <FaRupeeSign
                    style={{
                      fontSize: "10px",
                      fontWeight: "500",
                      marginBottom: "2px",
                    }}
                  />
                </span>
                {discountData?.ft?discountData?.ft:getdata?.finalPrice}
              </h6>
            </div>
            {/* <div className="coupon-grand-price">
              <ProceedButton
                className={tncChecked ? "pay-proce" : "pay-proce btn-opacity"}
                // onClick={() => console.log("Proceed Button Clicked")}
                disabled={tncChecked ? false : true}
              >
                Proceed to Pay
              </ProceedButton>
            </div> */}
          </div>
        </div>
      </div>
      <div className="gift-coupon-conditions">
        <Sidebar
          visible={tnCVisibleRight}
          position="right"
          onHide={() => setTnCVisibleRight(false)}
          showCloseIcon={false}
          // dismissable={false}
          className="gift-coupon-conditions"
        >
          <div className="terms-gift">
            <div className="cities-show terms-spaces">
              <div className="select-city">
                <h4>Terms & Conditions</h4>
              </div>
              <div className="colse-btn">
                <span onClick={() => setTnCVisibleRight(false)}>
                  <i
                    className="pi pi-times"
                    style={{ color: "#000", cursor: "pointer" }}
                  ></i>
                </span>
              </div>
            </div>
            <div className="divider-coupon"></div>
            <div className="terms-content">
              <p>{giftCardSummary?.tnc} </p>
            </div>
            <div className="divider-coupon"></div>
          </div>
        </Sidebar>
      </div>
    </>
    :<>
      <div className="container">
        <div className="row show-in-mobile-view">
          <div className="col-md-12">
            <span className="coupon-photo-summary">
              <img
                src={giftCardSummary?.img ? giftCardSummary?.img : gift}
                alt=""
                className="img-fluid"
              />
            </span>
          </div>
        </div>
        <div className="order-summary mt-3 show-in-desktop-view">
          <h4>Order Summary</h4>
        </div>
        <div className="enter-amount summary-mobile-bg">
          <div className="recpient">
            <h6>Recipient Details</h6>
          </div>
          <div className="recpet-details">
            <div className="recepit-name">
              <h6>Name</h6>
            </div>
            <div className="recepit-user">
              <h6>{giftCardSummary?.rn}</h6>
            </div>
          </div>
          <div className="recpet-details">
            <div className="recepit-name">
              <h6>Mobile No.</h6>
            </div>
            <div className="recepit-user">
              <h6>{giftCardSummary?.rm}</h6>
            </div>
          </div>
          <div className="recpet-details">
            <div className="recepit-name">
              <h6>Email ID</h6>
            </div>
            <div className="recepit-user">
              <h6>{giftCardSummary?.re}</h6>
            </div>
          </div>
        </div>
        <div className="shoow-coupon">
          <div className=" Summary-sub-total">
            <div className="sub-view">
              <div className="sub-total">
                <h6>Sub Total</h6>
              </div>
              <div className="sub-price">
                <h6>
                  <FaRupeeSign
                    style={{
                      fontSize: "10px",
                      fontWeight: "500",
                      marginBottom: "2px",
                    }}
                  />
                  {giftCardSummary?.finalPrice}
                </h6>
              </div>
            </div>
            <div className="divider-coupon value-line show-in-desktop-view"></div>
            {giftCardSummary?.denominations?.map((item, idx) => {
              return (
                <div className="customies-coupon" key={idx}>
                  <div className="coupon-shows">
                    <span className="coupon-photo">
                      <img
                        src={giftCardSummary?.img ? giftCardSummary?.img : gift}
                        alt=""
                        className="img-fluid"
                      />
                    </span>
                    <div className="coupon-discounts">
                      <h6 className="custom">
                        {item.quantity} x {item.denomination}
                      </h6>
                    </div>
                  </div>
                  <div className="coupon-cart">
                    <h5>
                      <FaRupeeSign
                        style={{
                          fontSize: "10px",
                          fontWeight: "500",
                          marginBottom: "2px",
                        }}
                      />
                      {item.giftcardPrice}
                    </h5>
                  </div>
                </div>
              );
            })}
            <div className="dashed-line"></div>
            {giftCardSummary?.discount &&
            Number(giftCardSummary?.discount) > 0 ? (
              <div className="sub-view rudec-view">
                <div className="sub-total">
                  <h6>Discount</h6>
                </div>
                <div className="sub-price">
                  <h6 className="disc-offer">
                    -
                    <FaRupeeSign
                      style={{
                        fontSize: "10px",
                        fontWeight: "500",
                        marginBottom: "2px",
                      }}
                    />
                    {giftCardSummary?.discount}
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="divider-coupon"></div>
            <div className="tobepaid">
              <div className="grand-total-head">
                <h6>To be Paid</h6>
              </div>
              <div className="coupon-grand-price ">
                <h6>
                  {giftCardSummary?.discount &&
                  Number(giftCardSummary?.discount) > 0 ? (
                    <span className="price-cut">
                      <FaRupeeSign
                        style={{
                          fontSize: "10px",
                          fontWeight: "500",
                          marginBottom: "2px",
                        }}
                      />
                      {giftCardSummary?.totalPrice}
                    </span>
                  ) : (
                    ""
                  )}{" "}
                  <FaRupeeSign
                    style={{
                      fontSize: "10px",
                      fontWeight: "500",
                      marginBottom: "2px",
                    }}
                  />
                  {giftCardSummary?.finalPrice}
                </h6>
              </div>
            </div>
            <div className="divider-coupon show-in-desktop-view"></div>
          </div>
          <div className="divider-coupon"></div>
          <div className="coupon-grand show-in-desktop-view">
            <div className="grand-total-head">
              <p>Total</p>
              <h6 className="price-lastest">
                <span className="rupees-icon">
                  <FaRupeeSign
                    style={{
                      fontSize: "10px",
                      fontWeight: "500",
                      marginBottom: "2px",
                    }}
                  />
                </span>
                {giftCardSummary?.finalPrice}
              </h6>
            </div>
            {/* <div className="coupon-grand-price">
              <ProceedButton
                className={tncChecked ? "pay-proce" : "pay-proce btn-opacity"}
                // onClick={() => console.log("Proceed Button Clicked")}
                disabled={tncChecked ? false : true}
              >
                Proceed to Pay
              </ProceedButton>
            </div> */}
          </div>
        </div>
      </div>
      <div className="gift-coupon-conditions">
        <Sidebar
          visible={tnCVisibleRight}
          position="right"
          onHide={() => setTnCVisibleRight(false)}
          showCloseIcon={false}
          // dismissable={false}
          className="gift-coupon-conditions"
        >
          <div className="terms-gift">
            <div className="cities-show terms-spaces">
              <div className="select-city">
                <h4>Terms & Conditions</h4>
              </div>
              <div className="colse-btn">
                <span onClick={() => setTnCVisibleRight(false)}>
                  <i
                    className="pi pi-times"
                    style={{ color: "#000", cursor: "pointer" }}
                  ></i>
                </span>
              </div>
            </div>
            <div className="divider-coupon"></div>
            <div className="terms-content">
              <p
                dangerouslySetInnerHTML={{
                  __html: giftCardSummary?.tnc,
                }}
                className="px-1"
              ></p>
            </div>
            <div className="divider-coupon"></div>
          </div>
        </Sidebar>
      </div>
    </>
  );
};

export default GiftCardSummary;
