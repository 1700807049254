import React, { useEffect, useState, useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import user from "../../../assets//nav-icon/user.svg";
import qrm from "../../../assets/nav-icon/qr-code.svg";
import { Divider } from "primereact/divider";
import avtar from "../../../assets/profile/avtar.svg";
import offer from "../../../assets/profile/offer.svg";
import arrow from "../../../assets/profile/navigatenext.svg";
import info from "../../../assets/profile/info.svg";
import booking from "../../../assets/profile/booking.svg"; 
import "../mobile-profile/mprofile.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoginViewMode } from "src/redux/actions/loginViewAction";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import * as appConstants from "../../../constants/index";
import angle_left from "../../../assets/mobilemoviedetail/arrow-b.svg";
// import MobileProfilePersonalDetails from '../mobile-profile/mobile-profile-pages/mobileProfilePersonalDetails'
import ProfilePersonalDeatils from "./mobile-profile-pages/ProfilePersonalDeatils";
import ProfileMyBooking from "./mobile-profile-pages/ProfileMyBooking";
import ProfileMovieAlert from "./mobile-profile-pages/ProfileMovieAlert";
import ProfilePromotionalAlert from "./mobile-profile-pages/ProfilePromotionalAlert";
import whatsappProfile from "../../../assets/profile/whatsapp-line 1.svg";
import ProfileTncPrivacy from "./mobile-profile-pages/ProfileTncPrivacy";
import ProfileContactUs from "./mobile-profile-pages/ProfileContactUs";
import ProfileFavouriteCinemas from "./mobile-profile-pages/ProfileFavouriteCinemas";
import ProfileSavedCard from "./mobile-profile-pages/ProfileSavedCard";
import ProfileGiftCardBalance from "./mobile-profile-pages/ProfileGiftCardBalance";
import { getSplashData } from "src/services/OtherServices";
const MobileProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = localStorage.getItem("theme");
  const token = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  const [showMsg, setShowMsg] = useState(null);
  const [value1, setValue1] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const interval = useRef(null);
  const [loading, setLoading] = useState(false);
  //const [whatsNotification, setWhatsNotification] = useState(false);
  const currentPath = window.location.pathname.split("/");
  const [showPromotions, setShowPromotions] = useState(false);
  useEffect(() => {
    getSplashData()
      .then((res) => {
        const dt = res.data;

        if (dt.result === "success") {
          // Check if promotions is true and update state
          if (dt.output.promotions === "true") {
            setShowPromotions(true);
          } else {
            setShowPromotions(false);
          }
        } else {
          console.error("API call failed");
          setShowPromotions(false);
        }
      })
      .catch((err) => {
        console.log("Error fetching data:", err);
        setShowPromotions(false); // Ensure fallback
      });
  }, []);

  useEffect(() => {
    let val = value1;
    interval.current = setInterval(() => {
      val += Math.floor(Math.random() * 10) + 1;

      if (val >= 20) {
        val = 20;
        clearInterval(interval.current);
      }

      setValue1(val);
    }, 2000);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (currentPath[1] === "m.profile") {
      whatsappCheckbox();
    }
  }, []);

  const logoutService = async () => {
    setShowAlert(true);
    setShowMsg({ title: "Success", msg: "You are logged out successfully" });
    localStorage.removeItem("loginInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("refrace_token");
    setTimeout(() => {
      navigate("/");
    }, 2000);
    setVisible(false);
  };

  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };

  const whatsappCheckbox = async () => {
    const postData = {
      email: "",
      mobile: loginInfo?.data?.mob,
      name: "",
      otp: "",
      city: "",
      refreshToken: "",
      type: "",
      reason: "",
    };
    
    try {
      await axios
        .post(`${appConstants.base_url}v1/user/optin/check`, postData, {
          headers: {
            Authorization: `Bearer ${token}`,
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        })
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setWhatsNotification(dt.output);
          } else {
            setLoading(false);
            
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const changeNotification = (e) => {
    if (e.target.checked === true) {
      hitGa("whatsapp_notification_alert_on")
      trueCheckbox();
    } else {
      hitGa("whatsapp_notification_alert_off")

      falseCheckbox();
    }
  };
  const trueCheckbox = async () => {
    const postData = {
      email: "",
      mobile: loginInfo?.data?.mob,
      name: "",
      otp: "",
      city: "",
      refreshToken: "",
      type: "",
      reason: "",
    };
    console.log("post data", postData);
    try {
      await axios
        .post(`${appConstants.base_url}v1/user/optin`, postData, {
          headers: {
            Authorization: `Bearer ${token}`,
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        })
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setWhatsNotification(dt.output);
          } else {
            setLoading(false);
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const falseCheckbox = async () => {
    const postData = {
      email: "",
      mobile: loginInfo?.data?.mob,
      name: "",
      otp: "",
      city: "",
      refreshToken: "",
      type: "",
      reason: "",
    };
    console.log("post data", postData);
    try {
      await axios
        .post(`${appConstants.base_url}v1/user/optout`, postData, {
          headers: {
            Authorization: `Bearer ${token}`,
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        })
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setWhatsNotification(dt.output);
          } else {
            setLoading(false);
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="show-mobile-view ">
        <div className="Account-view-show-mobile">
          <div className="account-angle-btn" onClick={() => navigate("/")}>
            <h4>
            <img src={angle_left} alt="" className="" />{" "} &nbsp; Account
            </h4>
          </div>
          <div className="">
            {/* <img src={qrm} alt="" className="img-fluid" width={25} /> */}
          </div>
        </div>
        <Divider style={{ marginTop: "3px" }} />
        <div className="profile-mobile-box-spaces">
          <ProfilePersonalDeatils />
          <div className="profile-username plr-0">
            <div className="offer-border mt-20"></div>
            <ProfileMyBooking />
            <ProfileMovieAlert />
            <ProfileFavouriteCinemas />
            {showPromotions && <ProfilePromotionalAlert />}
            <ProfileSavedCard />
            <ProfileGiftCardBalance/>

            {/* <div className="profile-img border-line-profile">
              <div className="profile-text profile-account">
                <h4>
                  <span className="avtar">
                    <img src={booking} alt="" className="" />
                  </span>
                </h4>
                <div className="profile-text-info">
                  <h2>My Preferences</h2>
                </div>
              </div>
              <div className="img-arrow">
                <img src={arrow} alt="" className="" />
              </div>
            </div> */}

            {/* <div className="profile-img border-line-profile border-none">
              <div className="profile-text profile-account">
                <h4>
                  <span className="avtar">
                    <img src={whatsappProfile} alt="" className="" />
                  </span>
                </h4>
                <div className="profile-text-info">
                  <h2>Whatsapp Notifications</h2>
                </div>
              </div>
              <div className="img-arrow toogle-checkbox">
                <div
                  className={
                    theme === "inoxTheme"
                      ? "mobile-alert-btn-inox"
                      : "mobile-alert-btn-pvr"
                  }
                >
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={whatsNotification}
                      onChange={changeNotification}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div> */}
            <div className="offer-border "></div>
            <ProfileTncPrivacy />
            <div className="luxe-divider"></div>
            <ProfileContactUs />
            <div className="luxe-divider"></div>
            <div className="profile-img border-line-profile border-none">
              <div className="profile-text profile-account">
                <div
                  className="profile-text-info"
                  onClick={() => {
                    logoutService();
                    hitGa("log_out");
                  }}
                >
                  <h2 className="logout-text">Log Out</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <SweetAlert
          success
          show={showAlert}
          title={showMsg?.title}
          onConfirm={() => {
            setShowAlert(false);
            // console.log('confirm')
          }}
          onCancel={() => setShowAlert(false)}
          confirmBtnCssClass={
            theme === "inoxTheme"
              ? "confirm-btn-inox border-0"
              : "confirm-btn-pvr border-0"
          }
          showCancel={false}
          showConfirm={true}
          timeout={2000}
        >
          {showMsg?.msg}
        </SweetAlert>
      </div>
    </>
  );
};
export default MobileProfile;
