import React, { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import offer from "../../../assets/payment/offercard.png";
import offerArrow from "../../../assets/payment/offer-card-arrow.svg";
import offerDetail from "../../../assets/payment/offer-details.png";
import BankPhoto from "../../../assets/payment/bankphoto.png";
import { DarkButton } from "src/styled-components/customButtons";
import { InputText } from "primereact/inputtext";
import { applycoupon } from "src/services/CouponPayment";
import SweetAlert from "react-bootstrap-sweetalert";
import mih_pvr from "../../../assets/default-images/horizontal-poster-pvr.svg";
import mih_inox from "../../../assets/default-images/horizontal-poster-inox.png";
import miv_pvr from "../../../assets/default-images/vertical-poster-pvr.svg";
import miv_inox from "../../../assets/default-images/vertical-poster-inox.png";
import BankInfo from "../../../assets/payment/bank-offer-info.svg";
import { TabView, TabPanel } from "primereact/tabview";

const BankOffers = ({
  offerType,
  payMode,
  imgUrl,
  viewMode,
  sendDataForPayment,
}) => {
  const theme = localStorage.getItem("theme");
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  const [visibleRight, setVisibleRight] = useState(false);
  const [offerViewMode, setOfferViewMode] = useState(0);
  const [bankOfferViewMode, setBankOfferViewMode] = useState(0);
  const [offerData, setOfferData] = useState({});
  const [cardNum, setCardNum] = useState(null);
  const [couponVal, setCouponVal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [cardViewMode, setCardViewMode] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showTncDeatil, setShowTncDeatil] = useState(false);
  const [selectedBankName, setSelectedBankName] = useState("All Banks");
  
	const transactionType = localStorage.getItem("TransactionType");
  const bankNameList0 = payMode?.bankoffers?.map((item) => {
    return item?.bank;
  });
  const bankNameList = ["All Banks", ...new Set(bankNameList0)];
  const cardNameList0 = payMode?.bankoffers?.map((item) => {
    return item?.discAppPayType;
  });
  const [cardNameList, setBankNameList] = useState([
    "All Offers",
    ...new Set(cardNameList0),
  ]);
  // const cardNameList = ;
  const [filteredPayModeList, setFilteredPayModeList] = useState(
    payMode?.bankoffers
  );
  const [filteredPayModeListByCard, setFilteredPayModeListBycard] = useState(
    payMode?.bankoffers
  );
  // console.log("cardNameList", cardNameList);
  // console.log("offerData", offerData);

  useEffect(() => {
    offerData?.bankVouTypeSub?.includes("Credit Card")
      ? setCardViewMode(0)
      : offerData?.bankVouTypeSub?.includes("Debit Card")
      ? setCardViewMode(1)
      : "";
  }, [offerData]);

  const getApply = async (couponId, isNumber, netbanking = false) => {
    //console.log(offerViewMode);
    let type;
    if (viewMode === 2) {
      type = "BANKOFFER";
      if (!cardNum && !netbanking) {
        setErrorMsg("Please enter card Number");
        setShowAlert(true);
        return "";
      }
    } else {
      type = "VOUCHER";
      if (isNumber) {
        if (!couponVal) {
          setErrorMsg("Please enter voucher code");
          setShowAlert(true);
          return "";
        }
      }
    }
    setLoading(true);
    const postData = {
      ccode: localStorage.getItem("theaterId"),
      bookingId: localStorage.getItem("bookingid"),
      transId: localStorage.getItem("transid"),
      bookType: localStorage.getItem("booking_type")
        ? localStorage.getItem("booking_type")
        : "BOOKING",
      coupon: couponVal ? couponVal : couponId,
      couponType: type,
      couponId: couponId,
      qr: localStorage.getItem("isQR") && isMobile ? "YES" : "N0",
      "qrs": transactionType === "InCinemaFB" ? true : false,
      card: cardNum,
      cardType: netbanking ? "NB" : cardViewMode === 0 ? "CC" : "DC",
      mobile: "",
      otp: "",
    };
    applycoupon(postData)
      .then((res) => {
        const dt = res.data;
        // console.log(dt);
        dt.cardtype = netbanking ? "NB" : cardViewMode === 0 ? "CC" : "DC";
        dt.cardNum = cardNum;
        if (netbanking) {
          dt.bankName = netbanking;
        }
        dt.voucheName = viewMode === 2?"BANKOFFER":"VOUCHER";
        setVisibleRight(false);
        setOfferViewMode(0);
        sendDataForPayment(dt);
        setLoading(false);
        setCardNum(null);
        setCouponVal(null);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  const exploreOfferUI = () => {
    return (
      <>
        <div className="offer-explore">
          <div className="cities-show terms-spaces">
            <div className="select-city">
              <h4>Explore Offers</h4>
            </div>
          </div>
          <div className="divider-coupon-lastest"></div>
          <div className="offering-line"></div>
          {payMode && payMode?.vouchers.length > 0 ? (
            <>
              <div className="row">
                {payMode.vouchers.map((item, idx) => {
                  return (
                    <div className="col-md-6 equal-height-box">
                      <div
                        className="offer-cards pointer"
                        onClick={() => {
                          setOfferViewMode(1);
                          setOfferData(item);
                        }}
                      >
                        <div className="offer-cards-content">
                          <div className="arrow-offer">
                            <div className="arrow-head">
                              <h6>{item.vouDesc}</h6>
                            </div>
                          </div>
                          <div className="Apply-offer-new">
                            <div className="vaild-date-new">
                              <p>Valid till 20 Aug 2022</p>
                            </div>
                            <div className="Apply-new-offer">
                              {offerType === "Explore" ? (
                                ""
                              ) : (
                                <DarkButton
                                  className="explore-offer-new"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowTncDeatil(true);
                                  }}
                                >
                                  Apply
                                </DarkButton>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            ""
          )}
          {/* <div className="offer-cards" onClick={() => setViewMode(1)}>
          <div className="offer-card-photo">
            <img src={offer} alt="" className="img-fluid" />
          </div>
          <div className="offer-cards-content">
            <div className="arrow-offer">
              <div className="arrow-head">
                <h6>20% off on Kotak Cards</h6>
              </div>
              <div className="arrow-icon pointer">
                <img src={offerArrow} alt="" className="img-fluid" />
              </div>
            </div>
            <p>Valid till 20 Aug 2022</p>
          </div>
        </div> */}
        </div>
      </>
    );
  };

  const dateFormat = (data) => {
    const val = data && data?.split(",");
    const dateval = `${val[1]}, ${val[2]}`;
    return dateval;
  };

  const filterPaymodeByBankName = (bankName) => {
    if (bankName === "All Banks") {
      setFilteredPayModeList(payMode?.bankoffers);
    } else {
      const filterData =
        payMode?.bankoffers &&
        payMode?.bankoffers?.filter((item) => {
          return item?.bank === bankName;
        });
      setFilteredPayModeList(filterData);
    }
    // console.log('filterData', payMode?.bankoffers)
  };

  const customHeader = (name) => {
    return (
      <span
        onClick={() => {
          const filterData =
            payMode?.bankoffers &&
            payMode?.bankoffers?.filter((item1) => {
              return item1.discAppPayType === name;
            });
          if (name === "All Offers") {
            setFilteredPayModeListBycard(payMode?.bankoffers);
          } else {
            setFilteredPayModeListBycard(filterData);
            const bankNameList0 = filterData?.map((item) => {
              return item?.bank;
            });
            const bankNameList = ["All Banks", ...new Set(bankNameList0)];
            setBankNameList(bankNameList);
          }
        }}
      >
        {name}
      </span>
    );
  };

  const bankOfferUI = () => {
    return (
      <>
        <div className="cities-show terms-spaces-new show-in-desktop-view">
          <div className="select-city">
            <h4>Bank Offers</h4>
          </div>
        </div>

        <div className="bank-offer-new">
          <div className="card">
            <div
              className={
                theme === "inoxTheme"
                  ? "now-showing-filter-inox"
                  : "now-showing-filter-pvr"
              }
            >
              <TabView
                activeIndex={activeIndex}
                onTabChange={(e) => {
                  setActiveIndex(e.index);
                  if (e.index === 0) {
                    setFilterGiftCard(giftCardData);
                  }
                }}
              >
                <TabPanel header="All Offers">
                  <div className="all-bank-list-show">
                    {bankNameList?.map((item, idx) => (
                      <div
                        className={
                          selectedBankName !== item
                            ? "Bank-name"
                            : theme === "inoxTheme"
                            ? "Bank-name-inox"
                            : "Bank-name-pvr"
                        }
                        key={idx}
                        onClick={() => {
                          // const filterData = payMode && payMode?.filter((citem)=>{
                          //   return citem?.name === item
                          // })
                          // setFilteredPayModeList(filterData)
                          filterPaymodeByBankName(item);
                          setSelectedBankName(item);
                          setOfferData({});
                        }}
                      >
                        <p>{item}</p>
                      </div>
                    ))}
                  </div>
                  {payMode && payMode?.bankoffers.length > 0 ? (
                    <>
                      <div className="row">
                        {filteredPayModeList?.map((item, idx) => {
                          return (
                            <>
                              <div className="col-md-6 col-sm-12 equal-height-box">
                                <div
                                  className={"bank-offer-box pointer"}
                                  key={idx}
                                  onClick={() => {
                                    if (item.bankVouType === "Netbanking") {
                                      setCardNum("266727");
                                      getApply(
                                        item?.vouId,
                                        item?.askCode ? item?.askCode : false,
                                        item.bank
                                      );
                                    } else {
                                      setOfferData(item);
                                    }
                                  }}
                                >
                                  <div
                                    className={
                                      offerData?.id === item.id
                                        ? `bank-offer ${
                                            theme === "inoxTheme"
                                              ? "bg-light-inox"
                                              : "bg-light-pvr"
                                          }`
                                        : "bank-offer"
                                    }
                                  >
                                    <div className="row my-1 p-2 bank-offer-middle">
                                      <div className="col-md-5 col-5">
                                        <div className="bank-offer-img">
                                          <img
                                            src={
                                              item?.bankImage
                                                ? item?.bankImage
                                                : theme === "inoxTheme"
                                                ? miv_inox
                                                : miv_pvr
                                            }
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-7 col-7">
                                        <div className="bank-offer-details-side">
                                          <h5>{item.vouDesc}</h5>
                                          <h6>
                                            {/* {dateFormat(item.validTo)}{" "} */}
                                            <span
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setShowTncDeatil(true);
                                                setOfferData(item);
                                              }}
                                            >
                                              <img
                                                src={BankInfo}
                                                alt=""
                                                className="img-fluid"
                                              />
                                            </span>
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {offerData?.id === item.id ? (
                                <div className="col-md-12 equal-height-box">
                                  <div
                                    className={`offer-explore p-2 m-2 rounded ${
                                      theme === "inoxTheme"
                                        ? "border-dark-inox"
                                        : "border-dark-pvr"
                                    }`}
                                  >
                                    <div className="arrow-heads">
                                      <h6>{offerData?.vouDesc}</h6>
                                      <p>
                                        {" "}
                                        {offerData?.validTo
                                          ? "Valid till " + offerData?.validTo
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="how-avial">
                                      {offerData?.bankVouTypeSub ? (
                                        <>
                                          <div className="offer-card-show">
                                            {offerData?.bankVouTypeSub.includes(
                                              "Credit Card"
                                            ) ? (
                                              <span
                                                className={
                                                  cardViewMode === 0 &&
                                                  theme === "inoxTheme"
                                                    ? "card-view-offer card-active bg-light-inox bread-inox"
                                                    : cardViewMode === 0 &&
                                                      theme === "pvrTheme"
                                                    ? "card-view-offer card-active bg-light-pvr bread-pvr"
                                                    : "card-view-offer"
                                                }
                                                onClick={() => {
                                                  setCardViewMode(0);
                                                }}
                                              >
                                                Credit Card
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                            {offerData?.bankVouTypeSub.includes(
                                              "Debit Card"
                                            ) ? (
                                              <span
                                                className={
                                                  cardViewMode === 1 &&
                                                  theme === "inoxTheme"
                                                    ? "card-view-offer card-active bg-light-inox bread-inox"
                                                    : cardViewMode === 1 &&
                                                      theme === "pvrTheme"
                                                    ? "card-view-offer card-active bg-light-pvr bread-pvr"
                                                    : "card-view-offer"
                                                }
                                                onClick={() => {
                                                  setCardViewMode(1);
                                                }}
                                              >
                                                Debit Card
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {viewMode === 2 ? (
                                        <InputText
                                          placeholder="Card Number"
                                          type="text"
                                          id="gccardnum"
                                          className="offer-control exolpre-input"
                                          value={cardNum}
                                          onChange={(e) =>
                                            setCardNum(
                                              e.target.value.replace(
                                                /[^0-9]/g,
                                                ""
                                              )
                                            )
                                          }
                                          maxlength="18"
                                        />
                                      ) : offerData?.askCode ? (
                                        <InputText
                                          placeholder="Voucher Code"
                                          type="text"
                                          id="gccardnum"
                                          className="offer-control exolpre-input"
                                          value={couponVal}
                                          onChange={(e) =>
                                            setCouponVal(
                                              e.target.value.toUpperCase()
                                            )
                                          }
                                          maxlength="20"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="apply-offer-btn">
                                      <DarkButton
                                        className="offer-applys"
                                        onClick={() => {
                                          getApply(
                                            offerData?.vouId,
                                            offerData?.askCode
                                              ? offerData?.askCode
                                              : false
                                          );
                                        }}
                                      >
                                        Apply Offer
                                        {loading ? (
                                          <i
                                            className="pi pi-spin pi-spinner"
                                            style={{ fontSize: "1.5em" }}
                                          ></i>
                                        ) : (
                                          ""
                                        )}
                                      </DarkButton>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              <div
                                className="luxe-divider show-in-mobile-view"
                                style={{ margin: "0" }}
                              ></div>
                            </>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </TabPanel>
                {/* {cardNameList?.map((item, idx) => (
                  <TabPanel header={customHeader(item)} key={idx}>
                    <div className="all-bank-list-show">
                      {bankNameList?.map((item, idx) => (
                        <div
                          className={
                            selectedBankName !== item
                              ? "Bank-name"
                              : theme === "inoxTheme"
                              ? "Bank-name-inox"
                              : "Bank-name-pvr"
                          }
                          key={idx}
                          onClick={() => {
                            filterPaymodeByBankName(item);
                            setSelectedBankName(item);
                            setOfferData({});
                          }}
                        >
                          <p>{item}</p>
                        </div>
                      ))}
                    </div>
                    {payMode && payMode?.bankoffers.length > 0 ? (
                      <>
                        <div className="row">
                          {filteredPayModeListByCard?.map((item, idx) => {
                            return (
                              <>
                                <div className="col-md-6 equal-height-box">
                                  <div
                                    className={"bank-offer-box pointer"}
                                    key={idx}
                                    onClick={() => {
                                      if (item.bankVouType === "Netbanking") {
                                        setCardNum("266727");
                                        getApply(
                                          item?.vouId,
                                          item?.askCode ? item?.askCode : false,
                                          item.bank
                                        );
                                      } else {
                                        setOfferData(item);
                                      }
                                    }}
                                  >
                                    <div
                                      className={
                                        offerData?.id === item.id
                                          ? `bank-offer ${
                                              theme === "inoxTheme"
                                                ? "bg-light-inox"
                                                : "bg-light-pvr"
                                            }`
                                          : "bank-offer"
                                      }
                                    >
                                      <div className="row">
                                        <div className="col-md-2 align-self-center">
                                          <div className="bank-offer-img">
                                            <img
                                              src={
                                                item?.bankImage
                                                  ? item?.bankImage
                                                  : theme === "inoxTheme"
                                                  ? miv_inox
                                                  : miv_pvr
                                              }
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="bank-offer-details-side">
                                            <h5>{item.vouDesc}</h5>
                                            <h6>
                                              <span
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setShowTncDeatil(true);
                                                  setOfferData(item);
                                                }}
                                              >
                                                <img
                                                  src={BankInfo}
                                                  alt=""
                                                  className="img-fluid"
                                                />
                                              </span>
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {offerData?.id === item.id ? (
                                  <div className="col-md-12 equal-height-box">
                                    <div
                                      className={`offer-explore p-2 m-2 rounded ${
                                        theme === "inoxTheme"
                                          ? "border-dark-inox"
                                          : "border-dark-pvr"
                                      }`}
                                    >
                                      <div className="arrow-heads">
                                        <h6>{offerData?.vouDesc}</h6>
                                        <p>
                                          {" "}
                                          {offerData?.validTo
                                            ? "Valid till " + offerData?.validTo
                                            : ""}
                                        </p>
                                      </div>
                                      <div className="how-avial">
                                        {offerData?.bankVouTypeSub ? (
                                          <>
                                            <div className="offer-card-show">
                                              {offerData?.bankVouTypeSub.includes(
                                                "Credit Card"
                                              ) ? (
                                                <span
                                                  className={
                                                    cardViewMode === 0 &&
                                                    theme === "inoxTheme"
                                                      ? "card-view-offer card-active bg-light-inox bread-inox"
                                                      : cardViewMode === 0 &&
                                                        theme === "pvrTheme"
                                                      ? "card-view-offer card-active bg-light-pvr bread-pvr"
                                                      : "card-view-offer"
                                                  }
                                                  onClick={() => {
                                                    setCardViewMode(0);
                                                  }}
                                                >
                                                  Credit Card
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                              {offerData?.bankVouTypeSub.includes(
                                                "Debit Card"
                                              ) ? (
                                                <span
                                                  className={
                                                    cardViewMode === 1 &&
                                                    theme === "inoxTheme"
                                                      ? "card-view-offer card-active bg-light-inox bread-inox"
                                                      : cardViewMode === 1 &&
                                                        theme === "pvrTheme"
                                                      ? "card-view-offer card-active bg-light-pvr bread-pvr"
                                                      : "card-view-offer"
                                                  }
                                                  onClick={() => {
                                                    setCardViewMode(1);
                                                  }}
                                                >
                                                  Debit Card
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {viewMode === 2 ? (
                                          <InputText
                                            placeholder="Card Number"
                                            type="text"
                                            id="gccardnum"
                                            className="offer-control exolpre-input"
                                            value={cardNum}
                                            onChange={(e) =>
                                              setCardNum(
                                                e.target.value.replace(
                                                  /[^0-9]/g,
                                                  ""
                                                )
                                              )
                                            }
                                            maxlength="18"
                                          />
                                        ) : offerData?.askCode ? (
                                          <InputText
                                            placeholder="Voucher Code"
                                            type="text"
                                            id="gccardnum"
                                            className="offer-control exolpre-input"
                                            value={couponVal}
                                            onChange={(e) =>
                                              setCouponVal(
                                                e.target.value.toUpperCase()
                                              )
                                            }
                                            maxlength="20"
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="apply-offer-btn">
                                        <DarkButton
                                          className="offer-applys"
                                          onClick={() => {
                                            getApply(
                                              offerData?.vouId,
                                              offerData?.askCode
                                                ? offerData?.askCode
                                                : false
                                            );
                                          }}
                                        >
                                          Apply Offer
                                          {loading ? (
                                            <i
                                              className="pi pi-spin pi-spinner"
                                              style={{ fontSize: "1.5em" }}
                                            ></i>
                                          ) : (
                                            ""
                                          )}
                                        </DarkButton>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </TabPanel>
                ))} */}
              </TabView>
            </div>
          </div>
        </div>
      </>
    );
  };

  const bankOfferTnc = () => {
    return (
      <>
        <div className="offer-your">
          <div className="offer-title">
            <h5>Terms and Conditins</h5>
          </div>
          <div className="offer-cross">
            <span
              onClick={() => {
                setVisibleRight(false);
                setBankOfferViewMode(0);
              }}
            >
              <i className="pi pi-times"></i>
            </span>
          </div>
        </div>
        <div>Terms and consitions UI</div>
        <div>
          <DarkButton onClick={() => setBankOfferViewMode(2)}>
            accept
          </DarkButton>
        </div>
      </>
    );
  };

  const bankOfferPayment = () => {
    return (
      <>
        <div className="offer-your">
          <div className="offer-title">
            <h5>Apply Now</h5>
          </div>
          <div className="offer-cross">
            <span
              onClick={() => {
                setVisibleRight(false);
                setBankOfferViewMode(0);
              }}
            >
              <i className="pi pi-times"></i>
            </span>
          </div>
        </div>
        <div>Apply Now</div>
        <div>
          <DarkButton
            onClick={() => {
              setBankOfferViewMode(0);
              setVisibleRight(false);
            }}
          >
            Pay
          </DarkButton>
        </div>
      </>
    );
  };

  // console.log('offerData', offerData)

  const offerDetails = () => {
    return (
      <>
        <div className="offer-explore">
          <div className="offer-your">
            <div className="offer-title">
              <h5>Offers for you</h5>
            </div>
            <div className="offer-cross">
              <span
                onClick={() => {
                  setVisibleRight(false);
                  setOfferViewMode(0);
                }}
              >
                <i className="pi pi-times"></i>
              </span>
            </div>
          </div>
          <div className="offer-card-photo">
            <img
              src={
                offerData?.imageVertical
                  ? offerData?.imageVertical
                  : theme === "inoxTheme"
                  ? mih_inox
                  : mih_pvr
              }
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="arrow-heads">
            <h6>{offerData?.vouDesc}</h6>
            <p>
              {" "}
              {offerData?.validTo ? "Valid till " + offerData?.validTo : ""}
            </p>
          </div>
          <div className="how-avial">
            {offerData?.bankVouTypeSub ? (
              <>
                <div className="offer-card-show">
                  {offerData?.bankVouTypeSub.includes("Credit Card") ? (
                    <span
                      className={
                        cardViewMode === 0 && theme === "inoxTheme"
                          ? "card-view-offer card-active bg-light-inox bread-inox"
                          : cardViewMode === 0 && theme === "pvrTheme"
                          ? "card-view-offer card-active bg-light-pvr bread-pvr"
                          : "card-view-offer"
                      }
                      onClick={() => {
                        setCardViewMode(0);
                      }}
                    >
                      Credit Card
                    </span>
                  ) : (
                    ""
                  )}
                  {offerData?.bankVouTypeSub.includes("Debit Card") ? (
                    <span
                      className={
                        cardViewMode === 1 && theme === "inoxTheme"
                          ? "card-view-offer card-active bg-light-inox bread-inox"
                          : cardViewMode === 1 && theme === "pvrTheme"
                          ? "card-view-offer card-active bg-light-pvr bread-pvr"
                          : "card-view-offer"
                      }
                      onClick={() => {
                        setCardViewMode(1);
                      }}
                    >
                      Debit Card
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              ""
            )}
            {/* <ol>
              <li>Lorem ipsum dolor sit amet, consectetur lorem</li>
              <li>Lorem ipsum dolor sit amet, consectetur lorem</li>
              <li>Lorem ipsum dolor sit amet, consectetur lorem</li>
            </ol> */}
            {viewMode === 2 ? (
              <InputText
                placeholder="Card Number"
                type="text"
                id="gccardnum"
                className="offer-control exolpre-input"
                value={cardNum}
                onChange={(e) =>
                  setCardNum(e.target.value.replace(/[^0-9]/g, ""))
                }
                maxlength="18"
              />
            ) : offerData?.askCode ? (
              <InputText
                placeholder="Voucher Code"
                type="text"
                id="gccardnum"
                className="offer-control exolpre-input"
                value={couponVal}
                onChange={(e) => setCouponVal(e.target.value.toUpperCase())}
                maxlength="20"
              />
            ) : (
              ""
            )}
          </div>
          <div className="apply-offer-btn">
            <DarkButton
              className="offer-applys"
              onClick={() => {
                getApply(
                  offerData?.vouId,
                  offerData?.askCode ? offerData?.askCode : false
                );
              }}
            >
              Apply Offer
              {loading ? (
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "1.5em" }}
                ></i>
              ) : (
                ""
              )}
            </DarkButton>
          </div>
          <div className="how-avial">
            {offerData?.tnc ? (
              <>
                <h4>Term & Conditions</h4>
                <ol>
                  {offerData?.tnc.map((item, idx) => {
                    return <li>{item}</li>;
                  })}
                </ol>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        <div className="terms-gift">
          {viewMode === 1 ? (
            <div className="">
              {offerViewMode === 1 ? (
                <div>{offerDetails()}</div>
              ) : (
                <div>{exploreOfferUI()}</div>
              )}
            </div>
          ) : (
            <div className="">
              {offerViewMode === 1 ? (
                <div>{offerDetails()}</div>
              ) : (
                <div>{bankOfferUI()}</div>
              )}
            </div>
          )}
        </div>
      </div>
      <SweetAlert
        error
        show={showAlert}
        title={errorMsg}
        onConfirm={() => {
          setShowAlert(false);
          // console.log('confirm')
          //navigate('/')
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnCssClass={
          theme === "inoxTheme"
            ? "confirm-btn-inox border-0"
            : "confirm-btn-pvr border-0"
        }
      >
        {errorMsg}
      </SweetAlert>
      <div>
        <Sidebar
          visible={showTncDeatil}
          position="right"
          onHide={() => {
            setShowTncDeatil(false);
          }}
          dismissable={true}
          className=""
          showCloseIcon={false}
          blockScroll
        >
          {viewMode === 1 ? (
            <>
              <div className="d-flex justify-content-between">
                <span>Offer Details</span>
                <span
                  className="pointer"
                  onClick={() => setShowTncDeatil(false)}
                >
                  <i className="pi pi-times" />{" "}
                </span>
              </div>
              <div className="divider-coupon-lastest"></div>
            </>
          ) : (
            <div className="how-avial">
              <div className="d-flex justify-content-between">
                <h4>Term & Conditions</h4>
                <span onClick={() => setShowTncDeatil(false)}>
                  <i className="pi pi-times" />{" "}
                </span>
              </div>
              <div className="luxe-divider"></div>
              <ol>
                {offerData?.tnc?.length > 0
                  ? offerData?.tnc.map((item, idx) => {
                      return <li key={idx}>{item}</li>;
                    })
                  : ""}
              </ol>
            </div>
          )}
        </Sidebar>
      </div>
    </>
  );
};

export default BankOffers;
