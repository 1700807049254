import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import * as appConstants from "../../../constants/index";
import Passportvoucher from "../../../assets/payment/voucher.png";
import { DonateButton } from "../paymentTags";
import master from "../../../assets/payment/master.png";
import visa from "../../../assets/payment/visa.png";
import SweetAlert from "react-bootstrap-sweetalert";
import savedCardImg from '../../../assets/payment/savecard.svg'

const SavedCard = ({ juspayinit, payMode }) => {
  const [searchText, setSearchText] = useState(null);
  const [creditCardarray, setCreditCardarr] = useState([]);
  const [debitcardarray, setDebitcardarray] = useState([]);
  const bookingid = localStorage.getItem("bookingid");
  const theme = localStorage.getItem("theme");
  const [errorMsg, setErrorMsg] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingtoken, setLoadingtoken] = useState(false);
  const [paymentModes, setPaymentModes] = useState(null);

  console.log("payMode", juspayinit, payMode);

  useEffect(() => {
    if (juspayinit?.list_cards?.length > 0) {
      juspayinit?.list_cards?.forEach((element) => {
        //element.valid = false;
        console.log(element);
        if (element.card_type == "DEBIT") {
          setDebitcardarray([element, ...debitcardarray]);
          //debit.push(element);
        } else if (element.card_type == "CREDIT") {
          setCreditCardarr([element, ...creditCardarray]);
          // credit.push(element);
        }
      });
      setTimeout(() => {
        juspayinit?.list_cards.forEach((element, index) => {
          juspayinit.list_cards[index]["valid"] = false;
          initJuspaysavedcard(element.card_token);
        });
      }, 100);
    }
  }, []);
  // console.log(creditCard, debitcard);
  const initJuspaysavedcard = async (token) => {
    var juspay_form = Juspay.Setup({
      payment_form: "#savedcard_form" + token,
      success_handler: function (status) {
        console.log(status);
      },
      error_handler: function (
        error_code,
        error_message,
        bank_error_code,
        bank_error_message,
        gateway_id
      ) {
        setLoading(false);
        setErrorMsg(error_message);
        setShowAlert(true);
        console.log(
          error_code,
          error_message,
          bank_error_code,
          bank_error_message,
          gateway_id
        );
      },
      iframe_elements: {
        security_code: {
          /* Class name of the <div> which will hold the iframe element for card security code. */
          container: ".security_code_div_saved_card",

          attributes: {
            /* Field Attributes, which you want to set for the <input> field inside the iframe element. */
            placeholder: "xxx",
          },
        },
      },
      styles: {
        /* Add the styling for card security code input field here */
        input: {
          outline: "none",
          "border-bottom": "none",
          padding: "0",
          height: "22px",
          color: "#FFFFFF",
          "text-transform": "uppercase",
        },
        ".security_code": {
          "line-height": "0px",
          "font-size": "16px",
          width: "60px",
          color: "#000",
        },
        /* Add the styling to be added to input fields in focus state */
        ":focus": {},
      },
      /* 
       * This function will be called with an event object as parameter in two cases:
       * 1. When some event occurs on the security_code field inside iframe element.
       * 2. The user clicks on the submit button and the values in some of the input fields are invalid. (In second case, we will send the event object with state of the first invalid field in checkout form, which is security_code here.)
       
       * This event object will contain the state of the input field. You should use this event object to show validation messages in your checkout form. */

      iframe_element_callback: function (event) {
        //self.jusPayInit.list_cards[index].valid = event.valid;
        juspayinit.list_cards.forEach((item, index) => {
          if (item.card_token == token) {
            juspayinit.list_cards[index]["valid"] = event.valid;
          }
        });
        /* The event information will be available in the event object 
           
           *  1. event.target_element - (security_code) 
           *  2. event.valid - (true/false) 
           *  3. event.empty - (true/false) 
           *  4. event.card_brand  */
      },
    });
  };
  const savedCardHmac = async (card_token) => {
    let valid = false;
    let filterdata = juspayinit.list_cards.map((item) => {
      if (item.card_token == card_token && item.valid) {
        valid = true;
      }
    });
    //console.log(filterdata, filterdata.length);
    const btn = document.getElementById("btn-" + card_token);
    if (!valid) {
      setErrorMsg("Please enter valid CVV!");
      setShowAlert(true);
      return "";
    }
    setLoading(true);
    setLoadingtoken(card_token);
    const postData = {
      transId: localStorage.getItem("transid"),
      bookingId: localStorage.getItem("bookingid"),
      bookType: localStorage.getItem("booking_type"),
      paytype: "SAVEDCARD",
      pgtype: "JUSPAY",
    };

    await axios
      .post(`${appConstants.base_url}v1/payment/juspay/hmac`, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          localStorage.setItem("paymentid", dt.output.paymentId);
          localStorage.setItem("payment_type", "nonwallet");
          //console.log("bankList==>", dt);
          btn.click();
        } else {
          //console.log(res.data.msg);
          setLoading(true);
        }
      })
      .catch((err) => {
        //console.log("error", err);
        setLoading(false);
      });
  };
  return (
    <>
      <SweetAlert
        error
        show={showAlert}
        title={errorMsg}
        onConfirm={() => {
          setShowAlert(false);
          // console.log('confirm')
          //navigate('/')
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnCssClass={
          theme === "inoxTheme"
            ? "confirm-btn-inox border-0"
            : "confirm-btn-pvr border-0"
        }
      >
        {errorMsg}
      </SweetAlert>

      <div className="row">
        <div className="col-md-12">
          <div className="saved-card">
            <h6>SAVED CARDS</h6>
          </div>
        </div>
      </div>
      <div className="all-card-saved">
        <div className="row">
          {creditCardarray && creditCardarray.length > 0 ? (
            <>
              <div className="col-md-12">
                <div className="voucher">
                  <h6>Credit Cards</h6>
                </div>
              </div>
              {creditCardarray.map((item, idx) => {
                return (
                  <form
                    role="form"
                    id={"savedcard_form" + item.card_token}
                    key={idx}
                  >
                    <input
                      type="hidden"
                      className="order_id"
                      value={bookingid}
                    />
                    <input
                      type="hidden"
                      className="merchant_id"
                      value={juspayinit ? juspayinit.merchant_id : "pvrcinemas"}
                    />
                    <input
                      type="hidden"
                      className="card_token"
                      value={item.card_token}
                    />
                    <input
                      type="hidden"
                      className="card_isin"
                      value={item.card_isin}
                    />
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      className="auth_type"
                      value=""
                      name="auth_type"
                    />
                    <input type="hidden" className="redirect" value="true" />
                    <input
                      type="hidden"
                      className="payment_channel"
                      value="WEB"
                    />
                    <div className="col-md-12">
                      <div className="Saved-card-box">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="card-show">
                              <div className="card-type">
                                <img
                                  src={
                                    item.card_brand == "VISA"
                                      ? visa
                                      : item.card_brand == "MASTER"
                                      ? master
                                      : savedCardImg
                                  }
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="Card-hoder">
                                <h6>
                                  {item.nickname ? item.nickname : item.name}
                                </h6>
                                <p>{item.card_number}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="cvv-card">
                              <div className="offers-start">
                                <div className="coupon-input">
                                  {/* <input type="text" maxlength="18" className="offer-control" placeholder="CVV" value={searchText}
                                  onChange={(e) => setSearchText(e.target.value)} /> */}
                                  <div className="security_code_div_saved_card"></div>
                                </div>
                                <div className="Pay-cv">
                                  <DonateButton
                                    type="button"
                                    className="btn-apply"
                                    onClick={() =>
                                      !loading
                                        ? savedCardHmac(item.card_token)
                                        : ""
                                    }
                                  >
                                    Pay
                                    {loading && loadingtoken == item.card_token ? (
                                      <i
                                        className="pi pi-spin pi-spinner"
                                        style={{ fontSize: "1.5em" }}
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                  </DonateButton>
                                  <button
                                    id={"btn-" + item.card_token}
                                    type="submit"
                                    style={{ display: "none" }}
                                  >
                                    Pay
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              })}
            </>
          ) : (
            ""
          )}
          {debitcardarray && debitcardarray.length > 0 ? (
            <>
              <div className="col-md-12">
                <div className="voucher">
                  <h6>Debit Cards</h6>
                </div>
              </div>
              {debitcardarray &&
                debitcardarray.map((item, idx) => {
                  return (
                    <form
                      role="form"
                      id={"savedcard_form" + item.card_token}
                      key={idx}
                    >
                      <input
                        type="hidden"
                        className="order_id"
                        value={bookingid}
                      />
                      <input
                        type="hidden"
                        className="merchant_id"
                        value={
                          juspayinit ? juspayinit.merchant_id : "pvrcinemas"
                        }
                      />
                      <input
                        type="hidden"
                        className="card_token"
                        value={item.card_token}
                      />
                      <input
                        type="hidden"
                        className="card_isin"
                        value={item.card_isin}
                      />
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        className="auth_type"
                        value=""
                        name="auth_type"
                      />
                      <input type="hidden" className="redirect" value="true" />
                      <input
                        type="hidden"
                        className="payment_channel"
                        value="WEB"
                      />
                      <div className="col-md-12">
                        <div className="Saved-card-box">
                          <div className="row">
                            <div className="col-md-8">
                              <div className="card-show">
                                <div className="card-type">
                                  <img
                                    src={
                                      item.card_brand == "VISA"
                                        ? visa
                                        : item.card_brand == "MASTER"
                                        ? master
                                        : savedCardImg
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="Card-hoder">
                                  <h6>
                                    {item.nickname ? item.nickname : item.name}
                                  </h6>
                                  <p>{item.card_number}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="cvv-card">
                                <div className="offers-start">
                                  <div className="coupon-input">
                                    {/* <input type="text" maxlength="18" className="offer-control" placeholder="CVV" value={searchText}
                                  onChange={(e) => setSearchText(e.target.value)} /> */}
                                    <div className="security_code_div_saved_card"></div>
                                  </div>
                                  <div className="Pay-cv">
                                    <DonateButton
                                      type="button"
                                      className="btn-apply"
                                      onClick={() =>
                                        !loading
                                          ? savedCardHmac(item.card_token)
                                          : ""
                                      }
                                    >
                                      Pay
                                      {loading && loadingtoken == item.card_token ? (
                                        <i
                                          className="pi pi-spin pi-spinner"
                                          style={{ fontSize: "1.5em" }}
                                        ></i>
                                      ) : (
                                        ""
                                      )}
                                    </DonateButton>
                                    <button
                                      id={"btn-" + item.card_token}
                                      type="submit"
                                      style={{ display: "none" }}
                                    >
                                      Pay
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  );
                })}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default SavedCard;
