import styled from "styled-components";

export const GiftName = styled.div`
  background-color: ${(props) => props.theme.colors.bgColorLight};
  width: 100%;
`;

export const ProceedButton = styled.button`
  color: ${(props) => props.theme.colors.txtColor};
  background-color: ${(props) => props.theme.colors.bgColor};
`;

export const LightButton = styled.button`
  background-color: ${(props) => props.theme.colors.bgColorLight};
  color: ${(props) => props.theme.colors.txtColor};
`;