// AurusIt Devloper
import { constantsTypes } from "../reduxConstants";

const initialData = JSON.parse(localStorage.getItem('foodVoucher')) || {
  FoodVoucher: [],
  cartItems: [],
  userSelectedFood: null,
};

export const addToCartReduceHandler = (state = initialData, action) => {
  switch (action.type) {
    case constantsTypes.FOOD_VOUCHER: {
      const newItems = action.payload.FoodVoucher;
      const updatedCart = Array.isArray(state.FoodVoucher) ? [...state.FoodVoucher] : [];

      newItems?.forEach(newItem => {
        const existingItemIndex = updatedCart.findIndex(item => item.id === newItem.id);
        if (existingItemIndex !== -1) {
          updatedCart[existingItemIndex] = { ...updatedCart[existingItemIndex], ...newItem };
        } else {
          updatedCart.push(newItem);
        }
      });

      return { ...state, FoodVoucher: updatedCart };
    }

    case constantsTypes.ADD_FOOD_TO_CART: {
      const newItem = action.payload;
      return { 
        ...state,
        cartItems: newItem 
      };
    }

    case constantsTypes.REMOVE_FOOD_TO_CART:
      return {
        ...state,
        cartItems: state.cartItems && state.cartItems.filter(item => item.ItemID !== action.payload)
      };

    case constantsTypes.SET_USER_SELECTED_FOOD:
      return {
        ...state,
        userSelectedFood: action.payload
      };

    // Handle other action types from your actions file
    case constantsTypes.ADD_TO_CART:
    case constantsTypes.REMOVE_FROM_CART:
      // Implement these cases if needed, or remove if not used
      console.warn(`Action type ${action.type} not implemented in reducer`);
      return state;

    default:
      return state;
  }
};
