import { getGoogleWalletApi } from "src/services/OtherServices";
import { ProceedButton } from "src/views/gift-cards/giftCardTags";
import googleWallet from "src/assets/brand/googlewallet.svg"
import { useState } from "react";
import { hitGa } from "src/services/PassportServices";

const GoogleWallet = ({orderID, booktype}) => {
    const [loading, setLoading] = useState(false);
    const hitGa = (event) => {
        let dataLayer = window["dataLayer"] || [];
        dataLayer.push({
          event: event,
        });
      };
    const redirectGoogleWallet = async (bookingId, booktype1) =>{
        // setBookingLoading(true);
        setLoading(true);
        try {
        getGoogleWalletApi(bookingId, booktype1)
        .then((res) => {
            setLoading(false);
            const dt = res.data;
            // setBookingLoading(false);
            if (dt.result === "success") {
            const ndt = dt.output;
            let url = `https://pay.google.com/gp/v/save/${ndt.token}`
            window.open(url, '_blank');
            }
        });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        // <ProceedButton className="my-download" type="button"
            // onClick={()=>{
            // redirectGoogleWallet(orderID, booktype)
            // }}
        // >
        //     <span className="mx-2">
        //         <i className="pi pi-google"/>
        //     </span>
            
        // </ProceedButton>
        <>
            <img src={googleWallet} className="pointer" onClick={()=>{
            loading?"":redirectGoogleWallet(orderID, booktype)
           hitGa("add_to_wallet");
           }} alt="add to google wallet" /> 
            {loading?
                <span className="google-wallet-loader">
                    {/* <i className="pi pi-spin pi-spinner"/> */}
                </span>
            :""}
        </>
    );
};

export default GoogleWallet;