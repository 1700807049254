import React, { useEffect, useState } from "react";
import cinemaIcon from "../../../../assets/nav-icon/Cinema.svg";
import arrow from "../../../../assets/profile/navigatenext.svg";
import { Sidebar } from "primereact/sidebar";
import angle_left from "../../../../assets/mobilemoviedetail/arrow-b.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import { postDataService } from "src/services/postApiService";

const ProfileFavouriteCinemas = () => {
  const theme = localStorage.getItem("theme");
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  const [visibleRight, setVisibleRight] = useState(false);
  const [favouriteCinemasList, setFavouriteCinemasList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPreferenceInfo, setSelectedPreferenceInfo] = useState(null);
  const [selectedTabId, setSelectedTabId] = useState("11");

  const preferenceTabList = [
    { id: "11", name: "Cinemas" },
    { id: "12", name: "Genre" },
    { id: "13", name: "Languages" },
  ];

  useEffect(() => {
    getPreferenceData();
  }, []);

  const getPreferenceData = async () => {
    setLoading(true);
    const postData = {
      city: localStorage.getItem("city"),
      id: "",
      like: true,
      type: "",
      did: "",
    };
    try {
      const data = await postDataService(`v1/user/prefrences/get`, postData);
      const dt = data?.output;
      setFavouriteCinemasList(dt);
      setLoading(false);
      //   console.log("favouriteCinemaData", dt);
    } catch (error) {
      console.error("Error fetching chain data:", error);
      setLoading(false);
    }
  };

  const updatePrefrences = async (dataInfo) => {
    setLoading(true);
    const postData = {
      city: localStorage.getItem("city"),
      id: dataInfo.id,
      like: dataInfo.action,
      type: dataInfo.type,
      did: "",
    };

    console.log("postData postData", postData);
    const data = await postDataService(`v1/user/prefrences/set`, postData);
    const dt = data?.output;
    if (data.result === "success") {
      setShowAlert(false);
      getPreferenceData();
      swal({
        title: "Success",
        text: `${
          dataInfo.type === "t"
            ? "Cinema"
            : dataInfo.type === "g"
            ? "Geners"
            : "Language"
        } prefrence  succesfully updated`,
        icon: "success",
        timer: 2000,
        buttons: false,
      });
    } else if (data.result === "error" || data.result === "dialog") {
      console.log("error");
      swal({
        title: "Error",
        text: data.msg,
        icon: "error",
        timer: 2000,
        buttons: false,
      });
    }
  };

  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };

  const preferencedCinemaUI = () => {
    return (
      <div className="row p-2">       
        {favouriteCinemasList?.theater?.other?.length > 0 ? (
          favouriteCinemasList?.theater?.other?.map((item, idx) => {
            const likedIds = favouriteCinemasList?.theater?.liked?.map(
              (CItem) => CItem.id
            );
            return (
              <div className="col-md-6" key={idx}>
                <div
                  className={
                    likedIds.includes(item.id) && theme === "inoxTheme"
                      ? "active-gener-lng-inox border pointer rounded-pill my-1 px-2 py-1"
                      : likedIds.includes(item.id) && theme === "pvrTheme"
                      ? "active-gener-lng-pvr border pointer rounded-pill my-1 px-2 py-1"
                      : "border pointer rounded-pill my-1 px-2 py-1"
                  }
                  onClick={() => {
                    if (likedIds.includes(item.id)) {
                      setShowAlert(true);
                      setSelectedPreferenceInfo({
                        id: item.id,
                        type: "t",
                        action: "false",
                      });
                    } else {
                      updatePrefrences({
                        id: item.id,
                        type: "t",
                        action: "true",
                      });
                    }
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <span>{item?.na}</span>{" "}
                    <span
                      className="mx-1"
                    >
                      <i
                        className={`${
                          likedIds.includes(item.id)
                            ? "pi pi-times"
                            : "pi pi-plus"
                        }`}
                      />{" "}
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <span className="mx-1">No cinema found</span>
        )}
      </div>
    );
  };

  const preferencedGenerUI = () => {
    return (
        <div className="d-flex flex-wrap p-1">
          {favouriteCinemasList?.genre?.other?.length > 0 ? (
            favouriteCinemasList?.genre?.other?.map((item, idx) => {
              const likedIds = favouriteCinemasList?.genre?.liked?.map(
                (CItem) => CItem.id
              );
              return (
                <div className="mx-1" key={idx}>
                  <div
                    className={
                      likedIds.includes(item.id) && theme === "inoxTheme"
                        ? "active-gener-lng-inox border pointer rounded-pill my-1 px-2 py-1"
                        : likedIds.includes(item.id) && theme === "pvrTheme"
                        ? "active-gener-lng-pvr border pointer rounded-pill my-1 px-2 py-1"
                        : "border pointer rounded-pill my-1 px-2 py-1"
                    }
                    onClick={() => {
                      if (likedIds.includes(item.id)) {
                        setShowAlert(true);
                        setSelectedPreferenceInfo({
                          id: item.id,
                          type: "g",
                          action: "false",
                        });
                      } else {
                        updatePrefrences({
                          id: item.id,
                          type: "g",
                          action: "true",
                        });
                      }
                    }}
                  >
                    <span>{item?.na}</span>{" "}
                    <span
                      className=" mx-2"
                      
                    >
                      <i
                        className={`${
                          likedIds.includes(item.id)
                            ? "pi pi-times"
                            : "pi pi-plus"
                        }`}
                      />{" "}
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <span className="mx-1">No genre found</span>
          )}
        </div>
    );
  };

  const preferencedLanguageUI = () => {
    return (
        <div className="d-flex flex-wrap p-1">
          {favouriteCinemasList?.lang?.other?.length > 0 ? (
            favouriteCinemasList?.lang?.other?.map((item, idx) => {
              const likedIds = favouriteCinemasList?.lang?.liked?.map(
                (CItem) => CItem.id
              );
              return (
                <div className="mx-1" key={idx}>
                  <div
                    className={
                      likedIds.includes(item.id) && theme === "inoxTheme"
                        ? "active-gener-lng-inox border pointer rounded-pill my-1 px-2 py-1"
                        : likedIds.includes(item.id) && theme === "pvrTheme"
                        ? "active-gener-lng-pvr border pointer rounded-pill my-1 px-2 py-1"
                        : "border pointer rounded-pill my-1 px-2 py-1"
                    }
                    onClick={() => {
                      if (likedIds.includes(item.id)) {
                        setShowAlert(true);
                        setSelectedPreferenceInfo({
                          id: item.id,
                          type: "l",
                          action: "false",
                        });
                      } else {
                        updatePrefrences({
                          id: item.id,
                          type: "l",
                          action: "true",
                        });
                      }
                    }}
                  >
                    <span>{item?.na}</span>{" "}
                    <span
                      className="mx-2"
                      
                    >
                      <i
                        className={`${
                          likedIds.includes(item.id)
                            ? "pi pi-times"
                            : "pi pi-plus"
                        }`}
                      />{" "}
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <span className="mx-1">No language found</span>
          )}
        </div>
    );
  };

  return (
    <>
      <div
        className="profile-img border-line-profile"
        onClick={() => {
          setVisibleRight(true);
        }}
      >
        <div className="profile-text profile-account">
          <h4>
            <span className="avtar">
              <img src={cinemaIcon} alt="" className="" />
            </span>
          </h4>
          <div
            className="profile-text-info"
            onClick={() => hitGa("favourite_cinemas")}
          >
            <h2 onClick={() => hitGa("favourite_cinemas")}>Preferences</h2>
          </div>
        </div>
        <div className="img-arrow" onClick={() => hitGa("favourite_cinemas")}>
          <img src={arrow} alt="" className="" />
        </div>
      </div>
      <div>
        <Sidebar
          visible={visibleRight}
          showCloseIcon={false}
          className="watchlist-show-m"
          style={{ width: "100vw" }}
          position={"right"}
          onHide={() => {
            setVisibleRight(false);
          }}
          dismissable={false}
          blockScroll={true}
          fullScreen
        >
          <>
            <div
              className="bg-white show-in-mobile-view"
              style={{ position: "sticky", top: "0", zIndex: "999" }}
            >
              <div className="watch-list-mobile-box">
                <span onClick={() => setVisibleRight(false)}>
                  <img src={angle_left} alt="" className="" />{" "}
                </span>
                <h4>Preferences</h4>
              </div>
            </div>
            <div className="d-flex px-1">
              {preferenceTabList?.map((item, idx) => (
                <div
                  className={
                    selectedTabId === item.id && theme === "inoxTheme"
                      ? "preference-active-tab-inox"
                      : selectedTabId === item.id && theme === "pvrTheme"
                      ? "preference-active-tab-pvr"
                      : "preference-inactive-tab"
                  }
                  key={idx}
                  onClick={() => setSelectedTabId(item.id)}
                >
                  {item.name}
                </div>
              ))}
            </div>
            <div>
              {loading ? (
                <span>
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "2rem" }}
                  />
                </span>
              ) : (
                <div>
                  <div className="mt-3">
                    {selectedTabId === "11"
                      ? preferencedCinemaUI()
                      : selectedTabId === "12"
                      ? preferencedGenerUI()
                      : preferencedLanguageUI()}
                  </div>
                </div>
              )}
            </div>
            {/* <div>
              {loading ? (
                <span>
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "2rem" }}
                  />
                </span>
              ) : (
                <>
                  {favouriteCinemasList?.length > 0 ? (
                    favouriteCinemasList?.map((item, idx) => {
                      return (
                        <div className="col-md-12 px-1" key={idx}>
                          <div className="border rounded d-flex justify-content-between my-1 p-2">
                            <span>{item?.na}</span>{" "}
                            <span
                              className="pointer mx-1"
                              onClick={() => {
                                setShowAlert(true);
                                setSelectedCinema(item);
                              }}
                            >
                              <i className="pi pi-times" />{" "}
                            </span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <span className="mx-1">No cinema found</span>
                  )}
                </>
              )}
            </div> */}
            <div>
              <SweetAlert
                warning
                show={showAlert}
                title={"Are you sure?"}
                onConfirm={() => updatePrefrences(selectedPreferenceInfo)}
                onCancel={() => setShowAlert(false)}
                confirmBtnText="Yes"
                confirmBtnCssClass={
                  theme === "inoxTheme"
                    ? "confirm-btn-inox border-0"
                    : "confirm-btn-pvr border-0"
                }
                showCancel={true}
                showConfirm={true} //not Display the "OK" button
                timeOut={3000}
                className="sucess-popup"
              >
                {"You want to remove from favourire list!"}
              </SweetAlert>
            </div>
          </>
        </Sidebar>
      </div>
    </>
  );
};

export default ProfileFavouriteCinemas;
