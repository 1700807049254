import React, { useEffect, useState } from "react";
import savedCardIcon from "../../../../assets/payment/savecard.svg";
import arrow from "../../../../assets/profile/navigatenext.svg";
import { Sidebar } from "primereact/sidebar";
import angle_left from "../../../../assets/mobilemoviedetail/arrow-b.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import { postDataService } from "src/services/postApiService";
import master_card_icon from "../../../../assets/payment/master.png";
import visa_card_icon from "../../../../assets/payment/visa.png";

const ProfileSavedCard = () => {
  const theme = localStorage.getItem("theme");
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  const [visibleRight, setVisibleRight] = useState(false);
  const [favouriteCinemasList, setFavouriteCinemasList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedCinema, setSelectedCinema] = useState(null);
  const [loading, setLoading] = useState(false);
  const [savedCardList, setSavedCardList] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    getSavedCardList();
  }, []);

  const getSavedCardList = async () => {
    setLoading(true);
    const postData = {};
    try {
      const data = await postDataService(
        `v1/payment/juspay/card/list`,
        postData
      );
      const dt = data?.output;
      setSavedCardList(dt);
      setLoading(false);
      // console.log("savedCardList", dt);
    } catch (error) {
      console.error("Error fetching chain data:", error);
      setLoading(false);
    }
  };

  const deteteCard = async (data1) => {
    setLoading(true);
    const postData = {
      bookingId: "",
      key: data1?.card_token,
      mandate: true,
    };
    try {
      const data = await postDataService(
        `v1/payment/juspay/card/delete`,
        postData
      );
      const dt = data?.output;
      if (data.result === "success") {
        setShowAlert(false);
        getSavedCardList();
        swal({
          title: "Success",
          text: "Card succesfully deleted",
          icon: "success",
          timer: 2000,
          buttons: false,
        });
      } else if (data.result === "error" || data.result === "dialog") {
        console.log("error");
        swal({
          title: "Error",
          text: data.msg,
          icon: "error",
          timer: 2000,
          buttons: false,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching chain data:", error);
      setLoading(false);
    }
  };

  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };

  return (
    <>
      <div
        className="profile-img border-line-profile"
        onClick={() => {
          setVisibleRight(true);
        }}
      >
        <div className="profile-text profile-account">
          <h4>
            <span className="avtar">
              <img src={savedCardIcon} alt="" className="" />
            </span>
          </h4>
          <div
            className="profile-text-info"
            onClick={() => hitGa("saved_cards")}
          >
            <h2 onClick={() => hitGa("saved_cards")}>Saved Cards</h2>
          </div>
        </div>
        <div className="img-arrow" onClick={() => hitGa("saved_cards")}>
          <img src={arrow} alt="" className="" />
        </div>
      </div>
      <div>
        <Sidebar
          visible={visibleRight}
          showCloseIcon={false}
          className="watchlist-show-m"
          style={{ width: "100vw" }}
          position={"right"}
          onHide={() => {
            setVisibleRight(false);
          }}
          dismissable={false}
          blockScroll={true}
          fullScreen
        >
          <>
            <div
              className="bg-white show-in-mobile-view"
              style={{ position: "sticky", top: "0", zIndex: "999" }}
            >
              <div className="watch-list-mobile-box">
                <span onClick={() => setVisibleRight(false)}>
                  <img src={angle_left} alt="" className="" />{" "}
                </span>
                <h4>Saved Cards</h4>
              </div>
            </div>
            <div>
              {loading ? (
                <span>
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "2rem" }}
                  />
                </span>
              ) : (
                <>
                  {savedCardList?.length > 0 ? (
                    savedCardList?.map((item, idx) => {
                      return (
                        <div className="col-md-12" key={idx}>
                          <div className="border rounded d-flex justify-content-between my-1 p-2">
                            <div className="d-flex">
                              <span>
                                <img
                                  src={
                                    item.card_brand === "MASTERCARD"
                                      ? master_card_icon
                                      : visa_card_icon
                                  }
                                  alt=""
                                  width={70}
                                />
                              </span>
                              <span className="d-flex flex-column mx-2">
                                <span>{item?.name_on_card}</span>
                                <span>{item?.card_number}</span>
                              </span>
                            </div>{" "}
                            <div
                              className="pointer mx-1"
                              onClick={() => {
                                setShowAlert(true);
                                setSelectedCard(item);
                              }}
                            >
                              <i className="pi pi-times" />{" "}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (        
                      <span className="mx-3 my-2">No saved card found</span>
                  )}
                </>
              )}
            </div>
            <div>
              <SweetAlert
                warning
                show={showAlert}
                title={"Are you sure?"}
                onConfirm={() => deteteCard(selectedCard)}
                onCancel={() => setShowAlert(false)}
                confirmBtnText="Yes"
                confirmBtnCssClass={
                  theme === "inoxTheme"
                    ? "confirm-btn-inox border-0"
                    : "confirm-btn-pvr border-0"
                }
                showCancel={true}
                showConfirm={true} //not Display the "OK" button
                timeOut={3000}
                className="sucess-popup"
              >
                {"You want to remove from favourire list!"}
              </SweetAlert>
            </div>
          </>
        </Sidebar>
      </div>
    </>
  );
};

export default ProfileSavedCard;
