import miv_inox from "../assets/default-images/vertical-poster-inox.png";
import miv_pvr from "../assets/default-images/vertical-poster-pvr.svg";
import mih_inox from "../assets/default-images/horizontal-poster-inox.png";
import mih_pvr from "../assets/default-images/horizontal-poster-pvr.svg";
import food_img from '../assets/default-images/food-inox.svg';
import noImg from "../assets/food/noimage250.png";
const theme = localStorage.getItem("theme");

export const handleMivImageError = (e) => {
  e.target.src = theme === "pvrTheme" ? miv_pvr : miv_inox;
};
// onError={handleMivImageError}  //=== add this line as img atribute for miv placeholder image.

export const handleMiHImageError = (e) => {
  e.target.src = theme === "pvrTheme" ? mih_pvr : mih_inox;
};
// onError={handleMiHImageError}  //=== add this line as img atribute for mih placeholder image.


export const handleFoodImageError = (e) => {
  e.target.src = noImg;
};
// onError={handleFoodImageError}  //=== add this line as img atribute for food image.
