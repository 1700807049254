import styled from "styled-components";
import { Button } from 'primereact/button';
const theme = localStorage.getItem('theme');

export const ThemeDiv = styled.div`
  color: ${(props) => props.theme.colors.txtColor} !important;
  background-color: ${(props) => props.theme.colors.bgColor} !important;
`;
export const ThemeLightDiv = styled.div`
  background-color: ${(props) => props.theme.colors.bgColorLight} !important;
`;
 
export const ProceedButton = styled.button`
  color: ${(props) => props.theme.colors.txtColor} !important;
  background-color: ${(props) => props.theme.colors.bgColor} !important;
`;

export const BorderLightButton = styled.span`
  background-color: ${(props) => theme === 'inoxTheme' ? '' : props.theme.colors.bgColorLight} !important;
  border: 1px solid ${(props) => theme === 'inoxTheme' ? '' : props.theme.colors.bgColor} !important
`; 

export const ProceedLightButton = styled.button`
  background-color: ${(props) => props.theme.colors.bgColorLight} !important;
`; 

export const ViewDetailButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.bgColor} !important;
  color: #000;
  border: none;
  border-radius: 7px;
  padding: 13px 28px;
  font-family: 'Poppins';

  &:hover {
    background-color: ${(props) => props.theme.colors.bgColor} !important;
    color: ${(props) => props.theme.colors.txtColor} !important;
    cursor: pointer;
  }
`;


export const SaveButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.bgColor} !important;
  color: ${(props) => props.theme.colors.txtColor} !important;
  border: none;
  border-radius: 7px;
  padding: 13px 28px;
  font-family: 'Poppins';

  &:hover {
    background-color: ${(props) => props.theme.colors.bgColor} !important;
    color: ${(props) => props.theme.colors.txtColor} !important;
    cursor: pointer;
  }
`;

export const CancelButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.cancelBtnBg};
  color: ${(props) => props.theme.colors.buttonTxt};
  border: none;
  border-radius: 7px;
  padding: 10px 20px;

  &:hover {
    background-color: ${(props) => props.theme.colors.cancelBtnHoverBg} !important;
    cursor: pointer;
  }
`;

export const Themespan = styled.span`
  color: ${(props) => props.theme.colors.bgColor} !important;
`;
